import './RoundedContainer.scss'

import React from 'react'

type Props = {
  children?: React.ReactNode
}

export const RoundedContainer: React.FC<Props> = ({ children }) => {
  return <div className='rounded-container'>{children}</div>
}

export default RoundedContainer
