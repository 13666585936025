import { Button } from 'antd'
import moment from 'moment'
import React, { FC, ReactElement } from 'react'
import { connect } from 'react-redux'

import Icon from '@ant-design/icons'

import { groupingByTime } from '../../containers/header/notifications/NotificationCenter'
import { notificationsSelector } from '../../containers/header/notifications/notificationSelector'
import { INotification } from '../../interfaces/Notification'
import { IState } from '../../interfaces/Product'
import { ICONS_MAP } from '../header/notifications/Note'

interface ProductNotificationsProps {
  notifications: INotification[]
  apps: number[]
  productName: string
  handlerNotificationClick: (args1: INotification) => void
  handleMarkAllSeen: (args1: number[]) => void
  markAllSeenLoading: boolean
}

const ACTION_TYPE_MAP = {
  critical_insight: 'Product Intelligence Discovery',
  new_release: 'Product Intelligence Discovery',
  new_discovery_similar_to_marked: 'Product Intelligence Discovery',
  monthly_mass_marketing: 'Marketing Campaign Discovery',
  daily_benchmarking: 'Product Benchmark Discovery'
}

const ProductNotifications: FC<ProductNotificationsProps> = ({
  notifications,
  apps,
  productName,
  handlerNotificationClick,
  handleMarkAllSeen,
  markAllSeenLoading
}): ReactElement | null => {
  const filteredNotes = notifications.filter(
    (n: INotification) => n.app_id && apps.includes(n.app_id)
  )

  if (filteredNotes.length === 0) {
    return null
  }

  const groupedNotes = groupingByTime(filteredNotes)

  return (
    <div className='stacked-bar-container'>
      <h3>
        Recent Activity
        {filteredNotes.filter(n => !n.app_read_at).length > 0 && (
          <span className='prd-unread-notes-count'>
            {filteredNotes.filter(n => !n.app_read_at).length}
          </span>
        )}
        <div className='prd-mark-all-wrap'>
          <Button
            className='ant-btn-light btn-ntf-mark-all-seen'
            data-testid='mark-all-as-seen'
            onClick={() => handleMarkAllSeen(filteredNotes.map(n => n.id))}
          >
            Mark all as Read
            {markAllSeenLoading && <Icon type='loading' />}
          </Button>
        </div>
      </h3>

      <div className='prd-notes-header'>
        {['', 'App', 'Action', 'Time', ''].map((title: string) => (
          <div key={title}>{title}</div>
        ))}
      </div>
      <div className='products-notifications'>
        {groupedNotes.map((grp, i) => (
          <div className={`notification-sub-list ${grp.className}`} key={i}>
            {grp.data.length > 0 ? (
              <h4 className='prd-grp-notes-header'>{grp.label}</h4>
            ) : (
              ''
            )}
            {grp.data.map((note: INotification) => (
              <div
                key={note.id}
                className={`products-note ${
                  !note.app_read_at ? 'unread' : 'read'
                } ${note.link ? 'clickable' : ''}`}
                onClick={() =>
                  note.link ? handlerNotificationClick(note) : ''
                }
              >
                <div></div>
                <div>
                  <img className='prd-note-icon' src={note.icon} />
                  {
                    //@ts-ignore
                    ICONS_MAP[note.icon_platform_overlay]
                  }
                  <span className='prd-note-product'>{productName}</span>(
                  {note.icon_platform_overlay})
                </div>
                <div>
                  <div className='prd-note-descr'>{note.description}</div>
                  <div className='prd-note-type'>
                    {
                      ACTION_TYPE_MAP[
                        note.action_type as keyof typeof ACTION_TYPE_MAP
                      ]
                    }
                  </div>
                </div>
                <div>
                  {grp.humanize
                    ? `${moment
                        .duration(moment().diff(moment(note.time)))
                        .humanize()} ago`
                    : moment(note.time).format('ll')}
                </div>
                <div></div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state: IState) => {
  return {
    notifications: notificationsSelector('product')(state)
  }
}

export default connect(mapStateToProps, {})(ProductNotifications)
