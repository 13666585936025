import { MARKETING_INSIGHT_TYPES } from 'containers/MarketingCampaings/utils/conts'
import {
  darkenColor,
  generateBrightTransparentColor
} from 'containers/MarketingCampaings/utils/helpers'
import {
  UserRoleType,
  UserType
} from 'containers/MarketingCampaings/utils/types'
import {
  GET_MARKETING_APPS_QUERY,
  GET_MARKETING_DATA_QUERY,
  GET_MARKETING_PERSONAS_QUERY,
  GET_PROMO_DAYS_OF_WEEK,
  GET_PROMO_TIME_OF_DAY
} from 'gql/marketing/overview'
import _, { groupBy } from 'lodash'
import moment, { Moment } from 'moment'
import { useEffect, useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { Channels } from '../utils/consts'
import {
  createHexColorChangeFunc,
  DayToIndex,
  getPersonaTitle,
  TimeOfDayToIndex
} from '../utils/helpers'
import { App } from '../utils/types'
import { HandleOverviewFilterChangeType } from './useOverviewFilters'

type OverviewFilters = {
  appIds?: number[]
  channelIds?: string[]
  daysAgo?: number
  customRange?: boolean
  customRangeTime?: Moment[]
  personaIds?: number[]
  personaRoleIds?: number[]
}

type Props = {
  filters: OverviewFilters
  handleUpdateFilters: HandleOverviewFilterChangeType
}

export const useOverviewApps = () => {
  const { data: marketingAppsData, loading: appsLoading } = useQuery(
    GET_MARKETING_APPS_QUERY
  )

  const apps: App[] = useMemo(
    () => marketingAppsData?.marketing?.apps || [],
    [marketingAppsData]
  )

  return { apps, appsLoading }
}

export const useOverviewData = ({ filters, handleUpdateFilters }: Props) => {
  // Apps
  const { apps, appsLoading } = useOverviewApps()

  const customerApp = useMemo(
    () => apps?.find(app => app.isCustomerOwnApp),
    [apps]
  )

  const selectedApps = useMemo(() => {
    if (!filters.appIds?.length) {
      return apps
    }

    return apps.filter(app => filters.appIds?.includes(app.id))
  }, [apps, filters.appIds])

  // Filters
  const startTimeGte = filters?.customRange
    ? filters.customRangeTime?.[0]
    : moment().subtract(filters.daysAgo || 0, 'days')
  const startTimeLt = filters.customRange
    ? filters.customRangeTime?.[1]
    : moment().add(1, 'day')

  const appIds = filters.appIds?.length
    ? selectedApps.map(app => app.id)
    : undefined
  const channelIds = filters.channelIds?.length
    ? filters.channelIds
    : Object.values(MARKETING_INSIGHT_TYPES)

  const { personaIds, personaRoleIds } = filters

  const { data: personasData, loading: personasLoading } = useQuery(
    GET_MARKETING_PERSONAS_QUERY,
    {
      variables: {
        appIds
      },
      skip: !appIds
    }
  )

  // Marketing Charts
  const { data: appsChartsData, loading: chartsLoading } = useQuery(
    GET_MARKETING_DATA_QUERY,
    {
      variables: {
        appIds,
        insightTypes: channelIds,
        startTimeGte: startTimeGte?.format('YYYY-MM-DD'),
        startTimeLt: startTimeLt?.format('YYYY-MM-DD'),
        personaIds,
        personaRoleIds
      },
      nextFetchPolicy: 'network-only'
    }
  )

  // Marketing Aggressiveness
  const appsWithInsightsCounts = useMemo(() => {
    const appsInsightsCount =
      appsChartsData?.marketing?.overview?.appInsights || []

    return selectedApps
      .map(app => {
        const appInsightsCount = appsInsightsCount.find(
          (appInsightsCount: any) => appInsightsCount.appId === app.id
        )

        return {
          ...app,
          insightsCount: parseInt(appInsightsCount?.count) || 0
        }
      })
      .filter(({ insightsCount }: any) => insightsCount > 0)
      .sort((a, b) => {
        return b.insightsCount - a.insightsCount
      })
  }, [appsChartsData, selectedApps])

  // Campaign Channels Breakdown
  const appsByInsightType = useMemo(() => {
    const appsInsightTypes =
      appsChartsData?.marketing?.overview?.appInsightTypes || []

    return selectedApps
      .map(app => {
        const appInsightTypes = appsInsightTypes.filter(
          (appInsightType: any) => appInsightType.appId === app.id
        )

        const totalAppInsights = appInsightTypes.reduce(
          (acc: number, { count }: any) => acc + parseInt(count),
          0
        )

        const hasPrevPeriod = appInsightTypes.some(
          ({ prevPeriod }: any) => prevPeriod !== null
        )

        const totalPrevPeriod = appInsightTypes.reduce(
          (acc: number, { prevPeriod }: any) =>
            acc + parseInt(prevPeriod?.count || 0),
          0
        )

        const appInsights = appInsightTypes.map((appInsightType: any) => {
          return {
            ...appInsightType,
            count: parseInt(appInsightType.count),
            percentage: Math.round(
              (parseInt(appInsightType.count) / totalAppInsights) * 100
            )
          }
        })

        return {
          ...app,
          insightTypes: appInsights,
          totalAppInsights,
          // We need it to be nullable so we can hide the stats in case there
          // isn't enough data on the server
          totalPrevPeriod: hasPrevPeriod ? totalPrevPeriod : null
        }
      })
      .filter(({ insightTypes }: any) => insightTypes.length > 0)
      .sort((a, b) => {
        if (a.totalAppInsights === b.totalAppInsights) {
          return 0
        }

        if (a.totalAppInsights > b.totalAppInsights) {
          return -1
        }

        return 1
      })
  }, [appsChartsData, selectedApps])

  // Promotions Calendar
  const appsByDateAndType = useMemo(() => {
    const appInsightTypesByDates =
      appsChartsData?.marketing?.overview?.appInsightTypesByDates || []

    const mapByDateAndType = new Map()

    appInsightTypesByDates.forEach(
      ({ date, appId, insightType, count }: any) => {
        if (count < 1) return

        const dateKey = `${moment(date).format('YYYY-MM-DD')}-${appId}`

        if (!mapByDateAndType.has(dateKey)) {
          mapByDateAndType.set(dateKey, {})
        }

        const dateData = mapByDateAndType.get(dateKey)
        mapByDateAndType.set(dateKey, {
          ...dateData,
          date,
          appId,
          [insightType]: count
        })
      }
    )

    const appsByDateAndType = _.sortBy(
      Array.from(mapByDateAndType.values()),
      'date'
    )

    return appsByDateAndType.map((appByDateAndType: any) => ({
      ...appByDateAndType,
      app: apps.find((app: any) => app.id === appByDateAndType.appId),
      totalInsights: Object.entries(appByDateAndType).reduce(
        (acc: number, [key, value]: any) => {
          // SQUAD2-1244: remove in app-banners from timeline chart
          if (key === Channels.inapp_campaign) return acc

          if (Object.values(Channels).includes(key)) {
            return acc + value
          }

          return acc
        },
        0
      )
    }))
  }, [appsChartsData, selectedApps])

  // Promo Type
  const focusAreas = useMemo(() => {
    const appsFocusAreas =
      appsChartsData?.marketing?.overview?.appFocusAreas || []

    const focusAreasMap = new Map()

    appsFocusAreas.forEach(({ focusArea }: any) => {
      if (!focusArea) return

      // aggregate focus areas by name
      if (!focusAreasMap.has(focusArea.name)) {
        focusAreasMap.set(focusArea.name, {
          ids: [focusArea.id]
        })
      }

      const focusAreaData = focusAreasMap.get(focusArea.name)
      focusAreasMap.set(focusArea.name, {
        ...focusAreaData,
        ...focusArea,

        ids: [...new Set([...focusAreaData.ids, focusArea.id])]
      })
    })

    const generateHexColor = createHexColorChangeFunc()

    return Array.from(focusAreasMap.values()).map((focusArea: any) => {
      const { color, isLight } = generateHexColor()
      const textColor = isLight ? '#23146E' : '#ffffff'

      return {
        id: focusArea.id,
        name: focusArea.name,
        color,
        textColor,

        ids: focusArea.ids
      }
    })
  }, [appsChartsData, selectedApps])

  const appsByFocusArea = useMemo(() => {
    const appFocusAreas =
      appsChartsData?.marketing?.overview?.appFocusAreas || []

    const appsMap = new Map()

    appFocusAreas.forEach(({ appId, focusArea, count }: any) => {
      if (!appId || !focusArea || count < 1) return

      if (!appsMap.has(appId)) {
        appsMap.set(appId, {
          byFocusArea: []
        })
      }

      const focusAreaItem = focusAreas.find((focusAreaItem: any) =>
        focusAreaItem.ids.includes(focusArea.id)
      )

      if (!focusAreaItem) return

      const appData = appsMap.get(appId)

      const focusAreaInAppData = appData.byFocusArea.find(
        (focusAreaInData: any) => focusAreaInData.id === focusArea.id
      )

      const newFocusArea = {
        ...focusAreaItem,
        count: focusAreaInAppData ? focusAreaInAppData.count + count : count
      }

      const byFocusArea = appData.byFocusArea.filter(
        (focusAreaInData: any) => focusAreaInData.id !== focusArea.id
      )

      appsMap.set(appId, {
        ...appData,
        appId,
        byFocusArea: [...byFocusArea, newFocusArea]
      })
    })

    return Array.from(appsMap.values()).map((appByFocusAreas: any) => ({
      ...appByFocusAreas,
      byFocusArea: _.sortBy(appByFocusAreas.byFocusArea, 'name'),
      app: apps.find((app: any) => app.id === appByFocusAreas.appId)
    }))
  }, [appsChartsData, selectedApps, focusAreas])

  // Promotions per User Type
  const personas = useMemo(() => {
    const appPersonas = appsChartsData?.marketing?.overview?.appPersonas || []
    const personasMap = new Map()

    appPersonas.forEach(({ persona }: any) => {
      if (!persona) return
      const roleConvertedPersona = {
        ...persona
      }

      const personaKey = getPersonaTitle(roleConvertedPersona)

      if (!personasMap.has(personaKey)) {
        personasMap.set(personaKey, {
          ids: [roleConvertedPersona.id]
        })
      }

      const personaData = personasMap.get(personaKey)
      personasMap.set(personaKey, {
        ...personaData,
        ...roleConvertedPersona,

        ids: [...new Set([...personaData.ids, roleConvertedPersona.id])]
      })
    })

    const generateHexColor = createHexColorChangeFunc()

    return Array.from(personasMap.values()).map((persona: any) => {
      const { color, isLight } = generateHexColor()
      const textColor = isLight ? '#23146E' : '#ffffff'

      return {
        id: persona.id,
        name: getPersonaTitle(persona),
        color,
        textColor,
        ids: persona.ids
      }
    })
  }, [appsChartsData, selectedApps])

  const appsByPersonas = useMemo(() => {
    const appPersonas = appsChartsData?.marketing?.overview?.appPersonas || []

    const appsMap = new Map()

    appPersonas.forEach(({ appId, persona, count }: any) => {
      if (!appId || !persona || count < 1) return

      if (!appsMap.has(appId)) {
        appsMap.set(appId, {
          byPersona: []
        })
      }

      const personaItem = personas.find((personaItem: any) =>
        personaItem.ids.includes(persona.id)
      )

      if (!personaItem) return

      const appData = appsMap.get(appId)

      const personaInAppData = appData.byPersona.find(
        (personaInData: any) => personaInData.id === persona.id
      )

      const newPersona = {
        ...personaItem,
        count: personaInAppData ? personaInAppData.count + count : count
      }

      const byPersona = appData.byPersona.filter(
        (personaInData: any) => personaInData.id !== newPersona.id
      )

      appsMap.set(appId, {
        ...appData,
        appId,
        byPersona: [...byPersona, newPersona]
      })
    })

    return Array.from(appsMap.values()).map((appByPersonas: any) => ({
      ...appByPersonas,
      app: apps.find((app: any) => app.id === appByPersonas.appId)
    }))
  }, [personas, appsChartsData, selectedApps])

  // Mass vs Personalized
  const appsByCampaignTypes = useMemo(() => {
    const appCampaignTypes =
      appsChartsData?.marketing?.overview?.appCampaignTypes || []

    const appsMap = new Map()

    appCampaignTypes.forEach(({ appId, campaignType, count }: any) => {
      if (!appId || !campaignType || count < 1) return
      if (campaignType === 'unknown') return

      if (!appsMap.has(appId)) {
        appsMap.set(appId, {
          byCampaignType: []
        })
      }

      const appData = appsMap.get(appId)
      appsMap.set(appId, {
        ...appData,
        appId,
        byCampaignType: [...appData.byCampaignType, { campaignType, count }]
      })
    })

    return Array.from(appsMap.values()).map((appByCampaignTypes: any) => ({
      ...appByCampaignTypes,
      app: apps.find((app: any) => app.id === appByCampaignTypes.appId)
    }))
  }, [appsChartsData, selectedApps])

  useEffect(() => {
    if (!apps) return

    handleUpdateFilters({
      app_id: apps.map(app => app.id.toString())
    })

    handleUpdateFilters({ channel_id: Object.values(MARKETING_INSIGHT_TYPES) })
  }, [apps])

  const promoDaysOfWeekData = useQuery(GET_PROMO_DAYS_OF_WEEK, {
    variables: {
      appIds,
      insightTypes: channelIds,
      startTimeGte: startTimeGte?.format('YYYY-MM-DD'),
      startTimeLt: startTimeLt?.format('YYYY-MM-DD'),
      personaIds,
      personaRoleIds
    }
  })

  const promoDaysOfWeek = useMemo(() => {
    if (!promoDaysOfWeekData.data || !selectedApps?.length) {
      return []
    }

    const data =
      promoDaysOfWeekData.data?.marketing?.overview?.appInsightTypesByWeekDays

    const groupedByAppId = groupBy(data, 'appId')

    return Object.entries(groupedByAppId).map(([appId, values]) => {
      const foundApp = selectedApps.find(app => app.id.toString() === appId)

      if (!foundApp) {
        throw new Error(`App id ${appId} not found`)
      }

      const weekdayValues = [0, 0, 0, 0, 0, 0, 0]

      values.forEach(value => {
        const weekdayIndex = DayToIndex(value.weekDay)

        weekdayValues[weekdayIndex] += value.count
      })

      return {
        app: foundApp,
        counts: weekdayValues
      }
    })
  }, [promoDaysOfWeekData, selectedApps])

  const promoTimeOfDayData = useQuery(GET_PROMO_TIME_OF_DAY, {
    variables: {
      appIds,
      insightTypes: channelIds,
      startTimeGte: startTimeGte?.format('YYYY-MM-DD'),
      startTimeLt: startTimeLt?.format('YYYY-MM-DD'),
      personaIds,
      personaRoleIds
    }
  })

  const promoTimeOfDay = useMemo(() => {
    if (!promoTimeOfDayData.data || !selectedApps?.length) {
      return []
    }

    const data =
      promoTimeOfDayData.data?.marketing?.overview?.appInsightTypesByDayTimes

    const groupedByAppId = groupBy(data, 'appId')

    return Object.entries(groupedByAppId).map(([appId, values]) => {
      const foundApp = selectedApps.find(app => app.id.toString() === appId)

      if (!foundApp) {
        throw new Error(`App id ${appId} not found`)
      }

      const timeValues = [0, 0, 0, 0, 0, 0]

      values.forEach(value => {
        const timeOfDayIndex = TimeOfDayToIndex(value.dayTime)

        timeValues[timeOfDayIndex] += value.count
      })

      return {
        app: foundApp,
        counts: timeValues
      }
    })
  }, [promoTimeOfDayData, selectedApps])

  const userTypes: UserType[] = useMemo(
    () =>
      !personasLoading && personasData
        ? personasData?.marketing?.personas?.map((persona: UserType) => {
            const bgColor = generateBrightTransparentColor(0.3)
            const textColor = darkenColor(bgColor)

            return {
              ...persona,
              bgColor,
              textColor
            }
          }) ?? []
        : [],
    [personasData]
  )

  const userRoleTypes: UserRoleType[] = useMemo(() => {
    if (!personasLoading && personasData) {
      const roleMap = new Map<number, UserRoleType>()
      const personas = personasData?.marketing?.personas ?? []
      for (const persona of personas) {
        for (const role of persona.roles) {
          if (!roleMap.has(role.id)) {
            const bgColor = generateBrightTransparentColor(0.3)
            const textColor = darkenColor(bgColor)
            roleMap.set(role.id, {
              ...role,
              description: persona.description ?? '',
              bgColor,
              textColor
            })
          } else {
            const roleMapItem = roleMap.get(role.id) as UserRoleType
            roleMapItem.description =
              roleMapItem.description || persona.description || ''
          }
        }
      }
      const result = Array.from(roleMap.values())
      return result
    }
    return []
  }, [personasData])

  const isLoading =
    personasLoading ||
    chartsLoading ||
    appsLoading ||
    promoDaysOfWeekData.loading ||
    promoTimeOfDayData.loading

  return {
    apps,
    selectedChannels: channelIds,
    appsWithInsightsCounts,
    appsByInsightType,
    appsByDateAndType,
    appsByFocusArea,
    focusAreas,
    personas,
    appsByPersonas,
    customerApp,
    appsByCampaignTypes,
    isLoading,
    promoDaysOfWeek,
    promoTimeOfDay,
    userTypes,
    userRoleTypes
  }
}
