import {
  darkenColor, generateBrightTransparentColor
} from 'containers/MarketingCampaings/utils/helpers';
import {
  GET_AB_TEST_DATA_ID_QUERY, GET_INSIGHT_BY_ID_QUERY, GET_INSIGHT_DATA_BY_ID, GET_INSIGHTS,
  GET_PERSONAS, GET_PRODUCTS, GET_RELEASES
} from 'gql/intel';
import { useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { INTEL_INSIGHT_TYPES, INTEL_INSIGHT_TYPES_SELECT, ITEMS_PER_PAGE } from '../utils/consts';
import { CardInsight, Insight, Persona, Product, Release, SideFilterCounts } from '../utils/types';
import { HandleIntelFilterChangeType, Modes } from './useIntelFilters';

const defaultInsightTypes = Object.keys(INTEL_INSIGHT_TYPES_SELECT);

const INTEL_INSIGHT_TYPES_TO_TITLE = {
  [INTEL_INSIGHT_TYPES_SELECT.ab_test]: "Experiments",
  [INTEL_INSIGHT_TYPES_SELECT.features]: "Features",
  [INTEL_INSIGHT_TYPES_SELECT.screen_change]: "UI Changes",
  [INTEL_INSIGHT_TYPES_SELECT.indication]: "Future Prediction",
};

type Props = {
  handleChangeFilter: HandleIntelFilterChangeType;
  filters: {
    insightId: number;
    platforms: string[];
    productIds: number[];
    startTimeGte: string;
    startTimeLt: string;
    abTestStatuses: string[];
    features: string[];
    type: string;
    personaIds: number[];
    offset: number;
    limit: number;
    mode: Modes;
    releaseId: string;
  };
};

type FiltersTypesOptions = {
  features?: string[];
  abTestStatuses?: string[];
};

const getTypesForCurrentTypeFilter = (
  type: string,
  options: FiltersTypesOptions
) => {
  let abTestStatuses = options.abTestStatuses || [];

  if (abTestStatuses.includes("abandoned")) {
    abTestStatuses = [...abTestStatuses, "abandoned_no_data"];
  }

  if (!type) {
    return {
      types: Object.values(INTEL_INSIGHT_TYPES),
      abTestStatuses,
    };
  }

  let types: string[] = [];

  if (type !== INTEL_INSIGHT_TYPES_SELECT.ab_test) {
    abTestStatuses = [];
  }

  // in case features is not selected need to remove new_feature and removed_feature
  if (type !== INTEL_INSIGHT_TYPES_SELECT.features) {
    types = types.filter(
      (type) => type !== "new_feature" && type !== "removed_feature"
    );
    types.push(type);
  } else {
    // push the selected ones
    if (options.features?.length) {
      options.features.forEach((feature) => {
        types.push(
          feature === "new_feature" ? "new_feature" : "removed_feature"
        );
      });
    }
    // if no selected push all
    else {
      types.push("new_feature", "removed_feature");
    }
  }

  return {
    types,
    abTestStatuses,
  };
};

export const useIntelData = ({
  filters: {
    insightId,
    platforms,
    productIds,
    startTimeGte,
    startTimeLt,
    abTestStatuses,
    features,
    type,
    personaIds,
    limit,
    offset,
    mode,
    releaseId,
  },
  handleChangeFilter,
}: Props) => {
  const { data: productsData, loading: isProductsLoading } = useQuery(GET_PRODUCTS, {
    variables: {
      hasInsights: true,
    },
  });

  const products: Product[] = useMemo(() => {
    if (!productsData) {
      return [];
    }

    return productsData.intel.products.map(({ id, name, icon, apps }: any) => ({
      id,
      name,
      icon,
      apps: apps.map(({ id, platform, insightsCnt }: any) => ({
        id,
        platform,
        insightsCnt,
      })),
    }));
  }, [productsData]);

  const releaseAppId = useMemo(() => {
    if (mode !== "by_release") return;

    const platform = platforms[0];
    const product = products?.find(({ id }) => productIds.includes(id));

    return product?.apps.find(({ platform: appPlatform }) => {
      return appPlatform.toLowerCase() === platform.toLowerCase();
    })?.id;
  }, [mode, products, productIds, platforms]);

  const selectedAppIdsByPlatformAndProduct: number[] = useMemo(() => {
    const filteredProducts = products.filter(({ id }) =>
      productIds.length > 0 ? productIds.includes(id) : true
    );
    const selectedAppIds: number[] = [];

    filteredProducts.forEach(({ apps }) => {
      apps.forEach(({ id, platform }) => {
        if (!platforms.length) {
          selectedAppIds.push(id);
        }

        if (platforms.includes(platform.toLocaleLowerCase())) {
          selectedAppIds.push(id);
        }
      });
    });

    return selectedAppIds;
  }, [platforms, productIds, products]);

  const { data: personasData, loading: isAllPersonasLoading } = useQuery(GET_PERSONAS, {
    variables: {
      appIds: selectedAppIdsByPlatformAndProduct,
    },
    skip: !selectedAppIdsByPlatformAndProduct.length,
  });

  const personas: Persona[] = useMemo(() => {
    if (!personasData) {
      return [];
    }

    return personasData.intel.personas.map((persona: any) => {
      const bgColor = generateBrightTransparentColor(0.3);
      const textColor = darkenColor(bgColor);

      return {
        ...persona,
        bgColor,
        textColor,
      };
    });
  }, [personasData]);

  const typesFilters = getTypesForCurrentTypeFilter(type, {
    features,
    abTestStatuses,
  });

  const { data: intelInsightsData, previousData: intelInsightsPrevData, loading: isInsightsLoading } =
    useQuery(GET_INSIGHTS, {
      variables: {
        appIds: selectedAppIdsByPlatformAndProduct,
        ...(mode !== "by_release" ? { startTimeGte, startTimeLt } : null),
        ...(typesFilters.types.length > 0 ? { types: typesFilters.types } : {}),
        ...(typesFilters.abTestStatuses.length > 0
          ? { abTestStatuses: typesFilters.abTestStatuses }
          : {}),
        ...(personaIds.length > 0 ? { personaIds } : {}),
        offset,
        limit,
        ...(mode === "by_release" && releaseId
          ? { releaseIds: [parseInt(releaseId)] }
          : {}),
      },
    });

  const intelInsightsDataChanged = intelInsightsData
    ? intelInsightsData
    : intelInsightsPrevData;

  const summaryByType = useMemo(() => {
    const summary =
      intelInsightsDataChanged?.intel?.insightPage?.summary?.byType ?? [];

    return defaultInsightTypes.map((type) => {
      const currentType =
        type === INTEL_INSIGHT_TYPES_SELECT.features ? "feature" : type;
      const summaryItem = summary.find((item: any) =>
        item.type.includes(currentType)
      );

      return {
        id: type,
        title: INTEL_INSIGHT_TYPES_TO_TITLE[type],
        amount: summaryItem?.count ?? 0,
      };
    });
  }, [intelInsightsDataChanged]);

  const experimentsFilterCounts: SideFilterCounts[] = useMemo(() => {
    const summary =
      intelInsightsDataChanged?.intel?.insightPage?.summary?.byAbTestStatus ??
      [];

    let summaryFiltered = summary.filter(
      (s: any) => s.abTestStatus !== "abandoned_no_data"
    );

    if (summary.find((s: any) => s?.abTestStatus === "abandoned_no_data")) {
      summaryFiltered = summaryFiltered.filter(
        (s: any) => s?.abTestStatus !== "abandoned"
      );
      const abandonedCount =
        summary.find((s: any) => s?.abTestStatus === "abandoned")?.count || 0;
      const noDataCount =
        summary.find((s: any) => s?.abTestStatus === "abandoned_no_data")
          ?.count || 0;

      summaryFiltered.push({
        abTestStatus: "abandoned",
        count: abandonedCount + noDataCount,
      });
    }

    return summaryFiltered.map((status: any) => ({
      name: status.abTestStatus,
      count: status.count,
    }));
  }, [intelInsightsDataChanged]);

  const featuresFilterCounts: SideFilterCounts[] = useMemo(() => {
    const summary =
      intelInsightsDataChanged?.intel?.insightPage?.summary?.byType ?? [];

    return summary
      .filter(({ type }: any) => type.includes("feature"))
      .map((byType: any) => ({
        name: byType.type,
        count: byType.count,
      }));
  }, [intelInsightsDataChanged]);

  const insights: CardInsight[] = useMemo(() => {
    const insights =
      intelInsightsDataChanged?.intel?.insightPage?.insights ?? [];

    return insights.map((insight: any) => ({
      ...insight,
    }));
  }, [intelInsightsDataChanged]);

  const { data: selectedInsightData } = useQuery(GET_INSIGHT_BY_ID_QUERY, {
    variables: {
      insightId,
    },
    skip: !insightId,
  });

  const { data: selectedInsightAdditionalData } = useQuery(
    GET_INSIGHT_DATA_BY_ID,
    {
      variables: {
        insightId,
      },
      skip: !insightId,
    }
  );

  const selectedInsight = useMemo(() => {
    if (!selectedInsightData || !selectedInsightAdditionalData) {
      return null;
    }

    const { app, personas } = selectedInsightAdditionalData.intel.insight;

    return {
      ...selectedInsightData.insight.data,
      app,
      personas,
    } as Insight;
  }, [selectedInsightData, selectedInsightAdditionalData]);

  const { data: abTestData } = useQuery(GET_AB_TEST_DATA_ID_QUERY, {
    variables: {
      abTestId: selectedInsight?.ab_test_id,
    },
    skip: !selectedInsight?.ab_test_id,
  });

  const abTest = useMemo(() => {
    if (!abTestData) {
      return null;
    }

    return abTestData.ab_test.data;
  }, [abTestData]);

  const { data: releasesData, loading: isReleaseLoading } = useQuery(GET_RELEASES, {
    variables: {
      appId: releaseAppId,
    },
    skip: !releaseAppId,
  });

  const releases: Release[] = useMemo(() => {
    if (!releasesData) {
      return [];
    }

    return releasesData.releases.data;
  }, [releasesData]);

  const hasMoreInsights = useMemo(() => {
    if (!intelInsightsDataChanged || !summaryByType) return false;

    const total =
      intelInsightsDataChanged?.intel?.insightPage?.summary.totalCount;

    const isNoFilterSelected =
      !type && !features?.length && !abTestStatuses?.length;

    if (isNoFilterSelected) return total > limit;

    const inCurrentCategoryType = summaryByType?.find(({ id }) => id === type);

    const insightCount = inCurrentCategoryType
      ? inCurrentCategoryType.amount
      : total;

    return insightCount > limit;
  }, [offset, limit, intelInsightsDataChanged, summaryByType]);

  const fetchMoreInsights = () => {
    if (!intelInsightsDataChanged || !hasMoreInsights) return;

    handleChangeFilter("limit", (limit + ITEMS_PER_PAGE).toString());
  };

  // after all data is initially loaded set filters to selected
  const { loading: isPersonasLoading } = useQuery(GET_PERSONAS, {
    variables: {
      appIds: products.map(({ apps }) => apps.map(({ id }) => id)).flat(),
    },
    skip: !products.length,
  });

  useEffect(() => {
    if (!products) return;

    // Temporary disable user types filter SQUAD2-1329
    // const personasIds = initialPersonas.intel.personas.map(
    //   ({ id }: { id: number }) => id.toString()
    // );
    const allProductIds = products.map(({ id }) => id.toString());
    const selectedProductIds = productIds.map((id) => id.toString());

    handleChangeFilter("features", ["new_feature", "removed_feature"]);
    handleChangeFilter("experiments", ["active", "integrated", "abandoned"]);
    if (!platforms.length) {
      handleChangeFilter("platform", ["ios", "android", "web"]);
    }

    // Temporary disable user types filter SQUAD2-1329
    // handleChangeFilter("persona_id", personasIds);
    handleChangeFilter(
      "product_id",
      selectedProductIds.length ? selectedProductIds : allProductIds
    );

    if (
      mode === "by_release" &&
      Array.isArray(releases) &&
      releases.length > 0
    ) {
      handleChangeFilter(
        "release_id",
        releases[releases.length - 1]?.id.toString()
      );
    }
  }, [products, releases, mode]);

  const isLoading = [isInsightsLoading, isReleaseLoading, isPersonasLoading, isProductsLoading, isAllPersonasLoading].some(x => x)

  return {
    insights,
    selectedInsight,
    abTest,
    products,
    personas,
    summaryByType,
    experimentsFilterCounts,
    featuresFilterCounts,
    hasMoreInsights,
    fetchMoreInsights,
    releases,
    isLoading,
  };
};
