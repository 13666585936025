import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  getBiWeeklyHighlights,
  setRateBiWeeklyHighlights
} from '../../redux/acts/biweekly'
import SneakPeakInsightModal from '../../components/sneakPeakDiscoveryModal/SneakPeakInsightModal'

import share_ico from '../../assets/images/new_design/bi-weekly-share.svg'

import { Button } from 'antd'
import castle_ico from '../../assets/images/new_design/rate-modal/castle.svg'
import cloud_ico from '../../assets/images/new_design/rate-modal/cloud.svg'
import flag_ico from '../../assets/images/new_design/rate-modal/green-flag.svg'
import flag_mark_ico from '../../assets/images/new_design/rate-modal/mark-flag.svg'

import { isMobile } from 'react-device-detect'
import { handleShareClick } from './BiweeklyUtils'
import { useNavigate, useParams } from 'react-router'

export const BiWeekly = ({
  location,
  getBiWeeklyHighlights,
  discoveries,
  reports,
  dates,
  rate,
  organization,
  analytics,
  user,
  setRateBiWeeklyHighlights
}) => {
  const navigate = useNavigate()
  const params = useParams()
  const [currentItem, currentItemChange] = useState(null)
  const [visibilitySneakPeak, visibilitySneakPeakChange] = useState(false)
  const [showShareModal, showShareModalChange] = useState(false)
  const [rated, ratedChange] = useState(false)

  const biWeeklyHighlightID = params.highlight_id

  const searchParams = new URLSearchParams(location?.search)
  const requestedItem = parseInt(searchParams.get('id'))
  const requestedType = searchParams.get('type')

  const changeItem = (discovery, i) => {
    visibilitySneakPeakChange(true)
    if (discovery.lastSlide) {
      currentItemChange({
        item: {
          lastSlide: true,
          metadata: discoveries[0].app
        },
        key: i
      })
    } else if (discovery.insightOrReport === 'report') {
      currentItemChange({
        item: {
          ...discovery
        },
        insightOrReport: 'report',
        key: i
      })
    } else {
      const app = discovery.app
      currentItemChange({
        item: {
          ...discovery,
          ...app,
          metadata: app,
          app_id: app.id,
          id: discovery.id,
          insightId: discovery.id
        },
        insightOrReport: 'insight',
        key: i
      })
    }
    const gets = new URLSearchParams({
      type: discovery.insightOrReport,
      id: discovery.id
    }).toString()
    const toURL = `/bi_weekly/${biWeeklyHighlightID}`
    navigate(`${toURL}?${gets}`, { replace: true })
  }

  useEffect(() => {
    getBiWeeklyHighlights(params.highlight_id)
    console.log(
      '-------------------------------get BiWeekly  -------------------'
    )
  }, [])

  useEffect(() => {
    if (reports?.length > 0 || discoveries?.length > 0) {
      const items = [
        ...reports.map(report => ({
          ...report,
          insightOrReport: 'report'
        })),
        ...discoveries.map(dis => ({ ...dis, insightOrReport: 'insight' }))
      ]

      const item =
        items.find(
          item =>
            item.id === requestedItem && item.insightOrReport === requestedType
        ) || items[0]
      let itemIndex = items.findIndex(
        item =>
          item.id === requestedItem && item.insightOrReport === requestedType
      )
      if (itemIndex === -1) {
        // findIndex returns -1 if item doesn't exist, we actually need item 0
        itemIndex = 0
      }
      if (!requestedItem) {
        navigate(
          `/bi_weekly/${biWeeklyHighlightID}?type=${items[0].insightOrReport}&id=${items[0].id}`,
          { replace: true }
        )
      }

      if (item.insightOrReport === 'report') {
        currentItemChange({
          item: {
            ...item
          },
          key: itemIndex,
          insightOrReport: 'report'
        })
      } else {
        currentItemChange({
          item: {
            ...item,
            ...item.app,
            metadata: item.app,
            app_id: item.app.id,
            id: item.id,
            insightId: item.id
          },
          key: itemIndex,
          insightOrReport: 'insight'
        })
      }
      if (organization) {
        analytics.analytic('Open bi-weekly gallery', {
          org: organization.name,
          org_id: organization.id,
          url: `https://app.watchful.ai/link-bi-weekly/${params.highlight_id}`
        })
      }

      visibilitySneakPeakChange(true)
    }
  }, [reports, discoveries])

  const closeModal = () => {
    visibilitySneakPeakChange(false)
    if (user.email) {
      if (window.location.pathname.includes('bi_weekly')) {
        setTimeout(() => {
          window.location = '/overview'
        }, 150)
      }
    } else {
      window.location = '/login'
    }
  }

  const handleRate = val => {
    ratedChange(true)
    analytics.analytic('Rank bi-weekly gallery', {
      org_name: organization.name,
      org_id: organization.id,
      galleryURL: location.pathname,
      rate: val
    })
  }

  const getLastSlide = () => (
    <div className='last-slide-bi-weekly'>
      <div className='last-slide-bi-weekly-animation-wrap'>
        <div className='castle-animation'>
          <img src={castle_ico} className='castle-img' />
          <div className='flag'>
            <img src={flag_ico} />
            <img src={flag_mark_ico} />
          </div>
          <img src={cloud_ico} className='cloud cloud-1' />
          <img src={cloud_ico} className='cloud cloud-2' />
        </div>
      </div>
      <h1>You are now up to date!</h1>
      <div className='last-slide-bw-share-wrap'>
        {user && (
          <Button
            type='default'
            className='share-btn'
            onClick={() =>
              handleShareClick(
                organization,
                biWeeklyHighlightID,
                showShareModalChange,
                analytics
              )
            }
          >
            Share with colleagues
            <img src={share_ico} />
          </Button>
        )}
      </div>
      {!rated ? (
        <div className='last-slide-bw-suggestion-rate'>
          Was this information helpful to you?
          <div className='last-slide-bw-suggestion-rate-yes-no'>
            <div onClick={() => handleRate('yes')}>Yes</div>
            <div onClick={() => handleRate('no')}>No</div>
          </div>
        </div>
      ) : (
        <div className='last-slide-bw-suggestion-rated'>
          Thank you very much! Your feedback is important to us.
        </div>
      )}
    </div>
  )

  const items = [
    ...reports.map(report => ({ ...report, insightOrReport: 'report' })),
    ...discoveries.map(dis => ({ ...dis, insightOrReport: 'insight' }))
  ]
  return (
    <div>
      {currentItem && currentItem.item && (
        <SneakPeakInsightModal
          closeModalAndRemoveViewedInsights={closeModal}
          currentItem={currentItem}
          changeItem={changeItem}
          insights={[
            ...(isMobile ? items.slice(0, 2) : items),
            { lastSlide: true }
          ]}
          visibility={visibilitySneakPeak}
          analytics={analytics}
          user={user}
          organization={organization}
          isMarketingDiscovery={true}
          biWeekly={true}
          dates={dates}
          rate={rate}
          getLastSlide={getLastSlide}
          biWeeklyHighlightID={biWeeklyHighlightID}
          setRateBiWeeklyHighlights={setRateBiWeeklyHighlights}
          showShareModal={showShareModal}
          showShareModalChange={showShareModalChange}
        />
      )}
    </div>
  )
}

const mapStateToProps = ({ biWeekly, appData, userData }) => ({
  discoveries: biWeekly.discoveries || [],
  reports: biWeekly.reports || [],
  dates: biWeekly.dates || [],
  rate: biWeekly.rate,
  organization: biWeekly.organization,
  user: appData.user,
  userData: userData,
  analytics: appData.user.analytics
})

const mapDispatchToProps = { getBiWeeklyHighlights, setRateBiWeeklyHighlights }

export default connect(mapStateToProps, mapDispatchToProps)(BiWeekly)
