// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trial.main {
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
  background-color: white;
  box-shadow: 0px -4px 19px rgba(255, 39, 65, 0.24);
  border-radius: 4px;
}
.trial.main .trial-expiration-text {
  padding: 0.6rem;
  background: #FF2741;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: #FFFFFF;
}
.trial.main .trial-expiration-text .img {
  width: 1.2rem;
  height: 0.9rem;
  margin-right: 0.3rem;
  vertical-align: baseline;
}
.trial.main .expires-in {
  background-color: white;
  margin-top: 0.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}
.trial.main .days-left {
  font-weight: 300;
  font-size: 2.2rem;
  line-height: 2.6rem;
  color: #FF2741;
  background-color: white;
  padding: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/trialLicense/DaysToTrialExperation.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,uBAAA;EACA,iDAAA;EACA,kBAAA;AACF;AAAE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AAEJ;AADI;EACE,aAAA;EACA,cAAA;EACC,oBAAA;EACD,wBAAA;AAGN;AAAE;EACE,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;AAEJ;AAAE;EACE,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;EACA,uBAAA;EACA,eAAA;AAEJ","sourcesContent":[".trial.main{\n  display: flex;\n  flex-direction: column;\n  font-family: 'Roboto';\n  font-style: normal;\n  text-align: center;\n  text-transform: uppercase;\n  background-color: white;\n  box-shadow: 0px -4px 19px rgba(255, 39, 65, 0.24);\n  border-radius: 4px;\n  .trial-expiration-text {\n    padding: 0.6rem;\n    background: #FF2741;\n    font-weight: 700;\n    font-size: 1.1rem;\n    line-height: 1.4rem;\n    color: #FFFFFF;\n    .img {\n      width: 1.2rem;\n      height: 0.9rem;\n       margin-right: 0.3rem;\n      vertical-align: baseline;\n    }\n  }\n  .expires-in {\n    background-color: white;\n    margin-top: 0.5rem;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 1rem;\n    line-height: 1.2rem;\n    align-items: center;\n    text-align: center;\n    text-transform: uppercase;\n  }\n  .days-left {\n    font-weight: 300;\n    font-size: 2.2rem;\n    line-height: 2.6rem;\n    color: #FF2741;\n    background-color: white;\n    padding: 0.3rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
