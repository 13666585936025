import './PromoDays.scss'

import { ChartData, ChartTooltipItem } from 'chart.js'
import ChartLegend from 'components/charts/ChartLegend'
import useGoToPromotionsTable from 'containers/MarketingOverview/hooks/useGoToPromotionsTable'
import { App } from 'containers/MarketingOverview/utils/types'
import { onHoverSetCursor } from 'helpers/chartHelpers'
import { datasetIndexToColor } from 'helpers/colorHelpers'
import React, { useRef } from 'react'
import { Bar } from 'react-chartjs-2'

import { HeadingWithTooltip } from '../../HeadingWithTooltip'

type PromoDayValue = {
  app: App
  counts: number[]
}

type Props = {
  promoDaysValues: PromoDayValue[]
}

export const PromoDays: React.FC<Props> = ({ promoDaysValues }: Props) => {
  const goToTable = useGoToPromotionsTable()
  const chartRef = useRef<Bar>(null)

  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: promoDaysValues?.map((data: PromoDayValue, index: number) => {
      return {
        key: data.app.id,
        label: data.app.displayName,
        data: data.counts,
        backgroundColor: datasetIndexToColor(index)
      }
    })
  }

  const onClick = (event: any, elements: any) => {
    const chart = elements[0]._chart
    const element = chart.getElementAtEvent(event)[0]
    const dataset = chart.data.datasets[element._datasetIndex]

    goToTable([dataset.key])
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      filter: (tooltipItem: ChartTooltipItem) => {
        if (tooltipItem.datasetIndex == 5) {
          return false
        }

        return true
      }
    },
    scales: {
      yAxes: [
        {
          grid: {
            drawBorder: false
          },
          gridLines: {
            display: false
          },
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Discoveries Found',
            fontColor: '#000000',
            fontSize: 12,
            fontFamily: 'Roboto'
          },
          ticks: {
            beginAtZero: true,
            fontColor: '#9AA1A9',
            fontSize: 14,
            padding: 14,
            stepSize: 15
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          },
          stacked: true,
          ticks: {
            fontColor: '#9AA1A9',
            fontSize: 11
          }
        }
      ]
    },
    onHover: onHoverSetCursor,
    onClick
  }

  return (
    <div className='promo-days-of-week'>
      <HeadingWithTooltip
        title='Promo Days Of Week'
        subtitle='Average Daily Promotions Messages by Competitor'
      />

      <div className='promo-days-chart-container'>
        <Bar ref={chartRef} data={data} options={options as any} />
      </div>
      <ChartLegend chart={chartRef.current?.chartInstance} />
    </div>
  )
}

export default PromoDays
