import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import config from '../../shared/config'
import back_img from '../../assets/images/new_design/guest-email-valid-back.svg'
import './styles.scss'
import analyticsService from '../../services/analyticsService'
import { useParams } from 'react-router'

const GuestEmailValidation = () => {
  const params = useParams()

  const [error, errorChange] = useState('')
  const analytics = analyticsService.getInstance()
  useEffect(() => {
    fetch(`${config.dataApi.backendUrl}/user/guests/email-validation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: params.guest_email_valid_token })
    }).then(res => {
      if (res.status === '401') {
        console.log(res)
      }

      res.json().then(res => {
        if (res.status === 'error') {
          if (res.msg === 'validation link token is expired') {
            errorChange('Discovery link expired!')
          }
        } else if (res.status === 'ok') {
          setTimeout(() => {
            window.location = `/link/${res.data.link_code}?guest_token=${res.data.insight_access_token}`
          }, 200)
          analytics.analytic('Guest validated email', {
            guest_email: res.data.email,
            guest_role: res.data.role,
            link_code: res.data.link_code
          })
        }
      })
    })
  }, [])

  return (
    <div className='guest-email-validation-page'>
      <div className='guest-email-validation-error'>
        <div className='back-wrapper'>
          <img src={back_img} />
        </div>
        <div className='logo_wrapper'>
          <a href='/'>
            <img src='/images/new_logo.svg' />
          </a>
        </div>
        <div className='title-h1'>{error}</div>
        {error && (
          <div className='title-h3'>
            Please contact the user who sent you the discovery link
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestEmailValidation)
