// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-wrapper {
  background-color: #f8f8fa;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6.9rem;
  z-index: 995;
}
.footer-wrapper .footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.4rem 0rem;
}
.footer-wrapper .footer .watchful-img {
  margin-right: 1.4rem;
  width: 2rem;
}
.footer-wrapper .footer .watchfulAi2021The360CompetitiveM {
  display: flex;
  margin-right: 1.4rem;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0rem;
  text-align: left;
  margin-left: 1rem;
}
.footer-wrapper .footer .watchfulAi2021The360CompetitiveM .watchfulAi2021The360CompetitiveMEmphasis0 {
  margin-right: 1rem;
  font-weight: 500;
}
.footer-wrapper .footer .watchfulAi2021The360CompetitiveM .blended {
  font-weight: 200;
  color: #b9b9b9;
}
.footer-wrapper .footer .privacyEndUserLicenseAgreement {
  margin-left: 1.4rem;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0rem;
  text-align: left;
}
.footer-wrapper .footer .privacyEndUserLicenseAgreement a {
  color: black;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/containers/footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,cAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,oBAAA;AACJ;AAAI;EACE,oBAAA;EACA,WAAA;AAEN;AAAI;EACE,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;EACA,gBAAA;EACA,iBAAA;AAEN;AADM;EACE,kBAAA;EACA,gBAAA;AAGR;AADM;EACE,gBAAA;EACA,cAAA;AAGR;AACI;EACE,mBAAA;EAMA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;AAJN;AAPM;EACE,YAAA;EACA,0BAAA;AASR","sourcesContent":[".footer-wrapper {\n  background-color: #f8f8fa;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  height: 6.9rem;\n  z-index: 995;\n\n  .footer {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 2.4rem 0rem;\n    .watchful-img {\n      margin-right: 1.4rem;\n      width: 2rem;\n    }\n    .watchfulAi2021The360CompetitiveM {\n      display: flex;\n      margin-right: 1.4rem;\n      font-family: Roboto;\n      font-size: 1rem;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 12px;\n      letter-spacing: 0rem;\n      text-align: left;\n      margin-left: 1rem;\n      .watchfulAi2021The360CompetitiveMEmphasis0 {\n        margin-right: 1rem;\n        font-weight: 500;\n      }\n      .blended {\n        font-weight: 200;\n        color: #b9b9b9;\n      }\n    }\n\n    .privacyEndUserLicenseAgreement {\n      margin-left: 1.4rem;\n      a {\n        color: black;\n        text-decoration: underline;\n      }\n\n      font-family: Roboto;\n      font-size: 1rem;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 1.2rem;\n      letter-spacing: 0rem;\n      text-align: left;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
