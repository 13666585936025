import React from "react";
import blackArrowSlider from "../../../../assets/images/icons/blackArrowSlider.svg";
import blackArrowSlider2 from "../../../../assets/images/icons/blackArrowSlider2.svg";

const ViewExperimentArrowSlick = ({
  className = '',
  onClick,
  direction = '',
  page,
  currentSlide = 0,
  slideCount = 0,
  slider = null
}) => {

  const handleClick = (slider) => {
    if (direction === "prv") {
      slider.current.slickGoTo(0)
    } else if (direction === "next" && currentSlide + 6 < slideCount - 6) {
      slider.current.slickGoTo(currentSlide + 3)
    } else {
      slider.current.slickGoTo(slideCount - 6)
    }
  }

  if (page === "overview") {
     
    if (
      ((direction === "prv" && currentSlide !== 0) ||
        (direction === "next" && currentSlide + 6 < slideCount)) &&
      slideCount > 7
    )
      return (
        <div
          className={`${className}  slick-arrow__${page} slick-${direction}__${page}`}
          onClick={() => handleClick(slider)}
          data-testid="slick-arrow"
        >
          <div className={`slider-arrow ${direction === "prv" ? "arrow-left" : "arrow-right"}`}>
            <img src={blackArrowSlider2} alt="" />
          </div>

        </div>
      );
    return null;
  }

  if (page === "marketing") {
     
    if (
      ((direction === "prv" && currentSlide !== 0) ||
        (direction === "next" && currentSlide < slideCount - 1))
    )
      return (
        <div
          className={`${className}  slick-arrow__${page} slick-${direction}__${page}`}
          onClick={onClick}
          data-testid="slick-arrow"
        >
          <div className={`slider-arrow ${direction === "prv" ? "arrow-left" : "arrow-right"}`}>
            <img src={blackArrowSlider} alt="" />
          </div>

        </div>
      );
    return null;
  }

  if (page === "image-gallery") {
     
    if (
      ((direction === "prv" && currentSlide !== 0) ||
        (direction === "next" && currentSlide < slideCount - 1))
    )
      return (
        <div
          className={`${className}  slick-arrow__${page} slick-${direction}__${page}`}
          onClick={onClick}
          data-testid="slick-arrow"
        >
          <div className={`slider-arrow ${direction === "prv" ? "arrow-left" : "arrow-right"}`}>
            <img src={blackArrowSlider} alt="" />
          </div>

        </div>
      );
    return null;
  }

  if (page === 'versions') {
    if (direction === "next" && currentSlide >= slideCount - 6) {
      return <div className="versions-last-versions">
        Latest Release
      </div>
    }
    if (direction === "prv" && currentSlide === 0) {
      return null
    }

    return (

      <div
        className={`${className}  slick-arrow__${page} slick-${direction}__${page}`}
        onClick={onClick}
        data-testid="slick-arrow"
      >
        <div className={`slider-arrow ${direction === "prv" ? "arrow-left" : "arrow-right"}`}>
          <img src={blackArrowSlider} alt="" />
        </div>

      </div>
    );
  }


  

  return (
    <div
      className={`${className}  slick-arrow-def slick-${direction} `}
      onClick={onClick}
      data-testid="slick-arrow"
    >
      <div className={`slider-arrow ${direction === "prev" ? "arrow-left" : "arrow-right"}`}>
        <img src={blackArrowSlider} alt="" />
      </div>
    </div>
  );
};

export default ViewExperimentArrowSlick;
