import React from 'react'
import { blackArrow } from './DiscoveryViewWidgets'

export default function AppHeader ({
  app,
  demoMode,
  clickBack,
  isMarketingDiscovery,
  isDraft = false
}) {
  return (
    <div
      className={`view-insight-app ${demoMode ? 'demo' : ''}`}
      onClick={() => !demoMode && clickBack()}
      data-testid='goBack'
    >
      {!demoMode && blackArrow()}
      <div className='view-insight-app-icon'>
        <img src={app.metadata.icon} />
      </div>
      <div className='view-insight-app-name'>
        <h2>
          {app.metadata.display_name || app.metadata.app_name}{' '}
          {isDraft && <div className='draft'>Draft, not released</div>}{' '}
        </h2>

        {!isMarketingDiscovery && (
          <div
            className={`platform app-platform-${app.metadata.platform.toLowerCase()}`}
          >
            {app.metadata.platform}
          </div>
        )}
      </div>
    </div>
  )
}
