import React from "react";
import AppDrawerOld from "../../../../common/appDrawer/AppDrawerOld";
import PerformanceDrawer from "./PerformanceDrawer";
import {
  buildFormattedEventsList,
  buildFormattedDevicesList,
  buildFormattedOSList,
  buildFormattedLocationsList,

  getFormattedEvent,
  getFormattedDevice,
  getFormattedLocation
} from "../comparePageUtils"
import { useIsFeatureByUserID } from "../../../../helpers/features";


const PerformanceAppDrawer = ({
  event,
  index,
  appsList,
  perfInfrastructure,
  isMainApp,
  woutColorLine,
  appDeviceEvents,

  appChange,
  handleEventChange,
  handleDeviceChange,
  handleOSChange,
  handleLocationChange,
  disabled,
  isMatchedEvent,
  user
}) => {
  const deactivateEventsWithoutData = useIsFeatureByUserID('performance_cmp_deactivate_events_without_data', user.email)
  const eventNamesWithData = (appDeviceEvents || []).filter(
    x => x.app_id === event.app.id
      && x.model === event.device.model
      && (!event.os_version || x.os_version === event.os_version)
      && x.location === event.location
  ).map(x => x.event_name)
  const performanceEventOptions = event.app ? buildFormattedEventsList(
    event.app.performance_events.map(x => (
      { ...x, disabled: deactivateEventsWithoutData ? !eventNamesWithData.includes(x.name) : false })
    )
  ) : []

  return (
    <div className={`performance-app-drawer ${disabled ? 'disabled' : ''}`}>
      {disabled && <div className="disabled-cover"/>}
      <div className={`performance__header perf-header-${index}`}>
        <AppDrawerOld
          page="performance_compare"
          isPerformance={true}
          appsList={appsList || []}
          selectedApp={event.app ? appsList.find(app => app.id === event.app.id) : null}
          isMainApp={isMainApp}
          appChange={appChange}
          dataPoints={event && event.data &&
            event.data.num_datapoints}
          lastVersionName={event && event.history && event.history && event.history.duration_s ? event.history.duration_s[event.history.duration_s.length - 1].release_id.split('-')[0] : ''}
          canClear={index !== 0}
          disabled={disabled}
          woutColorLine={woutColorLine}
        />
      </div>
      {event.app && <div className="performance-filters">
        <div className="perf-filter-event">
          <PerformanceDrawer
            drawerName="Event"
            selectionCallback={handleEventChange}
            selectedItem={event.id && getFormattedEvent(event.app.performance_events, event.id)}
            optionList={performanceEventOptions}
            isActive={event.app}
            isMatched={isMatchedEvent}
          />
        </div>
        <div className="perf-filter-locations">
          <PerformanceDrawer
            drawerName="Location"
            selectedItem={event.location && getFormattedLocation(event.location)}
            selectionCallback={handleLocationChange}
            optionList={event.app ? buildFormattedLocationsList(perfInfrastructure) : []}
            isActive={event.app}
          />
        </div>
        <div className="perf-filter-device">
          <PerformanceDrawer
            drawerName="Device"
            selectionCallback={handleDeviceChange}
            selectedItem={event.device && event.app && getFormattedDevice(event.app.device_models, event.device)}
            optionList={event.app && event.app && event.location ? buildFormattedDevicesList(event.app.device_models, perfInfrastructure, event.location) : []}
            isActive={event.app && event.location}
          />
        </div>
        <div className="perf-filter-device-os">
          <PerformanceDrawer
            drawerName="OS Ver."
            selectionCallback={handleOSChange}
            selectedItem={event.os_version && {
              value: event.os_version,
              label: event.os_version
            }}
            optionList={event.app && event.device ? buildFormattedOSList(event.app.device_models, event.device) : []}
            isActive={event.app && event.location}
            allVariant={event.device && buildFormattedOSList(event.app.device_models, event.device).length > 1 ? true : false}
          />
        </div>

      </div>}
    </div>
  )
}

export default PerformanceAppDrawer