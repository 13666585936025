import moment from 'moment'
import { useLocation, useNavigate } from 'react-router'

import { Channels } from '../utils/consts'
import { App, ChartValuesToggle } from '../utils/types'

export enum MarketingOverviewFiltersParams {
  app_id = 'app_id',
  channel_id = 'channel_id',
  days_ago = 'days_ago',
  custom_range = 'custom_range',
  custom_range_time = 'custom_range_time',
  campaign_channel_breakdown_chart_values = 'campaign_channel_breakdown_chart_values',
  per_user_type_chart_values = 'per_user_type_chart_values',
  campaign_calendar_show_lines = 'campaign_calendar_show_lines',
  user_types = 'user_types',
  user_role_types = 'user_role_types'
}

type MarketingOverviewFiltersTypes = {
  app_id: string[]
  channel_id: string[]
  days_ago: string
  custom_range: string
  custom_range_time: string[]

  per_user_type_chart_values: ChartValuesToggle
  campaign_channel_breakdown_chart_values: ChartValuesToggle
  campaign_calendar_show_lines: string
  user_types: number[]
  user_role_types: number[]
}

export type HandleOverviewFilterChangeType = <
  T extends keyof MarketingOverviewFiltersTypes
>(filters: {
  [key: string]: MarketingOverviewFiltersTypes[T]
}) => void

export const useOverviewFilters = (apps: App[]) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const appIds = params.getAll(MarketingOverviewFiltersParams.app_id) || []
  let parsedAppIds = appIds.map(id => parseInt(id))

  if (!appIds.length && apps?.length) {
    parsedAppIds = apps.slice(0, 5).map(app => app.id)
  }

  let channelIds = params.getAll(MarketingOverviewFiltersParams.channel_id)

  const daysAgo = parseInt(
    params.get(MarketingOverviewFiltersParams.days_ago) || '30'
  )
  const customRange = !!(
    params.get(MarketingOverviewFiltersParams.custom_range) &&
    params.get(MarketingOverviewFiltersParams.custom_range) !== 'false'
  )
  const customRangeTimes = params.getAll(
    MarketingOverviewFiltersParams.custom_range_time
  )

  // TODO change to dayjs
  const customRangeTime =
    customRangeTimes.length > 1
      ? [moment(customRangeTimes[0]), moment(customRangeTimes[1])]
      : [moment(), moment()]

  const perUserTypeChartValues = (params.get(
    MarketingOverviewFiltersParams.per_user_type_chart_values
  ) || 'percentages') as ChartValuesToggle
  const campaignChannelBreakdownChartValues = (params.get(
    MarketingOverviewFiltersParams.campaign_channel_breakdown_chart_values
  ) || 'percentages') as ChartValuesToggle

  const campaignsCalendarShowLinesValue = params.get(
    MarketingOverviewFiltersParams.campaign_calendar_show_lines
  )

  // if undefined, default to true otherwise convert to boolean
  const campaignCalendarShowLines =
    campaignsCalendarShowLinesValue === null ||
    campaignsCalendarShowLinesValue === 'true'

  const selectedUserTypes =
    params.getAll(MarketingOverviewFiltersParams.user_types)?.map(id => +id) ||
    []
  const selectedUserRoleTypes =
    params
      .getAll(MarketingOverviewFiltersParams.user_role_types)
      ?.map(id => +id) || []

  // We need to receive all filters for update at once as location.search is cached,
  // so if you run handleUpdateFilters twice with different filters, the seconds will override the first.
  // For example, {a: 1, b: 2}:
  // handleUpdateFilters({a: 2}) = {a: 2, b: 2}
  // if you immediately call handleUpdateFilters again without waiting for the render with ({b: 3}) = {a: 1, b: 3}
  const handleUpdateFilters: HandleOverviewFilterChangeType = filters => {
    const newParams = new URLSearchParams(location.search)

    Object.entries(filters).forEach(([filter, newValue]) => {
      if (Array.isArray(newValue)) {
        newParams.delete(filter)
        newValue.forEach(value => {
          newParams.append(filter, value.toString())
        })
      } else {
        newParams.set(filter, newValue)
      }
    })

    navigate(`${location.pathname}?${newParams.toString()}`, { replace: true })
  }

  return {
    handleUpdateFilters,
    appIds: parsedAppIds,
    channelIds,
    daysAgo,
    customRange,
    customRangeTime,

    perUserTypeChartValues,
    campaignChannelBreakdownChartValues,
    campaignCalendarShowLines,
    selectedUserTypes,
    selectedUserRoleTypes
  }
}
