import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router'
import TopicsView from './TopicsView'
import moment from 'moment'
import { MATCH_TYPES } from '../intelligence/Marketing/FilterTypes'

import { getTopLabels } from '../../redux/acts/labels'
import { useDebounce } from '../../helpers/customHooks'
import { getIntelDiscoveriesLabels } from '../../redux/acts/labels'
import { getDiscoveries } from '../../redux/actions/overviewActions'
import {
  PL_ANDROID,
  PL_IOS,
  PL_WINDOWS,
  PL_CHROME,
  PL_WEB
} from '../../shared/consts'

import { useIsFeatureByUserID } from '../../helpers/features'
import { convertDaysAgoToCustomRange } from "../../helpers/dates";
import dayjs from "dayjs";

export const TopicsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const products = useSelector(state => state.overview.products)
  const analytics = useSelector(state => state.appData.user.analytics)
  const apps = useSelector(state => state.appData.apps)
  const user = useSelector(state => state.appData.user)
  const userDiscoveries = useSelector(
    state => state.userDiscoveries.discoveries
  )
  const topLabels = useSelector(state => state.labels.topTagsWithProducts)
  const tagsList = useSelector(state => state.labels.intelTagsList)
  const loadingFilteredDiscoveries = useSelector(
    state => state.overview.loadingFilteredDiscoveries
  )
  const discoveries = useSelector(state => state.overview.filteredDiscoveries)

  const TopicsPageAccess = useIsFeatureByUserID('topics', user.email)

  const url = new URLSearchParams(location.search)

  const u = k => url.get(k)

  const [customRangeTime, customRangeTimeChange] = useState(
    u('customRangeTime')
      ? [
          dayjs(u('customRangeTime').split(',')[0]),
          dayjs(u('customRangeTime').split(',')[1]),
        ]
      : convertDaysAgoToCustomRange(30)
  )
  const [platforms, platformsChange] = useState(
    u('platforms') ? u('platforms').split(',') : []
  )
  const [filterTags, filterTagsChange] = useState(
    u('label_ids_any')
      ? u('label_ids_any').split(',')
      : u('label_ids_all')
      ? u('label_ids_all').split(',')
      : []
  )
  const [filterTagsMatch, setFilterTagsMatch] = useState(
    u('label_ids_any')
      ? MATCH_TYPES[0]
      : u('label_ids_all')
      ? MATCH_TYPES[1]
      : MATCH_TYPES[0]
  )

  const [currentProducts, currentProductsChange] = useState(
    u('product_ids')
      ? u('product_ids')
          .split(',')
          .map(a_id => Number(a_id))
      : []
  )

  const [currentPage, currentPageChange] = useState(
    u('currentPage') ? Number(u('currentPage')) : 1
  )
  const [currentPageSize, currentPageSizeChange] = useState(
    u('currentPageSize') ? Number(u('currentPageSize')) : 40
  )

  const updateURL = () => {
    let dataForURL = {
      platforms,
      product_ids: currentProducts.join(),
      customRange: 1,
      customRangeTime: customRangeTime.map(date => date.format('YYYY-MM-DD')),
      [filterTagsMatch === MATCH_TYPES[0] ? 'label_ids_any' : 'label_ids_all']:
        filterTags.join()
    }
    let getsURL = new URLSearchParams(dataForURL).toString()
    navigate(`${location.pathname}?${getsURL}`, { replace: true })
  }

  const requestDiscoveries = (page = 1) => {
    const appsFromProducts = currentProducts
      .reduce(
        (acc, product_id) => [
          ...acc,
          ...products.find(p => p.id === product_id).apps.map(app => app.id)
        ],
        []
      )
      .join()
    const vars = {
      num_items: currentPageSize,
      page_num: page,
      app_id_only: appsFromProducts,
      type: [
        'ab_test',
        'indication',
        'screen_change',
        'new_feature',
        'removed_feature'
      ]
    }

    vars['start_time_gte'] = customRangeTime[0].format('YYYY-MM-DD')
    vars['start_time_lte'] = customRangeTime[1].format('YYYY-MM-DD')
    if (platforms.length) {
      vars['platform'] = platforms
    } else {
      vars['platform'] = [PL_ANDROID, PL_IOS, PL_WINDOWS, PL_CHROME, PL_WEB]
    }

    const labelsField =
      filterTagsMatch === MATCH_TYPES[0] ? 'label_ids_any' : 'label_ids_all'
    if (filterTags.length > 0) {
      vars[labelsField] = filterTags.join()
    } else {
      vars[labelsField] = ''
    }

    const gets = new URLSearchParams(vars).toString()
    dispatch(
      getDiscoveries({
        gets: gets
      })
    )
    analytics.analytic('get discoveries', vars)
  }

  const requestTopLabels = () => {
    const vars = {}
    vars['from_date'] = customRangeTime[0].format('YYYY-MM-DD')
    vars['until_date'] = customRangeTime[1].add(1, 'day').format('YYYY-MM-DD')
    if (platforms.length) {
      vars['platforms'] = platforms
    } else {
      vars['platforms'] = [PL_ANDROID, PL_IOS, PL_WINDOWS, PL_CHROME, PL_WEB]
    }
    if (currentProducts.length > 0) {
      vars['product_ids'] = currentProducts.join()
    }
    const gets = new URLSearchParams(vars).toString()

    dispatch(
      getTopLabels({
        gets: gets
      })
    )

    dispatch(
      getIntelDiscoveriesLabels({
        gets: gets
      })
    )

    analytics.analytic('get top labels', vars)
  }

  const debouncedRequestDiscoveries = useDebounce(requestDiscoveries, 800)
  const debouncedRequestLabels = useDebounce(requestTopLabels, 800)

  useEffect(debouncedRequestDiscoveries, [
    customRangeTime,
    filterTags,
    filterTagsMatch,
    platforms,
    currentProducts
  ])

  useEffect(debouncedRequestLabels, [
    customRangeTime,
    filterTags,
    platforms,
    currentProducts
  ])

  useEffect(updateURL, [
    customRangeTime,
    filterTags,
    filterTagsMatch,
    platforms,
    currentProducts
  ])

  const onShowSizeChange = (current, pageSize) => {
    currentPageSizeChange(pageSize)
    currentPageChange(1)
  }

  const onPageChange = page => {
    currentPageChange(page)
    requestDiscoveries(page)
  }

  if (!TopicsPageAccess) {
    return null
  }

  return (
    <TopicsView
      products={products}
      analytics={analytics}
      user={user}
      userDiscoveries={userDiscoveries}
      topLabels={topLabels}
      apps={apps}
      tagsList={tagsList}
      filterTags={filterTags}
      filterTagsChange={filterTagsChange}
      filterTagsMatch={filterTagsMatch}
      setFilterTagsMatch={setFilterTagsMatch}
      platforms={platforms}
      platformsChange={platformsChange}
      customRangeTime={customRangeTime}
      customRangeTimeChange={customRangeTimeChange}
      currentProducts={currentProducts}
      currentProductsChange={currentProductsChange}
      loadingFilteredDiscoveries={loadingFilteredDiscoveries}
      discoveries={discoveries}
      currentPage={currentPage}
      currentPageSize={currentPageSize}
      onShowSizeChange={onShowSizeChange}
      onPageChange={onPageChange}
    />
  )
}

export default TopicsPage
