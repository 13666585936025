import React from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { LOCATIONS_EXTRA } from '../../../shared/consts'
import { checkAvailLocations } from '../../../helpers/usersHelper'
import { addCommas } from '../../../helpers/numbersHelper'

import { Link } from 'react-router-dom'

export function BenchmarkRanksFastestAppComp (props) {
  const { app, deviceModels, winner, place, lastRes, hideLoc = false } = props

  const availLocs = checkAvailLocations(props.userData)

  if (!app || availLocs.indexOf(app.rank.loc) === -1) {
    return null
  }

  const deviceModel = deviceModels.find(
    mod =>
      mod.model === app.rank.model && mod.os_version === app.rank.os_version
  )

  return (
    <div className='fastest-app-item'>
      <h3>{place}</h3>
      <div className='fastest-app-record'>
        {winner && <div className='winner-tag'>Winner</div>}
        <div className='fastest-app-data'>
          <div className={`fastest-app-icon-wrap`}>
            <img src={app.icon} className='fastest-app-icon' />
          </div>
          <div className='fastest-app-name-model'>
            <h4 className='fastest-app-name'>{app.name}</h4>
            <div className='fastest-app-extra'>
              <div className='fastest-app-phone'>
                {deviceModel ? (
                  <>
                    {deviceModel.product_name}
                    <span className='device-os-version'>
                      {deviceModel.platform} {deviceModel.os_version}
                    </span>
                  </>
                ) : (
                  'Unk'
                )}
              </div>
              {!hideLoc && (
                <div className='fastest-app-loc'>
                  <span
                    className={`flag-icon fi fi-${app.rank.loc.toLowerCase()}`}
                    style={{
                      marginRight: '1rem'
                    }}
                  />
                  {LOCATIONS_EXTRA[app.rank.loc].name}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='fastest-app-record-value'>
          <div className='fastest-app-record-value-time'>
            {app.rank.duration_s.toFixed(1)}
            <span>Sec</span>
          </div>
          <div className='ranks-event-duration'>Event Duration</div>
          <div className='ranks-compare-text'>
            Based on {addCommas(app.rank.num_samples)} Data Points
          </div>
        </div>
      </div>
      <div className='compare-line-wrap'>
        <div
          className='compare-line-val'
          style={{ width: `${(app.rank.duration_s / lastRes) * 100}%` }}
        />
      </div>
    </div>
  )
}

function BenchmarkOverviewFastestApp (props) {
  const {
    app,
    eventName,
    record,
    deviceModels,
    userData,
    performanceEventTypes,
    analytics
  } = props

  const availLocs = checkAvailLocations(userData)
  if (availLocs.indexOf(record.loc) === -1) {
    return null
  }

  if (!app) {
    return null
  }

  const event = performanceEventTypes.find(event => event.name === eventName)

  const handleClickFastestApp = () => {
    analytics.analytic(`Click fastest app from benchmarking Overview`, {
      app: app.name,
      platform: app.platform,
      event: event.title
    })
  }

  const enrichedRecord = {
    ...record,
    deviceModel:
      deviceModels.find(
        mod =>
          mod.model === record.device_model &&
          mod.os_version === record.os_version
      ) || {}
  }

  return (
    <div className='fastest-app-item clickable' onClick={handleClickFastestApp}>
      <Link
        to={`/benchmark/ranks?model_in=${record.device_model}&os_version_in=${record.os_version}&loc_in=${record.loc}&event_in=${eventName}&category_in=`}
      >
        <h3>
          {event.title}
          <Tooltip
            placement='top'
            title={event.description}
            key={app.id}
            overlayClassName='fastest-app-event-tooltip'
          >
            <InfoCircleOutlined />
          </Tooltip>
        </h3>
        <div className='fastest-app-record'>
          <div className='winner-tag'>Winner</div>
          <div className='fastest-app-data'>
            <div className={`fastest-app-icon-wrap`}>
              <img src={app.icon} className='fastest-app-icon' />
            </div>
            <div className='fastest-app-name-model'>
              <h4 className='fastest-app-name'>{app.name}</h4>
              <div className='fastest-app-extra'>
                <div className='fastest-app-phone'>
                  {enrichedRecord.deviceModel.product_name}
                  <span className='device-os-version'>
                    {enrichedRecord.deviceModel.platform}{' '}
                    {enrichedRecord.deviceModel.os_version}
                  </span>
                </div>
                <div className='fastest-app-loc'>
                  <span
                    className={`flag-icon fi fi-${record.loc.toLowerCase()}`}
                    style={{
                      marginRight: '1rem'
                    }}
                  />
                  {LOCATIONS_EXTRA[record.loc].name}
                </div>
              </div>
            </div>
          </div>
          <div className='fastest-app-record-value'>
            <div className='fastest-app-record-value-time'>
              {record.value_avg.toFixed(1)}
              <span>Sec</span>
            </div>
            <div className='fastest-app-record-value-handicap'>
              {Math.abs(
                Math.round(
                  (record.value_general -
                    record.value_avg / record.value_general) *
                    100
                )
              )}
              % Faster Than Avg.
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export const BenchmarkRanksFastestApp = connect(
  ({ appData, performance, userData }) => ({
    deviceModels: performance.deviceModels,
    user: appData.user,
    userData
  }),
  null
)(BenchmarkRanksFastestAppComp)

export default connect(
  ({ appData, performance, userData }) => ({
    deviceModels: performance.deviceModels,
    performanceEventTypes: performance.eventTypes,
    user: appData.user,
    userData,
    analytics: appData.user.analytics
  }),
  null
)(BenchmarkOverviewFastestApp)
