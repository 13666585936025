import React from 'react'
import { Img } from 'react-image'
import WatchfulThumbnail from 'assets/images/icons/default_thumbnail.png'

import './StickyHeader.scss'

const StickyHeader = ({
  visible,
  appIcon = '',
  appName = '',
  eventName = '',
  selectedDevice = '',
  selectedLocation = '',
  selectedOS = ''
}) => {
  return (
    <div
      className='performance-sticky-header'
      style={{ visibility: visible ? 'visible' : 'hidden' }}
    >
      <div className='performance-sticky-header-content'>
        <div className='performance-sticky-header-app-name'>
          <Img src={[appIcon, WatchfulThumbnail]} />
          <div className='performance-sticky-header-app-name-title'>
            {appName || ''}
          </div>
        </div>

        <div className='performance-sticky-header-event-name'>
          {eventName || ''}
        </div>
        <div className='performance-sticky-header-device'>
          {(selectedDevice || '') + ' ' + selectedOS}
        </div>
        <div className='performance-sticky-header-location'>
          {selectedLocation}
        </div>
      </div>
    </div>
  )
}

export default StickyHeader
