import { call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  GET_NOTIFICATIONS, getNotificationsSuccess, SET_NOTIFICATIONS_FOR_INSIGHT_READ,
  SET_NOTIFICATIONS_READ, SET_NOTIFICATIONS_SEEN, setNotificationForInsightReadSuccess,
  setNotificationsReadSuccess, setNotificationsSeenSuccess
} from '../acts/notifications';
import * as notificationApi from '../api/notificationsApi';

//handleres
function* getNotifications() {
  // @ts-ignore
  const result = yield call(notificationApi.getNotifications)
  yield put(getNotificationsSuccess(result.data))
}

function* setNotificationsReadHandler(action: any) {
  // @ts-ignore
  const result = yield call(notificationApi.setNotificationsRead, action.payload)
  yield put(setNotificationsReadSuccess(result.data))
}

function* setNotificationsSeenHandler(action: any) {
  // @ts-ignore
  const result = yield call(notificationApi.setNotificationsSeen, action.payload)
  yield put(setNotificationsSeenSuccess(result.data))
}

function* setNotificationsForInsightReadHandler(action: any) {
  try {
    // @ts-ignore
    const result = yield call(notificationApi.setNotificationsForInsightRead, action.payload)
    yield put(setNotificationForInsightReadSuccess(result.data))
  } catch (e) {
    console.log(e)
  }
}

//watchers
function* watchNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);
  yield takeEvery(SET_NOTIFICATIONS_READ, setNotificationsReadHandler)
  yield takeEvery(SET_NOTIFICATIONS_FOR_INSIGHT_READ, setNotificationsForInsightReadHandler)
  yield takeEvery(SET_NOTIFICATIONS_SEEN, setNotificationsSeenHandler)
}

const notificationsSagas = [
  fork(watchNotifications),
];

export default notificationsSagas;