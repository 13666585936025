import './PopularDaysChart.scss'

import moment from 'moment'
import React, { FC, ReactElement } from 'react'

import WGroupedBarChart, {
  Iimage,
  namesDays
} from '../../../../components/charts/GroupedBarChart/WGroupedBarChart'
import { IDataPoint, IDisWithApp } from '../../../../interfaces/Product'

interface EmailChartProps {
  discoveries: Array<IDisWithApp>
}

const PopularDaysChart: FC<EmailChartProps> = ({
  discoveries
}): ReactElement | null => {
  const getOptions = () => {
    return {
      height: '35rem'
    }
  }

  const getGroupedData = (discoveries: IDisWithApp[]): IDataPoint[] => {
    const allNames: Set<string> = new Set()
    const allDates = namesDays
    discoveries.forEach(dis => {
      allNames.add(dis.app.display_name)
    })
    const uniqueNames: string[] = Array.from(allNames)
    const groupedData: IDataPoint[] = []
    for (const name of uniqueNames) {
      for (const date of allDates) {
        groupedData.push({
          group: date,
          key: name,
          value: 0
        })
      }
    }

    discoveries.forEach((dis: IDisWithApp) => {
      groupedData.forEach((item: IDataPoint) => {
        if (
          item.key === dis.app.display_name &&
          item.group === namesDays[moment(dis.cam.start_time).day()]
        ) {
          item.value++
        }
      })
    })

    return groupedData
  }

  let groupedData: IDataPoint[] = []

  groupedData = getGroupedData(discoveries)
  const setOfGroup = Array.from(
    new Set(discoveries.map(d => d.app.display_name))
  )

  const uniqueApps = setOfGroup.map((name: string): Iimage => {
    const thisDiscovery = discoveries.find(dis => dis.app.display_name === name)
    return {
      text: name,
      //@ts-ignore
      icon: thisDiscovery.app.icon
    }
  })
  return (
    <div className={'main-container'}>
      <WGroupedBarChart
        options={getOptions()}
        data={groupedData}
        images={uniqueApps}
        groupLegend={namesDays}
      />
    </div>
  )
}
export default PopularDaysChart
