import { createAction, createReducer } from 'redux-act';

import {
  GET_BIWEEKLY_BY_ID,
  GET_BIWEEKLY_BY_ID_SUCCESS,
  SET_RATE_BIWEEKLY_BY_ID,
  SET_DISCOVERY_RATE_SUCCESS
} from "../actions/_actionsTypes";

export const getBiWeeklyHighlights = createAction(GET_BIWEEKLY_BY_ID, (data) => data);
export const getBiWeeklyHighlightsSuccess = createAction(GET_BIWEEKLY_BY_ID_SUCCESS, (data) => data);

export const setRateBiWeeklyHighlights = createAction(SET_RATE_BIWEEKLY_BY_ID, (data) => data);


export const BiWeeklyReducer = createReducer({

  [GET_BIWEEKLY_BY_ID_SUCCESS]: (state: object, payload: any): object => {
    return {
      ...state,
      discoveries: payload.data.insights,
      reports: payload.data.reports,
      dates: payload.data.highlight,
      rate: payload.data.highlight.rate,
      organization: payload.data.organization,
    }
  },
  [SET_DISCOVERY_RATE_SUCCESS]: (state: object, payload: any): object => {
    //@ts-ignore
    const discoveriesCopy = state.discoveries ? state.discoveries.map(dis => dis.id == payload.data.id ? {
      ...dis,
      rating_count: payload.data.rating_count,
      rating_global: payload.data.rating_global,
      rating_user: payload.data.rating_user
    } : dis)
      : null
    return {
      ...state,
      discoveries: discoveriesCopy,
    }
  },
}, []);