import './MarketingOverviewFilters.scss'

import { Popover, Radio, RadioChangeEvent, Switch } from 'antd'
import calendar_ico from 'assets/images/icons/calendar.svg'
import caret_ico from 'assets/images/icons/caret-down.svg'
import device_ico from 'assets/images/icons/device.svg'
import persona_ico from 'assets/images/icons/persona.svg'
import share_ico from 'assets/images/icons/share-network.svg'
import clsx from 'clsx'
import { AppDrawer } from 'components/AppDrawer'
import HeaderWithFilters from 'components/HeaderWithFilters'
import { TimeFilter } from 'components/TimeFilter'
import { UserTypeFilter } from 'components/UserTypeFilter'
import {
  UserRoleType,
  UserType
} from 'containers/MarketingCampaings/utils/types'
import {
  HandleOverviewFilterChangeType,
  MarketingOverviewFiltersParams
} from 'containers/MarketingOverview/hooks/useOverviewFilters'
import { trackMarketingOverviewEvent } from 'containers/MarketingOverview/utils/analytics'
import { Channels } from 'containers/MarketingOverview/utils/consts'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import { App } from 'containers/MarketingOverview/utils/types'
import { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { daysAgoList } from 'shared/consts'

import { ChannelSelect } from './ChannelSelect'

export enum ViewType {
  Outreach,
  InApp
}

type Props = {
  apps: App[]
  channelIds: string[]
  selectedAppIds: number[]
  customerApp?: App
  handleUpdateFilters: HandleOverviewFilterChangeType
  customRangeTime: Dayjs[]
  viewType: ViewType
  viewTypeChanged: Function
  userTypes: UserType[]
  userRoleTypes: UserRoleType[]
  selectedUserTypes: number[]
  selectedUserRoleTypes: number[]
}

const viewOptions = [
  { label: 'Outreach', value: ViewType.Outreach },
  { label: 'In App', value: ViewType.InApp }
]

export const MarketingOverviewFilters = ({
  apps,
  selectedAppIds,
  handleUpdateFilters,
  customRangeTime,
  channelIds,
  customerApp,
  viewType,
  viewTypeChanged,
  userTypes,
  userRoleTypes,
  selectedUserTypes,
  selectedUserRoleTypes
}: Props) => {
  // TODO check if we need to also filter by user role types, and how to do it
  const [timeFrameOpen, setTimeFrameOpen] = useState(false)
  const [channelOpen, setChannelOpen] = useState(false)
  const [appsOpen, setAppsOpen] = useState(false)
  const isMobile = isMobileDevice()

  const appsList = apps.map(app => ({
    id: app.id,
    name: app.displayName,
    thumbnail: app.icon,
    count: 0
  }))

  const selectedApps =
    selectedAppIds.length > 0
      ? apps.filter(app => selectedAppIds.includes(app.id))
      : []

  const handleAppsSelected = (appIds: number[]) => {
    const selectedApps = apps.filter(app => appIds.includes(app.id))

    trackMarketingOverviewEvent('overview_app_filter', {
      selected_app_names: selectedApps.map(app => app.displayName)
    })

    handleUpdateFilters({
      app_id: appIds.map(appId => appId.toString())
    })
  }

  const handleCustomRangeChange = (dates: [Dayjs, Dayjs] | null) => {
    if (!dates?.length) {
      handleUpdateFilters({
        custom_range: false.toString(),
        custom_range_time: []
      })
    } else {
      trackMarketingOverviewEvent('overview_time_interval_filter', {
        number_of_days: dates[0].diff(dates[1], 'day')
      })

      handleUpdateFilters({
        custom_range: true.toString(),
        custom_range_time: dates.map(date => date.toISOString())
      })
    }
  }

  const handleChannelSelect = (channels: string[]) => {
    trackMarketingOverviewEvent('overview_channel_changed', {
      selected_channel_names: channels
    })
    handleUpdateFilters({ channel_id: channels })
  }

  const handleCurrentAppToggle = () => {
    const currentCustomerAppId = customerApp?.id.toString()
    if (!currentCustomerAppId) return

    const appIds =
      selectedAppIds.length > 0
        ? selectedAppIds.map(id => id.toString())
        : apps.map(app => app.id.toString())

    const appIdsWithoutCurrentApp = appIds.filter(
      id => id !== currentCustomerAppId
    )
    const appIdsWithCurrentApp = [
      ...appIdsWithoutCurrentApp,
      currentCustomerAppId
    ]

    trackMarketingOverviewEvent('overview_comparison_mode_changed', {
      state: appIds.includes(currentCustomerAppId) ? 'off' : 'on'
    })

    handleUpdateFilters({
      app_id: appIds.includes(currentCustomerAppId)
        ? appIdsWithoutCurrentApp
        : appIdsWithCurrentApp
    })
  }

  const handleUserOrRoleTypeSelect = (
    userTypesIds: number[],
    userRoleTypeIds: number[]
  ) => {
    const userTypesSelected = userTypesIds
      .map(id => userTypes.find(userType => userType.id === id))
      .filter(userType => !!userType)

    const userRoleTypesSelected = userRoleTypeIds
      .map(id => userRoleTypes.find(userRoleType => userRoleType.id === id))
      .filter(userRoleType => !!userRoleType)

    trackMarketingOverviewEvent('user_types_filter', {
      user_types_selected: userTypesSelected.map(
        // For some weird reason TS doesn't recognize the filter aboves that insures it will not be undefined
        userType => userType?.title || ''
      ),
      user_role_types_selected: userRoleTypesSelected.map(
        userRoleType => userRoleType?.name || ''
      )
    })

    handleUpdateFilters({
      [MarketingOverviewFiltersParams.user_types]: userTypesIds,
      [MarketingOverviewFiltersParams.user_role_types]: userRoleTypeIds
    })
  }

  const selectedUserTypesObjects = userTypes.filter(userType =>
    selectedUserTypes.includes(userType.id)
  )

  const selectedUserRoleTypesObject = userRoleTypes.filter(userRoleType =>
    selectedUserRoleTypes.includes(userRoleType.id)
  )

  const totalTypesSelected =
    selectedUserTypes.length + selectedUserRoleTypes.length

  return (
    <div>
      <HeaderWithFilters title='Marketing Promotions'>
        <div className='marketing-filters-container'>
          <Radio.Group
            size='large'
            options={viewOptions}
            onChange={(event: RadioChangeEvent) => {
              const viewType = event.target.value as ViewType
              viewTypeChanged(viewType)

              if (viewType === ViewType.InApp) {
                handleUpdateFilters({ channel_id: [Channels.inapp_campaign] })
              } else {
                handleUpdateFilters({
                  channel_id: [
                    Channels.email_campaign,
                    Channels.push_notification_campaign
                  ]
                })
              }
            }}
            value={viewType}
            optionType='button'
            buttonStyle='solid'
          />
          <div className='dropdown-filters'>
            <Popover
              placement='bottom'
              title={null}
              destroyTooltipOnHide={true}
              content={
                <AppDrawer
                  appsList={appsList}
                  onAppsSelected={handleAppsSelected}
                  currentAppsIds={selectedApps.map(app => app.id)}
                  closeDrawer={() => setAppsOpen(false)}
                />
              }
              open={appsOpen}
              onOpenChange={open => setAppsOpen(open)}
              trigger='click'
              overlayClassName={clsx(
                'popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps fixed-filters',
                {
                  'mobile-full-screen': isMobile
                }
              )}
            >
              <div
                className='select-filter'
                style={{ minWidth: isMobile ? '24%' : '160px' }}
              >
                <div className='select-body'>
                  {!isMobile ? (
                    <span>
                      <img src={device_ico} />
                      {`Apps (${selectedApps.length})`}
                    </span>
                  ) : (
                    <>
                      <span>Apps</span>
                      {selectedApps.length > 0 && (
                        <div className='select-body-apps-count'>
                          {selectedApps.length}
                        </div>
                      )}
                    </>
                  )}
                  <img src={caret_ico} />
                </div>
              </div>
            </Popover>
            {
              <div className='mkc-user-type'>
                <Popover
                  placement='bottom'
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    // TODO All the user types filter should really be merged into one component who takes
                    // care of everything, including url changing, due to the nature filters are today, it is too big of a change
                    // in current timelines
                    <UserTypeFilter
                      onSelect={handleUserOrRoleTypeSelect}
                      selectedUserTypes={selectedUserTypesObjects}
                      selectedUserRoleTypes={selectedUserRoleTypesObject}
                      userTypes={userTypes}
                      userRoleTypes={userRoleTypes}
                    />
                  }
                  trigger='click'
                >
                  <div className='select-filter' style={{ minWidth: '150px' }}>
                    <div className='select-body'>
                      <img src={persona_ico} />
                      <span>{`User Types (${totalTypesSelected || 0})`}</span>
                      <img src={caret_ico} style={{ marginLeft: '6px' }} />
                    </div>
                  </div>
                </Popover>
              </div>
            }
            {viewType === ViewType.Outreach && (
              <Popover
                placement='bottom'
                title={null}
                destroyTooltipOnHide={true}
                content={
                  <ChannelSelect
                    onChannelSelect={handleChannelSelect}
                    selectedChannels={channelIds}
                    closeChannel={() => setChannelOpen(false)}
                    availableChannels={[
                      Channels.email_campaign,
                      Channels.push_notification_campaign
                    ]}
                  />
                }
                open={channelOpen}
                onOpenChange={open => setChannelOpen(open)}
                trigger='click'
                overlayClassName={clsx(
                  'filter-wrap all-discoveries all-discoveries-time fixed-filters',
                  {
                    'mobile-full-screen': isMobile
                  }
                )}
                overlayStyle={{ width: '23rem' }}
              >
                <div
                  className='select-filter'
                  style={{ minWidth: isMobile ? '26%' : '105px' }}
                >
                  <div className='select-body'>
                    {!isMobile ? (
                      <>
                        <img src={share_ico} />
                        <span>{`Channels (${channelIds.length})`}</span>
                        <img src={caret_ico} />
                      </>
                    ) : (
                      <>
                        <span>Channels</span>
                        {channelIds.length > 0 && (
                          <div className='select-body-apps-count'>
                            {channelIds.length}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Popover>
            )}
            <Popover
              placement='bottom'
              title={null}
              destroyTooltipOnHide={true}
              open={timeFrameOpen}
              onOpenChange={setTimeFrameOpen}
              content={
                <TimeFilter
                  value={[customRangeTime[0], customRangeTime[1]]}
                  daysAgoList={daysAgoList}
                  onChange={handleCustomRangeChange}
                  onClose={() => {
                    setTimeFrameOpen(false)
                  }}
                />
              }
              trigger='click'
              overlayClassName={clsx(
                'filter-wrap all-discoveries all-discoveries-time fixed-filters',
                {
                  'mobile-full-screen': isMobile
                }
              )}
            >
              <div
                className='select-filter'
                style={{ minWidth: isMobile ? '30%' : '180px' }}
              >
                <div className='select-body'>
                  <img src={calendar_ico} />
                  <span>
                    {`${customRangeTime[0].format(
                      'MMM DD, YYYY'
                    )} - ${customRangeTime[1].format('MMM DD, YYYY')}`}
                  </span>
                  <img src={caret_ico} style={{ marginLeft: '10px' }} />
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </HeaderWithFilters>
      {/* <div className='selected-filters'>
        {selectedApps.map(selectedApp => (
          <div key={selectedApp.id} className='selected-filter'>
            <img src={selectedApp.icon} className='selected-icon' />
            <img
              src={x_ico}
              className='close-icon'
              onClick={() => {
                handleAppsSelected(
                  selectedApps
                    .filter(app => app.id !== selectedApp.id)
                    .map(({ id }) => id)
                )
              }}
            />
          </div>
        ))}
        {channelIds.map(channel => (
          <div key={channel} className='selected-filter'>
            <span>{ChannelToLabel[channel]}</span>
            <img
              src={x_ico}
              className='close-icon'
              onClick={() => {
                handleChannelSelect(channelIds.filter(c => c !== channel))
              }}
            />
          </div>
        ))}
      </div> */}
    </div>
  )
}
