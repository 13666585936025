import { createAction, createReducer } from 'redux-act';

import { ISubscriptionsState } from '../../interfaces/Subscription';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS'

export const getSubscriptions = createAction(GET_SUBSCRIPTIONS);
export const getSubscriptionsSuccess = createAction(GET_SUBSCRIPTIONS_SUCCESS, (data) => data);

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILED = 'CREATE_SUBSCRIPTION_FAILED';

export const createSubscription = createAction(CREATE_SUBSCRIPTION, (data) => data);
export const createSubscriptionSuccess = createAction(CREATE_SUBSCRIPTION_SUCCESS, (data) => data);
export const createSubscriptionFailed = createAction(CREATE_SUBSCRIPTION_FAILED);

export const EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION'
export const EDIT_SUBSCRIPTION_SUCCESS = 'EDIT_SUBSCRIPTION_SUCCESS';
export const EDIT_SUBSCRIPTION_FAILED = 'EDIT_SUBSCRIPTION_FAILED';

export const editSubscription = createAction(EDIT_SUBSCRIPTION, (data) => data);
export const editSubscriptionSuccess = createAction(EDIT_SUBSCRIPTION_SUCCESS, (data) => data);
export const editSubscriptionFailed = createAction(EDIT_SUBSCRIPTION_FAILED);

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_FAILED = 'DELETE_SUBSCRIPTION_FAILED';

export const deleteSubscription = createAction(DELETE_SUBSCRIPTION, (data) => data);
export const deleteSubscriptionSuccess = createAction(DELETE_SUBSCRIPTION_SUCCESS, (id) => id);
export const deleteSubscriptionFailed = createAction(DELETE_SUBSCRIPTION_FAILED);

export const PUSH_SUBSCRIPTION_UPDATE = 'PUSH_SUBSCRIPTION_UPDATE'
export const pushSubscriptionUpdate = createAction(PUSH_SUBSCRIPTION_UPDATE, (data) => data);

const initState: ISubscriptionsState = {
  subscriptionsList: [],
  subscriptionsError: '',
  subscriptionsInit: false,
  subscriptionsFetched: false
}

export const subscriptionsReducer = createReducer({

  [GET_SUBSCRIPTIONS_SUCCESS]: (state: ISubscriptionsState, payload: any): ISubscriptionsState => {
    return {
      ...state,
      subscriptionsList: payload.data,
      subscriptionsFetched: true
    }
  },
  [CREATE_SUBSCRIPTION_SUCCESS]: (state: ISubscriptionsState, payload: any): ISubscriptionsState => {
    const subscriptionsListCopy = [...state.subscriptionsList]
    subscriptionsListCopy.push(payload.data)
    const firstSub = (state.subscriptionsList.length === 0)
    return {
      ...state,
      subscriptionsList: subscriptionsListCopy,
      subscriptionsError: '',
      subscriptionsInit: firstSub,
      subscriptionsFetched: true
    }
  },
  [EDIT_SUBSCRIPTION_SUCCESS]: (state: ISubscriptionsState, payload: any): ISubscriptionsState => {
    let subscriptionsListCopy
    if (Array.isArray(payload.data)) {
      subscriptionsListCopy = payload.data
    } else {
      subscriptionsListCopy = [...state.subscriptionsList.filter(s => s.id !== payload.data.id)]
      subscriptionsListCopy.push(payload.data)
    }

    return {
      ...state,
      subscriptionsList: subscriptionsListCopy,
      subscriptionsError: ''
    }
  },

  [CREATE_SUBSCRIPTION]: (state: ISubscriptionsState): ISubscriptionsState => {
    return {
      ...state,
      subscriptionsError: ''
    }
  },
  [EDIT_SUBSCRIPTION]: (state: ISubscriptionsState): ISubscriptionsState => {
    return {
      ...state,
      subscriptionsError: ''
    }
  },
  [DELETE_SUBSCRIPTION]: (state: ISubscriptionsState): ISubscriptionsState => {
    return {
      ...state,
      subscriptionsError: ''
    }
  },


  [CREATE_SUBSCRIPTION_FAILED]: (state: ISubscriptionsState): ISubscriptionsState => {
    return {
      ...state,
      subscriptionsError: 'Something went wrong'
    }
  },
  [EDIT_SUBSCRIPTION_FAILED]: (state: ISubscriptionsState): ISubscriptionsState => {
    return {
      ...state,
      subscriptionsError: 'Something went wrong'
    }
  },
  [DELETE_SUBSCRIPTION_FAILED]: (state: ISubscriptionsState): ISubscriptionsState => {
    return {
      ...state,
      subscriptionsError: 'Something went wrong'
    }
  },


  [DELETE_SUBSCRIPTION_SUCCESS]: (state: ISubscriptionsState, payload: any): ISubscriptionsState => {
    const subscriptionsListCopy = [...state.subscriptionsList.filter(s => s.id !== payload.id)]
    return {
      ...state,
      subscriptionsList: subscriptionsListCopy,
      subscriptionsError: ''
    }
  },
}, initState);