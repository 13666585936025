// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.platform-drawer-container {
  padding: 1.6rem;
  min-width: 200px;
}
.platform-drawer-container .platform-drawer-options {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.platform-drawer-container .platform-drawer-options .x-close {
  width: 24px;
  height: 24px;
  margin-left: auto;
}
.platform-drawer-container .platform-drawer-options h5 {
  color: #858585;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.platform-drawer-container .platform-drawer-options .all {
  padding: 0.8rem;
  border-radius: 4px;
  border: 1px solid #e3e4e3;
  background: #f9f9fb;
  margin-left: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.platform-drawer-container .platform-drawer-options .platform-drawer-option {
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  height: 56px;
  border: 1px solid #d4d4d4;
}
.platform-drawer-container .platform-drawer-options .platform-drawer-option.selected {
  background: #f7f7fb;
}
.platform-drawer-container .platform-drawer-options .platform-drawer-option img {
  height: 2.4rem;
  width: 2.4rem;
}
.platform-drawer-container .platform-drawer-options .ant-checkbox-inner {
  background-color: transparent;
  border: 1px solid black;
}
.platform-drawer-container .platform-drawer-options .ant-checkbox-checked::after {
  border: none;
}
.platform-drawer-container .platform-drawer-options .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid black;
  border-top: 0;
  border-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Intel/components/IntelFilters/PlatformDrawer/PlatformDrawer.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACN;AAEI;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAAN;AAGI;EACE,eAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;EACA,0BAAA;EAAA,uBAAA;EAAA,kBAAA;AADN;AAII;EACE,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;AAFN;AAIM;EACE,mBAAA;AAFR;AAKM;EACE,cAAA;EACA,aAAA;AAHR;AAOI;EACE,6BAAA;EACA,uBAAA;AALN;AAQI;EACE,YAAA;AANN;AASI;EACE,uBAAA;EACA,aAAA;EACA,cAAA;AAPN","sourcesContent":[".platform-drawer-container {\n  padding: 1.6rem;\n  min-width: 200px;\n\n  .platform-drawer-options {\n    display: flex;\n    flex-direction: column;\n    gap: 0.8rem;\n\n    .x-close {\n      width: 24px;\n      height: 24px;\n      margin-left: auto;\n    }\n\n    h5 {\n      color: #858585;\n      font-family: Inter;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n    }\n\n    .all {\n      padding: 0.8rem;\n      border-radius: 4px;\n      border: 1px solid #e3e4e3;\n      background: #f9f9fb;\n      margin-left: 0;\n      width: fit-content;\n    }\n\n    .platform-drawer-option {\n      cursor: pointer;\n      padding: 8px 16px;\n      display: flex;\n      align-items: center;\n      gap: 8px;\n      border-radius: 8px;\n      height: 56px;\n      border: 1px solid #d4d4d4;\n\n      &.selected {\n        background: #f7f7fb;\n      }\n\n      img {\n        height: 2.4rem;\n        width: 2.4rem;\n      }\n    }\n\n    .ant-checkbox-inner {\n      background-color: transparent;\n      border: 1px solid black;\n    }\n\n    .ant-checkbox-checked::after {\n      border: none;\n    }\n\n    .ant-checkbox-checked .ant-checkbox-inner::after {\n      border: 2px solid black;\n      border-top: 0;\n      border-left: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
