import './TimeFilter.scss'

import { Button, DatePicker } from 'antd'
import leftArrow from 'assets/images/icons/arrow_left.svg'
import rightArrow from 'assets/images/icons/arrow_right.svg'
import caret_left_ico from 'assets/images/icons/newIntel/caret-left.svg'
import clsx from 'clsx'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import dayjs, { Dayjs } from 'dayjs'
import { convertDaysAgoToCustomRange } from 'helpers/dates'
import React, { useRef, useState } from 'react'

const { RangePicker } = DatePicker

type Props = {
  daysAgoList: { title: string; value: number }[]
  value: [Dayjs, Dayjs] | null
  onChange: (dates: [Dayjs, Dayjs] | null) => void
  onClose?: () => void
}

export const TimeFilter = ({
  daysAgoList,
  value,
  onChange,
  onClose
}: Props) => {
  const isMobile = isMobileDevice()
  const [rangeDates, setRangeDates] = useState<[Dayjs, Dayjs] | null>(value)
  const calendarContainerRef = useRef<HTMLDivElement>(null)

  const handleOnTodayClick = () => {
    setRangeDates([rangeDates?.[0] || dayjs(), dayjs()])
  }

  const renderDaysAgoList = () => {
    return daysAgoList.map(o => {
      const dateRange = convertDaysAgoToCustomRange(o.value)
      const isSelected = rangeDates?.every((date: Dayjs, index: number) =>
        date.isSame(dateRange?.[index], 'day')
      )

      return (
        <div
          key={o.value}
          className={clsx('range-value', {
            active: isSelected
          })}
          onClick={() => setRangeDates(dateRange)}
        >
          {o.title}
        </div>
      )
    })
  }

  const onApply = () => {
    onChange?.(rangeDates)

    onClose?.()
  }

  return (
    <div className='time-filter-wrapper'>
      <h2>
        {isMobile && <img onClick={onClose} src={caret_left_ico} />}
        Time Filter
      </h2>
      <div className='content-wrapper'>
        <div className='left-side'>
          <h4>Presets</h4>
          {isMobile ? (
            <div className='mobile-presets'>{renderDaysAgoList()}</div>
          ) : (
            renderDaysAgoList()
          )}
        </div>
        <div className='right-side'>
          <div className='manual-inputs-dates'>
            <h4>Custom</h4>

            <div className='date-pickers-wrapper'>
              <div className='date-pickers-inputs'>
                <RangePicker
                  variant='borderless'
                  nextIcon={<img src={rightArrow} />}
                  prevIcon={<img src={leftArrow} />}
                  superNextIcon={null}
                  superPrevIcon={null}
                  separator=''
                  open={true}
                  format='DD/MM/YYYY'
                  placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                  defaultOpen
                  popupClassName='wf-ant-range-picker-popup'
                  value={rangeDates}
                  onChange={(dates: any) => {
                    setRangeDates(dates)
                  }}
                  getPopupContainer={(triggerNode: HTMLElement) =>
                    calendarContainerRef.current ||
                    (triggerNode.parentNode as HTMLElement)
                  }
                  popupStyle={{
                    position: 'inherit',
                    inset: 0
                  }}
                  allowClear={false}
                />
                <div className='today' onClick={handleOnTodayClick}>
                  Today
                </div>
              </div>
              <div
                className='calendar-container'
                ref={calendarContainerRef}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className='actions'>
        <Button onClick={onClose}>Cancel</Button>
        <Button type='primary' onClick={onApply}>
          Apply
        </Button>
      </div>
    </div>
  )
}
