import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Card } from "./Card/Card";
import { HandleIntelFilterChangeType } from "pages/Intel/hooks/useIntelFilters";
import { CardInsight } from "pages/Intel/utils/types";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { LinearProgress } from "@mui/material";
import { PLATFORM } from "pages/Intel/utils/consts";
import { trackIntelEvent } from "pages/Intel/utils/analytics";

type Props = {
  handleChangeFilter: HandleIntelFilterChangeType;
  insights: CardInsight[];
  fetchMoreInsights: () => void;
  hasMoreInsights: boolean;
};

export const CardsList = ({
  handleChangeFilter,
  insights,
  fetchMoreInsights,
  hasMoreInsights,
}: Props) => {
  const [hasMore, setHasMore] = useState(true);

  const onCardClick = (id: number) => () => {
    const insight = insights.find((insight) => insight.id === id);

    if (insight) {
      trackIntelEvent("intel_insight_clicked", {
        insight_id: insight?.id,
        insight_title: insight?.title,
        insight_type: insight?.type,
      });

      trackIntelEvent("intel_insight_viewed", {
        insight_id: insight?.id,
        insight_title: insight?.title,
        insight_type: insight?.type,
      });
    }

    handleChangeFilter("insight_id", id.toString());
  };

  useEffect(() => {
    setHasMore(hasMoreInsights);

    let timeout: any = null;

    if (hasMoreInsights) {
      timeout = setTimeout(() => {
        setHasMore(false);
      }, 3000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [hasMoreInsights]);

  return (
    <InfiniteScroll
      dataLength={insights.length}
      next={fetchMoreInsights}
      hasMore={hasMoreInsights}
      loader={hasMore ? <LinearProgress /> : null}
      style={{ overflowX: "hidden" }}
      scrollThreshold={0.5}
    >
      <Row gutter={[24, 24]}>
        {insights.map((insight) => (
          <Col key={insight.id} xs={24} md={12} xxl={8}>
            <Card
              id={insight.id}
              appIcon={insight.app.icon}
              thumbnail={insight.thumbnailOriginal}
              date={moment(insight.startTime).format("MMM DD, YYYY")}
              platform={
                insight.app.platform.toLowerCase() as keyof typeof PLATFORM
              }
              title={insight.title}
              type={insight.type}
              abTestStatus={insight.abTest?.status as any}
              onClick={onCardClick(insight.id)}
            />
          </Col>
        ))}
      </Row>
    </InfiniteScroll>
  );
};
