import moment from 'moment'
import { useLocation, useNavigate } from 'react-router'
import { SELECTED_APPS_MAX_NUM } from "../../../shared/consts";

export enum IntelFiltersParams {
  product_id = 'product_id',
  platform = 'platform',
  days_ago = 'days_ago',
  custom_range = 'custom_range',
  custom_range_time = 'custom_range_time',
  type = 'type',
  experiments = 'experiments',
  features = 'features',
  insight_id = 'insight_id',
  persona_id = 'persona_id',
  offset = 'offset',
  limit = 'limit',
  mode = 'mode',
  release_id = 'release_id'
}

export type Modes = 'by_release' | 'by_date'

type IntelFiltersTypes = {
  product_id: string[]
  platform: string[]
  days_ago: string
  custom_range: string
  custom_range_time: string[]
  type: string
  experiments: string[]
  features: string[]
  insight_id: string
  persona_id: string[]
  offset: string
  limit: string
  mode: Modes
  release_id: string
}

export type HandleIntelFilterChangeType = <T extends keyof IntelFiltersTypes>(
  filter: T,
  newValue: IntelFiltersTypes[T]
) => void

export const useIntelFilters = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const productIds = (params.getAll(IntelFiltersParams.product_id) || []).slice(0, SELECTED_APPS_MAX_NUM)
  const productIdsNumbers = productIds.map(productId => parseInt(productId))
  const platforms = params.getAll(IntelFiltersParams.platform) || []
  const daysAgo = params.get(IntelFiltersParams.days_ago)
    ? parseInt(params.get(IntelFiltersParams.days_ago) || '30')
    : 30
  const customRange = !!(
    params.get(IntelFiltersParams.custom_range) &&
    params.get(IntelFiltersParams.custom_range) !== 'false'
  )
  const customRangeTimes = params.getAll(IntelFiltersParams.custom_range_time)
  const customRangeTime =
    customRangeTimes.length > 1
      ? [moment(customRangeTimes[0]), moment(customRangeTimes[1])]
      : [moment(), moment()]
  const type = params.get(IntelFiltersParams.type) || ''
  const experiments = params.getAll(IntelFiltersParams.experiments) || []
  const features = params.getAll(IntelFiltersParams.features) || []
  const insightId = params.get(IntelFiltersParams.insight_id) || ''
  const personaIds = params.getAll(IntelFiltersParams.persona_id) || []
  const personaIdsNumbers = personaIds.map(personaId => parseInt(personaId))
  const offset = parseInt(params.get(IntelFiltersParams.offset) || '0')
  const limit = parseInt(params.get(IntelFiltersParams.limit) || '30')
  const mode = (params.get(IntelFiltersParams.mode) as Modes) || 'by_date'
  const releaseId = params.get(IntelFiltersParams.release_id) || ''

  const startTimeGte = customRange
    ? customRangeTime[0].format('YYYY-MM-DD')
    : moment().subtract(daysAgo, 'days').format('YYYY-MM-DD')

  const startTimeLt = customRange
    ? customRangeTime[1].format('YYYY-MM-DD')
    : moment().add(1, 'day').format('YYYY-MM-DD')

  // Note that calling this twice in a row before a render will cause params to be stale on the second call
  // and can cause params to be reverted back
  const handleChangeFilter: HandleIntelFilterChangeType = (
    filter,
    newValue
  ) => {
    if (Array.isArray(newValue)) {
      params.delete(filter)
      newValue.forEach(value => {
        params.append(filter, value)
      })
    } else {
      params.set(filter, newValue.toString())
    }

    navigate(`${location.pathname}?${params.toString()}`, { replace: true })
  }

  return {
    productIds: productIdsNumbers,
    personaIds: personaIdsNumbers,
    insightId: parseInt(insightId),
    platforms,
    daysAgo,
    customRange,
    customRangeTime,
    type,
    experiments,
    features,
    startTimeGte,
    startTimeLt,
    offset,
    limit,
    mode,
    releaseId,
    handleChangeFilter
  }
}
