import { Button, Checkbox, message, Popover, Radio, Tabs } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import React, { FC, ReactElement, useEffect, useState } from 'react'

import chevron_white from '../../assets/images/icons/chevron_white.svg'
import Benchmark_ico_hovered from '../../assets/images/new_design/hover/Benchmark-mouse-hover.svg'
import Intelligence_ico_hover from '../../assets/images/new_design/hover/Intelligence-mouse-hover.svg'
import Marketing_ico_hover from '../../assets/images/new_design/hover/Marketing-mouse-hover.svg'
import subscription_ico from '../../assets/images/new_design/hover/Subscriptions-mouse-hover.svg'
import arrow_tab_ico from '../../assets/images/new_design/icons/arrow-tab.svg'
import watchful_logo_stroke from '../../assets/images/new_design/icons/watchful-logo-stroke.svg'
import Benchmark_ico from '../../assets/images/new_design/non-selected/Benchmark-non-selected.svg'
import Intelligence_ico from '../../assets/images/new_design/non-selected/Intelligence-non-selected.svg'
import Marketing_ico from '../../assets/images/new_design/non-selected/Marketing-non-selected.svg'
import { ISubscription } from '../../interfaces/Subscription'
import { IEmailPref, IUserData } from '../../interfaces/User'
import SubscriptionEmpty from '../subscriptions/SubscriptionEmpty'

const { TabPane } = Tabs

interface IProps {
  userData: IUserData
  emailPrefChangesHandle: (emailPref: object) => void
  editSubscriptionHandle: (
    key: string,
    value: boolean | string,
    sub: ISubscription | string
  ) => void
  subscriptions: ISubscription[]
}

export const EMAIL_PREF_TREE = {
  watchful_platform: ['newsletters', 'reports'],
  product_intelligence: [
    'new_meaningful_discoveries',
    'new_releases',
    'related_discoveries',
    'change_in_ab_test',
    'bi_weekly_highlights'
  ],
  product_benchmark: ['app_optimization_and_improvements'],
  marketing_campaigns: ['mass_marketing_competitors']
}

export const EMAIL_PREF_LABELS = {
  newsletters: 'Newsletters',
  reports: 'Reports',
  new_meaningful_discoveries: 'New Meaningful Discoveries Emails',
  new_releases: 'New Discoveries Emails',
  related_discoveries: 'Related Discoveries Emails',
  change_in_ab_test: "Experiments' Status Changes",
  app_optimization_and_improvements: 'Apps Optimization & Improvements Emails',
  mass_marketing_competitors: 'Mass Marketing Competitors Emails',
  bi_weekly_highlights: 'Bi-weekly Highlights'
}

export const EMAIL_PREF_DESCR = {
  newsletters:
    'Newsletters with a set of insights directly related to your product',
  reports: 'Reports with accurate information collected especially for you',
  new_meaningful_discoveries:
    'Informative emails about new meaningful discoveries',
  new_releases: 'Informative emails about new discoveries',
  related_discoveries: 'Informative emails about related discoveries',
  change_in_ab_test: "Informative emails about experiments' outcomes",
  app_optimization_and_improvements:
    'Informative emails about new apps optimization and improvements',
  mass_marketing_competitors:
    'Emails identifying mass marketing email campaigns from your monitored competitors.',
  bi_weekly_highlights:
    'Reports with the main discoveries collected in the last two weeks'
}

export const EMAIL_PREF_DEFAULT = {
  app_optimization_and_improvements: 'daily',
  mass_marketing_competitors: 'monthly',
  new_releases: 'on_demand',
  change_in_ab_test: 'on_demand',
  new_meaningful_discoveries: 'on_demand',
  newsletters: 'on_demand',
  related_discoveries: 'on_demand',
  reports: 'on_demand',
  bi_weekly_highlights: 'bi_weekly'
}

const EmailPreferences: FC<IProps> = ({
  userData,
  emailPrefChangesHandle,
  editSubscriptionHandle,
  subscriptions
}): ReactElement => {
  const isIntelligence = userData.pages.includes('intelligence')
  const isPerformance = userData.pages.includes('benchmark_overview')
  const isMarketing = userData.pages.includes('marketing')
  const [emailPref, emailPrefChange] = useState<IEmailPref>(
    userData.email_preferences
  )

  useEffect(() => {
    emailPrefChange(userData.email_preferences)
    message.destroy()
  }, [userData])

  useEffect(() => {
    message.destroy()
  }, [subscriptions])

  const onChangeParent = (e: CheckboxChangeEvent, product_par_type: string) => {
    const emailPrefCopy = { ...emailPref }
    if (e.target.checked) {
      EMAIL_PREF_TREE[product_par_type as keyof typeof EMAIL_PREF_TREE].forEach(
        type => {
          emailPrefCopy[type as keyof typeof emailPrefCopy] =
            EMAIL_PREF_DEFAULT[type as keyof typeof EMAIL_PREF_DEFAULT]
        }
      )
    } else {
      EMAIL_PREF_TREE[product_par_type as keyof typeof EMAIL_PREF_TREE].forEach(
        type => {
          emailPrefCopy[type as keyof typeof emailPrefCopy] = 'never'
        }
      )
    }
    emailPrefChangesHandle(emailPrefCopy)
    message.loading('saving...', 0)
  }

  const onChangeEmailPref = (e: CheckboxChangeEvent, email_pref: string) => {
    const emailPrefCopy = { ...emailPref }
    emailPrefCopy[email_pref as keyof typeof emailPref] = e.target.checked
      ? EMAIL_PREF_DEFAULT[email_pref as keyof typeof EMAIL_PREF_DEFAULT]
      : 'never'
    emailPrefChangesHandle(emailPrefCopy)
    message.loading('saving...', 0)
  }

  const renderEmailPrefs = (product_par_type: string) => {
    //@ts-ignore
    return EMAIL_PREF_TREE[product_par_type].map((type, i) => (
      <div className='email-pref' key={i}>
        <Checkbox
          checked={emailPref[type as keyof typeof emailPref] !== 'never'}
          className={`checkbox`}
          onChange={(e: CheckboxChangeEvent) => onChangeEmailPref(e, type)}
        />
        {/* @ts-ignore */}
        <span className='email-pref-title'>{EMAIL_PREF_LABELS[type]}</span>
        {/* @ts-ignore */}
        <div className='email-pref-descr'>{EMAIL_PREF_DESCR[type]}</div>
      </div>
    ))
  }

  return (
    <div>
      <h3 className='title'>Preferred Discovery Categories</h3>
      <div className='extra-text'>
        Select the modules you would like to be notified about
      </div>
      <div className={'subscription-buttons'}>
        <Tabs tabPosition='left'>
          <TabPane
            tab={
              <div
                className={`subscription-button subscription-button-enabled`}
              >
                <Checkbox
                  checked={EMAIL_PREF_TREE['watchful_platform'].some(
                    (type: string): boolean =>
                      emailPref[type as keyof typeof emailPref] !== 'never'
                  )}
                  className={`checkbox`}
                  onChange={(e: CheckboxChangeEvent) =>
                    onChangeParent(e, 'watchful_platform')
                  }
                  data-testid={'watchful_platform'}
                />
                <img src={watchful_logo_stroke} className={`icon w_platform`} />
                Watchful Platform
                <div className='arrow-wrap'>
                  <img src={arrow_tab_ico} />
                </div>
              </div>
            }
            key='watchful_platform'
          >
            {renderEmailPrefs('watchful_platform')}
          </TabPane>

          <TabPane
            tab={
              <div className={`subscription-button`}>
                <Checkbox
                  checked={
                    EMAIL_PREF_TREE['product_intelligence'].some(
                      (type: string): boolean =>
                        emailPref[type as keyof typeof emailPref] !== 'never'
                    ) && isIntelligence
                  }
                  className={`checkbox ${
                    !isIntelligence ? 'checkbox-unchecked' : ''
                  }`}
                  onChange={(e: CheckboxChangeEvent) =>
                    onChangeParent(e, 'product_intelligence')
                  }
                  data-testid={'subscribe-intelligence'}
                  disabled={!isIntelligence}
                />
                <img
                  src={
                    isIntelligence ? Intelligence_ico_hover : Intelligence_ico
                  }
                  className={`icon`}
                />
                Product Intelligence
                <div className='arrow-wrap'>
                  <img src={arrow_tab_ico} />
                </div>
              </div>
            }
            key='product_intelligence'
            disabled={!isIntelligence}
          >
            {renderEmailPrefs('product_intelligence')}
          </TabPane>

          <TabPane
            tab={
              <div className={`subscription-button `}>
                {/* @ts-ignore */}
                <Checkbox
                  checked={
                    EMAIL_PREF_TREE['product_benchmark'].some(
                      (type: string): boolean =>
                        emailPref[type as keyof typeof emailPref] !== 'never'
                    ) && isPerformance
                  }
                  className={`checkbox ${
                    !isPerformance ? 'checkbox-unchecked' : ''
                  }`}
                  onChange={(e: CheckboxChangeEvent) =>
                    onChangeParent(e, 'product_benchmark')
                  }
                  data-testid={'product_benchmark'}
                  disabled={!isPerformance}
                />
                <img
                  src={isPerformance ? Benchmark_ico_hovered : Benchmark_ico}
                  className={`icon`}
                />
                Product Benchmark
                <div className='arrow-wrap'>
                  <img src={arrow_tab_ico} />
                </div>
              </div>
            }
            key='product_benchmark'
            disabled={!isPerformance}
          >
            {renderEmailPrefs('product_benchmark')}
          </TabPane>

          <TabPane
            tab={
              <div className={`subscription-button`}>
                <Checkbox
                  checked={
                    EMAIL_PREF_TREE['marketing_campaigns'].some(
                      (type: string): boolean =>
                        emailPref[type as keyof typeof emailPref] !== 'never'
                    ) && isMarketing
                  }
                  className={`checkbox ${
                    !isMarketing ? 'checkbox-unchecked' : ''
                  }`}
                  onChange={(e: CheckboxChangeEvent) =>
                    onChangeParent(e, 'marketing_campaigns')
                  }
                  data-testid={'marketing_campaigns'}
                  disabled={!isMarketing}
                />
                <img
                  src={isMarketing ? Marketing_ico_hover : Marketing_ico}
                  className={`icon`}
                />
                <div className='sub-topic'>Marketing Campaigns</div>
                <div className='arrow-wrap'>
                  <img src={arrow_tab_ico} />
                </div>
              </div>
            }
            key='marketing_campaigns'
            disabled={!isMarketing}
          >
            {renderEmailPrefs('marketing_campaigns')}
          </TabPane>

          <TabPane
            tab={
              <div className={`subscription-button`}>
                {/* @ts-ignore */}
                <Checkbox
                  checked={
                    subscriptions &&
                    subscriptions.some((sub: ISubscription) => sub.email)
                  }
                  className={`checkbox`}
                  onChange={e =>
                    editSubscriptionHandle('email', e.target.checked, 'all')
                  }
                  data-testid={'subscribe-subcriptions'}
                />
                <img src={subscription_ico} className={`icon`} />
                My Subscriptions
                <div className='arrow-wrap'>
                  <img src={arrow_tab_ico} />
                </div>
              </div>
            }
            key='subscription'
          >
            {subscriptions && subscriptions.length > 0 ? (
              <div className='subscriptions'>
                <div className='sub-digest-freq-descr'>
                  DIGEST FREQUENCY TO ALL SUBSCRIPTIONS:
                  <Popover
                    content={
                      <div className='sub-freq-popup'>
                        <Radio.Group
                          onChange={e =>
                            editSubscriptionHandle(
                              'frequency',
                              e.target.value,
                              'all'
                            )
                          }
                          value={subscriptions[0].frequency}
                        >
                          <Radio value='daily'>Daily</Radio>
                          <Radio value='weekly'>Weekly</Radio>
                        </Radio.Group>
                      </div>
                    }
                    placement='bottom'
                  >
                    <Button>
                      {subscriptions[0].frequency} <img src={chevron_white} />
                    </Button>
                  </Popover>
                </div>
                {subscriptions
                  .sort((sub1: ISubscription, sub2: ISubscription) =>
                    sub1.name > sub2.name ? -1 : 1
                  )
                  .map((sub: ISubscription) => (
                    <div key={sub.id} className='email-pref'>
                      <Checkbox
                        checked={sub.email}
                        className={`checkbox`}
                        onChange={(e: CheckboxChangeEvent) =>
                          editSubscriptionHandle('email', e.target.checked, sub)
                        }
                      />
                      <span className='email-pref-title sub-title'>
                        {sub.name}
                      </span>
                      <div className='email-pref-sub-labels'>
                        {sub.labels.map(lab => (
                          <div className='tags__tags-tag-element' key={lab.id}>
                            {lab.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <SubscriptionEmpty />
            )}
          </TabPane>
        </Tabs>
      </div>
      <div className='email-pref-management'>
        <Button
          className={'unsubscribe-button'}
          data-testid='unsubscribe-from-all-emails'
          onClick={() => {
            const clearedEmailPref = Object.values(EMAIL_PREF_TREE)
              .flat()
              .reduce((acc, type) => ({ ...acc, [type]: 'never' }), {})
            emailPrefChangesHandle(clearedEmailPref)
            editSubscriptionHandle('email', false, 'all')
          }}
        >
          <span className={'unsubscribe-text'}>
            Unsubscribe from all emails
          </span>
        </Button>
      </div>
    </div>
  )
}

export default EmailPreferences
