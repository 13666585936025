import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Modal } from 'antd'

import X from '../../assets/images/icons/close_modal.svg'
import blackArrowSlider from '../../assets/images/icons/blackArrowSlider.svg'
import { setInsightAsViewed } from '../../redux/actions/dashboardActions'
import { getABTestTimeLine } from '../../redux/actions/abTestsActions'
import SneakPeakBiWeeklySubHeader from './SneakPeakBiWeeklySubHeader'
import './styles.scss'
import {
  getRecipients,
  sendShareBiWeeklyEmail
} from '../../redux/actions/directLinkActions'

import { useIsFeatureByUserID } from '../../helpers/features'
import InsightPreview from './InsightPreview'
import ReportPreview from '../../containers/BiWeeklyGallery/ReportPreview'
import { sendReportShare } from '../../redux/acts/reports'
import { PL_MARKETING } from '../../shared/consts'

export const SneakPeakInsightModal = ({
  currentItem,
  insights,
  visibility,
  changeItem,
  analytics,
  user,
  closeModalAndRemoveViewedInsights,
  setInsightAsViewed,
  getABTestTimeLine,
  ABTestData,
  biWeekly = false,
  dates,
  rate,
  biWeeklyHighlightID,
  organization,
  setRateBiWeeklyHighlights,
  getRecipients,
  recipients,
  sendShareBiWeeklyEmail,
  getLastSlide,
  showShareModal,
  showShareModalChange,
  sendReportShare,
  linkReferrer,
  source
}) => {
  const [currentItemViewed, currentItemViewedChange] = useState(0)
  const [maxItemViewed, maxItemViewedChange] = useState(0)
  const disRatingIsEnable = useIsFeatureByUserID('discovery_rating', user.email)

  useEffect(() => {
    if (!recipients) {
      getRecipients()
    }
  }, [])

  useEffect(() => {
    if (
      currentItem &&
      currentItem.item &&
      currentItem.insightOrReport === 'insight'
    ) {
      const txt = `View insight (${
        source === 'marketing_campaigns_page' ? 'Marketing' : 'Intel'
      })`
      analytics.analytic(txt, {
        insightID: currentItem.item.id,
        insight_name: currentItem.item.title,
        platform: currentItem.item.platform,
        insightURL: `https://app.watchful.ai${insightLink}`,
        priority: currentItem.item.priority,
        app: currentItem.item.metadata.app_name,
        source: source
      })
      setInsightAsViewed(currentItem.item.insightId)
    } else {
      const txt = 'View report preview'
      analytics.analytic(txt, {
        id: currentItem.item.id,
        name: currentItem.item.name,
        type: currentItem.item.type,
        screenshot_url: currentItem.item.screenshot_url,
        description: currentItem.item.description
      })
    }
    if (currentItem.item && currentItem.item.ab_test_id) {
      if (
        !ABTestData ||
        !ABTestData.find(d => d.id === currentItem.item.ab_test_id)
      ) {
        getABTestTimeLine(currentItem.item.ab_test_id)
      }
    }
  }, [currentItem])

  useEffect(() => {
    currentItemViewedChange(currentItem.key)
    if (currentItem.key > maxItemViewed) {
      maxItemViewedChange(currentItem.key)
    }
  }, [currentItem])

  if (!currentItem) {
    return null
  }
  const isMarketingDiscovery = currentItem?.item?.platform === PL_MARKETING

  let filInsights
  if (Array.isArray(insights)) {
    filInsights = insights
  } else {
    filInsights = insights[currentItem.item.metadata.id]
  }

  if (!filInsights) {
    return null
  }

  const getWidthModal = () =>
    window.innerWidth > 780
      ? 900
      : window.innerWidth > 520
      ? 700
      : window.innerWidth - 20

  const insightLink =
    currentItem.insightOrReport === 'report'
      ? `/reports/${currentItem.item.id}`
      : isMarketingDiscovery
      ? `/marketing/${currentItem.item.insightId}`
      : `/intelligence/versions/${currentItem.item.metadata.id}/${
          currentItem.item.version_id ||
          currentItem.item.release_id ||
          currentItem.item.current_release_id
        }/${currentItem.item.insightId}`
  return (
    <Modal
      maskClosable={!biWeekly}
      onCancel={closeModalAndRemoveViewedInsights}
      width={getWidthModal()}
      {...(biWeekly
        ? {
            style: {
              top: window.innerWidth > 520 ? '25rem' : '30rem',
              centered: true
            }
          }
        : { centered: true })}
      footer={null}
      closeIcon={<img src={X} alt='close-icon' />}
      className='sneak-peak-modal'
      open={visibility}
      maskStyle={{
        backgroundColor: 'rgba(2, 13, 23, 0.9)'
      }}
    >
      {filInsights[currentItem.key - 1] && (
        <div
          className={`slider-arrow arrow-left hover-effect`}
          onClick={() => {
            changeItem(filInsights[currentItem.key - 1], currentItem.key - 1)
          }}
        >
          <img src={blackArrowSlider} alt='' />
        </div>
      )}

      {filInsights[currentItem.key + 1] && (
        <div
          className={`slider-arrow arrow-right hover-effect`}
          onClick={() => {
            changeItem(filInsights[currentItem.key + 1], currentItem.key + 1)
          }}
        >
          <img src={blackArrowSlider} alt='' />
        </div>
      )}

      {biWeekly && (
        <SneakPeakBiWeeklySubHeader
          dates={dates}
          insights={insights}
          currentItemViewed={currentItemViewed}
          currentItem={currentItem}
          biWeeklyHighlightID={biWeeklyHighlightID}
          user={user.email}
          alreadyRated={rate}
          organization={organization}
          setRateBiWeeklyHighlights={setRateBiWeeklyHighlights}
          recipients={recipients}
          sendShareBiWeeklyEmail={sendShareBiWeeklyEmail}
          analytics={analytics}
          showShareModal={showShareModal}
          showShareModalChange={showShareModalChange}
          maxItemViewed={maxItemViewed}
          linkReferrer={linkReferrer}
        />
      )}
      {currentItem.item.lastSlide ? (
        getLastSlide()
      ) : currentItem.insightOrReport === 'insight' ? (
        <InsightPreview
          currentItem={currentItem}
          ABTestData={ABTestData}
          disRatingIsEnable={disRatingIsEnable}
          biWeekly={biWeekly}
          insightLink={insightLink}
          user={user}
          closeModalAndRemoveViewedInsights={closeModalAndRemoveViewedInsights}
          analytics={analytics}
          isMarketingDiscovery={isMarketingDiscovery}
        />
      ) : (
        <ReportPreview
          report={currentItem.item}
          reportLink={insightLink}
          user={user}
          analytics={analytics}
          sendReportShare={sendReportShare}
          recipients={recipients}
        />
      )}
    </Modal>
  )
}

const mapStateToProps = ({ appData, abTestsData, linkData }) => ({
  analytics: appData.user.analytics,
  ABTestData: abTestsData.data,
  recipients: linkData.recipients,
  linkReferrer: linkData.user
})

const mapDispatchToProps = {
  setInsightAsViewed,
  getABTestTimeLine,
  getRecipients,
  sendShareBiWeeklyEmail,
  sendReportShare
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SneakPeakInsightModal)
