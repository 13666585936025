export function hexToRgb (hex: string) {
  // Remove the hash at the front if it's there
  hex = hex.replace(/^#/, '')
  let r = 0,
    g = 0,
    b = 0

  // 3 digits
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16)
    g = parseInt(hex[1] + hex[1], 16)
    b = parseInt(hex[2] + hex[2], 16)
  }
  // 6 digits
  else if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16)
    g = parseInt(hex.slice(2, 4), 16)
    b = parseInt(hex.slice(4, 6), 16)
  }

  return [r, g, b]
}

export function luminance (r: number, g: number, b: number) {
  const a = [r, g, b].map((v: number) => {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export function luminanceFromHex (hex: string) {
  const [r, g, b] = hexToRgb(hex)

  return luminance(r, g, b)
}

export function datasetIndexToColor (index: number) {
  const colors = ['#DB85F5', '#496FFA', '#FDE073', '#F14E4E', '#20B189']

  return colors[index]
}

export function extractAlpha (rgba: string) {
  const parts = rgba.replace('rgba(', '').replace(')', '').split(',')
  return parseFloat(parts[3].trim())
}
