// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctl-insight {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.ctl-insight .container {
  width: 400px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.ctl-insight .container .insight-date {
  position: absolute;
  left: 25px;
  top: 5px;
  color: #8c9398;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
}
.ctl-insight .container .insight-title {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  text-transform: capitalize;
}
.ctl-insight .container .insight-type {
  align-self: flex-start;
  display: flex;
  padding: 2px 16px;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  line-height: 190%; /* 26.6px */
  text-transform: capitalize;
}
.ctl-insight .container .insight-thumbnail img {
  max-width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ContextTimeline/components/main/insights-list/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,SAAA;EACA,sBAAA;AACJ;AACI;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,0BAAA;AACN;AAEI;EACE,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,0BAAA;AAAN;AAGI;EACE,sBAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,0BAAA;AADN;AAII;EACE,gBAAA;AAFN","sourcesContent":[".ctl-insight {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  cursor: pointer;\n\n  .container {\n    width: 400px;\n    display: flex;\n    gap: 16px;\n    flex-direction: column;\n\n    .insight-date {\n      position: absolute;\n      left: 25px;\n      top: 5px;\n      color: #8c9398;\n      font-family: Inter;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 120%; /* 16.8px */\n      text-transform: capitalize;\n    }\n\n    .insight-title {\n      color: rgba(0, 0, 0, 0.8);\n      font-family: Inter;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 160%; /* 22.4px */\n      text-transform: capitalize;\n    }\n\n    .insight-type {\n      align-self: flex-start;\n      display: flex;\n      padding: 2px 16px;\n      align-items: center;\n      justify-content: center;\n      font-family: Inter;\n      font-size: 14px;\n      border-radius: 4px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: 190%; /* 26.6px */\n      text-transform: capitalize;\n    }\n\n    .insight-thumbnail img {\n      max-width: 400px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
