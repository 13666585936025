import React from 'react'
import version_empty from '../../assets/images/version-empty.svg'

export const SubscriptionEmpty = () => {
  return (
    <div className='empty-subs'>
      <h3>Start creating a Subscription!</h3>
      <div className='empty-subs-desc'>
        And get notified when a new insight with the same topic is published.
      </div>
      <div className='empty-subs-img'>
        <img src={version_empty} />
      </div>
    </div>
  )
}

export default SubscriptionEmpty
