import { Tabs } from 'antd'
import dayjs from 'dayjs'
import { AppDispatch } from 'index'
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import ChartContainer from '../../../components/chartContainer/ChartContainer'
import CSelectBox from '../../../components/UICarbonStyleControls/SelectBox'
import { IDisWithApp, IState } from '../../../interfaces/Product'
import { getMarketingCampaigns } from '../../../redux/actions/dashboardActions'
import { getProducts } from '../../../redux/actions/overviewActions'
import { getPersonas } from '../../../redux/acts/personas'
import { getSortedProducts } from '../../products/ProductsSelector'
// import PersonalizedCampaignChart from "./pesonalisedCampainChart/PersonalizedCampaignChart";
import CompetitorsStatus from './competitorsStatus/CompetitorsStatus'
import EmailVolumeChart from './emailVolume/EmailVolumeChart'
import FullCalendarDiscoveries from './FullCalendarDiscoveries'
import MassMarketContent from './massmarketContent/MassMarketContent'
import { personasSelector } from './PersonaSelector'
import PersonaState from './personaState/PersonaState'
import PopularDaysChart from './popularDays/PopularDaysChart'
import UserSegmentChart from './userSegment/UserSegment'

const { TabPane } = Tabs

const MassMarket: FC = (): ReactElement | null => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const email_campaigns_all = useSelector(
    (state: IState) => state.overview?.email_campaigns
  )
  const marketingApps = useSelector(
    (state: IState) => state.overview?.marketingApps
  )
  const analytics = useSelector(
    (state: IState) => state.appData?.user?.analytics
  )
  const personas = useSelector(personasSelector)
  const products = useSelector(getSortedProducts)
  const discoveriesData = useSelector(
    (state: IState) => state.userDiscoveries?.discoveries
  )

  const url = new URLSearchParams(location.search)
  const [currentDate, currentDateChange] = useState<dayjs.Dayjs | null>(null)

  const [massMarketDiscoveries, massMarketDiscoveriesChange] = useState<
    IDisWithApp[]
  >([])

  const [isSticky, setSticky] = useState(false)
  const [months, monthsChange] = useState(
    url.get('time_frame') ? Number(url.get('time_frame')) : 3
  )

  const ref = useRef(null)

  const getDefDate = () => {
    //calendar_month=6&calendar_year=2020&time_frame=3
    const monthFromURL = url.get('calendar_month')
    const yearFromURL = url.get('calendar_year')
    const today = dayjs()
    if (monthFromURL && yearFromURL) {
      return dayjs()
        .month(monthFromURL ? Number(monthFromURL) - 1 : today.month())
        .year(Number(yearFromURL) || today.year())
    }
    return massMarketDiscoveries.find(
      d =>
        dayjs(d.cam.start_time).isSame(dayjs(), 'year') &&
        dayjs(d.cam.start_time).isSame(dayjs(), 'month')
    )
      ? dayjs()
      : dayjs().subtract(1, 'month')
  }

  const currentDateChangeHandler = (date: dayjs.Dayjs) => {
    currentDateChange(date)
    updateURL()
  }

  const updateURL = () => {
    if (currentDate) {
      navigate(
        `/marketing/mass_market?calendar_month=${
          currentDate.month() + 1
        }&calendar_year=${currentDate.year()}&time_frame=${months}`,
        { replace: true }
      )
    }
  }

  useEffect(updateURL, [months])

  useEffect(() => {
    if (!email_campaigns_all) {
      dispatch(getMarketingCampaigns())
    }
    if (!personas || personas.length == 0) {
      dispatch(getPersonas())
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  useEffect(() => {
    if (email_campaigns_all) {
      const data: Array<IDisWithApp> = email_campaigns_all.filter(
        (dis: IDisWithApp) =>
          ['mass_market', 'mass_market_by_segment'].includes(dis.cam.category)
      )
      massMarketDiscoveriesChange(data)
    }
  }, [email_campaigns_all])

  useEffect(() => {
    if (
      massMarketDiscoveries &&
      massMarketDiscoveries.length > 0 &&
      !currentDate
    ) {
      //@ts-ignore
      currentDateChange(getDefDate())
    }
  }, [massMarketDiscoveries])

  useEffect(() => {
    if (!products) {
      dispatch(getProducts())
    }
  })

  const handleScroll = () => {
    if (ref.current && document.querySelector('.ant-tabs-nav')) {
      //@ts-ignore
      setSticky(document.querySelector('.ant-tabs-nav').offsetTop > 0)
    }
  }
  const startTime = dayjs().subtract(months, 'months')
  const relevantDiscoveries = massMarketDiscoveries.filter(
    (dis: IDisWithApp): boolean => {
      if (
        !['mass_market', 'mass_market_by_segment'].includes(dis.cam.category)
      ) {
        return false
      }
      const dis_moment = dayjs(dis.cam.start_time)
      return dis_moment.diff(startTime, 'days') > 0
    }
  )
  if (!marketingApps) {
    return null
  }
  analytics.analytic('Mass Market Page', {
    numOfRelevantDiscoveries: relevantDiscoveries.length
  })

  const k = 4
  let l = 0

  const competitorsAppIDs = Array.from(
    new Set(relevantDiscoveries.map(d => d.app.id))
  ).reduce((acc: number[][], el: number) => {
    if (!acc[l]) {
      acc[l] = []
    }
    if (acc[l].length < k) {
      acc[l].push(el)
    } else {
      l = l + 1
    }
    return acc
  }, [] as number[][])

  return (
    <div className='new-marketing-page'>
      <h1 className='h1-title'>
        <strong>Marketing</strong> Overview
      </h1>
      <div className={'hr-row'} />
      <h2 className={'h2-title'}>Monitored Competitors</h2>
      <div className={'which-products-were'}>
        <div className={'bold'}>Products we’re currently monitoring</div>{' '}
      </div>

      <CompetitorsStatus
        discoveries={massMarketDiscoveries}
        marketingApps={marketingApps}
        personas={personas}
        products={products}
      />
      {currentDate &&
        massMarketDiscoveries &&
        massMarketDiscoveries.length > 0 && (
          <FullCalendarDiscoveries
            discoveries={massMarketDiscoveries}
            currentDate={currentDate}
            currentDateChange={currentDateChangeHandler}
            products={products}
            discoveriesData={discoveriesData}
          />
        )}

      <div className='wrapper-tabs'>
        <Tabs
          animated={{ inkBar: true, tabPane: false }}
          className={`tabs ${isSticky ? 'sticky' : ''}`}
          defaultActiveKey='1'
          tabBarExtraContent={
            <div className={`timeframe-selectbox`} ref={ref}>
              <div className='label'>Time Frame</div>
              <CSelectBox
                value={months + ' MONTHS'}
                onChange={(value: string): void => {
                  monthsChange(parseInt(value))
                }}
              >
                {[3, 6, 12].map(
                  (val: number, i: number): ReactElement => (
                    <CSelectBox.Option value={val} key={i}>
                      {val + ' MONTHS'}
                    </CSelectBox.Option>
                  )
                )}
              </CSelectBox>
            </div>
          }
        >
          <TabPane tab={`Volume`} key='1'>
            <h3 className={'title'}>Volume</h3>

            <div className={'extra-text'}>
              Email discoveries are analyzed to understand your competitors’
              strategy regarding how much Mass Market emails are being sent and
              when they send it
            </div>
            <ChartContainer title='Mass Market Email Volume'>
              <div style={{ minHeight: '25rem', padding: '1rem' }}>
                <EmailVolumeChart
                  discoveries={relevantDiscoveries}
                  timeFrame={months}
                />
              </div>
            </ChartContainer>

            <div className={'gap'} />
            <div className='popular-days-wrapper'>
              <ChartContainer title='Popular days'>
                <div style={{ minHeight: '25rem', padding: '1rem' }}>
                  {relevantDiscoveries &&
                    competitorsAppIDs &&
                    competitorsAppIDs.map((arr, i) => {
                      return (
                        <PopularDaysChart
                          key={i}
                          discoveries={relevantDiscoveries.filter(d =>
                            arr.find(id => id === d.app.id)
                          )}
                        />
                      )
                    })}
                </div>
              </ChartContainer>
            </div>
          </TabPane>

          <TabPane tab='Content' key='2'>
            <h3 className={'title'}>Content</h3>
            <div className={'extra-text'}>
              Deep-dive into what is actually promoted in your competitors’
              emails
            </div>

            <MassMarketContent discoveries={relevantDiscoveries} />

            <div className={'gap'} />
            {/* <div className={'mass-market-email-vo'}>Content Personalisation</div>
            <div className={'the-number-of-mass-m'}>The number of mass-market emails containing personalized content received in the specified time range from each competitor</div>
            <div className="personalized-email-wrapper">
              <PersonalizedCampaignChart discoveries={relevantDiscoveries} />
            </div> */}
          </TabPane>

          <TabPane tab='Personas' key='3'>
            <h3 className={'title'}>Persona State</h3>
            <div className={'extra-text'}>
              The number of mass-market emails received by Active and Inactive
              users in the specified time range by each competitor
            </div>

            <div className='persona-state-wrapper'>
              <PersonaState personas={personas} products={products} />
            </div>
            <div className={'gap'} />

            <div className={'mass-market-email-vo'}>User Segmentation</div>
            <div className={'the-number-of-mass-m'}>
              The number of mass-market emails received by different user
              segments in the specified time range from each competitor
            </div>
            <div className='segment-chart-wrapper'>
              {relevantDiscoveries && relevantDiscoveries.length > 0 && (
                <UserSegmentChart
                  discoveries={relevantDiscoveries}
                  timeFrame={months}
                />
              )}
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default MassMarket
