import * as actionTypes from '../actions/_actionsTypes'
import {
  POST_USER_DISCOVERY_COMMENT_SUCCESS,
  EDIT_USER_DISCOVERY_COMMENT_SUCCESS,
  DELETE_USER_DISCOVERY_COMMENT_SUCCESS
} from '../acts/userDiscoveries'
import { appsSorterFunction } from '../../helpers/appsHelper'

import { analyzeRequestsState } from '../../services/loaderLogs'

const INIT_STATE = {
  apps: [],
  ownApps: [],
  user: {},
  insightLabelsMap: {},
  allInsights: [],
  allVersions: [],
  impersonateUser: null,
  appSelect: 0,
  users: [],
  siteVersion: '',
  mainLoaderStatus: false,
  isLoadingInsightInterestingStatus: false,
  isSendingInsightQuestion: false,
  insightQuestionSuccess: undefined,
  filterByApps: {},
  isAllAppsDataFetched: false,
  currentRequests: [],
  intelligenceLastUpdate: null,
  appSelectedDemo: false,
  onlyPerformanceMode: null,
  appsLoaded: false,
  sharedInsightData: {}
}

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.ALL_APPS_DATA_FETCHED: {
      return {
        ...state,
        isAllAppsDataFetched: action.payload
      }
    }

    case actionTypes.CURRENT_REQUESTS_ADD: {
      const modCurrentRequests = [...state.currentRequests]
      if (!modCurrentRequests.includes(action.payload)) {
        modCurrentRequests.push(action.payload)
      }
      analyzeRequestsState(modCurrentRequests)
      return {
        ...state,
        currentRequests: modCurrentRequests
      }
    }

    case actionTypes.CURRENT_REQUESTS_REMOVE: {
      const modCurrentRequests = [...state.currentRequests]
      const index = modCurrentRequests.indexOf(action.payload)
      if (index !== -1) {
        modCurrentRequests.splice(index, 1)
      }
      analyzeRequestsState(modCurrentRequests)
      return {
        ...state,
        currentRequests: modCurrentRequests
      }
    }

    case actionTypes.CLEAR_APP_FILTER: {
      return {
        ...state,
        filterByApps: {}
      }
    }
    case actionTypes.SEARCH_APP_FILTER: {
      const { filterByApps } = state

      const { id } = action.payload
      if (filterByApps[id]) {
        delete filterByApps[id]
      } else filterByApps[id] = action.payload

      return {
        ...state,
        filterByApps
      }
    }
    case actionTypes.ASK_INSIGHT_QUESTION: {
      return {
        ...state,
        isSendingInsightQuestion: true,
        insightQuestionSuccess: undefined
      }
    }

    case actionTypes.SET_ASK_INSIGHT_QUESTION_SUCCESS: {
      const { status } = action.payload
      return {
        ...state,
        isSendingInsightQuestion: false,
        insightQuestionSuccess: status
      }
    }

    case actionTypes.GET_APPS_METADATA_SUCCESS: {
      let returnedAppsList = []
      let returnedOwnAppsList = []
      if (action.payload.apps.length === 0) {
        returnedAppsList = []
      } else {
        returnedAppsList = action.payload.apps
          .filter(app => !app.is_customer_own_app || app.has_experiments)
          .map(metadata => ({
            metadata,
            loaded: false
          }))
        returnedOwnAppsList = action.payload.apps
          .filter(app => app.is_customer_own_app && !app.has_experiments)
          .map(metadata => ({
            metadata,
            loaded: false
          }))

        returnedAppsList.sort((a, b) =>
          appsSorterFunction(a.metadata.display_name, b.metadata.display_name)
        )
      }

      returnedAppsList.forEach(app => {
        app.versions = app.versions || []
        app.versions.forEach(version => {
          version.ab_tests = version.ab_tests || []
          version.new_features = version.new_features || []
          version.indications = version.indications || []
          version.screen_changes = version.screen_changes || []
          version.removed_features = version.removed_features || []
        })
      })

      return {
        ...state,
        apps: returnedAppsList,
        appsLoaded: true,
        ownApps: returnedOwnAppsList
      }
    }
    case actionTypes.GET_APPS_METADATA_FAILED: {
      return {
        ...state,
        onlyPerformanceMode: true,
        apps: [],
        ownApps: []
      }
    }

    case actionTypes.SET_APPS_DATA: {
      const { apps, demo } = action.payload
      return {
        ...state,
        apps,
        appSelect: 0,
        appSelectedDemo: demo
      }
    }
    case actionTypes.SET_SITE_VERSION: {
      const { siteVersion } = action.payload
      return {
        ...state,
        siteVersion
      }
    }

    case actionTypes.SET_USER: {
      return {
        ...state,
        user: action.payload.user
      }
    }

    case actionTypes.SET_IMPERSONATE_USER: {
      let impersonateChange = action.payload.impersonateUser
      if (impersonateChange === state.user.sub) {
        impersonateChange = null
      }
      return {
        ...state,
        impersonateUser: impersonateChange
      }
    }
    case actionTypes.SET_MAIN_LOADER: {
      return {
        ...state,
        mainLoaderStatus: action.payload.status
      }
    }
    case actionTypes.SET_CAMPAIGN_DEMO_MODE: {
      return {
        ...state,
        appSelectedDemo: true
      }
    }

    case actionTypes.GET_UNSEEN_CRITICAL_INSIGHTS_PER_RELEASE_SUCCESS: {
      let data = action.payload.unSeenInsights
      let modAllVersions = [...state.allVersions]
      const appsCopy = [...state.apps]
      let app = appsCopy.find(app => app.metadata.id === action.payload.app_id)

      Object.keys(data).forEach(insID => {
        let ver = modAllVersions.find(ver => ver.id === Number(insID))
        if (ver) {
          ver.unseen_count = data[insID].length
        } else {
          console.log(`there isn't app with version - ${ver}`)
        }
        if (app.versions.find(ver => ver.id === Number(insID))) {
          app.versions.find(ver => ver.id === Number(insID)).unseen_count =
            data[insID].length
        }
      })

      return {
        ...state,
        allVersions: modAllVersions,
        apps: appsCopy
      }
    }

    case actionTypes.SET_ALL_INSIGHTS_VIEWED_SUCCESS: {
      let seenInsights = action.payload.seenInsights
      let release_id = action.payload.release_id
      let modAllVersions = [...state.allVersions]
      const appsCopy = [...state.apps]
      let appID = modAllVersions.find(
        ver => ver.id === Number(release_id)
      ).app_id
      let app = appsCopy.find(app => app.metadata.id === appID)

      seenInsights.forEach(() => {
        let ver = modAllVersions.find(ver => ver.id === Number(release_id))
        if (ver) {
          ver.unseen_count = null
        } else {
          console.log(`there isn't app with version - ${ver}`)
        }
        if (app.versions.find(ver => ver.id === Number(release_id))) {
          app.versions.find(ver => ver.id === Number(release_id)).unseen_count =
            null
        }
      })

      return {
        ...state,
        allVersions: modAllVersions,
        apps: appsCopy
      }
    }

    case actionTypes.GET_RELEVANT_APPS_INSIGHTS: {
      return {
        ...state,
        allInsights: [...state.allInsights, ...action.payload],
        appSelectedDemo: true
      }
    }
    case actionTypes.GET_LINK_DATA_SUCCESS: {
      const {
        data: { insight_data }
      } = action.payload
      const metadata = insight_data.metadata
      return {
        ...state,
        allInsights: [
          { insight: { ...insight_data, fullLoaded: true }, metadata: metadata }
        ]
      }
    }
    case actionTypes.GET_DISCOVERY_DATA_SUCCESS: {
      let allInsightsCopy = [...state.allInsights]
      let dis = action.payload.data
      const oldDis = allInsightsCopy.find(ins => ins.insight.id === dis.id)

      if (oldDis) {
        oldDis.insight = {
          ...oldDis.insight,
          ...dis,
          fullLoaded: true
        }
      } else {
        allInsightsCopy.push({
          metadata: state.apps.find(a => a.metadata.id === dis.app_id).metadata,
          insight: {
            ...dis,
            fullLoaded: true
          }
        })
      }

      action.payload.data.related_insights.forEach(relDis => {
        const oldRelDis = allInsightsCopy.find(
          ins => ins.insight.id === relDis.id
        )
        if (!oldRelDis) {
          allInsightsCopy.push({
            metadata: state.apps.find(a => a.metadata.id === relDis.app_id)
              .metadata,
            insight: { ...relDis, fullLoaded: true }
          })
        }
      })

      return {
        ...state,
        allInsights: allInsightsCopy
      }
    }

    case actionTypes.SET_SHARED_INSIGHT_DATA: {
      return {
        ...state,
        sharedInsightData: action.payload
      }
    }

    //COMMENTS
    case POST_USER_DISCOVERY_COMMENT_SUCCESS: {
      let { insight_id } = action.payload
      const allInsightsCopy = state.allInsights.map(dis =>
        dis.insight.id === insight_id
          ? {
              metadata: state.apps.find(
                a => a.metadata.id === dis.insight.app_id
              ).metadata,
              insight: {
                ...dis.insight,
                comments: [...dis.insight.comments, action.payload]
              }
            }
          : dis
      )
      return {
        ...state,
        allInsights: allInsightsCopy
      }
    }
    case DELETE_USER_DISCOVERY_COMMENT_SUCCESS: {
      let { insight_id, comment_id } = action.payload
      const allInsightsCopy = state.allInsights.map(dis =>
        dis.insight.id === insight_id
          ? {
              metadata: state.apps.find(
                a => a.metadata.id === dis.insight.app_id
              ).metadata,
              insight: {
                ...dis.insight,
                comments: dis.insight.comments.filter(c => c.id !== comment_id)
              }
            }
          : dis
      )
      return {
        ...state,
        allInsights: allInsightsCopy
      }
    }
    case EDIT_USER_DISCOVERY_COMMENT_SUCCESS: {
      let { comment, insight_id, comment_id } = action.payload

      const allInsightsCopy = state.allInsights.map(dis =>
        dis.insight.id === insight_id
          ? {
              metadata: state.apps.find(
                a => a.metadata.id === dis.insight.app_id
              ).metadata,
              insight: {
                ...dis.insight,
                comments: dis.insight.comments.map(c =>
                  c.id !== comment_id ? c : comment.data
                ),
                lastCommentUpdate: new Date().getTime()
              }
            }
          : dis
      )
      return {
        ...state,
        allInsights: allInsightsCopy
      }
    }

    default: {
      return state
    }
  }
}
