import './styles.scss'

import { Button, Checkbox, Col, Tooltip } from 'antd'
import { ReactComponent as InfoIco } from 'assets/images/icons/info-new-dark.svg'
import { getPersonaTitle } from 'containers/MarketingCampaings/utils/helpers'
import { UserType } from 'containers/MarketingCampaings/utils/types'
import React from 'react'

type Props = {
  userTypes: UserType[]
  selectedUserTypes: UserType[]
  onSelectUserTypes: (userTypeIds: number[]) => void
  disableSelectAll?: boolean
}

export const UserTypeFilter = ({
  selectedUserTypes,
  userTypes,
  onSelectUserTypes,
  disableSelectAll
}: Props) => {
  const selectedUserTypesIds = selectedUserTypes.map(userType => userType.id)

  const handleSelectType = (selectedUserTypesIds: any[]) => {
    onSelectUserTypes(selectedUserTypesIds.map(id => parseInt(id)))
  }

  return (
    <div className='user-type-popup'>
      <div className='items-selected'>
        <div className='items-selected-count'>
          {`${selectedUserTypes.length} Selected`}{' '}
        </div>
        {!disableSelectAll && selectedUserTypes.length !== 0 ? (
          <Button
            onClick={() => onSelectUserTypes([])}
            className='all ant-btn-dark'
          >
            Clear
          </Button>
        ) : !disableSelectAll ? (
          <Button
            onClick={() =>
              onSelectUserTypes(userTypes.map(userType => userType.id))
            }
            className='all ant-btn-dark'
          >
            All
          </Button>
        ) : null}
      </div>
      <div className='filters-content'>
        <Checkbox.Group
          onChange={handleSelectType}
          value={selectedUserTypesIds}
        >
          <div className='filters-content-checkbox-wrapper'>
            {userTypes.map(userType => (
              <Col key={userType.id}>
                <Checkbox
                  className='user-type-popup-checkbox-group-item'
                  value={userType.id}
                  style={{
                    backgroundColor: userType.bgColor,
                    color: userType.textColor
                  }}
                >
                  <span className='user-type-popup-checkbox-group-tem-label-text'>
                    {getPersonaTitle(userType)}
                  </span>
                  <span className='user-type-popup-checkbox-group-tem-label-icon-container'>
                    <Tooltip
                      overlayClassName='info-header-tooltip'
                      placement='top'
                      title={userType?.description || 'No description'}
                    >
                      <InfoIco fill={userType.textColor} />
                    </Tooltip>
                  </span>
                </Checkbox>
              </Col>
            ))}
          </div>
        </Checkbox.Group>
      </div>
    </div>
  )
}
