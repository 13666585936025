import { createAction, createReducer } from 'redux-act';

import { Ipersona } from '../../interfaces/Product';
import { GET_PERSONAS, GET_PERSONAS_FAILURE, GET_PERSONAS_SUCCESS } from '../actions/_actionsTypes';

export const getPersonas = createAction(GET_PERSONAS);
export const getPersonasSuccess = createAction(GET_PERSONAS_SUCCESS, (data) => data);
export const getPersonasFailure = createAction(GET_PERSONAS_FAILURE);



export const personasReducer = createReducer({

  [GET_PERSONAS]: (state) => {
    return state
  },
  [GET_PERSONAS_SUCCESS]: (state: object, payload: any): Ipersona[] | object => {
    return payload.data
  },
  [GET_PERSONAS_FAILURE]: (state: object) => {
    return state
  },

}, []);