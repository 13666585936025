import { useMemo } from 'react'

const useTableHeight = (parentElement: HTMLElement | null) => {
  const tableHeight = useMemo(() => {
    if (!parentElement) {
      return '67vh'
    }

    const viewportHeight = window.innerHeight
    const parentHeight = parentElement.clientHeight

    return `${(parentHeight / viewportHeight) * 100 - 8}vh`
  }, [parentElement])

  return tableHeight
}

export default useTableHeight
