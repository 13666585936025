import './ReportsList.scss'

import { Button, Input, Popover, Table } from 'antd'
import { AppDispatch } from 'index'
import moment from 'moment'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import download_ico from '../../assets/images/new_design/icons/download_ico_black.svg'
import share_ico from '../../assets/images/new_design/icons/share_ico_black.svg'
import watchful_icon from '../../assets/images/watchful_icon.svg'
import ViewModal from '../../components/discoveryView/viewModal/ViewModal'
import ViewTags from '../../components/discoveryView/viewTags/ViewTags'
import { IState } from '../../interfaces/Product'
import { IReport } from '../../interfaces/Reports'
import { getRecipients } from '../../redux/actions/directLinkActions'
import { sendReportShare } from '../../redux/acts/reports'
import { ALL_TIME, daysAgoList } from '../../shared/consts'
import FilterTime from '../dash-discoveries/FilterTime'
import FilterTypes from '../intelligence/Marketing/FilterTypes'
import { sendShareMail } from './ReportUtils'

const { Search } = Input

export const REPORTS_PLACE_HOLDER =
  'I am sharing with you a new report with significant insights. Check it out:'
export const NEWSLETTER_PLACE_HOLDER =
  'I am sharing with you a new newsletter with significant insights. Check it out:'

export const ReportsList: FC = (): ReactElement | null => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const { user, reports, recipients, analytics } = useSelector(
    (state: IState) => ({
      user: state.appData?.user,
      reports: state.reports?.reports,
      recipients: state.linkData?.recipients,
      analytics: state.appData?.user?.analytics
    })
  )

  const [shareModalShow, shareModalShowChange] = useState(false)
  const [reportForShare, reportForShareChange] = useState<IReport>()
  const [recipientForShare, recipientForShareChange] = useState(null)
  const [commentForShare, commentForShareChange] = useState('')

  //vars filter
  const url = new URLSearchParams(location.search)

  const [filteredTags, filteredTagsChange] = useState(
    url.get('filteredTags') ? url.get('filteredTags')?.split(',') : []
  )
  const [searchQuery, searchQueryChange] = useState(
    url.get('searchQuery') || ''
  )
  const [customRange, сustomRangeСhange] = useState(
    url.get('customRange') ? true : false
  )
  const [daysAgo, daysAgoChange] = useState(url.get('daysAgo') || ALL_TIME)
  //@ts-ignore
  const [customRangeTime, customRangeTimeChange] = useState(
    url.get('customRangeTime')
      ? [
          moment(url.get('customRangeTime')?.split(',')[0]),
          moment(url.get('customRangeTime')?.split(',')[1])
        ]
      : [moment(), moment()]
  )

  const [filteredReports, filteredReportsChange] = useState<IReport[]>(
    reports || []
  )
  const [isOpenTimeFilter, isOpenTimeFilterChange] = useState(false)

  const columns = [
    {
      title: '',
      dataIndex: 'img',
      key: 'img',
      render: (text: string, rep: IReport) =>
        rep.screenshot_url ? (
          <div
            className='report-icon real'
            onClick={() =>
              navigate(
                `/reports/${rep.id}?from=${location.pathname}&source=reports_list`
              )
            }
          >
            <img src={rep.screenshot_url} className='app-icon' />{' '}
          </div>
        ) : (
          <div className='report-icon'>
            <img src={watchful_icon} />
          </div>
        ),
      width: '5rem'
    },
    {
      title: 'Report Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (rep1: IReport, rep2: IReport) =>
        rep1.name < rep2.name ? 1 : -1,
      render: (text: string, rep: IReport) => (
        <div
          className={`report-name ${rep.app_read_at ? '' : 'unread'}`}
          onClick={() =>
            navigate(
              `/reports/${rep.id}?from=${location.pathname}&source=reports_list`
            )
          }
        >
          {text}
        </div>
      ),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Published Date',
      dataIndex: 'upload_date',
      key: 'upload_date',
      sorter: (rep1: IReport, rep2: IReport) =>
        moment(rep1.upload_date).isBefore(rep2.upload_date) ? -1 : 1,
      render: (upload_date: string, rep: IReport) => (
        <div className={`report-date ${rep.app_read_at ? '' : 'unread'}`}>
          {moment(upload_date).format('LL')}
        </div>
      ),
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'File Size',
      key: 'size_bytes',
      dataIndex: 'size_bytes',
      render: (size_bytes: number, rep: IReport) => (
        <div className={`report-size ${rep.app_read_at ? '' : 'unread'}`}>
          {(size_bytes / 1024).toFixed(0)} KB
        </div>
      ),
      width: '12rem',
      sorter: (rep1: IReport, rep2: IReport) =>
        rep1.size_bytes < rep2.size_bytes ? -1 : 1,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'File Type',
      key: 'is_newsletter',
      dataIndex: 'is_newsletter',
      render: (is_newsletter: number) => (
        <div className={`report-type`}>
          {is_newsletter ? 'Newsletter' : 'Report'}
        </div>
      ),
      sorter: (rep1: IReport, rep2: IReport) =>
        rep1.is_newsletter < rep2.is_newsletter ? -1 : 1,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: '',
      key: 'tags',
      render: (tags: string, rep: IReport) => (
        <ViewTags tags={rep.tags.map(tag => tag.name)} />
      )
    },
    {
      title: '',
      key: 'buttons',
      render: (str: string, rep: IReport) => (
        <div className='report-view-header-buttons'>
          <div className='report-share'>
            <Button
              onClick={() => {
                shareModalShowChange(true)
                reportForShareChange(rep)
              }}
            >
              <img src={share_ico} />
              Share
            </Button>
          </div>
          <a
            href={rep.url}
            className='report-download'
            download
            onClick={() => {
              analytics.analytic(`User downloaded ${rep.id} - ${rep.name}`)
            }}
          >
            <Button>
              <img src={download_ico} />
              Download
            </Button>
          </a>
        </div>
      )
    }
  ]

  useEffect(() => {
    if (!recipients) {
      dispatch(getRecipients())
    }
  }, [])

  useEffect(() => {
    filteredReportsChange(reports?.filter(checkFilter) || [])

    const filters = {
      daysAgo: daysAgo,
      filteredTags: filteredTags,
      searchQuery: searchQuery,
      customRange: customRange ? '1' : '',
      customRangeTime: customRangeTime.map(date => date.format('YYYY-MM-DD')),
      days_ago: daysAgo
    }

    //@ts-ignore
    const gets = new URLSearchParams(filters).toString()
    navigate(`${location.pathname}?${gets}`, { replace: true })
  }, [searchQuery, daysAgo, customRange, customRangeTime, filteredTags])

  const checkFilter = (report: IReport): boolean => {
    if (
      !customRange &&
      Math.abs(moment(report.upload_date).diff(moment(), 'days')) >
        Number(daysAgo)
    ) {
      return false
    }

    let passTag = false,
      passQuery = false,
      passCustomRange = false

    if (customRange) {
      const from = moment(customRangeTime[0])
        .hours(0)
        .minutes(0)
        .seconds(0)
        .subtract(1, 'seconds')
      const to = moment(customRangeTime[1]).hours(23).minutes(59).seconds(59)
      passCustomRange = moment(report.upload_date).isBetween(from, to)
    } else {
      passCustomRange = true
    }

    if (filteredTags?.length == 0) {
      passTag = true
    } else {
      if (
        (report.tags.filter(x => filteredTags?.includes(x.name)).length || 0) >
        0
      ) {
        passTag = true
      }
    }
    if (searchQuery == '') {
      passQuery = true
    } else {
      if (report.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        passQuery = true
      }
    }

    return passTag && passQuery && passCustomRange
  }

  //@ts-ignore
  const tags = reports.reduce((acc, rep: IReport) => {
    if (rep.tags.length) {
      //@ts-ignore
      return [...new Set([...acc, ...rep.tags.map(t => t.name)])]
    } else {
      return acc
    }
  }, [])

  return (
    <div className='report-list-page'>
      <h1 className='h1-title'>Reports</h1>
      <div className='hr-row' />
      <div className='reports-list-filters'>
        <div className='reports-list-filters-search'>
          <Search
            placeholder='Search for specific Report'
            value={searchQuery}
            //@ts-ignore
            onChange={e => searchQueryChange(e.target.value)}
            //onSearch={}
            style={{ width: 320 }}
          />
        </div>
        <div className='sep-flex-grow' />
        <div className='reports-list-filters-time-tags'>
          <Popover
            placement='bottom'
            title={null}
            content={
              <FilterTypes
                filteredTagsChange={filteredTagsChange}
                tags={tags}
                filteredTags={filteredTags || []}
                showClearAllButton={false}
              />
            }
            trigger='click'
            overlayClassName={`filter-wrap marketing-filter-type-wrapper`}
          >
            <Button className='filter-btn-new'>
              Filters
              <span className='filter-count'>{filteredTags?.length || 0}</span>
            </Button>
          </Popover>
          <span className='sep' />
          <Popover
            placement='bottomRight'
            title={null}
            open={isOpenTimeFilter}
            onOpenChange={isOpenTimeFilterChange}
            content={
              <FilterTime
                isOpen={isOpenTimeFilter}
                daysAgoList={daysAgoList}
                daysAgo={daysAgo}
                daysAgoChange={daysAgoChange}
                customRange={customRange}
                сustomRangeСhange={сustomRangeСhange}
                customRangeTime={customRangeTime}
                customRangeTimeChange={customRangeTimeChange}
              />
            }
            trigger='click'
            overlayClassName={`filter-wrap all-discoveries all-discoveries-time`}
            overlayStyle={{ width: customRange ? '75rem' : '18rem' }}
          >
            <Button className='filter-btn-new'>
              {customRange
                ? 'Custom Range'
                : //@ts-ignore
                  daysAgoList.find(d => d.value == daysAgo).title}
            </Button>
          </Popover>
        </div>
      </div>
      <div className='reports-table-wrapper'>
        <Table
          //@ts-ignore
          columns={columns}
          dataSource={filteredReports}
          pagination={false}
          rowClassName={record =>
            record.tags && record.tags.length > 0 ? 'has-tags' : ''
          }
        />
      </div>

      {reportForShare && shareModalShow && (
        <ViewModal
          closeModal={() => shareModalShowChange(false)}
          sendEmail={() =>
            sendShareMail(
              reportForShare,
              recipientForShare,
              (params: any) => dispatch(sendReportShare(params)),
              commentForShare,
              shareModalShowChange,
              analytics
            )
          }
          modalShow={shareModalShow}
          linkUrl={`${window.location.origin}/reports/${reportForShare.id}?source=reports_share`}
          recipients={recipients}
          recipient={recipientForShare}
          recipientChange={recipientForShareChange}
          comment={commentForShare}
          commentChange={commentForShareChange}
          subject='report'
          placeHolder={
            reportForShare.is_newsletter
              ? NEWSLETTER_PLACE_HOLDER
              : REPORTS_PLACE_HOLDER
          }
          linkCode={''}
          updateLinkData={() => {}}
          user={user}
          whoCanView={'Anyone with this link, will be able to view this report'}
        />
      )}
    </div>
  )
}

export default ReportsList
