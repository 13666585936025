import React from 'react'
import { useSelector } from 'react-redux'
import {
  NavigationType,
  useNavigationType,
  useLocation,
  Outlet
} from 'react-router'
import { updateViewportMeta } from 'shared/mobile-fix'

const ChangePageMonitor = () => {
  const analytics = useSelector(state => state.user?.analytics)
  const navigationType = useNavigationType()
  const location = useLocation()

  if (navigationType === NavigationType.Push) {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }
  if (analytics) {
    analytics.analytic(`Change page`, {
      Target: location.pathname,
      searchParams: location.search
    })
  }

  updateViewportMeta()

  return <Outlet />
}

export default ChangePageMonitor
