// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sec-container {
  display: flex;
  gap: 10px;
}
.sec-container .arrows {
  display: flex;
  gap: 4px;
  align-items: center;
}
.sec-container .arrows .arrow {
  padding: 6px 8px;
  cursor: pointer;
  background-color: white;
  border-radius: 4px;
  z-index: 20;
}
.sec-container .arrows .arrow:hover {
  background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/pages/ContextTimeline/components/sidebar/selected-event-count/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACJ;AACI;EACE,gBAAA;EACA,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;AACN;AACM;EACE,yBAAA;AACR","sourcesContent":[".sec-container {\n  display: flex;\n  gap: 10px;\n\n  .arrows {\n    display: flex;\n    gap: 4px;\n    align-items: center;\n\n    .arrow {\n      padding: 6px 8px;\n      cursor: pointer;\n      background-color: white;\n      border-radius: 4px;\n      z-index: 20;\n\n      &:hover {\n        background-color: #f5f5f5;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
