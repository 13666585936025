import { Button, Radio, Select, Tooltip } from 'antd'
import { isEqual } from 'lodash'
import React, { useState } from 'react'

import { MATCH_TYPES } from '../../containers/intelligence/Marketing/FilterTypes'
import { ILabel } from '../../interfaces/Label'
import { ClickableFiltersTags } from '../discoveryView/viewTags/ViewTags'

interface IProps {
  filterTagsChange: (args1: string[]) => void
  filterTagsMatch: number
  setFilterTagsMatch: (arg0: string) => void
  tagsList: ILabel[]
  filterTags: string[]
  maxAllowedTags?: number
}

export default function FilterTags ({
  filterTags,
  filterTagsChange,
  tagsList,
  setFilterTagsMatch,
  filterTagsMatch,
  maxAllowedTags
}: IProps) {
  const [filterWord, filterWordChange] = useState('')
  const isMaxTags = maxAllowedTags && filterTags.length >= maxAllowedTags

  return (
    <div className='filters-popup tags'>
      <div className='items-selected static'>
        <div className='button-and-radiobuttons'>
          <Radio.Group
            value={filterTagsMatch}
            onChange={(e: any) => setFilterTagsMatch(e.target.value)}
          >
            {MATCH_TYPES.map((type: string) => (
              <Tooltip
                key={type}
                title={
                  type === MATCH_TYPES[0]
                    ? 'Ex: Tag1 or Tag2 or...'
                    : 'Ex: Tag1 and Tag2 and...'
                }
              >
                <Radio value={type}>{type}</Radio>
              </Tooltip>
            ))}
          </Radio.Group>
          {!maxAllowedTags && (
            <Button
              onClick={() => {
                filterWordChange('')
                filterTagsChange([])
              }}
              className='ant-btn-dark tags-reset'
            >
              All
            </Button>
          )}
        </div>
      </div>
      <div>
        <div className={'tags-header-wrapper'}>
          <h3 className='sub-header'>Tags</h3>
          {isMaxTags && (
            <div className={'max-tags'}>Up to 10 tags selected</div>
          )}
        </div>
        <div>
          <Select
            style={{ width: '100%' }}
            mode='tags'
            onSearch={word => {
              filterWordChange(word ? word : '')
            }}
            onChange={(selected: string[]) => {
              const newTags = selected
                .filter(selTag =>
                  tagsList.find(
                    tag => tag.name.toLowerCase() == selTag.toLowerCase()
                  )
                )
                .map(selTag =>
                  String(
                    tagsList.find(
                      tag => tag.name.toLowerCase() == selTag.toLowerCase()
                    )?.id
                  )
                )
              if (!isEqual(newTags, filterTags)) {
                filterTagsChange(newTags)
              }
            }}
            onBlur={() => setTimeout(() => filterWordChange(''), 300)}
            tokenSeparators={[',']}
            placeholder='Start typing a relevant tag or choose one from the list'
            showArrow={true}
            dropdownRender={menu => <div className='filter-menu'>{menu}</div>}
            //@ts-ignore
            value={filterTags.map(
              tagID => tagsList.find(fT => fT.id == Number(tagID))?.name
            )}
            open={false}
          ></Select>
          <ClickableFiltersTags
            hideCount={true}
            tags={tagsList
              .filter(tag => !filterTags.includes(String(tag.id)))
              .map(tag => tag.name)
              .filter(
                tag =>
                  !filterWord ||
                  tag.toLowerCase().includes(filterWord.toLowerCase())
              )}
            selectedTags={filterTags.map(
              tagID => tagsList.find(fT => fT.id == Number(tagID))?.name
            )}
            tagSelectionHandler={(tag: string) => {
              if (isMaxTags) return
              const id = String(tagsList.find(fT => fT.name == tag)?.id)
              if (filterTags.includes(id)) {
                const filterTagsCopy = [...filterTags]
                filterTagsCopy.splice(
                  filterTags.findIndex(ft => ft == id),
                  1
                )
                filterTagsChange(filterTagsCopy)
              } else {
                filterTagsChange([...filterTags, id])
              }
              filterWordChange('')
            }}
          />
        </div>
      </div>
    </div>
  )
}
