// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.see-all-btn .ant-btn:not([disabled]) {
  height: 3.2rem;
  border: 1px solid rgba(121, 121, 121, 0.6784313725) !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
.see-all-btn .ant-btn:not([disabled]) img {
  width: 1.2rem;
  margin-right: 1rem;
}
.see-all-btn .ant-btn:hover:not([disabled]) {
  background-color: white !important;
  border: 1px solid #000;
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/components/SeeAllABTestsBTN/style.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,8DAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AACI;EACE,aAAA;EACA,kBAAA;AACN;AAGE;EACE,kCAAA;EACA,sBAAA;EACA,uBAAA;AADJ","sourcesContent":[".see-all-btn {\n  .ant-btn:not([disabled]) {\n    height: 3.2rem;\n    border: 1px solid #797979ad !important;\n    box-sizing: border-box;\n    border-radius: 4px;\n    font-size: 1.2rem;\n    display: flex;\n    align-items: center;\n    img {\n      width: 1.2rem;\n      margin-right: 1rem;\n    }\n  }\n\n  .ant-btn:hover:not([disabled]){\n    background-color: white !important;\n    border: 1px solid #000;\n    color: black !important;\n  }\n\n  .see-all-btn-icon {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
