import React, { useState, useEffect, useRef } from 'react'
import { Input, Badge, Button, Checkbox } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Img } from 'react-image'
import DownBlack from '../../assets/images/icons/down-black.svg'
import WatchfulThumbnail from '../../assets/images/watchful_icon_gray.svg'
import watchful_icon_gray from '../../assets/images/watchful_icon_gray.svg'
import moment from 'moment'

import android_logo from '../../assets/images/icons/android_logo.svg'
import ios_logo from '../../assets/images/icons/ios_logo.svg'
import empty_app_ico from '../../assets/images/new_design/benchmark/bench-comp-empty-app.svg'

const AppDrawerOld = ({
  appsList,
  selectedApp,
  page,
  appsChange,
  currentApps,
  clearCurrentApps,
  dataPoints,
  isIntelligence,
  isPerformance,
  currentAppsChange,
  isProduct,
  listOfFirstInsight,
  maxAppsSelected,
  lastVersionName,
  trackedSince,
  isMainApp,
  canClear,
  disabled,
  woutColorLine
}) => {
  const drawerWrapperRef = useRef(null)
  const dropDown = useRef(null)
  const [isOpened, isOpenedChange] = useState(false)
  const [searchQuery, searchQueryChange] = useState('')

  const handleOutsideClick = event => {
    if (
      drawerWrapperRef.current &&
      !drawerWrapperRef.current.contains(event.target)
    ) {
      if (isOpened) {
        isOpenedChange(false)
      }
    }
  }

  useEffect(() => {
    // Handle outside click
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleOutsideClick])

  useEffect(() => {
    searchQueryChange('')
  }, [])

  const handleAppSearchChange = event => {
    searchQueryChange(event.target.value)
  }

  const handleAppSelection = selectedApp => {
    const isAlreadyChecked = currentApps.some(app => app === selectedApp.id)

    if (
      maxAppsSelected &&
      !isAlreadyChecked &&
      currentApps.length >= maxAppsSelected
    ) {
      return
    }

    if (isAlreadyChecked) {
      appsChange(currentApps.filter(appId => appId !== selectedApp.id))
    } else {
      appsChange(currentApps.concat(selectedApp.id))
    }
  }

  const clearState = e => {
    appsChange([])
    isOpenedChange(false)
    e.stopPropagation()
  }

  if (appsList.length === 0) {
    return null
  }

  const filteredItemsList = appsList.filter(a => {
    if (searchQuery === '') return true
    let app = a.metadata ? a.metadata : a
    if (
      isProduct &&
      app.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return true
    }
    if (
      isIntelligence &&
      (app.display_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        app.package_name?.toLowerCase().includes(searchQuery.toLowerCase()))
    ) {
      return true
    }
    if (
      isPerformance &&
      app.name?.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return true
    }
    return false
  })

  if (page === 'marketing' || page === 'intelligence') {
    return (
      <div
        ref={drawerWrapperRef}
        className={`${page} app-drawer type-marketing ${
          isOpened ? 'opened' : ''
        }`}
        style={{
          minHeight: 90
        }}
      >
        <div
          className={`app-drawer__current-app`}
          data-testid={'open-drawer'}
          onClick={() => {
            if (appsList.length > 1) {
              if (!isOpened) {
                searchQueryChange('')
              }
              isOpenedChange(!isOpened)
            }
          }}
        >
          <div
            className={`img-wrap app-platform-${
              selectedApp.platform && selectedApp.platform.toLowerCase()
            }`}
          >
            <Img
              src={[selectedApp.icon, WatchfulThumbnail]}
              className='app-drawer__current-app-icon'
              loader={
                <Img
                  src={WatchfulThumbnail}
                  className='app-drawer__current-app-icon'
                />
              }
            />
          </div>

          <div className='app-drawer__current-app-title'>
            <div className='marketing-app'>{selectedApp.display_name}</div>
            {page === 'intelligence' && selectedApp.developer && (
              <div className='marketing-app-developer'>
                By <span> {selectedApp.developer}</span>
              </div>
            )}
            {page === 'intelligence' && trackedSince && (
              <div className='marketing-app-developer'>
                Tracked Since: <span> {trackedSince}</span>
              </div>
            )}
            {listOfFirstInsight && listOfFirstInsight[selectedApp.id] && (
              <div className='first-campains-app'>
                Tracked Since:{' '}
                {moment(listOfFirstInsight[selectedApp.id].start_time).format(
                  'MMMM DD, YYYY'
                )}
              </div>
            )}
            {appsList.length > 1 && (
              <Img className='down-arrow' src={DownBlack} />
            )}
          </div>
        </div>

        <div
          ref={dropDown}
          className={`app-drawer__apps ${isOpened ? 'opened' : ''}`}
        >
          <div className='performance-app-drawer__apps-search-input'>
            <Input
              placeholder={`Search for app`}
              prefix={<SearchOutlined />}
              onChange={handleAppSearchChange}
              value={searchQuery}
            />
          </div>
          <div className='apps-grid'>
            {filteredItemsList.map(appData => (
              <div
                key={appData.id}
                className={`app-drawer__apps-app ${
                  appData.id == selectedApp.id ? 'chosen' : ''
                }`}
                onClick={() => handleAppSelection(appData)}
              >
                <div
                  className={`img-wrap app-platform-${
                    appData.platform && appData.platform.toLowerCase()
                  }`}
                >
                  <Img
                    src={[appData.icon, WatchfulThumbnail]}
                    className='app-drawer__apps-app-icon'
                    loader={
                      <Img
                        src={WatchfulThumbnail}
                        className='app-drawer__apps-app-icon'
                      />
                    }
                  />
                </div>
                <div className='app-drawer__apps-app-title'>
                  {appData.display_name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  if (page === 'performance_compare') {
    return (
      <div
        className={`performance-app-drawer-wrap ${disabled ? 'disabled' : ''} `}
      >
        <div className='performance-app-drawer__current-app-details'>
          <div className='app-status'>
            <h5>Status</h5>
            {selectedApp ? (
              selectedApp.is_monitored ? (
                <Badge
                  className='app-status-value on'
                  color='#389E0D'
                  text='Monitored'
                />
              ) : (
                <Badge
                  className='app-status-value off'
                  color='#F5222D'
                  text='Deactivated'
                />
              )
            ) : (
              <Badge
                className='app-status-value unk'
                color='#A1ABBD'
                text=' '
              />
            )}
          </div>
          <div className='current-verion'>
            <h5>Current Version:</h5>
            <div className='current-verion-value'>{lastVersionName}</div>
          </div>
          <div className='last-updated-status'>
            <h5>Last updated:</h5>
            <div className='last-updated-status-value'>
              {selectedApp &&
                moment(selectedApp.end_time_lst).format('MMM D, YYYY')}
            </div>
          </div>
          <div className='samples-amount-status'>
            <h5>Data Points:</h5>
            <div className='samples-amount-status-value'>{dataPoints}</div>
          </div>
        </div>
        <div
          ref={drawerWrapperRef}
          className={`performance-app-drawer ${isOpened ? 'opened' : ''} ${
            woutColorLine ? 'wout-color-line' : ''
          }`}
        >
          <div
            className='performance-app-drawer__current-app'
            data-testid={'open-drawer'}
            onClick={e => {
              if (e.target.className.includes('-remove')) {
                return
              }
              if (!isOpened) {
                searchQueryChange('')
                dropDown.current.scrollTop = 0
              }
              isOpenedChange(!isOpened)
            }}
          >
            <div
              className={`img-wrap app-platform-${
                selectedApp ? selectedApp.platform.toLowerCase() : 'nope'
              } ${!isMainApp && !selectedApp ? 'not-main-app' : ''}`}
            >
              <Img
                src={[
                  (selectedApp && selectedApp.icon) || empty_app_ico,
                  empty_app_ico
                ]}
                className='performance-app-drawer__current-app-icon'
                loader={
                  <Img
                    src={watchful_icon_gray}
                    className='performance-app-drawer__current-app-icon'
                  />
                }
              />
            </div>
            <div className='performance-app-drawer__current-app-title'>
              {canClear && selectedApp && (
                <div className='clear-state' onClick={clearState}>
                  Clear
                </div>
              )}
              {(selectedApp && selectedApp.name) ||
                (isMainApp ? (
                  <div className='text'>
                    Select an <strong>app</strong>{' '}
                  </div>
                ) : (
                  <div className='text'>
                    Choose an app to <strong>compare</strong>
                  </div>
                ))}
              <Img
                className='down-arrow'
                src={DownBlack}
                style={{ right: 0 }}
              />
              <div className='performance-app-drawer__current-app-title-platform'>
                {selectedApp && selectedApp.platform}
              </div>
            </div>
          </div>
          <div
            ref={dropDown}
            className={`performance-app-drawer__apps ${
              isOpened ? 'opened' : ''
            }`}
          >
            <div className='performance-app-drawer__apps-search-input'>
              <Input
                placeholder={`Search for app`}
                prefix={<SearchOutlined />}
                onChange={handleAppSearchChange}
                value={searchQuery}
              />
            </div>

            <h4>
              <img src={android_logo} alt='android' />
              Android Apps{' '}
              <span className='count'>
                (
                {filteredItemsList.filter(a => a.platform === 'Android').length}
                )
              </span>
              <div className='tail' />
            </h4>
            <div className='android-apps'>
              {filteredItemsList
                .filter(a => a.platform === 'Android')
                .map(app => {
                  if (app.id !== (selectedApp && selectedApp.id)) {
                    return (
                      <div
                        key={app.id}
                        className={`performance-app-drawer__apps-app`}
                        onClick={() => handleAppSelection(app.id)}
                      >
                        <div
                          className={`img-wrap app-platform-${app.platform.toLowerCase()}`}
                        >
                          <Img
                            src={[app.icon, WatchfulThumbnail]}
                            className='performance-app-drawer__apps-app-icon'
                            loader={
                              <Img
                                src={WatchfulThumbnail}
                                className='performance-app-drawer__apps-app-icon'
                              />
                            }
                          />
                        </div>
                        <div className='performance-app-drawer__apps-app-title'>
                          {app.name}
                        </div>
                      </div>
                    )
                  }
                  return null
                })}
            </div>

            <div className='apps-platform-separator' />
            <h4>
              <img src={ios_logo} alt='ios' />
              iOS Apps{' '}
              <span className='count'>
                ({filteredItemsList.filter(a => a.platform === 'iOS').length})
              </span>
              <div className='tail' />
            </h4>
            <div className='ios-apps'>
              {filteredItemsList
                .filter(a => a.platform === 'iOS')
                .map(app => {
                  if (app.id !== (selectedApp && selectedApp.id)) {
                    return (
                      <div
                        key={app.id}
                        className={`performance-app-drawer__apps-app`}
                        onClick={() => handleAppSelection(app.id)}
                      >
                        <div
                          className={`img-wrap app-platform-${app.platform.toLowerCase()}`}
                        >
                          <Img
                            src={[app.icon, WatchfulThumbnail]}
                            className='performance-app-drawer__apps-app-icon'
                            loader={
                              <Img
                                src={WatchfulThumbnail}
                                className='performance-app-drawer__apps-app-icon'
                              />
                            }
                          />
                        </div>
                        <div className='performance-app-drawer__apps-app-title'>
                          {app.name}
                        </div>
                      </div>
                    )
                  }
                  return null
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (page === 'all-discoveries') {
    return (
      <div className='app-drawer-wrap'>
        <div
          ref={drawerWrapperRef}
          className={`app-drawer ${isOpened ? 'opened' : ''}`}
        >
          <div
            ref={dropDown}
            className={`app-drawer__apps ${isOpened ? 'opened' : ''}`}
          >
            <div className='app-drawer__apps-title'>
              <div className='app-drawer__apps-title-main'>
                Apps ({currentApps.length})
              </div>
              {maxAppsSelected && appsList.length >= maxAppsSelected && (
                <div className={'items-selected-warning-max'}>
                  You can select up to {maxAppsSelected} competitor
                </div>
              )}
            </div>
            <div className='items-selected'>
              <div className={'items-selected-warning'}>
                <div className='items-selected-warning-count'>
                  {`${currentApps.length} Selected`}
                </div>
              </div>
              {canClear && (
                <div className='clear' onClick={appsChange([])} type='text'>
                  clear selection
                </div>
              )}
            </div>
            <div className='app-drawer__apps-search-input'>
              <Input
                placeholder={`Search for app`}
                suffix={<SearchOutlined />}
                onChange={handleAppSearchChange}
                value={searchQuery}
              />
            </div>

            <div className='apps-grid'>
              {filteredItemsList.map(product => {
                return (
                  <div
                    key={product.id}
                    className={`app-drawer__apps-app ${
                      currentApps.indexOf(product.id) !== -1 ? 'act' : ''
                    }`}
                    onClick={() => handleAppSelection(product)}
                  >
                    <div className='app-checkbox'>
                      <Checkbox
                        checked={currentApps?.includes(product.id)}
                        value={product.id}
                      />
                    </div>
                    <div className={`img-wrap`}>
                      <Img
                        src={[product.thumbnail, WatchfulThumbnail]}
                        className='app-drawer__apps-app-icon'
                        loader={
                          <Img
                            src={WatchfulThumbnail}
                            className='app-drawer__apps-app-icon'
                          />
                        }
                      />
                    </div>
                    <div className='app-drawer__apps-app-title'>
                      {product.name}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default AppDrawerOld
