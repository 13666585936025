import React from 'react'
import Nav2 from '../../components/Nav2/Nav2'

export default function Sidebar ({ siteSize }) {
  return (
    <div className='sidebar'>
      <Nav2 siteSize={siteSize} />
      {/* <TrackedProducts
        products={products}
        unSeenCriticalInsights={unSeenCriticalInsights}
        siteSize={siteSize}
      /> */}
    </div>
  )
}
