import { Button, Popover } from "antd";
import React, { useState } from "react";
import { Persona } from "../../../utils/consts";
import { PersonaFilter } from "./PersonaFilter";
import "./PersonaSelect.scss";

const MAX_TITLE_LEN = 20;

const strEllipsis = (str: string, maxLen: number) => {
  return str.length >= maxLen ? str.slice(0, maxLen).concat("...") : str;
};

type Props = {
  persona: Persona | null;
  personas: Persona[];
  handlePersonaFilterSelect: (personaId: number) => void;
  width?: number;
  maxLen?: number;
};

export const PersonaSelect = ({
  handlePersonaFilterSelect,
  persona,
  personas,
  width = 220,
  maxLen = MAX_TITLE_LEN,
}: Props) => {
  const [personaFilterOpen, setPersonaFilterOpen] = useState(false);

  return (
    <div className="uj-filter-personas">
      <Popover
        placement="bottomLeft"
        title={null}
        open={personaFilterOpen}
        onOpenChange={setPersonaFilterOpen}
        trigger="click"
        content={
          <PersonaFilter
            selectedOption={persona ? persona.id : -1}
            options={personas}
            onPersonaSelect={handlePersonaFilterSelect}
          />
        }
      >
        <Button
          className="filter-btn-new platform-mult-selector uj-personas"
          style={{ width: `${width}px` }}
        >
          <span
            className={`flag-icon fi fi-${
              persona?.location.toLocaleLowerCase() || "us"
            }`}
            style={{ marginRight: "1rem" }}
          />
          {persona
            ? persona.roles?.length > 0
              ? strEllipsis(persona.roles[0], maxLen)
              : strEllipsis(persona.title, maxLen)
            : ""}
        </Button>
      </Popover>
    </div>
  );
};
