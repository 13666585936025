import React, { useEffect, useState } from 'react'
import { Table, Button, Modal, Row, Col, Input, message } from 'antd'
import moment from 'moment'
import FilterSearchQuery from '../dash-discoveries/FilterSearchQuery'

import user_delete_ico from '../../assets/images/new_design/user_delete.svg'
import user_invite_ico from '../../assets/images/new_design/invite-user-plus.svg'

const PENDING = 'pending'
const WAITING_FOR_RESPONSE = 'waiting_for_response'
const REJECTED = 'rejected'
const APPROVED = 'approved'
const INVITATION_CANCELED = 'invitation_canceled'
const ACCEPTED = 'accepted'

const STATUS_LABEL = {
  [PENDING]: 'Pending',
  [APPROVED]: 'Approved',
  [REJECTED]: 'Rejected',
  [WAITING_FOR_RESPONSE]: 'Waiting For Response',
  [INVITATION_CANCELED]: 'Invitation Canceled',
  [ACCEPTED]: 'Accepted'
}

export default function UsersManagement ({
  accessRequests,
  setAccessRequestStatus,
  analytics,
  orgUsers,
  deleteOrganizationUser,
  sendInviteForUser,
  cancelInviteForUser,
  resendInviteForUser,

  sendInviteError,
  sendInviteSuccess,
  cancelInviteError,
  resendInviteError
}) {
  const [searchQuery, searchQueryChange] = useState('')
  const [showAllReqs, showAllReqsChange] = useState(false)

  const [userForDelete, userForDeleteChange] = useState(null)
  const [inviteForCancel, inviteForCancelChange] = useState(null)
  const [inviteForResend, inviteForResendChange] = useState(null)

  const [showInviteModal, showInviteModalChange] = useState(false)
  const [inviteFirstName, inviteFirstNameChange] = useState('')
  const [inviteLastName, inviteLastNameChange] = useState('')
  const [inviteEmail, inviteEmailChange] = useState('')

  const filterUsers = user =>
    user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())

  useEffect(() => {
    if (sendInviteSuccess) {
      message.loading({
        content: 'The invitation has been sent',
        duration: 2
      })

      analytics.analytic('Invite User', {
        first_name: inviteFirstName,
        last_name: inviteLastName,
        email: inviteEmail,
        source: 'Invite'
      })
      inviteFirstNameChange('')
      inviteLastNameChange('')
      inviteEmailChange('')
    }
  }, [sendInviteSuccess])

  useEffect(() => {
    if (cancelInviteError) {
      message.error({
        content: cancelInviteError.msg,
        duration: 2
      })
    }
  }, [cancelInviteError])

  useEffect(() => {
    if (sendInviteError) {
      message.error({
        content: sendInviteError.msg,
        duration: 2
      })
    }
  }, [sendInviteError])

  useEffect(() => {
    if (resendInviteError) {
      message.error({
        content: resendInviteError.msg,
        duration: 2
      })
    }
  }, [resendInviteError])

  const columnsUsers = [
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      render: (n, req) => (
        <div className='full-name'>
          {req.first_name} {req.last_name}
        </div>
      ),
      width: '12rem',
      defaultSortOrder: 'descend',
      sorter: (rep1, rep2) =>
        rep1.first_name.trim().toLowerCase() <
        rep2.first_name.trim().toLowerCase()
          ? 1
          : -1,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '25rem'
    },
    {
      title: 'Joined',
      dataIndex: 'created_at',
      key: 'created_at',
      render: date => <div>{moment(date).format('DD MMM, YYYY')}</div>,
      width: '11rem',
      sorter: (rep1, rep2) =>
        moment(rep1.created_at).isBefore(rep2.created_at) ? 1 : -1,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: '',
      key: 'buttons',
      render: (str, req) => (
        <div
          className='all-user-del'
          onClick={() => handleOpenDeleteConfirm(req)}
        >
          <img src={user_delete_ico} /> Delete user
        </div>
      ),
      width: '14rem'
    }
  ]

  const columnsRequestsAccess = [
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      render: (n, req) => (
        <div className='full-name'>
          {req.first_name} {req.last_name}
        </div>
      ),
      width: '12rem'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '16rem'
    },
    {
      title: 'Request Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: date => <div>{moment(date).format('DD MMM, YYYY')}</div>,
      width: '8rem',
      sorter: (rep1, rep2) =>
        moment(rep1.created_at).isBefore(rep2.created_at) ? -1 : 1,
      defaultSortOrder: 'descend'
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: str => <div className={`state-${str}`}>{STATUS_LABEL[str]}</div>,
      width: '4em'
    },

    {
      title: '',
      key: 'buttons',
      render: (str, req) =>
        req.is_invitation ? (
          req.state === WAITING_FOR_RESPONSE ? (
            <div className='access-requests-buttons'>
              <div
                className='access-requests-btn access-requests-btn-cancel-inv'
                onClick={() => handleOpenCancelInviteConfirm(req)}
              >
                <img src={user_delete_ico} />
                Cancel Invitation
              </div>
              <div
                className='access-requests-btn access-requests-btn-resend'
                onClick={() => inviteForResendChange(req)}
              >
                Resend Invitation
              </div>
            </div>
          ) : req.state_changed_by ? (
            <div className='access-request-result'>
              by {req.state_changed_by.name} at{' '}
              {moment(req.state_changed_at).format('DD MMM, YYYY')}
            </div>
          ) : null
        ) : req.state === PENDING ? (
          <div className='access-requests-buttons'>
            <div
              className='access-requests-btn access-requests-btn-rej'
              onClick={() => handleChangeStatus(req, REJECTED)}
            >
              Reject
            </div>
            <div
              className='access-requests-btn access-requests-btn-apr'
              onClick={() => handleChangeStatus(req, APPROVED)}
            >
              Approve
            </div>
          </div>
        ) : req.state_changed_by ? (
          <div className='access-request-result'>
            by {req.state_changed_by.name} at{' '}
            {moment(req.state_changed_at).format('DD MMM, YYYY')}
          </div>
        ) : null,
      width: '18rem'
    }
  ]

  const handleResendInvitation = () => {
    resendInviteForUser({
      invitation_id: inviteForResend.id
    })
    message.loading({
      content: 'The invitation has been re-sent',
      duration: 2
    })
    analytics.analytic('Resend invitation', {
      first_name: inviteForResend.first_name,
      last_name: inviteForResend.last_name,
      email: inviteForResend.email,
      source: 'Invite'
    })
    inviteForResendChange(null)
  }

  const handleChangeStatus = (req, state) => {
    setAccessRequestStatus({
      request_id: req.id,
      state
    })
    analytics.analytic('Access request handled', {
      response: state,
      first_name: req.first_name,
      last_name: req.last_name,
      email: req.email,
      comment: req.comment,
      org: req.organization,
      source: 'Request'
    })
  }

  const handleCloseDeleteConfirm = () => {
    userForDeleteChange(null)
  }

  const handleOkDeleteConfirm = () => {
    if (userForDelete) {
      deleteOrganizationUser({
        user_id: userForDelete.id
      })
      userForDeleteChange(null)
    }
  }

  const handleOpenDeleteConfirm = user => {
    userForDeleteChange(user)
  }

  const handleOpenCancelInviteConfirm = user => {
    inviteForCancelChange(user)
  }

  const handleSendInvite = () => {
    sendInviteForUser({
      first_name: inviteFirstName,
      last_name: inviteLastName,
      email: inviteEmail,
      source: 'Invite'
    })
    showInviteModalChange(false)
  }

  const handleCancelInvite = () => {
    cancelInviteForUser({
      invitation_id: inviteForCancel.id
    })
    inviteForCancelChange(null)
  }

  const handleOpenInviteDialog = () => {
    showInviteModalChange(true)
    analytics.analytic('Open invite user dialog')
  }

  const hasPendingRequests =
    accessRequests &&
    accessRequests
      .filter(filterUsers)
      .find(us => [WAITING_FOR_RESPONSE, PENDING].includes(us.state))

  return (
    <div className='users-management-tab'>
      <h3 className='title'>Users Management</h3>
      <div className='invite-btn-wrap'>
        <Button
          className='ant-btn-blue-filled btn-invite-user'
          onClick={handleOpenInviteDialog}
        >
          <img src={user_invite_ico} />
          Invite New User
        </Button>
      </div>
      <Modal
        open={showInviteModal}
        title={null}
        onCancel={() => showInviteModalChange(false)}
        wrapClassName='invite-user-modal'
        footer={null}
        width={410}
        style={{ top: 'calc(50% - 20rem)' }}
      >
        <h2>Invite New User</h2>
        <div className='mod-invite-form'>
          <Row gutter={[20, 10]}>
            <Col span={12}>
              <label>
                First Name
                <span className={'star'}>*</span>
              </label>
              <Input
                value={inviteFirstName}
                onChange={e => {
                  inviteFirstNameChange(e.target.value)
                }}
              />
            </Col>
            <Col span={12}>
              <label>
                Last Name
                <span className={'star'}>*</span>
              </label>
              <Input
                value={inviteLastName}
                onChange={e => {
                  inviteLastNameChange(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label>
                Company Email
                <span className={'star'}>*</span>
              </label>
              <Input
                value={inviteEmail}
                autocomplete='off'
                onChange={e => {
                  inviteEmailChange(e.target.value)
                }}
              />
            </Col>
          </Row>
        </div>
        <div className='mod-invite-btns'>
          <Button
            onClick={() => showInviteModalChange(false)}
            className='ant-btn-dark lowercase'
          >
            Cancel
          </Button>
          <Button
            onClick={handleSendInvite}
            className='ant-btn-dark lowercase'
            disabled={!inviteFirstName && !inviteLastName && !inviteEmail}
          >
            Invite
          </Button>
        </div>
      </Modal>

      <FilterSearchQuery
        searchQuery={searchQuery}
        searchQueryChange={searchQueryChange}
        placeholder='Search for a specific user or email'
      />
      {accessRequests && (
        <div className='um-table-cont'>
          {accessRequests
            .filter(filterUsers)
            .find(
              us => ![WAITING_FOR_RESPONSE, PENDING].includes(us.state)
            ) && (
            <div
              className='show-hide-acc-reqs'
              onClick={() => showAllReqsChange(!showAllReqs)}
            >
              View Requests History
            </div>
          )}
          {hasPendingRequests && <h4>Pending Request</h4>}
          {hasPendingRequests && (
            <Table
              columns={columnsRequestsAccess}
              dataSource={[
                ...accessRequests
                  .filter(filterUsers)
                  .filter(us =>
                    [WAITING_FOR_RESPONSE, PENDING].includes(us.state)
                  )
                  .sort((r1, r2) =>
                    moment(r1.created_at).isAfter(
                      moment(r2.created_at) ? -1 : 1
                    )
                  )
              ]}
              pagination={false}
            />
          )}
        </div>
      )}
      {hasPendingRequests && <hr />}
      {orgUsers && (
        <div className='um-table-cont sub-pagination'>
          <h4>Users ({orgUsers.filter(filterUsers).length})</h4>
          <Table
            columns={columnsUsers}
            dataSource={orgUsers.filter(filterUsers)}
            pagination={{
              showSizeChanger: true,
              total: orgUsers.filter(filterUsers).length,
              defaultPageSize: 20,
              pageSizeOptions: ['10', '20', '40', '50']
            }}
          />
        </div>
      )}

      {userForDelete && (
        <Modal
          title={null}
          open={userForDelete}
          onOk={handleOkDeleteConfirm}
          onCancel={handleCloseDeleteConfirm}
          wrapClassName='delete-user-modal'
          footer={null}
          width={350}
          style={{ top: 'calc(50% - 20rem)' }}
        >
          <h2>Delete user</h2>
          <div className='mod-delete-descr'>
            Are you sure you want to delete the user{' '}
            <strong>{userForDelete.name}</strong>?
          </div>
          <div className='mod-delete-btns'>
            <Button
              onClick={handleCloseDeleteConfirm}
              className='ant-btn-dark lowercase'
            >
              Cancel
            </Button>
            <Button
              onClick={handleOkDeleteConfirm}
              className='ant-btn-red lowercase'
            >
              Delete
            </Button>
          </div>
        </Modal>
      )}

      {inviteForResend && (
        <Modal
          title={null}
          open={inviteForResend}
          onCancel={() => inviteForResendChange(false)}
          wrapClassName='delete-user-modal'
          footer={null}
          width={350}
          style={{ top: 'calc(50% - 20rem)' }}
        >
          <h2>Resend Invitation</h2>
          <div className='mod-delete-descr'>
            Are you sure you want to resend the invitation to user{' '}
            <strong>{inviteForResend.name}</strong>?
          </div>
          <div className='mod-delete-btns'>
            <Button
              onClick={() => inviteForResendChange(false)}
              className='ant-btn-dark lowercase'
            >
              Cancel
            </Button>
            <Button
              onClick={handleResendInvitation}
              className='ant-btn-blue lowercase'
            >
              Resend Invitation
            </Button>
          </div>
        </Modal>
      )}

      {inviteForCancel && (
        <Modal
          title={null}
          open={inviteForCancel}
          onCancel={() => inviteForCancelChange(false)}
          wrapClassName='cancel-invite-user-modal'
          footer={null}
          width={350}
          style={{ top: 'calc(50% - 20rem)' }}
        >
          <h2>Cancel Invitation</h2>
          <div className='mod-delete-descr'>
            Are you sure you want to cancel the invitation to user{' '}
            <strong>
              {inviteForCancel.first_name} {inviteForCancel.last_name}
            </strong>
            ?
          </div>
          <div className='mod-delete-btns'>
            <Button
              onClick={() => inviteForCancelChange(false)}
              className='ant-btn-dark lowercase'
            >
              Cancel
            </Button>
            <Button
              onClick={handleCancelInvite}
              className='ant-btn-red lowercase'
            >
              Cancel Invitation
            </Button>
          </div>
        </Modal>
      )}

      {accessRequests && (
        <Modal
          title={null}
          open={showAllReqs}
          onCancel={() => showAllReqsChange(false)}
          wrapClassName='req-history-user-modal'
          footer={null}
          width={900}
        >
          <div className='um-table-cont sub-pagination'>
            <h4>Requests History</h4>
            <Table
              columns={columnsRequestsAccess}
              dataSource={accessRequests.filter(ar => !ar.is_invitation)}
              pagination={true}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}
