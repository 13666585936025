// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.more-options-wrapper img {
  cursor: pointer;
  width: 3.4rem;
}

.ant-popover-inner .ant-popover-inner-content {
  padding: 0.5rem;
}
.ant-popover-inner .ant-popover-inner-content .options-wrapper {
  display: flex;
  flex-direction: column;
  width: 13.2rem;
}
.ant-popover-inner .ant-popover-inner-content .options-wrapper .item {
  height: 4rem;
  align-self: center;
  width: 100%;
  cursor: pointer;
  padding: 1rem 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #616161;
}
.ant-popover-inner .ant-popover-inner-content .options-wrapper .item img, .ant-popover-inner .ant-popover-inner-content .options-wrapper .item svg {
  margin: 0 0.7rem;
}
@media (max-width: 1100px) {
  .ant-popover-inner .ant-popover-inner-content .options-wrapper .item .hide {
    display: none;
  }
}
.ant-popover-inner .ant-popover-inner-content .options-wrapper .item:hover {
  background: #F6F6F6;
}

.ant-popover-placement-bottomRight {
  padding: 0 !important;
  margin-top: 1.7rem !important;
}
.ant-popover-placement-bottomRight .ant-popover-content {
  margin-top: 1rem;
}
.ant-popover-placement-bottomRight .ant-popover-content .ant-popover-arrow {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/discoveryView/viewHeader/MoreOptions.scss"],"names":[],"mappings":"AAEE;EACE,eAAA;EACA,aAAA;AADJ;;AAME;EACE,eAAA;AAHJ;AAII;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AAFN;AAGM;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;AAFR;AAGQ;EACE,gBAAA;AADV;AAGQ;EACE;IACE,aAAA;EADV;AACF;AAKM;EACG,mBAAA;AAHT;;AAQA;EACE,qBAAA;EACA,6BAAA;AALF;AAME;EACE,gBAAA;AAJJ;AAKI;EACE,aAAA;AAHN","sourcesContent":["\n.more-options-wrapper {\n  img {\n    cursor: pointer;\n    width: 3.4rem;\n  }\n}\n.ant-popover-inner{\n\n  .ant-popover-inner-content {\n    padding: 0.5rem;\n    .options-wrapper {\n      display: flex;\n      flex-direction: column;\n      width: 13.2rem;\n      .item {\n        height: 4rem;\n        align-self: center;\n        width: 100%;\n        cursor: pointer;\n        padding: 1rem 0;\n        font-family: 'Roboto';\n        font-style: normal;\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 16px;\n\n        color: #616161;\n        img, svg {\n          margin: 0 0.7rem;\n        }\n        @media (max-width: 1100px) {\n          .hide{\n            display: none;\n          }\n\n        }\n      }\n      .item:hover {\n         background: #F6F6F6;\n      }\n    }\n  }\n}\n.ant-popover-placement-bottomRight {\n  padding: 0 !important;\n  margin-top: 1.7rem !important;\n  .ant-popover-content {\n    margin-top: 1rem;\n    .ant-popover-arrow {\n      display: none;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
