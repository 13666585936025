import { gql } from '@apollo/client'

export const GET_MARKETING_APPS_QUERY = gql`
  query GetMarketingApps {
    marketing {
      apps {
        id
        displayName
        icon
        isCustomerOwnApp
      }
    }
  }
`

export const GET_MARKETING_PERSONAS_QUERY = gql`
  query GetMarketingPersonas($appIds: [Int]!) {
    marketing {
      personas(appIds: $appIds) {
        id
        title
        roles {
          id
          name
        }
        description
      }
    }
  }
`

export const GET_MARKETING_DATA_QUERY = gql`
  query GetMarketingData(
    $appIds: [Int]
    $insightTypes: [String]
    $startTimeGte: Date
    $startTimeLt: Date
    $personaIds: [Int]
    $personaRoleIds: [Int]
  ) {
    marketing {
      overview(
        appIds: $appIds
        insightTypes: $insightTypes
        startTimeGte: $startTimeGte
        startTimeLt: $startTimeLt
        personaIds: $personaIds
        personaRoleIds: $personaRoleIds
        subType: "promotional"
      ) {
        appInsights {
          appId
          count
        }
        appInsightTypes {
          appId
          insightType
          count
          prevPeriod {
            count
          }
        }
        appInsightTypesByDates {
          date
          appId
          insightType
          count
        }
        appFocusAreas {
          appId
          count
          focusArea {
            id
            name
          }
        }
        appPersonas {
          appId
          persona {
            id
            title
            description
            roles {
              id
              name
            }
          }
          count
        }
        appCampaignTypes {
          appId
          campaignType
          count
        }
      }
    }
  }
`

export const GET_PROMO_DAYS_OF_WEEK = gql`
  query GetMarketingPromoDaysData(
    $appIds: [Int]
    $insightTypes: [String]
    $startTimeGte: Date
    $startTimeLt: Date
    $personaIds: [Int]
    $personaRoleIds: [Int]
  ) {
    marketing {
      overview(
        appIds: $appIds
        startTimeGte: $startTimeGte
        startTimeLt: $startTimeLt
        insightTypes: $insightTypes
        personaIds: $personaIds
        personaRoleIds: $personaRoleIds
        subType: "promotional"
      ) {
        appInsightTypesByWeekDays {
          weekDay
          appId
          insightType
          count
        }
      }
    }
  }
`

export const GET_PROMO_TIME_OF_DAY = gql`
  query GetMarketingPromoTimeOfDay(
    $appIds: [Int]
    $insightTypes: [String]
    $startTimeGte: Date
    $startTimeLt: Date
    $personaIds: [Int]
    $personaRoleIds: [Int]
  ) {
    marketing {
      overview(
        appIds: $appIds
        startTimeGte: $startTimeGte
        startTimeLt: $startTimeLt
        insightTypes: $insightTypes
        personaIds: $personaIds
        personaRoleIds: $personaRoleIds
        subType: "promotional"
      ) {
        appInsightTypesByDayTimes {
          dayTime
          appId
          insightType
          count
        }
      }
    }
  }
`
