import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { getProducts } from '../../redux/actions/overviewActions'

const FallbackOldURLS = ({ products }) => {
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    const { app_id, release_id } = params

    let product

    if (app_id) {
      product = products.find(p => p.apps.find(a => a.id === Number(app_id)))
    }

    if (release_id) {
      navigate(
        `/product_intelligence/${product.id}?groupBy=release&releasePlatformID=${app_id}&versionID=${release_id}`,
        { replace: true }
      )
      return
    }

    if (!release_id && app_id) {
      navigate(
        `/product_intelligence/${product.id}?groupBy=release&releasePlatformID=${app_id}`,
        { replace: true }
      )
      return
    }
  }, [])

  return <div></div>
}

export default connect(
    state => {
      return {
        products: state.overview.products
      }
    },
    {
      getProducts
    }
)(FallbackOldURLS)
