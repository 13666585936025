// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mui-focused.MuiFormLabel-root {
  color: #59b0f6 !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #59b0f6 !important;
}

.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(89, 176, 246, 0.5) !important;
}

.material-input-field {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}
.material-input-field legend {
  width: auto !important;
}
.material-input-field .error-message {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/inputText/InputText.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;AAEI;EACI,gCAAA;AAAR;;AAII;EACI,gDAAA;AADR;;AAIA;EACI,2BAAA;EACA,8BAAA;AADJ;AAEI;EACI,sBAAA;AAAR;AAGI;EACI,eAAA;AADR","sourcesContent":[".Mui-focused {\n    &.MuiFormLabel-root {\n        color: #59b0f6 !important;\n    }\n    .MuiOutlinedInput-notchedOutline {\n        border-color: #59b0f6 !important;\n    }\n}\n.MuiInputBase-root:hover {\n    .MuiOutlinedInput-notchedOutline {\n        border-color: rgba($color: #59b0f6, $alpha: 0.5) !important\n    }\n}\n.material-input-field {\n    margin-top:10px !important;\n    margin-bottom: 20px !important;\n    legend {\n        width: auto !important;\n        // width: 40px !important;\n    }\n    .error-message {\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
