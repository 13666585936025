import analyticsService from 'services/analyticsService'

export type ChartNames =
  | 'Promotions Volume'
  | 'Campaign Channels Breakdown'
  | 'Promotions Calendar'
  | 'Promo Type'
  | 'Promo Days Of Week'
  | 'Promotions Validity Term'
  | 'Promotions per User Type'

type MarketingOverviewEvents = {
  overview_enter: null
  overview_app_filter: {
    selected_app_names: string[]
  }
  overview_comparison_mode_changed: {
    state: 'on' | 'off'
  }
  overview_time_interval_filter: {
    number_of_days: number
  }
  overview_channel_changed: {
    selected_channel_names: string[]
  }
  overview_chart_viewed: {
    chart_name: ChartNames
  }
  overview_chart_clicked: {
    chart_name: ChartNames
  }
  overview_exit: {
    seconds_spent: number
  }
  chart_category_clicked: {
    chart_name: ChartNames
    app_names: string[]
    category_name: string
    is_selected: boolean
  }
  chart_view_changed: {
    chart_name: ChartNames
    view_name: string
  }
  user_types_filter: {
    user_types_selected: string[]
    user_role_types_selected: string[]
  }
}

export const eventsData = new Map<keyof MarketingOverviewEvents, any>()

export const trackMarketingOverviewEvent = <
  T extends keyof MarketingOverviewEvents
>(
  event: T,
  data: MarketingOverviewEvents[T]
) => {
  analyticsService.getInstance().analytic(event, data)
}
