// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-heading h2 {
  color: #0b3252;
  font-size: 28px;
  font-family: "Nexa Heavy";
  text-transform: uppercase;
  letter-spacing: 0.28px;
}
.text-heading__underline {
  height: 5px;
  background-color: #2196f3;
  width: 95px;
}`, "",{"version":3,"sources":["webpack://./src/components/textHeading/TextHeading.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,eAAA;EACA,yBAAA;EACA,yBAAA;EACA,sBAAA;AAAR;AAEI;EACI,WAAA;EACA,yBAAA;EACA,WAAA;AAAR","sourcesContent":[".text-heading {\n    h2 {\n        color: #0b3252;\n        font-size: 28px;\n        font-family: 'Nexa Heavy';\n        text-transform: uppercase;\n        letter-spacing: 0.28px;\n    }\n    &__underline {\n        height: 5px;\n        background-color: #2196f3;\n        width: 95px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
