// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-pr-container {
  position: relative;
}
.products-pr-container .icon {
  border-radius: 4px;
}
.products-pr-container.add-hover:hover {
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.05);
  transform: translateY(-1px);
  z-index: 3;
}
.products-pr-container.with-title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.products-pr-container.with-title .icon {
  border-radius: 4px;
  width: 2.6rem !important;
  height: 2.6rem !important;
}
.products-pr-container.with-title .products-pr-title {
  margin-left: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  color: #000000;
  padding-right: 1rem;
  line-height: 1.5rem;
}

.product-name-tooltip .ant-tooltip-inner {
  border-radius: 5px;
}
.product-name-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background-color: rgba(0, 0, 0, 0.75);
}`, "",{"version":3,"sources":["webpack://./src/common/icon/Icon.scss"],"names":[],"mappings":"AAIA;EACE,kBAAA;AAHF;AAKE;EACE,kBAAA;AAHJ;AAME;EAVA,2CAAA;EAYE,2BAAA;EACA,UAAA;AAJJ;AAOE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AALJ;AAMI;EACE,kBAAA;EACA,wBAAA;EACA,yBAAA;AAJN;AAMI;EACE,iBAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,mBAAA;AAJN;;AAWE;EACE,kBAAA;AARJ;AAWE;EACE,qCAAA;AATJ","sourcesContent":["@mixin shadow() {\n  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.05);\n}\n\n.products-pr-container {\n  position: relative;\n\n  .icon {\n    border-radius: 4px;\n  }\n\n  &.add-hover:hover {\n    @include shadow();\n    transform: translateY(-1px);\n    z-index: 3;\n  }\n\n  &.with-title {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    .icon{\n      border-radius: 4px;\n      width: 2.6rem !important;\n      height: 2.6rem !important;\n    }\n    .products-pr-title {\n      margin-left: 1rem;\n      font-family: 'Roboto';\n      font-style: normal;\n      font-weight: 500;\n      font-size: 1.2rem;\n      color: #000000;\n      padding-right: 1rem;\n      line-height: 1.5rem;\n\n    }\n  }\n}\n\n.product-name-tooltip {\n  .ant-tooltip-inner {\n    border-radius: 5px;\n  }\n\n  .ant-tooltip-arrow .ant-tooltip-arrow-content {\n    background-color: rgba(0, 0, 0, 0.75);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
