// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guest-email-validation-page .logo_wrapper {
  width: 29rem;
  padding: 2.5rem 4.2rem;
  box-sizing: border-box;
  display: flex;
  margin: 4rem auto 6.8rem;
}
.guest-email-validation-page .logo_wrapper a {
  width: 24rem;
  margin: 6rem auto 7rem;
}
.guest-email-validation-page .title-h1 {
  color: #1a1515;
  font-family: "Roboto Slab";
  font-size: 55px;
  font-weight: bold;
  letter-spacing: 0.19px;
  line-height: 66px;
  text-align: center;
  margin-bottom: 4.8rem;
  text-transform: uppercase;
  font-weight: 700;
}
.guest-email-validation-page .title-h3 {
  color: #1a1515;
  text-align: center;
  width: 80rem;
  margin: auto;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
}
.guest-email-validation-page .descr {
  opacity: 0.5;
  color: #1a1515;
  font-family: Roboto;
  font-size: 1.5rem;
  letter-spacing: 0.06px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 2rem;
}
.guest-email-validation-page .back-wrapper {
  position: absolute;
  left: calc(50% - 241px);
  z-index: -1;
  top: 24rem;
}`, "",{"version":3,"sources":["webpack://./src/containers/GuestEmailValidation/styles.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,sBAAA;EACA,sBAAA;EACA,aAAA;EACA,wBAAA;AAAJ;AAGE;EACE,YAAA;EACA,sBAAA;AADJ;AAIE;EACE,cAAA;EACA,0BAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;AAME;EACE,cAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AAJJ;AAOE;EACE,YAAA;EACA,cAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AALJ;AAQE;EACE,kBAAA;EACA,uBAAA;EACA,WAAA;EACA,UAAA;AANJ","sourcesContent":[".guest-email-validation-page {\n  .logo_wrapper {\n    width: 29rem;\n    padding: 2.5rem 4.2rem;\n    box-sizing: border-box;\n    display: flex;\n    margin: 4rem auto 6.8rem;\n  }\n\n  .logo_wrapper a {\n    width: 24rem;\n    margin: 6rem auto 7rem;\n  }\n\n  .title-h1 {\n    color: #1a1515;\n    font-family: \"Roboto Slab\";\n    font-size: 55px;\n    font-weight: bold;\n    letter-spacing: 0.19px;\n    line-height: 66px;\n    text-align: center;\n    margin-bottom: 4.8rem;\n    text-transform: uppercase;\n    font-weight: 700;\n\n  }\n\n  .title-h3 {\n    color: #1a1515;\n    text-align: center;\n    width: 80rem;\n    margin: auto;\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 3rem;\n  }\n\n  .descr {\n    opacity: 0.5;\n    color: #1a1515;\n    font-family: Roboto;\n    font-size: 1.5rem;\n    letter-spacing: 0.06px;\n    line-height: 30px;\n    text-align: center;\n    margin-bottom: 2rem;\n  }\n\n  .back-wrapper {\n    position: absolute;\n    left: calc(50% - 241px);\n    z-index: -1;\n    top: 24rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
