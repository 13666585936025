import './UserTypeFilter.scss'

import { Button, Checkbox, Col, Input, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { ReactComponent as InfoIco } from 'assets/images/icons/info-new-dark.svg'
import {
  UserRoleType,
  UserType
} from 'containers/MarketingCampaings/utils/types'
import React, { useMemo, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'

type Props = {
  userTypes: UserType[]
  userRoleTypes: UserRoleType[]
  selectedUserTypes: UserType[]
  selectedUserRoleTypes: UserRoleType[]
  onSelect: (userTypeIds: number[], userRoleTypeIds: number[]) => void
}

enum TypeKind {
  role = 'role',
  user = 'user'
}

const createTypeId = (id: number, typeKind: TypeKind) => {
  return `${id} ${typeKind}`
}

const getIdAndItsKind = (typeId: string) => {
  const [id, kind] = typeId.split(' ')
  return { id: Number(id), kind }
}

export const UserTypeFilter = ({
  selectedUserTypes,
  selectedUserRoleTypes,
  userTypes,
  userRoleTypes,
  onSelect
}: Props) => {
  const [search, setSearch] = useState('')

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const selectableUserTypes = useMemo(
    () =>
      userTypes.filter(
        userType =>
          userType.roles.length === 0 &&
          userType.title.toLowerCase().includes(search.toLowerCase())
      ).sort((ut1: UserType, ut2: UserType) =>
          ut1.title < ut2.title ? -1 : 1
      ),
    [userTypes, search]
  )

  const filteredUserRoleTypes = useMemo(
    () =>
      userRoleTypes.filter(userRole =>
        userRole.name.toLowerCase().includes(search.toLowerCase())
      ).sort((urt1: UserRoleType, urt2: UserRoleType) =>
          urt1.name < urt2.name ? -1 : 1
      ),
    [userRoleTypes, search]
  )

  const selectedTypeIds = useMemo(() => {
    const resultSelectedUserTypes = selectedUserTypes.map(item =>
      createTypeId(item.id, TypeKind.user)
    )
    const resultSelectedUserRoleTypes = selectedUserRoleTypes.map(item =>
      createTypeId(item.id, TypeKind.role)
    )
    const result = resultSelectedUserRoleTypes.concat(resultSelectedUserTypes)
    return result
  }, [selectedUserTypes, selectedUserRoleTypes])

  const handleSelectType = (selectedTypeIds: any[]) => {
    const selectedUserTypeIds: number[] = []
    const selectedUserRoleTypeIds: number[] = []
    selectedTypeIds.forEach(idAndItsKind => {
      const { id, kind } = getIdAndItsKind(idAndItsKind)
      ;(kind === TypeKind.user
        ? selectedUserTypeIds
        : selectedUserRoleTypeIds
      ).push(id)
    })
    onSelect(selectedUserTypeIds, selectedUserRoleTypeIds)
  }

  return (
    <div className='user-type-popup'>
      <div className='title'>User Types ({selectedTypeIds.length})</div>
      <div className='items-selected'>
        <div className='items-selected-count'>
          {`${selectedTypeIds.length} Selected`}
        </div>
        {!!selectedTypeIds?.length && (
          <div
            className='clear'
            onClick={() => {
              onSelect([], [])
            }}
          >
            clear selection
          </div>
        )}
      </div>
      <div className='user-type-search filters-search'>
        <Input
          size='large'
          placeholder='Search User Types'
          suffix={<SearchOutlined />}
          onChange={onSearchChange}
          value={search}
        />
      </div>
      <div className='filters-content'>
        <Checkbox
          className='filter-checkbox'
          indeterminate={
            !!selectedTypeIds.length &&
            selectedTypeIds.length <
              selectableUserTypes.length + filteredUserRoleTypes.length
          }
          onChange={(event: CheckboxChangeEvent) => {
            if (event.target.checked) {
              onSelect(
                selectableUserTypes.map(({ id }) => id),
                filteredUserRoleTypes.map(({ id }) => id)
              )
            } else {
              onSelect([], [])
            }
          }}
          checked={
            selectedTypeIds.length ===
            selectableUserTypes.length + filteredUserRoleTypes.length
          }
        >
          All
        </Checkbox>
        <Checkbox.Group onChange={handleSelectType} value={selectedTypeIds}>
          <div className='filters-content-checkbox-wrapper'>
            {selectableUserTypes.map(userType => {
              const typeId = createTypeId(userType.id, TypeKind.user)

              return (
                <CheckboxWrapper
                  key={typeId}
                  type={userType}
                  typeKind={TypeKind.user}
                  checked={selectedTypeIds.includes(typeId)}
                />
              )
            })}
            {filteredUserRoleTypes.map(userRoleType => {
              const typeId = createTypeId(userRoleType.id, TypeKind.role)

              return (
                <CheckboxWrapper
                  key={typeId}
                  type={userRoleType}
                  typeKind={TypeKind.role}
                  checked={selectedTypeIds.includes(typeId)}
                />
              )
            })}
          </div>
        </Checkbox.Group>
      </div>
    </div>
  )
}

type CheckboxWrapperProps = {
  type: UserType | UserRoleType
  typeKind: TypeKind
  checked: boolean
}

const CheckboxWrapper = ({ type, typeKind, checked }: CheckboxWrapperProps) => {
  return (
    <Checkbox
      className={`user-type-popup-checkbox-group-item filter-checkbox ${
        checked ? 'filter-checked' : ''
      }`}
      value={createTypeId(type.id, typeKind)}
    >
      <span className='user-type-popup-checkbox-group-tem-label-text'>
        {typeKind === TypeKind.user
          ? (type as UserType).title
          : (type as UserRoleType).name}
      </span>
    </Checkbox>
  )
}
