import axios from 'axios'
import config from '../../shared/config'
const l = (...a) => console.log('dashboardApi', ...a)
export const askInsightQuestion = ({
  appName,
  insightID,
  insightURL,
  userEmail,
  question
}) => {
  const url = `${config.dataApi.backendUrl}/insight/${insightID}/question`
  return axios.post(url, {
    user_email: userEmail,
    question,
    insight_url: insightURL,
    app_name: appName
  })
}

const checkUrl = url => {
  if (url.endsWith('undefined')) {
    l('BAD URL', url)
    throw new Error('undfeined url')
  }
}
export const getAppsMetaData = () => {
  const url = `${config.dataApi.backendUrl}/app?has_experiments=1`
  return axios.get(url)
}

export const getAppData = ({ app_id }) => {
  const url = `${config.dataApi.backendUrl}/app/${app_id}/insights`
  return axios.get(url)
}

export const getRelevancedInsights = () => {
  const url = `${config.dataApi.backendUrl}/insight?relevancy_gte=1&meta=0`
  checkUrl(url)
  return axios.get(url)
}

const MAX_MARKETING_CAMPAIGNS = 5000
const MAX_MARKETING_CAMPAIGNS_REQUESTS = 3

export const getMarketingCampaignsAPI = async () => {
  const url = `${config.dataApi.backendUrl}/insight`
  checkUrl(url)

  let page = 1
  const campaigns = []

  while (page <= MAX_MARKETING_CAMPAIGNS_REQUESTS) {
    const { data: res } = await axios.get(url, {
      params: {
        campaigns_only: '1',
        meta: '0',
        page_num: page,
        num_items: MAX_MARKETING_CAMPAIGNS
      }
    })

    const { data } = res

    if (!Array.isArray(data)) break

    campaigns.push(...data)

    if (data.length < MAX_MARKETING_CAMPAIGNS) break

    page++
  }

  return {
    data: {
      data: campaigns
    }
  }
}

export const likeInsight = insightID => {
  const url = `${config.dataApi.backendUrl}/insight/${insightID}/like`
  checkUrl(url)
  return axios.post(url)
}

export const unlikeInsight = insightID => {
  const url = `${config.dataApi.backendUrl}/insight/${insightID}/like`
  checkUrl(url)
  return axios.delete(url)
}

export const setInsightAsViewed = insightID => {
  const url = `${config.dataApi.backendUrl}/insight/${insightID}/read`
  return axios.put(url)
}

export const setInsightAsSeen = insightIDs => {
  const url = `${config.dataApi.backendUrl}/insight/${insightIDs}/seen`
  return axios.put(url)
}

export const getDiscoveryDataAPI = ({ insightID }) => {
  const url = `${config.dataApi.backendUrl}/insight/${insightID}`
  return axios.get(url)
}

export const getDiscoveriesLabelsAPI = prop => {
  const url = `${config.dataApi.backendUrl}/insight/labels?${
    prop && prop.gets ? prop.gets : ''
  }`
  return axios.get(url)
}
