import { useLocation, useNavigate } from 'react-router';

export enum FiltersParams {
  app_id = "app_id",
  persona_id = "persona_id",
  date = "date",
  event_name = "event_name",
  insight_id = "insight_id",
}

type FiltersTypes = {
  app_id: string;
  persona_id: string;
  event_name: string;
  date: string;
  insight_id: string;
};

export type HandleFilterChangeType = <T extends keyof FiltersTypes>(
  filter: T,
  newValue: FiltersTypes[T]
) => void;


export const useFilters = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const params = new URLSearchParams(location.search);

  const appId = params.get(FiltersParams.app_id);
  const personaId = params.get(FiltersParams.persona_id) || [];
  const eventName = params.get(FiltersParams.event_name) || "";
  const date = params.get(FiltersParams.date) || "";
  const insightId = params.get(FiltersParams.insight_id) || "";

  const handleChangeFilter: HandleFilterChangeType = (filter, newValue) => {
    if (Array.isArray(newValue)) {
      params.delete(filter);
      newValue.forEach((value) => {
        params.append(filter, value);
      });
    } else {
      params.set(filter, newValue.toString());
    }

    navigate(`${location.pathname}?${params.toString()}`, { replace: true })
  };

  return {
    appId: Number(appId) || null,
    personaId: Number(personaId) || null,
    insightId: Number(insightId) || null,
    eventName,
    date,
    handleChangeFilter,
  };
};
