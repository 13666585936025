import { call, fork, put, takeEvery } from 'redux-saga/effects';

import * as actionTypes from '../actions/_actionsTypes';
import { getPersonasSuccess } from '../acts/personas';
import * as personasAPI from '../api/personaApi';

function* getPersonas(action: object) {
  // @ts-ignore
  const result = yield call(personasAPI.getPersonas, action.payload)
  yield put(getPersonasSuccess(result.data))
}
function* watchPersonas() {
  yield takeEvery(actionTypes.GET_PERSONAS, getPersonas);
}

const personasSagas = [
  fork(watchPersonas),
];

export default personasSagas;

