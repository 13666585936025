import React from 'react'

import { useSelector } from 'react-redux'

import android_logo from '../../../assets/images/icons/android_logo.svg'
import ios_logo from '../../../assets/images/icons/ios_logo.svg'
import web_logo from '../../../assets/images/icons/web_logo.svg'
import ProductsSmallList from '../../products/ProductsSmallList'
import { PL_MARKETING } from '../../../shared/consts'

export const OverviewWatch = ({
  apps,
  unSeenCriticalInsights,
  marketing = false
}) => {
  const infrastructure = useSelector(state => state.performance.infrastructure)

  if (unSeenCriticalInsights) {
    apps.sort((app1, app2) => {
      if (unSeenCriticalInsights[app1.id] && !unSeenCriticalInsights[app2.id]) {
        return -1
      } else if (
        !unSeenCriticalInsights[app1.id] &&
        unSeenCriticalInsights[app2.id]
      ) {
        return 1
      } else if (
        !unSeenCriticalInsights[app1.id] &&
        !unSeenCriticalInsights[app2.id]
      ) {
        return 0
      } else if (
        unSeenCriticalInsights[app1.id].length >
        unSeenCriticalInsights[app2.id].length
      ) {
        return -1
      } else {
        return 1
      }
    })
  }

  let listOfPlatforms = marketing ? ['Marketing'] : ['Android', 'iOS', 'Web']

  return (
    <div className='overview-page__watch'>
      <h3 className='title'>Apps We’re Watching</h3>
      <div className='extra-text'>Click an app to view the full app page.</div>
      <div className={`apps-on-watch ${apps.length < 12 ? 'short' : 'full'} `}>
        {listOfPlatforms.map((platform, i) => {
          const products = apps
            .map(
              a =>
                a.platform === platform && {
                  name: a.display_name,
                  thumbnail: a.icon,
                  link: marketing
                    ? `/marketing?app=${a.id}`
                    : `/benchmark/compare/${a.performance_events[0]?.id}?loc_1=${infrastructure[0]?.loc || ''}`,
                  enabled: marketing ? true : a.is_monitored
                }
            )
            .filter(a => a)
          return (
            apps.filter(a => a.platform === platform).length > 0 && (
              <div
                className='apps-per-platform'
                style={{
                  marginRight:
                    apps.filter(a => a.platform === platform).length > 15
                      ? ''
                      : '4rem'
                }}
                key={i}
              >
                {platform !== PL_MARKETING && (
                  <h4>
                    {platform === 'Android' && (
                      <img src={android_logo} alt={platform} />
                    )}
                    {platform === 'iOS' && (
                      <img src={ios_logo} alt={platform} />
                    )}
                    {platform === 'Web' && (
                      <img src={web_logo} alt={platform} />
                    )}
                    {platform} Apps{' '}
                    <span className='count'>
                      ({apps.filter(a => a.platform === platform).length})
                    </span>{' '}
                  </h4>
                )}
                <ProductsSmallList products={products} scrollToTop={false} />
                <div className='apps-platform-separator' />
              </div>
            )
          )
        })}
        <div className='apps-platform-separator' />
      </div>
    </div>
  )
}

export default OverviewWatch
