// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-flows-filter {
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}
.user-flows-filter .user-flow-filter-item {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22.4px; /* 160% */
  text-transform: uppercase;
  display: flex;
  padding: 4px 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 200px;
  border: 1px solid #000;
  cursor: pointer;
}
.user-flows-filter .user-flow-filter-item.selected {
  background: #000;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/containers/UserJourneys/components/UserFlowsFilter/UserFlowsFilter.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,yBAAA;EACA,QAAA;EACA,eAAA;AACF;AACE;EACE,WAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA,EAAA,SAAA;EACA,yBAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,QAAA;EACA,oBAAA;EACA,sBAAA;EAOA,eAAA;AALJ;AAAI;EACE,gBAAA;EACA,WAAA;AAEN","sourcesContent":[".user-flows-filter {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  align-content: flex-start;\n  gap: 8px;\n  flex-wrap: wrap;\n\n  .user-flow-filter-item {\n    color: #000;\n    font-family: Roboto;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 300;\n    line-height: 22.4px; /* 160% */\n    text-transform: uppercase;\n    display: flex;\n    padding: 4px 16px;\n    align-items: flex-start;\n    gap: 8px;\n    border-radius: 200px;\n    border: 1px solid #000;\n\n    &.selected {\n      background: #000;\n      color: #fff;\n    }\n\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
