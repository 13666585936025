import {
  GET_MARKETING_APPS_QUERY,
  GET_MARKETING_FOCUS_AREAS_QUERY,
  GET_MARKETING_INSIGHT_BY_ID_QUERY,
  GET_MARKETING_INSIGHT_PERSONAS_AND_FOCUS_AREA_QUERY,
  GET_MARKETING_PERSONAS_QUERY,
  PROMOTIONS_REPOSITORY_QUERY
} from 'gql/marketing/campaigns'
import moment from 'moment'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { useQuery } from '@apollo/client'

import {
  CAMPAIGNS_FILTERS,
  ITEMS_PER_PAGE,
  TABLE_SORT_FIELDS,
  TYPE_TO_CHANNEL
} from '../utils/conts'
import { darkenColor, generateBrightTransparentColor } from '../utils/helpers'
import {
  App,
  Channel,
  FocusArea,
  Insight,
  UserRoleType,
  UserType
} from '../utils/types'

const defaultChannels: Channel[] = Object.entries(TYPE_TO_CHANNEL).map(
  ([type, value]: any) => ({
    id: type,
    title: value,
    amount: 0
  })
)

export const useCampaigns = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)

  const offset = Number(searchParams.get(CAMPAIGNS_FILTERS.offset) || '0')
  const sort =
    searchParams.get(CAMPAIGNS_FILTERS.sort) || TABLE_SORT_FIELDS.desc.startTime
  const limit = Number(
    5000 ||
      searchParams.get(CAMPAIGNS_FILTERS.limit) ||
      ITEMS_PER_PAGE.toString()
  )
  const appIds =
    searchParams.getAll(CAMPAIGNS_FILTERS.app_ids)?.map(id => +id) || ''
  const insightId = searchParams.get(CAMPAIGNS_FILTERS.insight_id) || ''
  const channelIds = searchParams.getAll(CAMPAIGNS_FILTERS.channels) || []
  const selectedUserTypes =
    searchParams.getAll(CAMPAIGNS_FILTERS.user_types) || ''
  const selectedUserRoleTypes =
    searchParams.getAll(CAMPAIGNS_FILTERS.user_role_types) || ''

  const daysAgo = searchParams.get(CAMPAIGNS_FILTERS.days_ago)
    ? parseInt(searchParams.get(CAMPAIGNS_FILTERS.days_ago) || '30')
    : 30

  const customRangeTimes = searchParams.getAll(
    CAMPAIGNS_FILTERS.custom_range_time
  )

  const customRange = !!customRangeTimes?.length

  const customRangeTime =
    customRangeTimes.length > 1
      ? [moment(customRangeTimes[0]), moment(customRangeTimes[1])]
      : [moment(), moment()]

  const startTime = customRange
    ? customRangeTime[0].format('YYYY-MM-DD')
    : moment().subtract(daysAgo, 'days').format('YYYY-MM-DD')

  const endTime = (
    customRange ? customRangeTime[1] : moment().add(1, 'day')
  ).format('YYYY-MM-DD')

  const selectedFocusAreas = (
    searchParams.getAll(CAMPAIGNS_FILTERS.focus_areas) || []
  ).map((focusArea: string) => parseInt(focusArea))

  const { data: appsData, loading: appsLoading } = useQuery(
    GET_MARKETING_APPS_QUERY,
    {
      variables: {
        startTimeGte: startTime,
        startTimeLt: endTime,
      }
    }
  )

  const { data: personasData, loading: personasLoading } = useQuery(
    GET_MARKETING_PERSONAS_QUERY,
    {
      variables: {
        appIds
      },
      skip: !appIds
    }
  )

  const { data: focusAreasData, loading: focusAreasLoading } = useQuery(
    GET_MARKETING_FOCUS_AREAS_QUERY,
    {
      variables: {
        appIds
      },
      skip: !appIds
    }
  )

  const { data: selectedInsightData, loading: selectedInsightLoading } =
    useQuery(GET_MARKETING_INSIGHT_BY_ID_QUERY, {
      variables: {
        insightId: parseInt(insightId)
      },
      skip: !insightId
    })

  const {
    data: selectedInsightsPersonasData,
    loading: selectedInsightsPersonasLoading
  } = useQuery(GET_MARKETING_INSIGHT_PERSONAS_AND_FOCUS_AREA_QUERY, {
    variables: {
      insightId: parseInt(insightId),
      appIds: appIds
    },
    skip: !insightId || !appIds
  })

  const {
    data: insightsData,
    previousData: previousInsightsData,
    loading: insightsLoading
  } = useQuery(PROMOTIONS_REPOSITORY_QUERY, {
    variables: {
      appIds: appIds,
      subType: 'promotional',
      offset,
      limit,
      sort,
      ...(channelIds.length > 0 ? { types: channelIds } : {}),
      personaIds:
        selectedUserTypes.length > 0
          ? selectedUserTypes.map((userType: string) => parseInt(userType))
          : undefined,
      personaRoleIds:
        selectedUserRoleTypes.length > 0
          ? selectedUserRoleTypes.map((personaRole: string) =>
              parseInt(personaRole)
            )
          : undefined,
      startTimeGte: startTime,
      startTimeLt: endTime,
      ...(selectedFocusAreas.length > 0
        ? { focusAreaIds: selectedFocusAreas }
        : {})
    },
    skip: !appIds || !personasData
  })

  const insightsDataChanged = insightsData ? insightsData : previousInsightsData

  const apps: App[] = useMemo(
    () => (!appsLoading && appsData ? appsData?.marketing?.apps : []),
    [appsData]
  )
  const focusAreas: FocusArea[] = useMemo(
    () =>
      !focusAreasLoading && focusAreasData
        ? focusAreasData?.marketing?.focusAreas
        : [],
    [focusAreasData]
  )

  const userTypes: UserType[] = useMemo(
    () =>
      !personasLoading && personasData
        ? personasData?.marketing?.personas?.map((persona: UserType) => {
            const bgColor = generateBrightTransparentColor(0.3)
            const textColor = darkenColor(bgColor)

            return {
              ...persona,
              bgColor,
              textColor
            }
          }) ?? []
        : [],
    [personasData]
  )
  const userRoleTypes: UserRoleType[] = useMemo(() => {
    if (!personasLoading && personasData) {
      const roleMap = new Map<number, UserRoleType>()
      const personas = personasData?.marketing?.personas ?? []
      for (const persona of personas) {
        for (const role of persona.roles) {
          if (!roleMap.has(role.id)) {
            const bgColor = generateBrightTransparentColor(0.3)
            const textColor = darkenColor(bgColor)
            roleMap.set(role.id, {
              ...role,
              description: persona.description ?? '',
              bgColor,
              textColor
            })
          } else {
            const roleMapItem = roleMap.get(role.id) as UserRoleType
            roleMapItem.description =
              roleMapItem.description || persona.description || ''
          }
        }
      }
      const result = Array.from(roleMap.values())
      return result
    }
    return []
  }, [personasData])

  const insights: Insight[] = useMemo(() => {
    if (!insightsLoading && insightsDataChanged) {
      const resultInsights: Insight[] = []
      const insights = insightsDataChanged.marketing.insightPage.insights
      for (const insight of insights) {
        const personas = insight.personas.map((persona: any) => {
          const userType = userTypes.find(
            (userType: UserType) => userType.id === persona.id
          )
          return {
            ...persona,
            bgColor: userType?.bgColor,
            textColor: userType?.textColor
          }
        })

        const personaRoleIds = new Set<number>()
        for (const persona of personas) {
          if (persona?.roles == null || !Array.isArray(persona.roles)) {
            continue
          }

          for (const role of persona.roles) {
            personaRoleIds.add(role.id)
          }
        }

        const personaRoles: UserRoleType[] = []
        for (const personaRoleId of Array.from(personaRoleIds)) {
          const personaRole = userRoleTypes.find(
            userRoleType => userRoleType.id === personaRoleId
          )
          if (personaRole) {
            personaRoles.push({
              ...personaRole
            })
          }
        }

        resultInsights.push({
          ...insight,
          personaRoles,
          personas,
          personasAndRoles: { personas, personaRoles }
        })
      }
      return resultInsights
    }
    return []
  }, [insightsDataChanged])

  const channels: Channel[] = useMemo(
    () =>
      !insightsLoading && insightsDataChanged
        ? defaultChannels.map((channel: Channel) => ({
            ...channel,
            amount:
              insightsDataChanged?.marketing?.insightPage?.summary?.byType?.find(
                (type: any) => type.type === channel.id
              )?.count || 0
          }))
        : defaultChannels,
    [insightsDataChanged]
  )
  const totalDbInsights = useMemo(
    () =>
      !insightsLoading && insightsDataChanged
        ? insightsDataChanged?.marketing?.insightPage?.summary?.totalCount
        : 0,
    [insightsDataChanged]
  )

  const selectedInsight = useMemo(() => {
    if (selectedInsightData && selectedInsightsPersonasData) {
      const personas =
        selectedInsightsPersonasData?.marketing?.insightPage?.insights?.[0]
          ?.personas || []
      const metaData =
        selectedInsightsPersonasData?.marketing?.insightPage?.insights?.[0]
          ?.metaData || null

      const personaRolesMap = new Map<number, any>()
      for (const persona of personas) {
        const roles = persona?.roles ?? []
        for (const role of roles) {
          if (!personaRolesMap.has(role.id)) {
            personaRolesMap.set(role.id, {
              ...role,
              description: persona?.description ?? ''
            })
          } else {
            const foundRole = personaRolesMap.get(role.id)
            if (foundRole.description === '') {
              foundRole.description = persona.description ?? ''
            }
          }
        }
      }
      const personaRoles = Array.from(personaRolesMap.values())

      return {
        ...selectedInsightData?.insight?.data,
        personas,
        metaData: metaData && JSON.parse(metaData),
        personaRoles,
        focusArea:
          selectedInsightsPersonasData?.marketing?.insightPage?.insights?.[0]
            ?.focusArea || null
      }
    }
    return null
  }, [selectedInsightData, selectedInsightsPersonasData])

  const isLoading =
    appsLoading ||
    personasLoading ||
    insightsLoading ||
    selectedInsightLoading ||
    selectedInsightsPersonasLoading ||
    focusAreasLoading

  const fetchNextPage = () => {
    if (offset + limit >= totalDbInsights || insightsLoading) return

    searchParams.set(
      CAMPAIGNS_FILTERS.limit,
      (limit + ITEMS_PER_PAGE).toString()
    )

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true
    })
  }

  return {
    apps,
    userTypes,
    userRoleTypes,
    channels,
    insights,
    totalDbInsights,
    selectedInsight,
    fetchNextPage,
    isLoading,
    focusAreas
  }
}
