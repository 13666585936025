import React, { useState } from 'react'
import { connect } from 'react-redux'

import { setInsightAsViewed } from '../../redux/actions/dashboardActions'
import { removeReadRelevantInsights } from '../../redux/actions/overviewActions'
import GalleryDiscoveries from '../dash-discoveries/GalleryDiscoveries'
import compass from '../../assets/images/new_design/icons/Compass.svg'
import emptyListMarkedPng from '../../assets/images/new_design/marked-by-me-empty.png'
import { PL_MARKETING } from '../../shared/consts'

export const UnSeenCriticalInsights = ({
  apps,
  unSeenCriticalInsights,
  user,
  analytics,
  setInsightAsViewed,
  removeReadRelevantInsights,
  marketing,
  grouping = true,
  userDiscoveries,
  isFeaturedList,
  isMarkedList,
  source
}) => {
  const [fade, changeFade] = useState(false)
  const [insightsForUpdate, insightsForUpdateChange] = useState([])

  const closeModalAndRemoveViewedInsights = () => {
    insightsForUpdate.forEach(insID => {
      setInsightAsViewed(insID)
    })
    changeFade(true)
    insightsForUpdateChange([])
    setTimeout(removeReadRelevantInsights, 700)
  }

  if (!unSeenCriticalInsights) {
    return null
  }

  const updateDiscoveriesUserData = arrDis =>
    arrDis.map(dis => {
      if (!userDiscoveries) return dis
      let uD = userDiscoveries.find(d => d.insight_id === dis.id)
      if (uD) {
        return {
          ...dis,
          ...uD
        }
      } else {
        return dis
      }
    })

  const countOfInsights = Object.keys(unSeenCriticalInsights)
    .filter(app_id => apps.find(a => a.id == app_id))
    .reduce((acc, app_id) => acc + unSeenCriticalInsights[app_id].length, 0)

  return (
    <div className='unseen-crit-insights-wrapper'>
      {isFeaturedList &&
        ((countOfInsights === 0 && grouping) ||
          (unSeenCriticalInsights.length === 0 && !grouping)) && (
          <div className='empty-insights'>
            <img src={compass} />
            <div>
              We’ll notify you as soon as our team finds additional meaningful
              discoveries
            </div>
          </div>
        )}

      {isMarkedList &&
        ((countOfInsights === 0 && grouping) ||
          (unSeenCriticalInsights.length === 0 && !grouping)) && (
          <div className='empty-marked-insights'>
            <img src={emptyListMarkedPng} className='mrk-img' />
          </div>
        )}

      {grouping ? (
        Object.keys(unSeenCriticalInsights).map(app_id => {
          const app = apps.find(a => a.id === app_id)

          if (!app) {
            return null
          }

          if (!marketing && app.platform === PL_MARKETING) {
            return null
          }

          if (unSeenCriticalInsights[app_id].length === 0) {
            return null
          }

          return (
            <div className='per-app' key={app_id}>
              <div className='app-data'>
                <img src={app.icon} />{' '}
                <h3>
                  {app.display_name}
                  <span style={{ fontWeight: 400 }}>
                    {app.platform !== PL_MARKETING ? ` (${app.platform})` : ''}
                  </span>
                </h3>
              </div>

              <div
                className={`versions__list crit-insights ${fade ? 'fade' : ''}`}
              >
                <GalleryDiscoveries
                  loadingFilteredDiscoveries={false}
                  filteredDiscoveries={updateDiscoveriesUserData(
                    unSeenCriticalInsights[app_id]
                  )}
                  apps={apps}
                  showType={false}
                  user={user}
                  analytics={analytics}
                  productName={null}
                  handleClick={null}
                  usePagination={false}
                  closeModalHandler={closeModalAndRemoveViewedInsights}
                  source={source}
                />
              </div>
            </div>
          )
        })
      ) : (
        <div className={`versions__list crit-insights ${fade ? 'fade' : ''}`}>
          <GalleryDiscoveries
            loadingFilteredDiscoveries={false}
            filteredDiscoveries={updateDiscoveriesUserData(
              unSeenCriticalInsights.map(a => Object.values(a)[0]).slice(0, 8)
            )}
            apps={apps}
            showType={false}
            user={user}
            analytics={analytics}
            productName={null}
            handleClick={null}
            usePagination={false}
            closeModalHandler={closeModalAndRemoveViewedInsights}
            source={source}
          />
          {unSeenCriticalInsights.length > 8 && isFeaturedList && (
            <div className='featured-view-all'>
              <a
                target='_blank'
                href={`/all-discoveries?daysAgo=60&filterIntelEnabled=1&filterStatus=featured&filterType=ab_test%2Cindication%2Cscreen_change%2Cnew_feature%2Cremoved_feature`}
                rel='noreferrer'
              >
                View All ({unSeenCriticalInsights.length})
              </a>
            </div>
          )}
          {unSeenCriticalInsights.length > 8 && isMarkedList && (
            <div className='featured-view-all'>
              <a
                target='_blank'
                href={`/all-discoveries?daysAgo=60&filterIntelEnabled=1&filterStatus=marked&filterType=ab_test%2Cindication%2Cscreen_change%2Cnew_feature%2Cremoved_feature`}
                rel='noreferrer'
              >
                View All ({unSeenCriticalInsights.length})
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ userDiscoveries, appData }) => ({
  allVersions: appData.allVersions,
  analytics: appData.user.analytics,
  user: appData.user,
  userDiscoveries: userDiscoveries.discoveries
})

const mapDispatchToProps = {
  setInsightAsViewed,
  removeReadRelevantInsights
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnSeenCriticalInsights)
