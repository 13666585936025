import './MarketingCampaignChannelsBreakdown.scss'

import {
  ChannelToColor,
  ChannelToLabel
} from 'containers/MarketingOverview/utils/consts'
import { App } from 'containers/MarketingOverview/utils/types'
import React from 'react'

import { HeadingWithTooltip } from '../../HeadingWithTooltip'
import PromotionsVolumeChart from './PromotionsVolumeChart'

export type MarketingInsight = {
  insightType: string
  count: number
  percentage: number
}

export type AppByInsightType = App & {
  insightTypes: MarketingInsight[]
  totalAppInsights: number
  totalPrevPeriod: number
}

type Props = {
  apps: AppByInsightType[]
  selectedChannels: string[]
  numberOfDays: number
}

export const MarketingCampaignChannelsBreakdown = ({
  apps,
  selectedChannels,
  numberOfDays
}: Props) => {
  return (
    <div className='mccb'>
      <HeadingWithTooltip
        title='Promotions Volume'
        subtitle='Compare the amount of Promotions across different competitors for selected period'
      >
        <div className='legend'>
          {selectedChannels.map(channel => {
            return (
              <>
                <div
                  className='legend-color'
                  style={{ backgroundColor: ChannelToColor[channel] }}
                ></div>
                <div>{ChannelToLabel[channel]}</div>
              </>
            )
          })}
        </div>
      </HeadingWithTooltip>
      <div className='mccb-container'>
        {apps.slice(0, 5).map(app => {
          return (
            <PromotionsVolumeChart
              key={app.id}
              app={app}
              numberOfDays={numberOfDays}
            />
          )
        })}
      </div>
    </div>
  )
}

export default MarketingCampaignChannelsBreakdown
