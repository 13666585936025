import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Slider } from "antd";
import double_caret_left_ico from "assets/images/icons/context-timeline/double-caret-left.svg";
import double_caret_right_ico from "assets/images/icons/context-timeline/double-caret-right.svg";
import { Release } from "pages/Intel/utils/types";
import { HandleIntelFilterChangeType } from "pages/Intel/hooks/useIntelFilters";

type Props = {
  startVersion: string;
  endVersion: string;

  startVersionId: string;
  endVersionId: string;

  releases: Release[];
  releaseId: string;

  handleChangeFilter: HandleIntelFilterChangeType;
};

export const ReleaseTimeline = ({
  startVersion,
  endVersion,

  endVersionId,
  startVersionId,

  releases,
  releaseId,
  handleChangeFilter,
}: Props) => {
  const [scrollMax, setScrollMax] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const element = document.querySelector(
        ".i-releases-list"
      ) as HTMLDivElement;

      if (!element) return;

      element.onscroll = () => {
        setSliderValue(element.scrollLeft);
      };

      const maxScrollValue = element.scrollWidth;
      setScrollMax(maxScrollValue);
    }, 500);
  }, [releases]);

  useEffect(() => {
    const selectedCard = document.querySelector(
      ".card.selected"
    ) as HTMLDivElement;

    if (selectedCard) {
      const element = document.querySelector(
        ".i-releases-list"
      ) as HTMLDivElement;
      const cardRect = selectedCard.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      const scrollAdjustment = element.scrollLeft;
      const newSliderValue =
        cardRect.left - elementRect.left + scrollAdjustment;

      setSliderValue(newSliderValue);
    }
  }, [releaseId]);

  const onSliderChange = (value: number) => {
    const element = document.querySelector(
      ".i-releases-list"
    ) as HTMLDivElement;
    setSliderValue(value);

    if (element) {
      element.scrollLeft = value;
    }
  };

  const onReleaseChange = (releaseId: string) => {
    handleChangeFilter("release_id", releaseId);
  };

  return (
    <div className="i-timeline">
      <img
        src={double_caret_left_ico}
        onClick={() => {
          onReleaseChange(startVersionId);
          setSliderValue(0);
        }}
      />
      <div className="i-timeline-start-date">
        Tracked Since: Ver. {startVersion}
      </div>
      <Slider
        style={{ flex: 1 }}
        min={0}
        disabled={scrollMax === 0}
        max={scrollMax}
        value={sliderValue}
        onChange={onSliderChange}
        className="custom-slider"
        tooltipVisible={false}
      />
      <div className="i-timeline-end-date">Last Release: {endVersion}</div>
      <img
        src={double_caret_right_ico}
        onClick={() => {
          onReleaseChange(endVersionId);
          setSliderValue(scrollMax);
        }}
      />
    </div>
  );
};
