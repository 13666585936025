import './PlatformDrawer.scss'

import { Checkbox } from 'antd'
import android_ico from 'assets/images/icons/newIntel/android.svg'
import ios_ico from 'assets/images/icons/newIntel/ios.svg'
import web_ico from 'assets/images/icons/newIntel/web.svg'
import x_ico from 'assets/images/icons/x-no-outline.svg'
import clsx from 'clsx'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import { PLATFORM } from 'pages/Intel/utils/consts'
import React from 'react'

type Props = {
  selectedPlatforms: string[]
  onPlatformSelect: (platforms: string[]) => void
  platformCounts: Record<keyof typeof PLATFORM, number>
  closeDrawer: () => void
  isSelectAllDisabled: boolean
}

const PLATFORM_TO_IMAGE = {
  ios: ios_ico,
  android: android_ico,
  web: web_ico
}

export const PlatformDrawer = ({
  onPlatformSelect,
  selectedPlatforms,
  platformCounts,
  closeDrawer,
  isSelectAllDisabled
}: Props) => {
  const isMobile = isMobileDevice()

  const onAllToggle = () => {
    if (selectedPlatforms.length === 3) {
      onPlatformSelect([])
    } else {
      onPlatformSelect(['ios', 'android', 'web'])
    }
  }

  const onSelectPlatform = (platform: string) => () => {
    if (selectedPlatforms.includes(platform)) {
      onPlatformSelect(selectedPlatforms.filter(p => p !== platform))
    } else {
      onPlatformSelect([...selectedPlatforms, platform])
    }
  }

  return (
    <div className='platform-drawer-container'>
      <div className='platform-drawer-options'>
        {!isMobile ? (
          <Checkbox
            onChange={onAllToggle}
            checked={selectedPlatforms.length === 3}
            className='all'
            disabled={isSelectAllDisabled}
          >
            All
          </Checkbox>
        ) : (
          <img className='x-close' src={x_ico} onClick={closeDrawer} />
        )}
        {isMobile && <h5>Platforms</h5>}
        {platformCounts.ios > 0 && (
          <div
            className={clsx('platform-drawer-option', {
              selected: selectedPlatforms.includes('ios')
            })}
            onClick={onSelectPlatform('ios')}
          >
            <img src={PLATFORM_TO_IMAGE['ios']} alt='ios' />
            <Checkbox checked={selectedPlatforms.includes('ios')}>
              iOS ({platformCounts.ios})
            </Checkbox>
          </div>
        )}
        {platformCounts.android > 0 && (
          <div
            className={clsx('platform-drawer-option', {
              selected: selectedPlatforms.includes('android')
            })}
            onClick={onSelectPlatform('android')}
          >
            <img src={PLATFORM_TO_IMAGE['android']} alt='android' />
            <Checkbox checked={selectedPlatforms.includes('android')}>
              Android ({platformCounts.android})
            </Checkbox>
          </div>
        )}
        {platformCounts.web > 0 && (
          <div
            className={clsx('platform-drawer-option', {
              selected: selectedPlatforms.includes('web')
            })}
            onClick={onSelectPlatform('web')}
          >
            <img src={PLATFORM_TO_IMAGE['web']} alt='web' />
            <Checkbox checked={selectedPlatforms.includes('web')}>
              Web ({platformCounts.web})
            </Checkbox>
          </div>
        )}
      </div>
    </div>
  )
}
