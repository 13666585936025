import React from "react";
import "./Card.scss";
import {
  AB_TEST_STATUSES,
  INTEL_INSIGHT_TYPES,
  INTEL_INSIGHT_TYPES_PROPS,
  PLATFORM,
} from "pages/Intel/utils/consts";
import ios_ico from "assets/images/icons/newIntel/ios.svg";
import android_ico from "assets/images/icons/newIntel/android.svg";
import web_ico from "assets/images/icons/newIntel/web.svg";
import { Empty } from "antd";
import { trackIntelEvent } from "pages/Intel/utils/analytics";
import { useIntersection } from "containers/MarketingOverview/hooks/useIntersection";

type Props = {
  id: number;
  platform: keyof typeof PLATFORM;
  appIcon: string;
  type: keyof typeof INTEL_INSIGHT_TYPES;
  thumbnail: string;
  date: string;
  title: string;
  abTestStatus?: keyof typeof AB_TEST_STATUSES;
  variantsAmount?: number;
  onClick?: () => void;
};

const PLATFORM_TO_IMAGE = {
  ios: ios_ico,
  android: android_ico,
  web: web_ico,
};

const MAX_TITLE_LENGTH = 30;
const MAX_TITLE_LENGTH_MOBILE = 43;

type IntelCardLabelType = {
  textColor: string;
  bgColor: string;
  label: string;
};

const INTEL_CARD_LABELS_BY_TYPE: Record<string, IntelCardLabelType> = {
  [AB_TEST_STATUSES.integrated]: {
    textColor: "#017637",
    bgColor: "#B5F0B4",
    label: "Integrated",
  },
  [AB_TEST_STATUSES.active]: {
    textColor: "#017637",
    bgColor: "#B5F0B4",
    label: "Active",
  },
  [AB_TEST_STATUSES.abandoned]: {
    textColor: "#B11515",
    bgColor: "#F0C3B4",
    label: "Abandoned",
  },
  [INTEL_INSIGHT_TYPES.new_feature]: {
    textColor: "#016876",
    bgColor: "#B4EDF0",
    label: "New",
  },
  [INTEL_INSIGHT_TYPES.removed_feature]: {
    textColor: "#B11515",
    bgColor: "#F0C3B4",
    label: "Removed",
  },
};

export const Card = ({
  id,
  appIcon,
  thumbnail,
  date,
  platform,
  title,
  type,
  abTestStatus,
  variantsAmount,
  onClick,
}: Props) => {
  const typeProps = INTEL_INSIGHT_TYPES_PROPS[type];
  const labelProps =
    INTEL_CARD_LABELS_BY_TYPE[
      type === INTEL_INSIGHT_TYPES.ab_test && abTestStatus ? abTestStatus : type
    ];
  const { intersectionRef } = useIntersection({
    onIntersect: () => {
      trackIntelEvent("intel_card_viewed", {
        insight_id: id,
        insight_title: title,
        insight_type: type,
      });
    },
  });

  return (
    <div className="i-card-container" onClick={onClick} ref={intersectionRef}>
      <div className="i-card-asset-section">
        <div className="i-card-asset-section-top">
          <img src={PLATFORM_TO_IMAGE[platform]} />
          <img src={appIcon} />
          <div
            className="i-card-asset-section-top-type"
            style={{
              color: typeProps.textColor,
              backgroundColor: typeProps.bgColor,
            }}
          >
            {typeProps.label}
          </div>
        </div>
        <div className="i-card-asset-thumbnail">
          {variantsAmount && (
            <div className="i-card-asset-thumbnail-variants">
              Variants <span>{variantsAmount}</span>
            </div>
          )}
          {thumbnail ? (
            <img src={thumbnail} />
          ) : (
            <Empty description="No Images For This Insight" />
          )}
        </div>
      </div>
      <div className="i-card-info-section">
        <div className="i-card-info-section-date-and-label">
          <div className="date">Discovery Date: {date}</div>
          {labelProps && (
            <div
              style={{
                color: labelProps.textColor,
                backgroundColor: labelProps.bgColor,
              }}
              className="label"
            >
              {labelProps.label}
            </div>
          )}
        </div>
        <div className="i-card-info-section-title" title={title}>{title}</div>
      </div>
    </div>
  );
};
