import axios from 'axios'
import config from '../../shared/config'
import moment from 'moment'

const l = (...a) => console.log('overviewApi', ...a)

const checkUrl = url => {
  if (url.endsWith('undefined')) {
    l('BAD URL', url)
    throw new Error('undfeined url')
  }
}

export const getUnseenCriticalInsights = () => {
  const { backendUrl } = config.dataApi
  let url = `${backendUrl}/insight/per_app?unread_only=1&created_gte=${moment()
    .subtract(1, 'month')
    .format('YYYY-MM-DD')}` //&relevancy_gte=0
  checkUrl(url)
  return axios.get(url)
}

export const getDiscoveries = gets => {
  const { backendUrl } = config.dataApi
  let url = `${backendUrl}/insight?${gets}`
  checkUrl(url)
  return axios.get(url)
}

export const getCompletedVersionsWithDiscoveriesAPI = ({
  from_date,
  until_date
}) => {
  const { backendUrl } = config.dataApi
  let url = `${backendUrl}/releases?from_date=${from_date}&until_date=${until_date}`
  return axios.get(url)
}

export const getBeingAnalyzedVersionsAPI = ({ days_ago }) => {
  const { backendUrl } = config.dataApi
  let url = `${backendUrl}/releases?state=analysis_pending,analysis_in_progress&days_ago=${days_ago}`
  return axios.get(url)
}

export const getFeaturedDiscoveriesAPI = () => {
  const { backendUrl } = config.dataApi
  let url = `${backendUrl}/insight?featured_only=1&days_ago=60&meta=0&type=ab_test%2Cindication%2Cscreen_change%2Cnew_feature%2Cremoved_feature`
  checkUrl(url)
  return axios.get(url)
}

export const getMarkedDiscoveriesAPI = () => {
  const { backendUrl } = config.dataApi
  let url = `${backendUrl}/insight`
  return axios.get(url, {
    params: {
      liked_only: 1,
      days_ago: 60,
      meta: 0,
      type: 'ab_test,indication,screen_change,new_feature,removed_feature,email_campaign,push_notification_campaign,inapp_campaign'
    }
  })
}
