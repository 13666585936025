import { createSelector } from 'reselect';

import { Ipersona, IState } from '../../../interfaces/Product';

const getPersona = (state: IState): Ipersona[] => {
  return state.personas
};

export const personasSelector = createSelector(
  [getPersona],
  (personas: Ipersona[]): Ipersona[] => {
    return personas
  }
);