export const calcEventLine = (repSample, eventID) => {
  let globalEventDuration =
    repSample.event_end_offset_s - repSample.event_start_offset_s

  // If the event has no subevent, add a single event so the subevent bar in the ui won't appear empty
  if (repSample.sub_events.length === 0 || repSample.sub_events.length === 1) {
    repSample.sub_events = [
      {
        ...repSample.sub_events[0],
        video_time: 0,
        percentage: 100
      }
    ]
  } else {
    // calculating the percentage of each sub event from the entire event
    repSample.sub_events = repSample.sub_events.reduce(
      (acc, curr, currIndex) => {
        //debugger
        acc.push({
          ...curr,
          video_time: (curr.end_ts_s - repSample.event_start_offset_s).toFixed(
            2
          ),
          absPerc:
            ((curr.end_ts_s - repSample.event_start_offset_s) /
              globalEventDuration) *
            100,
          percentage:
            ((curr.end_ts_s -
              (acc[currIndex - 1]
                ? acc[currIndex - 1].end_ts_s
                : repSample.event_start_offset_s)) /
              globalEventDuration) *
            100
        })
        return acc
      },
      []
    )
  }
  repSample.id = eventID
  repSample.globalEventDuration = globalEventDuration

  return repSample
}

export const manipulateRawBackendData = (data, eventID) => {
  let averageSubevents = data.average_subevents
  let globalEventDuration
  const subEvtAvgsNames = new Set(averageSubevents.map(se => se.name))

  let reprSampleObject = data['representative_sample']
  //We will make fake representative_sample for avoiding problems
  if (!reprSampleObject) {
    reprSampleObject = data['representative_sample'] = {
      sub_events: [...averageSubevents],
      event_end_offset_s:
        averageSubevents[averageSubevents.length - 1].end_ts_s_in_event,
      event_start_offset_s: averageSubevents[0].end_ts_s_in_event,
      average_duration_factor: 1
    }
    reprSampleObject.sub_events[averageSubevents.length - 1].name = 'end_time'
    reprSampleObject.sub_events = reprSampleObject.sub_events.map(subEvnt => ({
      ...subEvnt,
      end_ts_s: subEvnt.end_ts_s_in_event
    }))
    globalEventDuration = reprSampleObject.event_end_offset_s
  } else {
    globalEventDuration =
      reprSampleObject.event_end_offset_s -
      reprSampleObject.event_start_offset_s
  }
  const returnedDataObj = { ...data }
  reprSampleObject.sub_events = reprSampleObject.sub_events
    .filter(subevent => subevent.end_ts_s)
    .sort((a, b) => (a.end_ts_s < b.end_ts_s ? -1 : 1))
    .filter(
      (subevt, index, arr) =>
        arr.map(s => s.name).indexOf(subevt.name) === index
    )

  reprSampleObject.sub_events = reprSampleObject.sub_events.filter(se =>
    subEvtAvgsNames.has(se.name)
  )
  // If the event has no subevent, add a single event so the subevent bar in the ui won't appear empty
  if (
    reprSampleObject.sub_events.length === 0 ||
    reprSampleObject.sub_events.length === 1
  ) {
    reprSampleObject.sub_events = [
      {
        ...reprSampleObject.sub_events[0],
        video_time: 0,
        percentage: 100
      }
    ]
  } else {
    // calculating the percentage of each sub event from the entire event
    reprSampleObject.sub_events = reprSampleObject.sub_events.reduce(
      (acc, curr, currIndex) => {
        if (currIndex === 0) {
          // first item, percentage is just the subevent time divided by the subevent time
          acc.push({
            ...curr,
            video_time: averageSubevents[currIndex].end_ts_s_in_event,
            percentage:
              (averageSubevents[currIndex].end_ts_s_in_event /
                globalEventDuration) *
              100
          })
          return acc
        }

        acc.push({
          ...curr,
          video_time: averageSubevents[currIndex].end_ts_s_in_event,
          percentage:
            ((averageSubevents[currIndex].end_ts_s_in_event -
              averageSubevents[currIndex - 1].end_ts_s_in_event) /
              globalEventDuration) *
            100
        })
        return acc
      },
      []
    )
  }

  returnedDataObj.id = eventID

  returnedDataObj.globalEventDuration = globalEventDuration

  /** This code is related to the graphs on top of the timeline view */
  if (
    !returnedDataObj.metrics ||
    Object.keys(returnedDataObj.metrics).length === 0
  ) {
    returnedDataObj.metrics = {
      network_rx: { resolution: 0.1, values: [null], filtered_values: [null] },
      network_tx: { resolution: 0.1, values: [null], filtered_values: [null] },
      mem_usage_total: {
        resolution: 0.1,
        values: [null],
        filtered_values: [null]
      },
      cpu_usage: { resolution: 0.1, values: [null], filtered_values: [null] }
    }
  } else {
    returnedDataObj.metrics.mem_usage_total.values =
      returnedDataObj.metrics.mem_usage_total.values.map(
        memVal => (memVal && memVal * 1024) || null
      )
    Object.values(returnedDataObj.metrics).forEach(metricDataObj => {
      if (!metricDataObj.values[0]) {
        // first metric value is null, replace with a 0
        metricDataObj.values.splice(0, 1, Number(0))
      }
    })
    returnedDataObj.metrics.network_rx['filtered_values'] =
      returnedDataObj.metrics.network_rx.values.filter(value => value)
    returnedDataObj.metrics.network_tx['filtered_values'] =
      returnedDataObj.metrics.network_tx.values.filter(value => value)
    // Converting from kb to bytes since the number formatter expect bytes
    // returnedDataObj.metrics.mem_usage_total.values = returnedDataObj.metrics.mem_usage_total.values.map(
    //   value => (value ? value : null)
    // );
    returnedDataObj.metrics.mem_usage_total['filtered_values'] =
      returnedDataObj.metrics.mem_usage_total.values.filter(value => value)
  }

  return returnedDataObj
}

export const manipulateRawHistoryBackendData = data => {
  return data.map(dataPoint => {
    return {
      date: dataPoint.start_time,
      subeventName: dataPoint.subevent_name,
      subeventTitle: dataPoint.subevent_title,
      value: dataPoint.value_avg * 1000,
      release_id: dataPoint.release_id,
      release_ids: dataPoint.release_ids
    }
  })
}

export const manipulateRawHistoryBackendData2 = data => {
  return data
    .map(dataPoint => {
      return {
        date: dataPoint.start_time,
        subeventName: dataPoint.subevent_name,
        subeventTitle: dataPoint.subevent_title,
        value: dataPoint.value_avg,
        release_id: dataPoint.release_id,
        release_ids: dataPoint.release_ids,
        metric: dataPoint.metric
      }
    })
    .reduce((acc, el) => {
      if (!acc[el.metric]) {
        acc[el.metric] = [el]
      } else {
        acc[el.metric].push(el)
      }
      return acc
    }, {})
}
