// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csr-container {
  height: 56px;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 92vw;
}
.csr-container .close {
  width: 20%;
  height: 100%;
  border-radius: 8px;
  background: #eaeaea;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.csr-container .crs-results {
  display: flex;
  width: 80%;
  height: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #2e5fff;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/CloseShowResults/CloseShowResults.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,UAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,0BAAA;AAAJ","sourcesContent":[".csr-container {\n  height: 56px;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  width: 92vw;\n\n  .close {\n    width: 20%;\n    height: 100%;\n    border-radius: 8px;\n    background: #eaeaea;\n    padding: 8px 16px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .crs-results {\n    display: flex;\n    width: 80%;\n    height: 100%;\n    padding: 8px;\n    justify-content: center;\n    align-items: center;\n    gap: 4px;\n    border-radius: 8px;\n    background: #2e5fff;\n    color: #fff;\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    text-transform: capitalize;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
