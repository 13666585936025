import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { addCommas } from '../../../helpers/numbersHelper'
import { ReactComponent as NavBenchmarkCompareIco } from '../../../assets/images/icons/compare_icon.svg'
import moment from 'moment'

import chevron_down_green_ico from '../../../assets/images/icons/chevron_down_green.svg'
import chevron_up_red_ico from '../../../assets/images/icons/chevron_up_red.svg'

import { getPerformanceRanksCompare } from '../../../redux/actions/performanceActions'
import { daysAgoList } from '../../../shared/consts'

const THRESHOLD = 0.5

export const BenchmarkTable = props => {
  const {
    apps,
    deviceModels,
    customRangeTime,
    customRange,
    getPerformanceRanksCompare,
    daysAgo,
    compareDate,
    currentDevice,
    currentOSVersion,
    mode,
    analytics,
    noRequest = false
  } = props

  const lastRes = apps[0] ? apps[apps.length - 1].rank.duration_s : ''

  useEffect(() => {
    if (noRequest) {
      return
    }
    //comapre between daysAgo and ~2months before
    let dates
    //debugger;
    if (customRange) {
      dates = {
        begin1range: moment(customRangeTime[0])
          .subtract(50, 'days')
          .format('YYYY-MM-DD'),
        end1range: moment(customRangeTime[0]).format('YYYY-MM-DD'),
        begin2range: moment(customRangeTime[0]).format('YYYY-MM-DD'),
        end2range: moment(customRangeTime[1]).format('YYYY-MM-DD')
      }
    } else {
      dates = {
        begin1range: moment()
          .subtract(Number(daysAgo) + 50, 'days')
          .format('YYYY-MM-DD'),
        end1range: moment()
          .subtract(Number(daysAgo) + 1, 'days')
          .format('YYYY-MM-DD'),
        begin2range: moment().subtract(daysAgo, 'days').format('YYYY-MM-DD'),
        end2range: moment().format('YYYY-MM-DD')
      }
    }

    getPerformanceRanksCompare(dates, currentDevice, currentOSVersion)
  }, [daysAgo, currentDevice, currentOSVersion, customRangeTime])

  const getValuesDiff = app => {
    const emptyDiff = <div className={`ranks-table-app-data-diff `}>—</div>
    if (!compareDate) {
      return emptyDiff
    }

    const relatedRec = compareDate.find(
      rec =>
        rec.segment.app_id === app.id &&
        rec.segment.event_name === app.rank.event_name &&
        rec.segment.loc === app.rank.loc
    )

    if (!relatedRec || !relatedRec['1st'] || !relatedRec['2nd']) {
      return emptyDiff
    }

    const diff = relatedRec['2nd'].value_lst - relatedRec['1st'].value_lst

    if (Math.abs(diff) <= THRESHOLD) {
      return emptyDiff
    }
    return (
      <div
        className={`ranks-table-app-data-diff ${
          diff > 0 ? 'worse' : 'better'
        } `}
      >
        <img
          src={`${diff > 0 ? chevron_up_red_ico : chevron_down_green_ico}`}
          style={{ width: `0.7rem`, marginRight: `0.5rem` }}
        />
        {/* {Math.abs(((relatedRec['2nd'].duration_s / relatedRec['1st'].duration_s) * 100 - 100)).toFixed(2)}% */}
        {Math.abs(diff).toFixed(1)}s
      </div>
    )
  }

  const handleClickCompare = app => {
    analytics.analytic(`Compare apps from benchmark ranks`, {
      app: app.name
    })
  }

  const getRelatedEventForLink = app => {
    const queryStr = `device_1=${app.rank.model}&os_version_1=${app.rank.os_version}&loc_1=${app.rank.loc}`
    let event = app.performance_events.find(
      ev => ev.name === app.rank.event_name
    )
    if (event) {
      return `/benchmark/compare/${event.id}?${queryStr}`
    } else {
      return `/benchmark/compare/${app.performance_events[0].id}?${queryStr}`
    }
  }
  const enrichedApps = apps.map(app => {
    const deviceModel = deviceModels.find(
      model =>
        app.rank.model === model.model &&
        app.rank.os_version === model.os_version
    )

    if (!deviceModel) {
      console.log(
        `Didn't find corresponding Device Model for app.rank.model == ${app.rank.model}`
      )
    } else {
      app.rank = {
        ...app.rank,
        deviceModel
      }
    }

    return app
  })

  return (
    <div className='ranks-table'>
      <div className='ranks-table-head'>
        <div>Rank</div>
        <div>App</div>
        {mode !== 'small' && <div>Device</div>}
        {mode !== 'small' && <div>Samples</div>}
        <div>Duration</div>
        <div style={{ fontSize: customRange && '0.8rem' }}>
          {customRange
            ? `${customRangeTime[0].format(
                'YYYY-MM-DD'
              )} - ${customRangeTime[1].format('YYYY-MM-DD')}`
            : daysAgoList.find(d => d.value == daysAgo).title}
        </div>
      </div>
      <div className='ranks-table-body'>
        {enrichedApps.map((app, i) => {
          return (
            <div className='ranks-table-app' key={i}>
              <div className='ranks-table-app-data'>
                <div className={`${i + 1 > 3 ? 'less3' : ''}`}>#{i + 1}</div>
                <div>
                  <img src={app.icon} className='ranks-app-icon' />
                  {app.name}
                </div>
                {mode !== 'small' && (
                  <div>
                    {app.rank.deviceModel ? (
                      <>
                        {' '}
                        {app.rank.deviceModel.product_name}
                        <span className='device-os-version'>
                          ({app.rank.deviceModel.platform}{' '}
                          {app.rank.deviceModel.os_version})
                        </span>
                      </>
                    ) : (
                      'Unk'
                    )}
                  </div>
                )}
                {mode !== 'small' && (
                  <div>{addCommas(app.rank.num_samples)}</div>
                )}

                <div className='ranks-table-app-data-duration'>
                  <strong>{app.rank.duration_s.toFixed(1)}</strong>SEC
                </div>
                {getValuesDiff(app)}
              </div>
              <div className='compare-line-wrap'>
                <div
                  className='compare-line-val'
                  style={{ width: `${(app.rank.duration_s / lastRes) * 100}%` }}
                />
              </div>

              {app.performance_events && (
                <Tooltip placement='top' title='Compare'>
                  <div
                    className='link-to-compare'
                    onClick={() => handleClickCompare(app)}
                  >
                    <Link to={getRelatedEventForLink(app)}>
                      <NavBenchmarkCompareIco title='' />
                    </Link>
                  </div>
                </Tooltip>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default connect(
  ({ appData, performance }) => ({
    deviceModels: performance.deviceModels,
    user: appData.user,
    compareDate: performance.compareRanks
      ? performance.compareRanks.comparison
      : null,
    analytics: appData.user.analytics
  }),
  { getPerformanceRanksCompare }
)(BenchmarkTable)
