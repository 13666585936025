import React from 'react'
import ReactModal from 'react-modal'

export const userProfile = ({
  isModalOpen,
  closeModal,
  children,
  confirmationModal
}) => (
  <ReactModal
    isOpen={isModalOpen}
    contentLabel='onRequestClose Example'
    onRequestClose={closeModal}
    className={`modal ${confirmationModal ? 'confirmation-modal' : ''}`}
    overlayClassName='overlay'
  >
    {children}
  </ReactModal>
)

export default userProfile
