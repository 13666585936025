import './HeaderWithFilters.scss'

import React from 'react'

type Props = {
  title: string
  children?: React.ReactNode
}

export const HeaderWithFilters: React.FC<Props> = ({ title, children }) => {
  return (
    <div className='filters-heading-container'>
      <h1 className='filters-heading'>{title}</h1>
      <div className='filters-container'>
        <div className='select-filters-container'>{children}</div>
      </div>
    </div>
  )
}

export default HeaderWithFilters
