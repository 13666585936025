import './MassMarketContent.scss'

import React, { FC, ReactElement } from 'react'

import ChartContainer from '../../../../components/chartContainer/ChartContainer'
import WDonutChart from '../../../../components/charts/DonutChart/WDonutChart'
import { IDataPoint, IDisWithApp } from '../../../../interfaces/Product'

interface IProps {
  discoveries: Array<IDisWithApp>
}

const MassMarketContent: FC<IProps> = ({
  discoveries
}): ReactElement | null => {
  const setOfGroup = Array.from(
    new Set(discoveries.map(d => d.app.display_name))
  )

  return (
    <div className={'marketing-distributions'}>
      {setOfGroup.map((competitor: string) => {
        const thisCompetitorDiscoveries = discoveries.filter(
          d => d.app.display_name === competitor
        )

        //@ts-ignore
        const countTags = thisCompetitorDiscoveries.reduce(
          (acc, el) => acc + (el.cam.labels?.length || 0),
          0
        )
        if (countTags == 0) return null
        return (
          <div key={competitor} className={'competitor-card'}>
            <ChartContainer title={competitor} titleCentered={true}>
              <Card
                discoveries={thisCompetitorDiscoveries}
                competitorName={competitor}
              />
            </ChartContainer>
          </div>
        )
      })}
    </div>
  )
}

const Card: FC<ICardProps> = ({
  discoveries,
  competitorName
}): ReactElement | null => {
  const setOfUniqueLabels: Set<string> = new Set()
  discoveries.map((d: IDisWithApp) => {
    // @ts-ignore
    d.cam.labels.map((label: string) => setOfUniqueLabels.add(label))
  })
  const getOptions = (height: number, count: number) => {
    return {
      height: `${height}rem`,
      pie: {
        labels: {
          //@ts-ignore
          formatter: d => {
            if (count < 50) return d.value
            return d.value > 1 ? d.value : ''
          }
        }
      }
    }
  }

  const uniqueLabels: string[] = Array.from(setOfUniqueLabels)
  const getGroupedData = (discoveries: IDisWithApp[]): IDataPoint[] => {
    const groupedData: IDataPoint[] = []
    discoveries.forEach(dis => {
      // @ts-ignore
      dis.cam.labels
        .filter(label => label)
        .forEach(label => {
          const i = groupedData.findIndex(
            (d: IDataPoint) => d.group === label.toUpperCase()
          )
          if (i === -1) {
            groupedData.push({
              group: label.toUpperCase(),
              value: 1
            })
          } else {
            groupedData[i].value += 1
          }
        })
    })

    return groupedData.map(s => ({
      value: s.value,
      group: `${s.group.toUpperCase()} x ${s.value}`
    }))
  }
  const icon = {
    icon: discoveries[0].app.icon,
    text: competitorName
  }
  const groupedData = getGroupedData(discoveries)
  const tagsCount = groupedData.reduce(
    (acc: number, c: IDataPoint): number => acc + c.value,
    0
  )
  const height = 32 + Math.floor(uniqueLabels.length / 2) * 2.5
  return (
    <div className={'card-layout'}>
      <div className={'pie'} style={{ height: `${height + 3.5}rem` }}>
        <WDonutChart
          data={groupedData}
          options={getOptions(height, tagsCount)}
          image={icon}
        />
      </div>
      <div className={'marketing-discoveries'}>
        {' '}
        {uniqueLabels.length} Tags in {discoveries.length} Marketing Discoveries
      </div>
    </div>
  )
}
interface ICardProps {
  discoveries: Array<IDisWithApp>
  competitorName: string
}
export default MassMarketContent
