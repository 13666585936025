import './CompetitorsStatus.scss'

import React, { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import {
  App,
  IDisWithApp,
  Ipersona,
  Product
} from '../../../../interfaces/Product'

interface IProps {
  discoveries: Array<IDisWithApp>
  marketingApps: App[]
  personas: Ipersona[]
  products: Product[]
}
const MAX_NAME_LENGTH = 17

const CompetitorsStatus: FC<IProps> = ({
  discoveries,
  marketingApps,
  products
}): ReactElement => {
  const competitorsAppIDs = Array.from(new Set(discoveries.map(d => d.app.id)))
  const allProdApp: IProdAppMapping[] = []
  products.forEach(p => {
    p.apps.forEach(a => {
      allProdApp.push({
        product_name: p.name,
        app_id: a.id
      })
    })
  })
  let competitorProds: IProdAppMapping[] = []
  competitorsAppIDs.forEach((appID: number) => {
    competitorProds = competitorProds.concat(
      allProdApp.find(a => a.app_id === appID) || []
    )
  })
  const monitoredData = competitorProds.map(
    (prodApp: IProdAppMapping): ICompetitorCardProps => {
      const filteredApps = marketingApps.filter(
        (apps: App): boolean => apps.id === prodApp.app_id
      )

      return {
        name: prodApp.product_name,
        // @ts-ignore
        icon: filteredApps[0].icon,
        isMonitored: true,
        app_id: prodApp.app_id
      }
    }
  )
  return (
    <div className={'competitors-container'}>
      {monitoredData.map(
        (data: ICompetitorCardProps, i: number): ReactElement => {
          return <CompetitorCard {...data} key={i} />
        }
      )}
    </div>
  )
}

const CompetitorCard: FC<ICompetitorCardProps> = ({
  name,
  icon,
  isMonitored,
  app_id
}) => {
  return (
    <Link to={`/marketing?app=${app_id}&tab=1&&days_ago=1825`}>
      <div
        className={`competitor-card ${isMonitored ? '' : 'not-monitored'}`}
        data-testid='competitor-card'
      >
        <img className={'icon'} src={icon} alt={name} />
        <div className={`monitored-app ${isMonitored ? '' : 'not-monitored'}`}>
          <div className={'monitored'}>
            <div className={`monitored-dot ${isMonitored ? '' : 'red-dot'}`} />
            {isMonitored ? 'Monitored' : 'Not monitored'}
          </div>
          <div className={'title'}>
            {name.length < MAX_NAME_LENGTH
              ? name
              : name.slice(0, MAX_NAME_LENGTH - 3) + '...'}
          </div>
        </div>
      </div>
    </Link>
  )
}

interface ICompetitorCardProps {
  name: string
  icon: string
  isMonitored: boolean
  app_id: number
}

interface IProdAppMapping {
  product_name: string
  app_id: number
}

export default CompetitorsStatus
