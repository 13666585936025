import mixpanel from "mixpanel-browser";
import config from '../shared/config';
import { hotjar } from "react-hotjar";

class analyticsService {

  constructor() {
    this.username = '';
    this.email = '';
    this.user_type = 'Guest';
    this.first_name = '';
    this.last_name = '';
  }

  analytic = (text, args) => {
    const { _email,_first_name, _last_name, _username, _isAdmin, _role, _department, _user_type } = this;
    if (config.mixpanelKey && window.location.host.includes('app.watchful.ai')) {
      mixpanel.identify(_email);
      const person =  {
        cognito: _username,
        name: _first_name,
        last_name: _last_name,
        email: _email,
        admin: _isAdmin,
        role: _role,
        department: _department,
        user_type: _user_type,
      };

      mixpanel.people.set(
        person
      );
      if (!args)
        mixpanel.track(text);
      else
        mixpanel.track(text, args);
      if (hotjar.initialized()) {
        hotjar.identify(_email, person);
        hotjar.event(_email, text);
      }
    } else {
      console.log(`dev-analytics ${text} - data: ${JSON.stringify(args)}`)
    }
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new analyticsService();
    }
    return this.instance;
  }

  set username(username) {
    this._username = username;
  }

  set email(email) {
    this._email = email;
  }

  set role(role) {
    this._role = role;
  }

  set isAdmin(isAdmin) {
    this._isAdmin = isAdmin;
  }

  set department(department) {
    this._department = department;
  }

  set user_type(type) {
    this._user_type = type;
  }

  set first_name(name) {
    this._first_name = name;
  }

  set last_name(name) {
    this._last_name = name;
  }
}

export default analyticsService
