import React from 'react'
import { Button } from 'antd'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import Recaptcha from 'react-recaptcha'

const APIBasePath = 'https://api.watchful.ai'
const sitekey = '6Lf1Y58UAAAAAAuNFgwvolfPDD6ixUELTYlTyhgj'

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Missing'),
  jobTitle: Yup.string().required('Missing'),
  email: Yup.string().required('Missing'),
  companyName: Yup.string().required('Missing'),
  companySize: Yup.string().required('Missing')
})

const dictionary = {
  name: 'Name',
  jobTitle: 'Job title',
  email: 'email',
  companyName: 'Company name',
  companySize: 'Company size'
}

class ContactForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isConfirmationModal: false
    }
    this.onConfirmationModal = this.onConfirmationModal.bind(this)
  }

  componentDidMount () {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }

  onConfirmationModal () {
    this.props.openModal()
    setTimeout(() => {
      const { isConModalOpen } = this.props
      if (isConModalOpen) this.props.closeModal()
    }, 5000)
  }

  render () {
    const { closeModal } = this.props
    return (
      <>
        {/* <FormHeader closeModal={closeModal}></FormHeader> */}
        <div className='form'>
          <p className='form__title'>Ask for an account</p>
          <Formik
            initialValues={{
              name: '',
              jobTitle: '',
              email: '',
              companyName: '',
              companySize: '1-9 (Micro)',
              recaptcha: ''
            }}
            onSubmit={({
              name,
              jobTitle,
              email,
              companyName,
              companySize,
              recaptcha
            }) => {
              if (recaptcha) {
                const recaptcha_token = recaptcha
                const form_values = {
                  name,
                  jobTitle,
                  email,
                  companyName,
                  companySize
                }

                const formattedValuesObj = {}
                Object.entries(form_values).forEach(([original_key, value]) => {
                  formattedValuesObj[dictionary[original_key]] = value
                })

                let data = { form_values: formattedValuesObj, recaptcha_token }

                ;(async () => {
                  const rawRsponse = await fetch(
                    `${APIBasePath}/landing-page/new-lead`,
                    {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(data)
                    }
                  )
                  // const responseContent = await rawRsponse.json();
                  if (rawRsponse.status === 200) {
                    this.onConfirmationModal()
                  }
                })()
              }
            }}
            validationSchema={SignupSchema}
            render={({
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              values
            }) => (
              <Form>
                {Object.keys(values)
                  .slice(0, -3)
                  .map(value => (
                    <div className='form__group' key={value}>
                      <div className='form__group form__text-group'>
                        <label htmlFor={value} className='form__label'>
                          {dictionary[value]}
                        </label>
                        {errors[value] && touched[value] ? (
                          <div className='form__error'>{errors[value]}</div>
                        ) : null}
                      </div>
                      <Field
                        type='text'
                        name={value}
                        className={`form__input ${
                          errors[value] && touched[value]
                            ? 'form__input--danger'
                            : ''
                        }`}
                      />
                    </div>
                  ))}
                <div className='form__bottom-group'>
                  <div className='form__group margin-right-sm'>
                    <div className='form__group form__text-group'>
                      <label htmlFor='companyName' className='form__label'>
                        Company Name
                      </label>
                      {errors.companyName && touched.companyName ? (
                        <div className='form__error'>{errors.companyName}</div>
                      ) : null}
                    </div>
                    <Field
                      type='text'
                      name='companyName'
                      className={`form__input ${
                        errors.companyName && touched.companyName
                          ? 'form__input--danger'
                          : ''
                      }`}
                    />
                  </div>
                  <div className='form__group margin-left-sm'>
                    <div className='form__group form__text-group'>
                      <label htmlFor='companySize' className='form__label'>
                        Company Size
                      </label>
                      {errors.companySize && touched.companySize ? (
                        <div className='form__error'>{errors.companySize}</div>
                      ) : null}
                    </div>
                    <Field
                      component='select'
                      name='companySize'
                      className={`form__input select ${
                        errors.companySize && errors.companySize
                          ? 'form__input--danger'
                          : ''
                      }`}
                    >
                      <option value='1-9 (Micro)' defaultValue>
                        1-9 (Micro)
                      </option>
                      <option value='10-49 (Small)'>10-49 (Small)</option>
                      <option value='50-249 (Medium)'>50-249 (Medium)</option>
                      <option value='250+ (Large)'>250+ (Large)</option>
                    </Field>
                  </div>
                </div>
                <Recaptcha
                  sitekey={sitekey}
                  render='explicit'
                  theme='light'
                  verifyCallback={response => {
                    setFieldValue('recaptcha', response)
                  }}
                  onloadCallback={() => {
                    console.log('done loading!')
                  }}
                />
                {errors.recaptcha && touched.recaptcha && (
                  <p>{errors.recaptcha}</p>
                )}

                <div className='form__buttons-section'>
                  <Button
                    className='btn btn-cancel btn--weak btn--small'
                    htmlType='button'
                    type='deafult'
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType='submit'
                    type='primary'
                    className='btn btn-submit btn--strong btn--small margin-left-sm'
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          />
        </div>
      </>
    )
  }
}

export default ContactForm
