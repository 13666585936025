import React, { createElement } from 'react'
import { Navigate, Route } from 'react-router'
import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

import ChangePageMonitor from '../ChangePageMonitor'
import Login from '../containers/login/Login'
import GuestShare from '../containers/GuestShare/GuestShare'
import ChangePWDOnFirstLogin from '../containers/login/ChangePWDOnFirstLogin/ChangePWDOnFirstLogin'
import Dashboard from '../containers/dashboard/Dashboard'
import NotFound from '../common/notFound/NotFound'
import AcceptingInvite from '../containers/AcceptingInvite'
import GuestEmailValidation from '../containers/GuestEmailValidation'

import routesData from '../shared/routesData'
import Discovery from '../containers/discovery/Discovery'

import ProductsPage from '../containers/products/ProductsPage'
import BiWeekly from '../containers/BiWeeklyGallery/BiWeekly'
import MarketingInsightView from '../containers/intelligence/Marketing/MarketingInsightView'
import Account from '../containers/account/Account'
import GraphPage from '../containers/GraphPage/GraphPage'

import { pagesTypes } from '../shared/consts'
import { getLandPageForUser } from '../helpers/usersHelper'

import { siteVersions } from '../shared/consts'
import IntelligenceProductPage from '../containers/products-discoveries/IntelligenceProductPage'
import ABTestsPage from '../containers/ABTests/ABTestsPage'
import TopicsPage from '../containers/topics/TopicsPage'
import PerformanceCompare from '../containers/benchmark/BenchmarkCompare/BenchmarkComparePage'
import BenchmarkMetricsPage from '../containers/benchmark/BenchmarkMetrics/BenchmarkMetricsPage'

import FallbackOldURLS from '../containers/versions/FallbackOldURLS'
import { UserJourneysPage } from '../containers/UserJourneys/UserJourneysPage'
import { MarketingCampaignsPage } from '../containers/MarketingCampaings'
import { MarketingOverviewPage } from '../containers/MarketingOverview'
import { IntelPage } from 'pages/Intel'
import { ContextTimelinePage } from 'pages/ContextTimeline'
import analyticsService from 'services/analyticsService'
import DeliveryOrdersOverviewPage from "../containers/DeliveryOrders/DeliveryOrdersOverviewPage";
import DeliveryOrdersTablePage from "../containers/DeliveryOrders/DeliveryOrdersTablePage";

const { routesType } = routesData

export function getDashboardRoutes (user, userData, siteVersion) {
  const routes = []
  const firstPage = getLandPageForUser(user)
  const appAnalytics = analyticsService.getInstance()
  const analytics = user.analytics

  const availItems = userData.pages
  if (availItems.includes(pagesTypes.DASH_OVERVIEW)) {
    routes.push(
      <Route
        key={pagesTypes.DASH_OVERVIEW}
        exact
        path='/overview'
        element={createElement(routesType[pagesTypes.DASH_OVERVIEW].component, {
          analytics: analytics
        })}
      />
    )

    routes.push(
      <Route
        key={pagesTypes.ALL_DISCOVERIES}
        exact
        path='/all-discoveries'
        element={createElement(
          routesType[pagesTypes.ALL_DISCOVERIES].component,
          { analytics: analytics }
        )}
      />
    )

    routes.push(
      <Route
        key='product-page'
        exact
        path='/products/:productID'
        element={<ProductsPage analytics={analytics} />}
      />
    )
  }

  if (
    availItems.includes(pagesTypes.VERSIONS) ||
    siteVersion === siteVersions.DEMO
  ) {
    routes.push(
      <Route
        key={`${pagesTypes.VERSIONS}`}
        exact
        path='/intelligence/'
        element={<FallbackOldURLS />}
      />,
      <Route
        key={`${pagesTypes.VERSIONS}`}
        exact
        path='/intelligence/:type'
        element={<FallbackOldURLS />}
      />,
      <Route
        key={`${pagesTypes.VERSIONS}-app`}
        exact
        path='/intelligence/:type/:app_id'
        element={<FallbackOldURLS />}
      />,
      <Route
        key={`${pagesTypes.VERSIONS}-vers`}
        exact
        path={`/intelligence/:type/:app_id/:release_id`}
        element={<FallbackOldURLS />}
      />,
      <Route
        key={`${pagesTypes.VERSIONS}-ins`}
        exact
        path={`/intelligence/versions/:app_id/:release_id/:id`}
        element={<Discovery />}
      />
    )
  }

  if (
    availItems.includes(pagesTypes.VERSIONS) ||
    siteVersion === siteVersions.DEMO
  ) {
    routes.push(
      <Route
        key={`${pagesTypes.VERSIONS}`}
        exact
        path='/product_intelligence/'
        element={<IntelPage />}
      />,
      <Route
        key={`${pagesTypes.TOPICS}`}
        exact
        path='/product_intelligence/topics/'
        element={<TopicsPage />}
      />,
      <Route
        key={`${pagesTypes.USER_JOURNEYS}`}
        exact
        path='/product_intelligence/user_journeys/:app_id?/'
        element={<UserJourneysPage />}
      />,
      <Route
        key={`${pagesTypes.AB_TESTS}`}
        exact
        path='/product_intelligence/ab-tests/'
        element={<ABTestsPage />}
      />,
      <Route
        key={`${pagesTypes.VERSIONS}`}
        exact
        path='/product_intelligence/:product_id/'
        element={<IntelligenceProductPage />}
      />
    )
  }

  if (
    availItems.includes(pagesTypes.MARKETING) ||
    siteVersion == siteVersions.DEMO
  ) {
    routes.push(
      <Route
        key={`${pagesTypes.MARKETING_CAMPAIGNS}`}
        exact
        path='/marketing'
        element={createElement(
          routesType[pagesTypes.MARKETING_CAMPAIGNS].component
        )}
      />
    )
    routes.push(
      <Route
        key={`${pagesTypes.PROMOTIONS_TABLE}`}
        exact
        path='/marketing/promotions/table'
        element={<MarketingCampaignsPage />}
      />
    )
    routes.push(
      <Route
        key={`${pagesTypes.PROMOTIONS_CHARTS}`}
        exact
        path='/marketing/promotions/charts'
        element={<MarketingOverviewPage />}
      />
    )
    routes.push(
      <Route
        key={`${pagesTypes.MARKETING_MASS_MARKETING}`}
        exact
        path='/marketing/mass_market'
        element={<MarketingOverviewPage />}
      />
    )
    routes.push(
      <Route
        key={`${pagesTypes.CONTEXT_TIMELINE}`}
        exact
        path='/marketing/context_timeline'
        element={<ContextTimelinePage />}
      />
    )
    routes.push(
      <Route
        key={`${pagesTypes.MARKETING}-view`}
        exact
        path='/marketing/:insightID'
        element={<MarketingInsightView />}
      />
    )
  }

  if (availItems.includes(pagesTypes.DELIVERY_ORDERS)) {
    routes.push(
      <Route
        key={`${pagesTypes.DELIVERY_ORDERS}-view`}
        exact
        path='/delivery/orders/table'
        element={<DeliveryOrdersTablePage />}
      />
    )
    routes.push(
      <Route
        key={`${pagesTypes.DELIVERY_ORDERS_OVERVIEW}-view`}
        exact
        path='/delivery/orders'
        element={<DeliveryOrdersOverviewPage />}
      />
    )
  }

  if (availItems.includes(pagesTypes.BENCHMARK_COMPARE)) {
    routes.push(
      <Route
        key={`${pagesTypes.BENCHMARK_COMPARE}`}
        exact
        path='/benchmark/compare'
        element={<PerformanceCompare analytics={analytics} />}
      />,
      <Route
        key={`${pagesTypes.BENCHMARK_COMPARE}-ids`}
        exact
        path='/benchmark/compare/:eventIDs'
        element={<PerformanceCompare analytics={analytics} />}
      />,
      <Route
        key={`bench-metrics-ids`}
        exact
        path='/benchmark/compare/metrics/:eventID'
        element={<BenchmarkMetricsPage analytics={analytics} />}
      />
    )
  }

  if (availItems.includes(pagesTypes.BENCHMARK_RANKS)) {
    routes.push(
      <Route
        key={pagesTypes.BENCHMARK_RANKS}
        exact
        path='/benchmark/ranks'
        element={createElement(
          routesType[pagesTypes.BENCHMARK_RANKS].component,
          { analytics: analytics }
        )}
      />
    )
  }

  routes.push(
    <Route
      key={'account'}
      exact
      path='/account'
      element={<Account analytics={appAnalytics} />}
    />
  )

  routes.push(
    <Route
      key={'report-view'}
      exact
      path='/reports/:id'
      element={createElement(routesType[pagesTypes.REPORTSVIEW].component)}
    />
  )

  routes.push(
    <Route
      key={'reports-list'}
      exact
      path='/reports'
      element={createElement(routesType[pagesTypes.REPORTSLIST].component)}
    />
  )

  routes.push(
    <Route
      key={'subscriptions-list'}
      exact
      path='/subscriptions'
      element={createElement(routesType[pagesTypes.SUBSCRIPTIONS].component)}
    />
  )
  routes.push(
    <Route
      key={'eula'}
      exact
      path='/eula'
      element={createElement(routesType[pagesTypes.EULA].component)}
    />
  )

  routes.push(
    <Route
      key={'bi_weekly'}
      exact
      path='/bi_weekly/:highlight_id'
      element={<BiWeekly />}
    />
  )

  routes.push(
    <Route
      key={'privacy'}
      exact
      path='/privacy'
      element={createElement(routesType[pagesTypes.PRIVACY].component)}
    />
  )

  routes.push(
    <Route
      key={'graph_view'}
      exact
      path='/app_graph/:graph_id'
      element={<GraphPage />}
    />
  )

  routes.push(
    <Route
      key='red-1'
      path='/versions/:package_name/:release_id/:id'
      element={
        <Navigate to='/intelligence/versions/:app_id/:release_id/:id' replace />
      }
    />
  )

  routes.push(
    <Route
      key='red-2'
      path='/versions/:package_name/:release_id'
      element={
        <Navigate to='/intelligence/versions/:app_id/:release_id' replace />
      }
    />
  )

  routes.push(
    <Route
      key='red-3'
      path='/versions/:package_name'
      element={<Navigate to='/intelligence/versions/:app_id' replace />}
    />
  )

  routes.push(
    <Route
      key='red-4'
      path='/versions'
      element={<Navigate to='/intelligence/versions' replace />}
    />
  )

  routes.push(
    <Route
      key='old-redir'
      exact
      path={`/:type/:package_name/:id`}
      element={<Discovery />}
    />
  )

  routes.push(
    <Route
      key='def-redir'
      index
      element={<Navigate to={`/${firstPage}`} replace />}
    />
  )

  routes.push(
    <Route exact path='*' element={<Navigate to={`/404`} replace />} />
  )

  return routes
}

export function getRouter (user, userData, siteVersion) {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<ChangePageMonitor />}>
        <Route exact path='/link/:id' element={<GuestShare />} />
        <Route path='/link-bi-weekly/:highlight_id/' element={<GuestShare />} />

        <Route
          exact
          path='/changePWD/:email/:nonce'
          element={<ChangePWDOnFirstLogin />}
        />
        <Route exact path='/login' element={<Login />} />

        <Route
          exact
          path='/invitation/:invitation_id'
          element={<AcceptingInvite />}
        />
        <Route
          exact
          path='/guest-email-validation/:guest_email_valid_token'
          element={<GuestEmailValidation />}
        />

        <Route path='/' element={<Dashboard />}>
          {getDashboardRoutes(user, userData, siteVersion)}
        </Route>

        <Route exact path='/404' element={<NotFound />} />
      </Route>
    )
  )

  return router
}
