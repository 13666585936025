import './Footer.scss'

import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import gray_watchful_logo from 'assets/images/gray_watchful_logo.svg'

const Footer = () => {
  return (
    <div className={'footer-wrapper'}>
      <div className={'rectangle1'} />
      <div className={'footer'}>
        <img
          alt='watchful gray icon'
          className={'watchful-img'}
          src={gray_watchful_logo}
        />
        <div className={'watchfulAi2021The360CompetitiveM'}>
          <strong className={'watchfulAi2021The360CompetitiveMEmphasis0'}>
            Watchful.ai © {moment().year()}
          </strong>
          <div className={'blended'}>
            The 360 Competitive Monitoring Platform
          </div>
        </div>
        <p className={'privacyEndUserLicenseAgreement'}>
          <Link to={'/privacy'}>Privacy</Link> |{' '}
          <Link to={'/eula'}>End User License Agreement</Link>
        </p>
      </div>
    </div>
  )
}
export default Footer
