import React, { useEffect, useState } from 'react'
import awsService from '../../services/awsService'
import config from '../../shared/config'
import storageService from '../../services/storageService'
import { connect } from 'react-redux'
import { jwtDecode } from 'jwt-decode'

import {
  setUser,
  setSiteVersion,
  setAppsData,
  getAppsMetadata,
  createNewUser
} from '../../redux/actions/dashboardActions'
import { getUserName } from '../../redux/actions/userActions'
import { setCampaignDemoMode } from '../../redux/actions/overviewActions'

import { updateAxiosToken } from '../../App'

import { getLinkData } from '../../redux/actions/directLinkActions'
import Loader from '../../common/NewLoader/NewLoader'
import { pagesTypes, PL_MARKETING, siteVersions } from '../../shared/consts'
import analyticsService from '../../services/analyticsService'
import { getProducts } from '../../redux/api/productApi'
import { useNavigate, useLocation, useParams } from 'react-router'

const GuestShare = props => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const aws = new awsService()
  const analytics = analyticsService.getInstance()

  const [biWeekly, biWeeklyChange] = useState(null)
  const [fromLink, fromLinkChange] = useState(false)
  const [sharingInsightLinkIsBroken, sharingInsightLinkIsBrokenChange] =
    useState(false)
  const [userStatus, userStatusChange] = useState(null)
  const source =
    location.search === ''
      ? ''
      : new URLSearchParams(location.search).get('source')

  //EFFECTS
  useEffect(() => {
    const shareInsightID = checkDemoVersion()
    const highlight_id = params.highlight_id
    console.log('SHARING DATA')
    console.log('shareInsightID', shareInsightID)
    console.log('highlight_id', highlight_id)

    const storage = new storageService()

    if (shareInsightID) {
      fromLinkChange(true)
      let url = new URLSearchParams(window.location.search)
      let guest_token = url.get('guest_token')
      if (guest_token) {
        storage.setItem('guest_token', guest_token)
      } else {
        guest_token = storage.getItem('guest_token') || null
      }
      props.getLinkData(shareInsightID, guest_token)
    }
    if (highlight_id) {
      biWeeklyChange(highlight_id)
    }

    const currentUser = aws.getCurrentUser()

    let token = ''

    if (!token) {
      token = storage.getItem('usertoken')
    }
    if (token) {
      let cognitoUser = jwtDecode(token)
      analytics.email = cognitoUser.email
      analytics.username = cognitoUser.sub
      aws.setCredentials(token)
      storage.setItem('usertoken', token)
      updateAxiosToken(token)
      props.createNewUser()
      userStatusChange('logged')
      return
    }

    // check if user are already login(cognito)
    if (!currentUser) {
      userStatusChange('not_logged')
      analytics.email = config.demoEmail
      props.setUser({ analytics })
      return
    }

    currentUser.getSession((err, session) => {
      if (err || !session || !session.idToken) {
        userStatusChange('not_logged')
        analytics.email = config.demoEmail
        props.setUser({ analytics })
        return
      }
      const cognitoUser = session.idToken.payload
      analytics.email = cognitoUser.email
      analytics.username = cognitoUser.sub
      aws.setCredentials(session.idToken.jwtToken)
      storage.setItem('usertoken', session.idToken.jwtToken)
      updateAxiosToken(session.idToken.jwtToken)
      props.createNewUser()
      props.setUser({ ...cognitoUser, analytics })
      props.getAppsMetadata(cognitoUser)
      props.getUserName(cognitoUser.email)
      userStatusChange('logged')
    })
  }, [])

  useEffect(() => {
    let { linkData } = props

    if (userStatus === 'not_logged') {
      props.setSiteVersion(siteVersions.DEMO)
    }
    if (userStatus && biWeekly) {
      navigate(`/bi_weekly/${biWeekly}${location.search}`)
    }
    if (userStatus === 'logged') {
      if (linkData.status === 'success') {
        setTimeout(async () => {
          const url = await getDemoVersionUrl(linkData)
          navigate(url)
        }, 0)
      } else if (linkData.status === 'failed') {
        navigate('/')
      }
    }
    if (
      userStatus === 'not_logged' &&
      fromLink &&
      ['success', 'failed'].includes(linkData.status)
    ) {
      if (linkData.status === 'failed') {
        sharingInsightLinkIsBrokenChange(true)
      }
      if (linkData.status === 'success') {
        const {
          data,
          data: { metadata }
        } = linkData
        let app = { metadata, loaded: true }
        app = {
          ...app,
          ab_tests: [
            {
              ...data,
              fullLoaded: true
            }
          ]
        }
        if (linkData.data.platform !== PL_MARKETING) {
          props.setAppsData([app], { demo: true })
        } else {
          props.setCampaignDemoMode(linkData.data)
        }
        setTimeout(async () => {
          const url = await getDemoVersionUrl(linkData)
          navigate(url)
        }, 0)
      }
    }
  }, [userStatus, props.linkData])

  //METHODS
  const checkDemoVersion = () => {
    return params.id || false
  }

  const getDemoVersionUrl = async linkData => {
    const {
      data: { id, current_release_id }
    } = linkData
    const app_id = linkData.data.metadata.id

    // FIXME: we need this fix as new pages(intel, marketing) don't work for unauthorized users
    if (userStatus === 'not_logged') {
      if (linkData.data.platform === PL_MARKETING) {
        return `/marketing/${id}?source=${source}`
      } else {
        return `/intelligence/${pagesTypes.VERSIONS}/${app_id}/${current_release_id}/${id}?source=${source}`
      }
    }

    if (linkData.data.platform === PL_MARKETING) {
      return `/marketing/campaigns?app_id=${app_id}&insight_id=${id}&source=${source}`
    } else {
      const { data: products } = await getProducts()
      const product = products.data.find(product =>
        product.apps.find(a => a.id === app_id)
      )
      const platform = linkData.data.platform

      const params = new URLSearchParams()

      params.append('source', source)
      params.append('insight_id', id)
      params.append('product_id', product.id)
      params.append('platform', platform.toLowerCase())

      return `/product_intelligence?${params.toString()}`
    }
  }

  if (sharingInsightLinkIsBroken) {
    return <div>Link is broken</div>
  }

  return <Loader />
}

const mapStateToProps = ({ appData, linkData, userData }) => {
  return {
    linkData,
    user: appData.user,
    analytics: appData.user && appData.user.analytics,
    userData
  }
}

export default connect(mapStateToProps, {
  setUser,
  getLinkData,
  setSiteVersion,
  setCampaignDemoMode,
  setAppsData,
  createNewUser,
  getAppsMetadata,
  getUserName
})(GuestShare)
