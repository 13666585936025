import axios from "axios";
import config from "../../shared/config";

export const getNotifications = () => {
  const { backendUrl } = config.dataApi;
  const url = `${backendUrl}/notifications`;
  return axios.get(url);
};

export const setNotificationsRead = (ids: string) => {
  const { backendUrl } = config.dataApi;
  const url = `${backendUrl}/notifications/read`;
  return axios.put(url, { "notification_ids": ids });
}

export const setNotificationsSeen = (ids: string) => {
  const { backendUrl } = config.dataApi;
  const url = `${backendUrl}/notifications/seen`;
  return axios.put(url, { "notification_ids": ids });
}


export const setNotificationsForInsightRead = (insight_id: number) => {
  const { backendUrl } = config.dataApi;
  const url = `${backendUrl}/insight/${insight_id}/notifications/all/read`;
  return axios.put(url);
}