import './CampaignPerUserType.scss'

import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartLegend from 'components/charts/ChartLegend'
import useGoToPromotionsTable from 'containers/MarketingOverview/hooks/useGoToPromotionsTable'
import {
  calculateExactPercentages,
  isMobileDevice
} from 'containers/MarketingOverview/utils/helpers'
import {
  App,
  ChartValuesToggle
} from 'containers/MarketingOverview/utils/types'
import { onHoverSetCursor, totalBarText } from 'helpers/chartHelpers'
import { extractAlpha, luminanceFromHex } from 'helpers/colorHelpers'
import React, { useEffect, useRef, useState } from 'react'
import { HorizontalBar } from 'react-chartjs-2'

type ByPersona = {
  id: number
  name: string
  count: number
  color: string
  textColor: string

  ids: number[]
}

type AppsByPersonas = {
  app: App
  byPersona: ByPersona[]
}

type Persona = {
  id: number
  name: string
  color: string
}

type Props = {
  userTypes: Persona[]
  appsByPersonas: AppsByPersonas[]
  chartValues: ChartValuesToggle
}

export const CampaignPerUserType = ({
  userTypes,
  appsByPersonas,
  chartValues
}: Props) => {
  const goToTable = useGoToPromotionsTable()

  const chartRef = useRef<HorizontalBar>(null)

  const onClick = (event: any) => {
    const chart = chartRef.current?.chartInstance

    if (!chart) {
      return
    }

    const element = chart.getElementAtEvent(event)[0] as any
    const userType = userTypes[element._datasetIndex]
    const appsByPersona = appsByPersonas[element._index]

    goToTable([appsByPersona.app.id], undefined, undefined, [userType.id])
  }
  const selectedPersonaLabels = ((
      chartRef.current?.chartInstance && chartRef.current?.chartInstance.options.legend?.labels?.generateLabels
          ?.(chartRef.current?.chartInstance)) || []).filter((x: any) => !x.hidden).map((x: any) => x.text)

  const getData = () => ({
    labels: appsByPersonas.map(appByPersona => appByPersona.app?.displayName),
    datasets: userTypes.sort((ut1, ut2) =>
      (ut1?.name ?? '') < (ut2?.name ?? '') ? -1 : 1
    ).map((user, i) => {
      // Used for color generation, add 2 so it doesn't get too light
      const normalizedIndex = i / (userTypes.length + 2)

      return {
        label: user.name,
        backgroundColor: `rgba(80, 97, 153, ${1 - normalizedIndex})`,
        data: appsByPersonas.map(appByPersona => {
          const foundUser = appByPersona.byPersona.find(
            persona => persona.id === user.id
          )

          if (!foundUser) {
            return 0
          }

          if (chartValues === 'numbers') {
            return foundUser.count
          }
          const totalCount = appByPersona.byPersona.filter(
              (x: any) => selectedPersonaLabels.includes(x?.name)
          ).reduce((sum, next) => {
            return sum + next.count
          }, 0)

          // Convert to percent and round two decimal places
          return Math.round((foundUser.count / totalCount) * 10000) / 100
        })
      }
    })
  })

  const [data, setData] = useState(getData())
  useEffect(() => setData(getData()), [selectedPersonaLabels.length, chartValues])

  const imagesY = {
    afterDraw: (chart: any) => {
      const ctx = chart.ctx
      const xAxis = chart.scales['x-axis-0']
      const yAxis = chart.scales['y-axis-0']

      yAxis.ticks.forEach((_: any, index: number) => {
        const y = yAxis.getPixelForTick(index)
        const image = new Image()
        image.src = appsByPersonas[index].app?.icon

        ctx.drawImage(image, xAxis.left - 56, y - 20, 40, 40)
      })
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 60,
        right: 10
      }
    },
    plugins: {
      totalBarText: chartValues === 'numbers',
      datalabels: {
        color: (context: any) => {
          const color = context.dataset?.backgroundColor

          const luminance = extractAlpha(color)

          return luminance < 0.5 ? '#212121' : '#ffffff'
        },
        formatter: (value: number) => {
          if (value) {
            return (
              Math.round(value) + (chartValues === 'percentages' ? '%' : '')
            )
          }

          return ''
        },
        font: {
          weight: 'bold',
          size: 12
        }
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawBorder: false
          },
          stacked: true,
          ticks: {
            display: false,
            beginAtZero: true,
            fontColor: '#9AA1A9',
            fontSize: 14,
            padding: 14,
            stepSize: 10,
            max: chartValues === 'percentages' ? 100 : undefined
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false
          },
          ticks: {
            display: false
          }
        }
      ]
    },
    onHover: onHoverSetCursor,
    onClick
  }

  const plugins = [ChartDataLabels, imagesY, totalBarText]

  return (
    <div className='cput-contianer'>
      <div className='cput-legend'>
        <ChartLegend
          vertical={true}
          chart={chartRef.current?.chartInstance}
          onLabelsChange={() => setData(getData())}
        />
      </div>
      <div className='cput-chart'>
        <HorizontalBar
          ref={chartRef}
          plugins={plugins}
          data={data}
          options={options as any}
        />
      </div>
    </div>
  )
}
