import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/icons/watchful_white_gray_background.png'
import Icon from '../../common/icon/Icon'
import { PL_MARKETING } from '../../shared/consts'
import chevron_blue from '../../assets/images/icons/chevron_blue.svg'
const NUM_OF_ROWS_TO_SHOW = 3

export const ProductsSmallList = ({
  products,
  unSeenCriticalInsights,
  scrollToTop
}) => {
  const [showAll, showAllChange] = useState(false)
  const [numOfColumns, numOfColumnsChange] = useState(0)
  const calcUnseenCritical = product =>
    product.apps &&
    product.apps.reduce(
      (acc, app) =>
        acc +
        (unSeenCriticalInsights &&
        app &&
        unSeenCriticalInsights[app.id] &&
        app.platform !== PL_MARKETING
          ? unSeenCriticalInsights[app.id]
            ? unSeenCriticalInsights[app.id].length
            : 0
          : 0),
      0
    )

  const resize = () => {
    const innerWidth = window.innerWidth
    numOfColumnsChange(
      innerWidth > 2085
        ? 8
        : innerWidth > 1849
        ? 7
        : innerWidth > 1680
        ? 6
        : innerWidth > 1280
        ? 5
        : innerWidth > 844
        ? 6
        : 5
    )
  }

  const scrollTopShowingHandle = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    resize()
  }, [showAll])

  useEffect(() => {
    window.addEventListener('resize', resize)
    resize()
    return () => window.removeEventListener('resize', resize)
  }, [])

  const iconWithLink = product =>
    product.enabled ? (
      <Link to={product.link}>
        <Icon
          src={product.thumbnail || logo}
          title={product.name}
          badge={true}
          countBadge={calcUnseenCritical(product)}
        />
      </Link>
    ) : (
      <Icon
        src={product.thumbnail || logo}
        title={product.name}
        badge={true}
        countBadge={calcUnseenCritical(product)}
      />
    )

  return (
    <div className='products-list-wrapper'>
      <div
        className={`products-list ${showAll ? '' : 'partial-products-list'}`}
        style={
          products.length < numOfColumns
            ? { gridTemplateColumns: `repeat(${numOfColumns}, 17rem)` }
            : {}
        }
      >
        {products &&
          products
            .sort((p1, p2) =>
              calcUnseenCritical(p1) > calcUnseenCritical(p2) ? -1 : 1
            )
            .map(product => (
              <div
                key={product.id}
                className={`image-item ${
                  calcUnseenCritical(product) > 0 ? 'with-dis' : ''
                } ${product.enabled ? '' : 'inactive'}`}
              >
                {iconWithLink(product)}
              </div>
            ))}
      </div>
      {products.length > NUM_OF_ROWS_TO_SHOW * numOfColumns && (
        <div
          className='show-more'
          onClick={() => {
            showAllChange(!showAll)
            if (showAll && scrollToTop) scrollTopShowingHandle()
          }}
        >
          <div className={`chevron ${showAll ? 'rotate' : ''}`}>
            <Icon src={chevron_blue} />{' '}
          </div>
          {showAll ? 'View less' : 'Show all'}
        </div>
      )}
    </div>
  )
}

export default ProductsSmallList
