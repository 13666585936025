import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { getGraphByID } from '../../redux/acts/graphs'

import { useIsFeatureByUserID } from '../../helpers/features'
import GraphView from './GraphView'
import { getAppData } from '../../redux/actions/dashboardActions'
import { useNavigate, useParams } from 'react-router'

export const GraphPage = ({
  location,
  apps,
  getGraphByID,
  graphs,
  user,
  allInsights,
  getAppData
}) => {
  const navigate = useNavigate()
  const params = useParams()
  const appGraphAccess = useIsFeatureByUserID('app_graph', user.email)
  const graph = graphs[params.graph_id]

  const graphNetwork = useRef(null)
  window.__graphNetwork = graphNetwork

  useEffect(() => {
    getGraphByID(params.graph_id)
  }, [])

  useEffect(() => {
    if (graph && graph.app_id && allInsights.length === 0) {
      getAppData({ app_id: graph.app_id, load_only: true })
    }
  }, [graph])

  if (!graphs[params.graph_id] || !appGraphAccess) {
    return null
  }

  const app = apps.find(a => a.metadata.id === graph.app_id)

  return (
    <GraphView
      graph={graph}
      app={app}
      user={user}
      graphID={graph.nodes.find(n => n.labels[0] === 'root_state').id}
      allInsights={allInsights}
      goBackHandle={() => {
        navigate(
          location.pathname.substr(0, location.pathname.lastIndexOf('/'))
        )
      }}
    />
  )
}

const mapStateToProps = ({ graphs, appData }) => ({
  graphs: graphs.graphs,
  apps: appData.apps,
  user: appData.user,
  allInsights: appData.allInsights
})

const mapDispatchToProps = { getGraphByID, getAppData }

export default connect(mapStateToProps, mapDispatchToProps)(GraphPage)
