import { AnyAction } from 'redux';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  GET_REPORTS, getReportsSuccess, SEND_REPORT_SHARE, sendReportShareFailed, sendReportShareSuccess,
  SET_REPORTS_NOTIFICATIONS_READ, SET_REPORTS_NOTIFICATIONS_SEEN,
  setReportsNotificationsReadSuccess, setReportsNotificationsSeenSuccess
} from '../acts/reports';
import * as reportsApi from '../api/reportsApi';

//handleres
function* getReportsHandler() {
  // @ts-ignore
  const result = yield call(reportsApi.getReportsAPI)
  yield put(getReportsSuccess(result.data))
}

function* setReportsNotificationsReadHandler(action: AnyAction) {
  // @ts-ignore
  const result = yield call(reportsApi.setReportsNotificationsRead, action.payload)
  yield put(setReportsNotificationsReadSuccess(result.data))
}

function* setReportsNotificationsSeenHandler(action: AnyAction) {

  // @ts-ignore
  const result = yield call(reportsApi.setReportsNotificationsSeen, action.payload)
  yield put(setReportsNotificationsSeenSuccess(result.data))
}

function* sendReportShareHandler(action: AnyAction) {
  try {
    yield call(reportsApi.sendReportsShare, action.payload)
    yield put(sendReportShareSuccess())
  } catch (err) {
    yield put(sendReportShareFailed())
  }

}
//watchers
function* watchReports() {
  yield takeEvery(GET_REPORTS, getReportsHandler);
  yield takeEvery(SET_REPORTS_NOTIFICATIONS_READ, setReportsNotificationsReadHandler)
  yield takeEvery(SET_REPORTS_NOTIFICATIONS_SEEN, setReportsNotificationsSeenHandler)
  yield takeEvery(SEND_REPORT_SHARE, sendReportShareHandler)
}


const reportsSagas = [
  fork(watchReports)
];

export default reportsSagas;