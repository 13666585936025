// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mvp-container {
  height: 376px;
  overflow-y: auto;
}
.mvp-container::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
}
.mvp-container::-webkit-scrollbar-track {
  background: #e9e9e9;
}
.mvp-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.mvp-container .mvp-container-body {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/containers/MarketingOverview/components/charts/MassVsPersonalizedCampaigns/MassVsPersonalizedCampaigns.scss","webpack://./src/containers/MarketingOverview/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gBAAA;AADF;ACFE;EACE,wBAAA;EACA,YAAA;EACA,WAAA;ADIJ;ACDE;EACE,mBAAA;ADGJ;ACAE;EACE,mBAAA;EACA,oCAAA;EACA,oDAAA;ADEJ;AARE;EACE,YAAA;AAUJ","sourcesContent":["@import \"../../../mixins\";\n\n.mvp-container {\n  height: 376px;\n  overflow-y: auto;\n\n  @include custom-scrollbar;\n\n  .mvp-container-body {\n    height: 100%;\n  }\n}\n","@mixin custom-scrollbar {\n  &::-webkit-scrollbar {\n    -webkit-appearance: none;\n    height: 10px;\n    width: 10px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: #e9e9e9;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    background-color: rgba(0, 0, 0, 0.3);\n    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
