import { takeLatest, call, put, fork } from 'redux-saga/effects'
import * as uApi from '../api/userApi'
import * as aType from '../actions/_actionsTypes'

import {
  GET_ORGANIZATION_USERS,
  DELETE_ORGANIZATION_USER,
  getOrganizationUsersSuccess,
  deleteOrganizationUserSuccess,
  SEND_INVITE_FOR_USER,
  CANCEL_INVITE_FOR_USER,
  RESEND_INVITE_FOR_USER,
  sendInviteForUserSuccess,
  cancelInviteForUserSuccess,
  sendInviteForUserFailed,
  cancelInviteForUserFailed,
  resendInviteForUserSuccess,
  resendInviteForUserFailed
} from '../acts/organization'

import { LOCATIONS_EXTRA } from '../../shared/consts'
import {
  getAccessRequestsSuccess,
  setAccessRequestStatusSuccess
} from '../actions/userActions'
export const USER_CREATION_FAILED_MESSAGE =
  'Request failed with status code 450'
export const USER_CREATION_EXPIRED_TOKEN = 'Request failed with status code 401'

function* getUserDataSaga () {
  let result

  result = yield call(uApi.getUserData)

  let user = result.data

  user.extra = {
    pages: [],
    features: []
  }

  user.locations_enabled = user.locations
  user.permissions.forEach(perm => {
    let p = perm.split('.')
    if (p[0] === 'all-orchestrators') {
      user.locations_enabled = [
        ...user.locations,
        ...Object.keys(LOCATIONS_EXTRA).filter(loc => loc != 'def')
      ]
    }
    if (user.extra[p[0]]) {
      user.extra[p[0]].push(p.slice(1).join('.'))
    }
  })

  yield put({ type: aType.GET_USER_NAME_SUCCESS, payload: user })
}

function* setUserDataSaga (action) {
  const result = yield call(uApi.setUserDetails, action.payload)
  yield put({
    type: aType.SET_USER_NAME_SUCCESS,
    payload: {
      ...result.data.details
    }
  })
}

function* setUserPassSaga (action) {
  const { oldPassword, newPassword } = action.payload
  try {
    yield call(uApi.setUserPass, oldPassword, newPassword)
    yield put({ type: aType.SET_USER_PASS_SUCCESS })
  } catch (e) {
    yield put({ type: aType.SET_USER_PASS_ERROR, payload: e })
  }
}

function* setUserPicSaga (action) {
  const { pic } = action.payload
  const result = yield call(uApi.setUserPic, pic)
  yield put({ type: aType.SET_USER_PIC_SUCCESS, payload: result })
}

function* deleteUserPicSaga () {
  yield call(uApi.deleteUserPic)
  yield put({ type: aType.DELETE_USER_PIC_SUCCESS })
}

function* createUser () {
  try {
    const result = yield call(uApi.createNewUser)
    yield put({
      type: aType.SET_USER_NAME_SUCCESS,
      payload: {
        ...result.data,
        isCreate: true
      }
    })
  } catch (error) {
    if (
      [USER_CREATION_FAILED_MESSAGE, USER_CREATION_EXPIRED_TOKEN].includes(
        error.message
      )
    ) {
      yield put({ type: aType.SET_USER_NAME_FAILED, payload: error })
    } else {
      throw error
    }
  }
}

function* changePWDOnFirstLogin (action) {
  const data = action.payload
  try {
    const result = yield call(uApi.changePWDOnFirstLogin, data)
    yield put({
      type: aType.CHANGE_PASSWORD_ON_FIRST_LOGIN_SUCCESS,
      payload: result.data
    })
  } catch (e) {
    yield put({
      type: aType.CHANGE_PASSWORD_ON_FIRST_LOGIN_FAILURE,
      payload: e.response.data.msg
    })
  }
}

function* sendNewChangePWDMail (action) {
  const data = action.payload
  try {
    const result = yield call(uApi.sendNewChangePWDMail, data)
    yield put({
      type: aType.SEND_NEW_CHANGE_PWD_MAIL_SUCCESS,
      payload: result.data
    })
  } catch (e) {
    console.error(e)
    // yield put({ type: aType.CHANGE_PASSWORD_ON_FIRST_LOGIN_FAILURE, payload: e.response.data.msg })
  }
}

function* checkNonceExpired (action) {
  const data = action.payload
  try {
    const result = yield call(uApi.checkNonceExpired, data)
    yield put({ type: aType.CHECK_NONCE_EXPIRED_SUCCESS, payload: result.data })
  } catch (e) {
    console.error(e)
    yield put({
      type: aType.CHECK_NONCE_EXPIRED_FAILURE,
      payload: e.response.data.msg
    })
  }
}
function* setEmailPref (action) {
  const { subscriptions } = action.payload
  const result = yield call(uApi.setEmailPref, subscriptions)
  yield put({ type: aType.SET_EMAIL_PREFERENCES_SUCCESS, payload: result.data })
}

function* getAccessRequestsSaga () {
  const result = yield call(uApi.getAccessRequestsAPI)
  yield put(getAccessRequestsSuccess(result.data))
}

function* setAccessRequestStatusSaga (action) {
  const result = yield call(uApi.setAccessRequestStatusAPI, action.payload)
  yield put(setAccessRequestStatusSuccess(result.data))
}

function* getOrganizationUsersSaga () {
  const result = yield call(uApi.getOrganizationUsersAPI)
  yield put(getOrganizationUsersSuccess(result.data))
}

function* deleteOrganizationUserSaga (action) {
  yield call(uApi.deleteOrganizationUserAPI, action.payload)
  yield put(
    deleteOrganizationUserSuccess({
      user_id: action.payload.user_id
    })
  )
}

function* sendInviteForUserSaga (action) {
  let result
  try {
    result = yield call(uApi.sendInviteForUserAPI, action.payload)
    yield put(sendInviteForUserSuccess(result.data))
  } catch (e) {
    console.log(e.response.data)
    yield put(sendInviteForUserFailed(e.response.data))
  }
}

function* cancelInviteForUserSaga (action) {
  try {
    const result = yield call(uApi.cancelInviteForUserAPI, action.payload)
    yield put(cancelInviteForUserSuccess(result.data))
  } catch (e) {
    yield put(cancelInviteForUserFailed(e.response.data))
  }
}

function* resendInviteForUserSaga (action) {
  try {
    const result = yield call(uApi.resendInviteForUserAPI, action.payload)
    yield put(resendInviteForUserSuccess(result.data))
  } catch (e) {
    yield put(resendInviteForUserFailed(e.response.data))
  }
}

// WATCHERS
function* watchUserGetDataSaga () {
  yield takeLatest(aType.GET_USER_NAME, getUserDataSaga)
}

function* watchUserSetDataSaga () {
  yield takeLatest(aType.SET_USER_NAME, setUserDataSaga)
}

function* watchUserSetPassSaga () {
  yield takeLatest(aType.SET_USER_PASS, setUserPassSaga)
}

function* watchUserSetPicSaga () {
  yield takeLatest(aType.SET_USER_PIC, setUserPicSaga)
}

function* watchUserDeletePicSaga () {
  yield takeLatest(aType.DELETE_USER_PIC, deleteUserPicSaga)
}
function* watchCreateUserSaga () {
  yield takeLatest(aType.CREATE_NEW_USER, createUser)
}

function* watchSetSubscription () {
  yield takeLatest(aType.SET_EMAIL_PREFERENCES, setEmailPref)
}

function* watchChangePWDOnFirstLogin () {
  yield takeLatest(aType.CHANGE_PASSWORD_ON_FIRST_LOGIN, changePWDOnFirstLogin)
}

function* watchSendNewChangePWDMail () {
  yield takeLatest(aType.SEND_NEW_CHANGE_PWD_MAIL, sendNewChangePWDMail)
}

function* watchCheckNonceExpired () {
  yield takeLatest(aType.CHECK_NONCE_EXPIRED, checkNonceExpired)
}

function* watchGetAccessRequest () {
  yield takeLatest(aType.GET_ACCESS_REQUESTS, getAccessRequestsSaga)
}
function* watchSetAccessRequestStatus () {
  yield takeLatest(aType.SET_ACCESS_REQUEST_STATUS, setAccessRequestStatusSaga)
}

function* watchGetOrganizationUsers () {
  yield takeLatest(GET_ORGANIZATION_USERS, getOrganizationUsersSaga)
}

function* watchDeleteOrganizationUser () {
  yield takeLatest(DELETE_ORGANIZATION_USER, deleteOrganizationUserSaga)
}

function* watchSendInviteForUser () {
  yield takeLatest(SEND_INVITE_FOR_USER, sendInviteForUserSaga)
}
function* watchCancelInviteForUser () {
  yield takeLatest(CANCEL_INVITE_FOR_USER, cancelInviteForUserSaga)
}
function* watchResendInviteForUser () {
  yield takeLatest(RESEND_INVITE_FOR_USER, resendInviteForUserSaga)
}

const userSagas = [
  fork(watchUserGetDataSaga),
  fork(watchUserSetDataSaga),
  fork(watchUserSetPassSaga),
  fork(watchUserSetPicSaga),
  fork(watchUserDeletePicSaga),
  fork(watchCreateUserSaga),
  fork(watchSetSubscription),
  fork(watchChangePWDOnFirstLogin),
  fork(watchSendNewChangePWDMail),
  fork(watchCheckNonceExpired),
  fork(watchGetAccessRequest),
  fork(watchSetAccessRequestStatus),
  fork(watchGetOrganizationUsers),
  fork(watchDeleteOrganizationUser),
  fork(watchSendInviteForUser),
  fork(watchCancelInviteForUser),
  fork(watchResendInviteForUser)
]

export default userSagas
