// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctl-filters-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ctl-filters-container :last-child {
  margin-left: auto;
}
.ctl-filters-container .select-filter .select-filter-title {
  color: #898989;
  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.ctl-filters-container .select-filter .select-body {
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 8px 8px 8px 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e3e4e3;
  background: #fff;
  gap: 8px;
}
.ctl-filters-container .select-filter .select-body span {
  color: #161515;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.ctl-filters-container .select-filter .select-body .select-app-icon {
  width: 31px;
  height: 31px;
  border-radius: 4px;
  flex-shrink: 0;
}

.event-indication {
  width: 5px;
  height: 5px;
  border-radius: 1000px;
  background-color: #1d91ff;
  z-index: 1000;
  position: absolute;
  right: 45%;
}`, "",{"version":3,"sources":["webpack://./src/pages/ContextTimeline/components/header/filters/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,iBAAA;AACJ;AAGI;EACE,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AADN;AAII;EACE,eAAA;EACA,aAAA;EACA,YAAA;EACA,wBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,QAAA;AAFN;AAIM;EACE,cAAA;EACA,6CAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAFR;AAKM;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;AAHR;;AASA;EACE,UAAA;EACA,WAAA;EACA,qBAAA;EACA,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;AANF","sourcesContent":[".ctl-filters-container {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  :last-child {\n    margin-left: auto;\n  }\n\n  .select-filter {\n    .select-filter-title {\n      color: #898989;\n      /* Body/regular */\n      font-family: Roboto;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 22px; /* 157.143% */\n    }\n\n    .select-body {\n      cursor: pointer;\n      display: flex;\n      height: 48px;\n      padding: 8px 8px 8px 8px;\n      justify-content: space-between;\n      align-items: center;\n      border-radius: 8px;\n      border: 1px solid #e3e4e3;\n      background: #fff;\n      gap: 8px;\n\n      span {\n        color: #161515;\n        font-feature-settings: \"clig\" off, \"liga\" off;\n        font-family: Inter;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 500;\n      }\n\n      .select-app-icon {\n        width: 31px;\n        height: 31px;\n        border-radius: 4px;\n        flex-shrink: 0;\n      }\n    }\n  }\n}\n\n.event-indication {\n  width: 5px;\n  height: 5px;\n  border-radius: 1000px;\n  background-color: #1d91ff;\n  z-index: 1000;\n  position: absolute;\n  right: 45%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
