import './styles.scss'

import { DatePicker, Popover } from 'antd'
import chevron_down_ico from 'assets/images/icons/context-timeline/chevron-down.svg'
import device_ico from 'assets/images/icons/device.svg'
import persona_ico from 'assets/images/icons/persona.svg'
import clsx from 'clsx'
import { AppDrawer } from 'components/AppDrawer'
import moment from 'moment'
import { useContextTimeline } from 'pages/ContextTimeline/context'
import { useIsOpen } from 'pages/ContextTimeline/hooks/useIsOpen'
import React from 'react'

import { UserTypeFilter } from '../../user-type-filter'

export const Filters = () => {
  const {
    isOpen: isAppDrawerOpen,
    close: closeAppDrawer,
    onOpenChange: openAppDrawerChange
  } = useIsOpen({})
  const {
    selectedApp,
    selectedPersona,
    appsList,
    personasList,
    date,
    handleChangeFilter,
    eventsByDateList
  } = useContextTimeline()

  const findNearestDate = (target: moment.Moment) => {
    const differences = (eventsByDateList || [])?.map(e => {
      return {
        date: e.date,
        difference: Math.abs(moment(e.date).diff(target))
      }
    })

    differences.sort((a, b) => a.difference - b.difference)

    return differences.length > 0 ? differences[0].date : target.toISOString()
  }

  const listOfDates = (eventsByDateList || []).map(e => e.date)

  return (
    <div className='ctl-filters-container'>
      <Popover
        placement='bottom'
        title={null}
        destroyTooltipOnHide={true}
        content={
          <AppDrawer
            appsList={(appsList || []).map(app => ({
              id: app.id,
              name: app.displayName,
              thumbnail: app.icon
            }))}
            currentAppsIds={selectedApp ? [selectedApp.id] : []}
            onAppsSelected={appIds => {
              // TODO omer check if need to change to multiple app ids
              handleChangeFilter?.('app_id', appIds[0]?.toString())
              handleChangeFilter?.('insight_id', '')
            }}
            closeDrawer={closeAppDrawer}
          />
        }
        open={isAppDrawerOpen}
        onOpenChange={openAppDrawerChange}
        trigger='click'
        overlayClassName={clsx(
          'popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps fixed-filters'
        )}
      >
        <div className='select-filter' style={{ minWidth: '130px' }}>
          <div className='select-body'>
            <img src={device_ico} />
            <span>{selectedApp ? selectedApp.displayName : 'App'}</span>
            <img src={chevron_down_ico} />
          </div>
        </div>
      </Popover>
      <Popover
        placement='bottom'
        title={null}
        destroyTooltipOnHide={true}
        content={
          <UserTypeFilter
            disableSelectAll
            onSelectUserTypes={personaIds => {
              if (personaIds.length < 1) return

              handleChangeFilter?.(
                'persona_id',
                personaIds[personaIds.length - 1].toString()
              )
            }}
            selectedUserTypes={
              selectedPersona
                ? [
                    {
                      id: selectedPersona.id,
                      roles: [],
                      title: selectedPersona.title
                    }
                  ]
                : []
            }
            userTypes={(personasList || []).map(persona => ({
              id: persona.id,
              roles: [],
              title: persona.title,
              bgColor: persona.bgColor,
              textColor: persona.textColor
            }))}
          />
        }
        trigger='click'
      >
        <div className='select-filter' style={{ minWidth: '180px' }}>
          <div className='select-body'>
            <img src={persona_ico} />
            <span>{selectedPersona ? selectedPersona.title : 'Persona'}</span>
            <img src={chevron_down_ico} />
          </div>
        </div>
      </Popover>
      <div className='select-filter' style={{ minWidth: '150px' }}>
        <div className='select-body'>
          <span style={{ color: '#757474' }}>Jump To: </span>
          <DatePicker
            onChange={date => {
              if (!date) return
              const nearestDate = findNearestDate(date)
              handleChangeFilter?.('date', nearestDate)
              handleChangeFilter?.('insight_id', '')
            }}
            clearIcon={null}
            value={date ? moment(date) : null}
            style={{ border: 'none' }}
            placeholder='DD/MM/YYYY'
            format='DD/MM/YYYY'
            dateRender={date => {
              const doesHaveEvents = listOfDates.includes(
                date.format('YYYY-MM-DD')
              )

              return (
                <div className='ant-calendar-date'>
                  {date.date()}
                  {doesHaveEvents && <div className='event-indication'> </div>}
                </div>
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}
