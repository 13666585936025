import React from 'react'
import { useIsFeatureByUserID } from "../../helpers/features";
import { useSelector } from "react-redux";

const IFRAME_URL = 'https://watchful.metabaseapp.com/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjM0fSwicGFyYW1zIjp7fSwiZXhwIjoxODk4NDM2MTAxfQ.bgnEHSt_JKHK-pJTUJKiG_BWKVk8BmOdU43lJUa6yuE#bordered=true&titled=true'

const DeliveryOrdersOverviewPage = () => {
  const user = useSelector((state: any) => state.appData.user)
  const contextTimelineAccess = useIsFeatureByUserID(
    'delivery_orders',
    user.email
  )
  if (!contextTimelineAccess) {
    return null
  }
  return (
    <iframe
        src={IFRAME_URL}
        frameBorder={0}
        width={"100%"}
        height={"100%"}
        allowTransparency
    />
  )
}

export default DeliveryOrdersOverviewPage
