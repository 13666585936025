import Chart from 'chart.js'

export const centerTextPlugin = {
  id: 'centerTextPlugin',
  afterDatasetsDraw: (chart: any) => {
    const {
      ctx,
      data: { datasets }
    } = chart

    ctx.save()

    const totalInsights: number = datasets[0].originalData.reduce(
      (acc: number, curr: number) => acc + curr,
      0
    )

    const x = chart.getDatasetMeta(0).data[0]._model.x
    const y = chart.getDatasetMeta(0).data[0]._model.y

    ctx.font = '12px Roboto'
    ctx.fillStyle = 'rgba(32, 32, 32, 0.65)'
    ctx.textAlign = 'center'

    ctx.fillText('Total', x, y - 10)

    ctx.fillStyle = '#474747'
    ctx.font = '16px Roboto'

    ctx.fillText(totalInsights.toString(), x, y + 15)
  }
}

export const totalBarText = {
  id: 'totalBarText',
  afterDatasetsDraw: (chartInstance: any) => {
    const ctx = chartInstance.ctx

    if (!ctx) {
      return
    }

    // render the value of the chart above the bar
    ctx.font = Chart.helpers.fontString(
      14,
      'normal',
      Chart.defaults.global.defaultFontFamily
    )
    ctx.textAlign = 'end'
    ctx.textBaseline = 'center'
    ctx.fillStyle = '#64748b'

    chartInstance.data.labels.forEach((label: any, i: number) => {
      const textData = chartInstance.data.datasets.reduce(
        (accumulator: any, dataset: any) => {
          const meta = dataset._meta[Object.keys(dataset._meta)[0]]

          if (dataset.data.length > i && !meta.hidden) {
            return {
              lastModel: meta.data[i]._model,
              count: accumulator.count + dataset.data[i]
            }
          }

          return accumulator
        },
        { lastModel: null, count: 0 }
      )

      // Find the last dataset that has a value for the label
      // as it will be the last part of the stacked bar
      ctx.fillText(
        `Total ${textData.count}`,
        textData.lastModel?.x + 90,
        textData.lastModel?.y + 6
      )
    })
  }
}

export const onHoverSetCursor = (event: any, chartElement: any) => {
  event.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
}
