import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import moment from 'moment'
import ABTestsView from './ABTestsView'
import { useDebounce } from '../../helpers/customHooks'

import { getABTests } from '../../redux/acts/product_intel'

import { useIsFeatureByUserID } from '../../helpers/features'

function ABTestsPage ({
  getABTests,

  ABTests,
  apps,
  products,
  analytics,
  user,
  userDiscoveries
}) {
  const navigate = useNavigate()
  const location = useLocation()

  const ABTestPageAccess = useIsFeatureByUserID('ab_tests_page', user.email)

  const url = new URLSearchParams(location.search)

  const u = k => url.get(k)

  const [daysAgo, daysAgoChange] = useState(
    u('daysAgo') ? Number(u('daysAgo')) : 60
  )
  const [customRange, сustomRangeСhange] = useState(
    u('customRange') ? true : false
  )

  const [customRangeTime, customRangeTimeChange] = useState(
    u('customRangeTime')
      ? [
          moment(u('customRangeTime').split(',')[0]),

          moment(u('customRangeTime').split(',')[1])
        ]
      : [moment(), moment()]
  )

  const [activeTab, activeTabChange] = useState(
    u('activeTab') ? u('activeTab') : '1'
  )
  const [types, typesChange] = useState(u('types') ? u('types') : '')
  const [platforms, platformsChange] = useState(
    u('platforms') ? u('platforms').split(',') : []
  )
  const [searchQuery, searchQueryChange] = useState(
    u('searchQuery') ? u('searchQuery') : ''
  )
  const [currentProducts, currentProductsChange] = useState(
    u('product_ids')
      ? u('product_ids')
          .split(',')
          .map(a_id => Number(a_id))
      : []
  )

  const updateURL = () => {
    let dataForURL = {
      daysAgo,
      types,
      platforms,
      activeTab,
      searchQuery,
      product_ids: currentProducts.join(),
      customRange: customRange ? '1' : '',
      customRangeTime: customRangeTime.map(date => date.format('YYYY-MM-DD'))
    }
    let getsURL = new URLSearchParams(dataForURL).toString()

    navigate(`${location.pathname}?${getsURL}`, { replace: true })
  }

  const requestDiscoveries = () => {
    const appsFromProducts = currentProducts
      .reduce(
        (acc, product_id) => [
          ...acc,
          ...products.find(p => p.id === product_id).apps.map(app => app.id)
        ],
        []
      )
      .join()

    const vars = {
      app_id_only: appsFromProducts
    }
    if (customRange) {
      vars['start_time_gte'] = customRangeTime[0].format('YYYY-MM-DD')
      vars['start_time_lte'] = moment(customRangeTime[1]).format('YYYY-MM-DD')
    } else {
      vars['days_ago'] = daysAgo
    }

    vars['type'] = types ? types : 'ab_test,indication'
    vars['platform'] = platforms
    const gets = new URLSearchParams(vars).toString()

    getABTests({
      gets: gets
    })
    analytics.analytic('get ABTests', vars)
  }

  const debouncedRequest = useDebounce(requestDiscoveries, 800)

  const debouncedUpdateURL = useDebounce(updateURL, 800)

  useEffect(debouncedRequest, [
    daysAgo,
    customRange,
    customRangeTime,
    types,
    platforms,
    currentProducts
  ])

  useEffect(debouncedUpdateURL, [
    daysAgo,
    customRange,
    customRangeTime,
    activeTab,
    types,
    platforms,
    searchQuery,
    currentProducts
  ])

  if (!ABTestPageAccess) return null

  return (
    <ABTestsView
      ABTests={ABTests}
      analytics={analytics}
      apps={apps.map(a => a.metadata)}
      products={products}
      user={user}
      userDiscoveries={userDiscoveries}
      activeTab={activeTab}
      activeTabChange={activeTabChange}
      types={types}
      typesChange={typesChange}
      platforms={platforms}
      platformsChange={platformsChange}
      daysAgo={daysAgo}
      daysAgoChange={daysAgoChange}
      customRange={customRange}
      сustomRangeСhange={сustomRangeСhange}
      customRangeTime={customRangeTime}
      customRangeTimeChange={customRangeTimeChange}
      searchQuery={searchQuery}
      searchQueryChange={searchQueryChange}
      currentProducts={currentProducts}
      currentProductsChange={currentProductsChange}
    />
  )
}

export default connect(
  state => {
    return {
      ABTests: state.productDiscoveries.ABTests,
      apps: state.appData.apps,
      products: state.overview.products,
      analytics: state.appData.user.analytics,
      user: state.appData.user,
      userDiscoveries: state.userDiscoveries.discoveries
    }
  },
  {
    getABTests
  }
)(ABTestsPage)
