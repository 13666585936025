import './ChartLegend.scss'

import { ChartLegendLabelItem } from 'chart.js'
import React, { useEffect, useState } from 'react'

import { Checkbox } from '@mui/material'

type Props = {
  chart?: Chart
  vertical?: boolean
  counts?: number[]
  onLabelsChange?: () => void
}

export const ChartLegend = ({ chart, vertical = false, counts, onLabelsChange = () => null }: Props) => {
  // We need to keep track of checkbox values since the chart can be recreated and then we will need to toggle
  // the visibility of the datasets without the user having to repress the checkbox
  const [checkboxValues, setCheckboxValues] = useState(
    new Map<number, boolean>()
  )

  useEffect(() => {
    if (!chart) {
      return
    }

    //;[...checkboxValues.values()].forEach(val => val())
  }, [chart])

  if (!chart) {
    return null
  }

  const labels = chart.options.legend?.labels?.generateLabels?.(chart) || []

  if (!labels.length) {
    return null
  }

  const toggleDataset = (
    visible: boolean,
    datasetIndex?: number,
    dataIndex?: number
  ) => {
    if (datasetIndex !== undefined) {
      chart.getDatasetMeta(datasetIndex).hidden = !visible
    } else if (dataIndex !== undefined) {
      chart.getDatasetMeta(0).data[dataIndex].hidden = !visible
    }
    onLabelsChange()
    chart.update()
  }

  return (
    <div
      className='chart-legend-wrapper'
      style={{
        flexDirection: vertical ? 'column' : 'row',
        flexWrap: vertical ? 'nowrap' : 'wrap'
      }}
    >
      {labels.map((item: ChartLegendLabelItem, i: number) => {
        const datasetIndex = item.datasetIndex
        const key = datasetIndex || item.index || i

        // Convert index to string so 0 will stay 0 and fallback to i
        return (
          <div key={key} className='chart-legend-item'>
            <div className='checkbox-wrapper'>
              <Checkbox
                checked={
                  checkboxValues.has(key)
                    ? checkboxValues.get(key)
                    : !item.hidden
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  checkboxValues.set(key, event.target.checked)

                  // Need to clone, otherwise react compares by ref and doesn't re-render
                  setCheckboxValues(new Map(checkboxValues))
                  toggleDataset(event.target.checked, datasetIndex, item.index)
                }}
                size='large'
                style={{
                  color: item.fillStyle as string
                }}
              />
              {item.text}
            </div>
            {counts && <div className='count'>({counts[i]})</div>}
          </div>
        )
      })}
    </div>
  )
}

export default ChartLegend
