//import info_ico from "assets/images/icons/info-new-dark.svg";
import './HeadingWithTooltip.scss'

import { Switch } from 'antd'
import clsx from 'clsx'
import { ChartValuesToggle } from 'containers/MarketingOverview/utils/types'
import React from 'react'

type Props = {
  title: string
  subtitle?: string
  tooltip?: string

  showToggle?: boolean
  currentToggle?: ChartValuesToggle
  onToggle?: (val: ChartValuesToggle) => void

  showSwitch?: boolean
  currentSwitch?: boolean
  onSwitch?: (val: boolean) => void
  children?: React.ReactNode
}

export const HeadingWithTooltip = ({
  title,
  subtitle,
  //tooltip,

  onToggle,
  showToggle,
  currentToggle,

  currentSwitch,
  onSwitch,
  showSwitch,
  children
}: Props) => {
  return (
    <div className='heading-tooltip-container'>
      <div className='top-container'>
        <div className='title-container'>
          <h3 className='heading-tooltip-title'>{title}</h3>
          {subtitle && <h4 className='heading-tooltip-subtitle'>{subtitle}</h4>}
        </div>
        {/* <Tooltip
        title={tooltip}
        placement="top"
        overlayClassName="info-header-tooltip"
      >
        <img src={info_ico} />
      </Tooltip> */}
        {showToggle && (
          <div className='heading-chart-percentage-toggle'>
            <div
              className={clsx('heading-chart-percentage', {
                active: currentToggle === 'percentages'
              })}
              onClick={() => onToggle?.('percentages')}
            >
              Percentage
            </div>
            <div
              className={clsx('heading-chart-percentage', {
                active: currentToggle === 'numbers'
              })}
              onClick={() => onToggle?.('numbers')}
            >
              Numbers
            </div>
          </div>
        )}
        {showSwitch && (
          <div className='heading-chart-switch-toggle'>
            <Switch size='small' checked={currentSwitch} onChange={onSwitch} />
            Trends Line
          </div>
        )}
      </div>
      {children}
    </div>
  )
}
