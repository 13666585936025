import './ReportsCenter.scss'

import { Button } from 'antd'
import { AppDispatch, RootState } from 'index'
import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Icon from '@ant-design/icons'

import rep_empty_state from '../../../assets/images/new_design/icons/empty-report.svg'
import { IReport } from '../../../interfaces/Reports'
import {
  setReportsNotificationsRead,
  setReportsNotificationsSeen
} from '../../../redux/acts/reports'
import ReportNote from './ReportsNote'

interface IProps {
  markAllSeenLoading: boolean
  closeReportCenter: () => void
}

export const ReportsCenter = ({
  markAllSeenLoading = false,
  closeReportCenter
}: IProps): ReactElement | null => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const reports =
    useSelector((state: RootState) => state.reports?.reports) || []

  const handleMarkAllRead = () => {
    dispatch(setReportsNotificationsRead(reports.map(n => Number(n.id))))
  }

  useEffect(() => {
    dispatch(setReportsNotificationsSeen(reports.map(n => Number(n.id))))
  }, [])

  const handlerReportNotificationClick = (report: IReport) => {
    if (report.url) {
      navigate(`/reports/${report.id}?source=reports_dropdown`)
    }
    closeReportCenter()
  }

  const handleViewAll = () => {
    navigate(`/reports/`)
    closeReportCenter()
  }

  return (
    <div className='report-center'>
      <div className='report-ctr-header'>
        <h2>Reports</h2>
        <div className='report-buttons'>
          <Button
            className='ant-btn-light btn-report-mark-all-seen'
            onClick={handleMarkAllRead}
            disabled={reports.length === 0}
            data-testid='mark-all-as-seen'
          >
            Mark all as Read
            {markAllSeenLoading && <Icon type='loading' />}
          </Button>
          <Button
            className='ant-btn-light view-all'
            disabled={reports.length === 0}
            onClick={handleViewAll}
          >
            View All
          </Button>
        </div>
      </div>
      {reports.length === 0 ? (
        <div className='empty-state-report'>
          <div className='report-empty-icon'>
            <img src={rep_empty_state} />
          </div>
          <div className='report-empty-header'>
            Welcome to your Reporting center
          </div>
          <div className='report-empty-descr'>
            There are currently no reports available for you, check again later
            for updates.
          </div>
        </div>
      ) : (
        <div className='report-list'>
          {reports.map(n => (
            <ReportNote
              report={n}
              key={n.id}
              //@ts-ignore
              options={{ humanize: true, format: 'LL' }}
              handlerReportNotificationClick={handlerReportNotificationClick}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ReportsCenter
