import React, { useState } from 'react'

import icon_arrow_dropdown_menu from '../../assets/images/icons/arrow-dropdown.svg'

const CampaignTimelineStep = ({ date, first, last }) => {
  return (
    <div className={`timeline-step ${date.i % 2 == 0 ? 'odd' : 'even'}`}>
      <div className='timeline-step-day'>{`Day ${date.day}`}</div>
      <div className='timeline-step-event'>{date.event}</div>
      <div
        className={`timeline-step-line ${first && 'first'} ${last && 'last'}`}
      ></div>
      <div
        className={`timeline-step-dot ${first && 'first'} ${last && 'last'}`}
      ></div>
    </div>
  )
}

export const CampaignTimeline = props => {
  let { dates } = props
  dates = dates.sort((a, b) => Number(a.day) - Number(b.day))
  dates.forEach((el, i) => {
    el.i = i
  })
  const isSplitted = dates.length > 6
  const [hideElements, changeHideElements] = useState(true)

  if (!isSplitted) {
    return (
      <div className='timeline'>
        {dates.map((date, i) => (
          <CampaignTimelineStep
            date={date}
            first={i === 0}
            last={i === dates.length - 1 && i !== 0}
            length={dates.length}
            key={i}
          />
        ))}
      </div>
    )
  } else {
    return (
      <div className='timeline'>
        {dates.slice(0, 2).map((date, i) => (
          <CampaignTimelineStep
            date={date}
            first={i === 0}
            length={dates.length}
            key={i}
          />
        ))}
        <div className='timeline-step splitter'>
          <div className='timeline-step-day'></div>
          <div className='timeline-step-event'>
            <span className='number-hide'>
              {dates.length - 4} more items
              <span
                className={`btn-change-hide  ${hideElements ? 'show' : 'hide'}`}
                onClick={() => changeHideElements(!hideElements)}
                data-testid='hide-element'
              >
                {hideElements ? 'Show' : 'Hide'}
                <img src={icon_arrow_dropdown_menu} className='arrow' />
              </span>
            </span>
          </div>
          <div
            className={`timeline-step-line ${hideElements ? 'split' : ''}`}
          ></div>
        </div>
        <div
          className={`hided-dates`}
          style={{ height: `${hideElements ? 0 : 5 * (dates.length - 4)}rem` }}
        >
          {dates.slice(2, dates.length - 2).map((date, i) => (
            <CampaignTimelineStep date={date} length={dates.length} key={i} />
          ))}
        </div>

        {dates.slice(dates.length - 2, dates.length).map((date, i) => (
          <CampaignTimelineStep
            date={date}
            last={i === 1}
            length={dates.length}
            key={i}
          />
        ))}
      </div>
    )
  }
}

export default CampaignTimeline
