export const CAMPAIGNS_FILTERS = {
  app_ids: 'app_ids',
  insight_id: 'insight_id',
  user_types: 'user_types',
  user_role_types: 'user_role_types',
  days_ago: 'days_ago',
  custom_range: 'custom_range',
  custom_range_time: 'custom_range_time',
  channels: 'channels',
  offset: 'offset',
  limit: 'limit',
  sort: 'sort',
  focus_areas: 'focus_areas'
}

export const ITEMS_PER_PAGE = 1000

export const MARKETING_INSIGHT_TYPES = {
  email_campaign: 'email_campaign',
  push_notification_campaign: 'push_notification_campaign'
}

export const TYPE_TO_CHANNEL: any = {
  email_campaign: 'Email',
  push_notification_campaign: 'Push Notification',
  inapp_campaign: 'In-App Banners'
}

export const TABLE_SORT_FIELDS: any = {
  asc: {
    type: '+type',
    priority: '+priority',
    startTime: '+start_time'
  },
  desc: {
    type: '-type',
    priority: '-priority',
    startTime: '-start_time'
  }
}

export const PRIORITY = {
  very_high: 'very_high',
  high: 'high',
  medium: 'medium',
  low: 'low',
  very_low: 'very_low'
}
