import React, { useEffect, useState, useRef } from 'react'

import { DatePicker, Input } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import InputMask from 'react-input-mask'
import moment from 'moment'
import cal_left_ico from '../../assets/images/new_design/icons/antd-cal-left.svg'
import cal_right_ico from '../../assets/images/new_design/icons/antd-cal-right.svg'

export default function FilterTime ({
  isOpen,
  daysAgoList,
  daysAgo,
  daysAgoChange,
  customRange,
  сustomRangeСhange,
  customRangeTime,
  customRangeTimeChange,
  oneDay = false,
  hideCustomRange = false
}) {
  const [openRangePicker, openRangePickerChange] = useState(false)
  const [manStartDate, manStartDateChange] = useState(
    customRangeTime[0].format('DD/MM/YYYY')
  )
  const [manEndDate, manEndDateChange] = useState(
    customRangeTime[1].format('DD/MM/YYYY')
  )

  const popover = useRef(null)
  const popover1 = useRef(null)
  const popover2 = useRef(null)

  const getCalendarContainer = () => {
    return popover.current
  }

  const choosePreset = time => {
    daysAgoChange(time)
    сustomRangeСhange(false)
  }

  const handleKeyDownStartDate = e => {
    if (e.key === 'Enter') {
      let newMoment = moment(manStartDate, 'DD/MM/YYYY')
      let newDate =
        newMoment.isAfter(moment()) || !newMoment._isValid
          ? moment()
          : newMoment
      if (newDate.isAfter(customRangeTime[1])) {
        newDate = moment(customRangeTime[1])
      }
      customRangeTimeChange([newDate, customRangeTime[1]])
    }
  }

  const handleKeyDownEndDate = e => {
    if (e.key === 'Enter') {
      let newMoment = moment(manEndDate, 'DD/MM/YYYY')
      let newDate =
        newMoment.isAfter(moment()) || !newMoment._isValid
          ? moment()
          : newMoment
      customRangeTimeChange([customRangeTime[0], newDate])
    }
  }

  const dateRender = cur => {
    const classList = []
    if (moment(cur).isBetween(customRangeTime[0], customRangeTime[1])) {
      classList.push('in-range')
    }
    if (!moment(customRangeTime[0]).isSame(customRangeTime[1])) {
      if (moment(cur).isSame(customRangeTime[0])) {
        classList.push('start-date')
      }
      if (moment(cur).isSame(customRangeTime[1])) {
        classList.push('end-date')
      }
    } else if (
      moment(cur).isSame(customRangeTime[0]) &&
      moment(cur).isSame(customRangeTime[1])
    ) {
      classList.push('only-today')
    }

    return (
      <div className={`ant-picker-cell-wrap ${classList.join(' ')}`}>
        <div className={`ant-picker-cell-inner `}>{cur.date()}</div>
      </div>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      openRangePickerChange(true)
    }, 250)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      openRangePickerChange(isOpen)
    }, 200)
  }, [isOpen])

  return (
    <div className='wrap-time-filter'>
      <div className='left-side'>
        <h2>Time Range</h2>
        <h4>Presets</h4>
        {daysAgoList.map(o => (
          <div
            key={o.value}
            className={`range-value ${
              !customRange && daysAgo == o.value ? 'active' : ''
            }`}
            onClick={() => choosePreset(o.value)}
          >
            {o.title}
          </div>
        ))}
        {!hideCustomRange && (
          <>
            <h4>Custom</h4>
            <div
              className={`range-value custom-range ${
                customRange ? 'active' : ''
              }`}
              onClick={() => {
                сustomRangeСhange(!customRange)
                //customRangeTime([null, null])
              }}
            >
              {oneDay ? 'Specific Date' : 'Custom Range'}
              <ArrowRightOutlined />
            </div>
          </>
        )}
      </div>
      <div className={`right-side ${customRange ? '' : 'hidden'}`}>
        <div className='manual-inputs-dates'>
          <div className='manual-input-date start-date'>
            <div className='man-input-label'>(DD/MM/YYYY)</div>
            <InputMask
              mask='99/99/9999'
              value={manStartDate}
              onChange={e => manStartDateChange(e.target.value)}
              onKeyDown={handleKeyDownStartDate}
            >
              {inputProps => (
                <Input
                  {...inputProps}
                  placeholder='Start Date'
                  className='man-input'
                  autoFocus={true}
                />
              )}
            </InputMask>
          </div>
          {!oneDay && (
            <div className='manual-input-date end-date'>
              <div className='man-input-label'>(DD/MM/YYYY)</div>
              <InputMask
                mask='99/99/9999'
                value={manEndDate}
                onChange={e => manEndDateChange(e.target.value)}
                onKeyDown={handleKeyDownEndDate}
              >
                {inputProps => (
                  <Input
                    {...inputProps}
                    placeholder='End Date'
                    className='man-input'
                    autoFocus={true}
                  />
                )}
              </InputMask>
            </div>
          )}
        </div>
        <div className='date-picker-wrapper' ref={popover}>
          {oneDay ? (
            <DatePicker
              separator=''
              open={openRangePicker && customRange}
              getCalendarContainer={getCalendarContainer}
              value={customRangeTime[0]}
              disabledDate={current =>
                current && current > moment().endOf('day')
              }
              onChange={date => {
                customRangeTimeChange([date, date])
              }}
            />
          ) : (
            <div className='wf-datepicker'>
              <div ref={popover1}>
                <DatePicker
                  separator=''
                  open={openRangePicker && customRange}
                  getPopupContainer={() => popover1.current}
                  value={customRangeTime[0]}
                  disabledDate={current =>
                    current && current > moment().endOf('day')
                  }
                  onChange={date => {
                    customRangeTimeChange([date, customRangeTime[1]])
                    manStartDateChange(date.format('DD/MM/YYYY'))
                  }}
                  defaultOpen={true}
                  dateRender={dateRender}
                  popupClassName='wf-ant-range-picker-popup'
                  superNextIcon={null}
                  superPrevIcon={null}
                  prevIcon={<img src={cal_left_ico} />}
                  nextIcon={<img src={cal_right_ico} />}
                />
              </div>
              <div ref={popover2}>
                <DatePicker
                  separator=''
                  open={openRangePicker && customRange}
                  getPopupContainer={() => popover2.current}
                  value={customRangeTime[1]}
                  defaultOpen={true}
                  onChange={date => {
                    customRangeTimeChange([customRangeTime[0], date])
                    manEndDateChange(date.format('DD/MM/YYYY'))
                  }}
                  disabledDate={cur =>
                    !(
                      moment(cur).add(1, 'day').isAfter(customRangeTime[0]) &&
                      cur.isBefore(moment().endOf('day'))
                    )
                  }
                  dateRender={dateRender}
                  popupClassName='wf-ant-range-picker-popup '
                  prevIcon={<img src={cal_left_ico} />}
                  nextIcon={<img src={cal_right_ico} />}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
//
