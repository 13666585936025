import './PromotionsVolumeChart.scss'

import ChartDataLabels from 'chartjs-plugin-datalabels'
import useGoToPromotionsTable from 'containers/MarketingOverview/hooks/useGoToPromotionsTable'
import {
  ChannelToColor,
  ChannelToLabel
} from 'containers/MarketingOverview/utils/consts'
import { centerTextPlugin, onHoverSetCursor } from 'helpers/chartHelpers'
import { diffInPercent } from 'helpers/numbersHelper'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import { AppByInsightType } from '../MarketingCampaignChannelsBreakdown'

export interface PromotionsVolumeChartProps {
  app: AppByInsightType
  numberOfDays: number
}

type FontStyle = number | 'bold' | 'normal' | 'bolder' | 'lighter' | undefined

const convertPercentToColor = (percent: number) => {
  if (percent > 0) {
    return '#00924C'
  }

  if (percent < 0) {
    return '#EE2D2D'
  }

  return ''
}

const buildOptions = (onClick: Function) => {
  return {
    width: '300px',
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          // Compare to undefined as 0 is also false, and it a valid index
          if (tooltipItem.index !== undefined) {
            return data.labels?.[tooltipItem.index]
          }

          return ''
        }
      }
    },
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        formatter: (_: number, ctx: any) => {
          const data = ctx.dataset.data

          return `${data[ctx.dataIndex]}%`
        },
        color: '#fff',
        font: {
          weight: 'bold' as FontStyle,
          size: 14
        }
      }
    },
    onHover: onHoverSetCursor,
    onClick
  }
}

export const PromotionsVolumeChart: React.FC<PromotionsVolumeChartProps> = ({
  app,
  numberOfDays
}) => {
  const goToTable = useGoToPromotionsTable()

  const data = {
    labels: app.insightTypes.map(
      insight => `Count: ${insight.count}, Percentage: ${insight.percentage}%`
    ),
    datasets: [
      {
        data: app.insightTypes.map(insight => insight.percentage),
        backgroundColor: app.insightTypes.map(
          insight => ChannelToColor[insight.insightType]
        ),
        originalData: app.insightTypes.map(insight => insight.count)
      }
    ]
  }

  const onClick = (_: any, chart: any) => {
    if (!chart?.length) {
      return
    }

    const insight = app.insightTypes[chart[0]._index]

    goToTable([app.id], [insight.insightType])
  }

  const options = buildOptions(onClick)

  const prevPeriodDiffInPercent =
    Math.round(
      diffInPercent(app.totalPrevPeriod || 0, app.totalAppInsights) * 10
    ) / 10

  return (
    <div className='volume-chart-wrapper'>
      <div className='app-name'>
        <img src={app.icon} />
        {app.displayName}
      </div>
      <div className='counters'>
        {app.insightTypes.map(insight => {
          return (
            <div key={insight.insightType} className='insight-count'>{`${
              ChannelToLabel[insight.insightType]
            } - ${insight.count}`}</div>
          )
        })}
      </div>
      <div className='doughnut-container'>
        <Doughnut
          plugins={[centerTextPlugin, ChartDataLabels]}
          data={data}
          options={{ ...options, onClick }}
        />
      </div>
      {app.totalPrevPeriod !== null && (
        <div className='comparison-to-prev'>
          <span
            className='diff-percent'
            style={{ color: convertPercentToColor(prevPeriodDiffInPercent) }}
          >
            {prevPeriodDiffInPercent}%{' '}
          </span>
          VS. previous {numberOfDays} days
        </div>
      )}
    </div>
  )
}

export default PromotionsVolumeChart
