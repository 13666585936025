import './styles.scss'

import { Col, message, Modal, Row, Spin, Tooltip } from 'antd'
import browsers_ico from 'assets/images/icons/browsers.svg'
import copy_ico from 'assets/images/icons/copy.svg'
import favorite_filled_ico from 'assets/images/icons/favorite-filled.svg'
import favorite_ico from 'assets/images/icons/favorite.svg'
import fullscreen_ico from 'assets/images/icons/full-screen.svg'
import info_ico, {
  ReactComponent as InfoIco
} from 'assets/images/icons/info-new-dark.svg'
import translate_ico from 'assets/images/icons/translate.svg'
import x_circle_ico from 'assets/images/icons/x-circle.svg'
import dis_error_img_ico from 'assets/images/new_design/dis-error-img.svg'
import ViewCopyButton from 'components/discoveryView/viewCopyButton/viewCopyButton'
import MoreOptions from 'components/discoveryView/viewHeader/MoreOptions'
import {
  debouncedTrackMarketingEvent,
  trackMarketingEvent
} from 'containers/MarketingCampaings/utils/analytics'
import { MARKETING_INSIGHT_TYPES } from 'containers/MarketingCampaings/utils/conts'
import {
  copyToClipboard,
  getPersonaTitle,
  getPriority
} from 'containers/MarketingCampaings/utils/helpers'
import { UserType } from 'containers/MarketingCampaings/utils/types'
import moment from 'moment'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import storageService from 'services/storageService'
import config from 'shared/config'

import { LoadingOutlined } from '@ant-design/icons'

import {
  likeInsight,
  unlikeInsight
} from '../../../../redux/actions/dashboardActions'
import { ClickableIFrame } from './ClickableIFrame'

const TypeToLabel: any = {
  email_campaign: 'Email Campaign',
  push_notification_campaign: 'Push Notification',
  inapp_campaign: 'In-App Banner'
}

type StateProps = {
  user?: any
  userDiscoveries?: any[]
}

type DispatchProps = {
  likeInsight?: Function
  unlikeInsight?: Function
}

type Props = {
  open: boolean
  onClose: () => void
  type: string
  insightId: number
  title: string
  date: string
  priority: string
  labels: string[]
  focusArea: string
  messageContent?: string
  userTypes: UserType[]
  userTypesSeen: UserType[]
  insightDescription: string
  assets: any[]
  appId: string
  appName: string
  thumbnail?: string
} & StateProps &
  DispatchProps

const MarketingInsightPreviewModalBase = ({
  onClose,
  appId,
  appName,
  open,
  insightId,
  type,
  title,
  date,
  priority,
  labels,
  focusArea,
  messageContent,
  userTypes,
  userTypesSeen,
  insightDescription,
  assets,
  user,
  userDiscoveries,
  likeInsight,
  unlikeInsight,
  thumbnail
}: Props) => {
  const isAllUserTypesSeen = userTypesSeen.length === userTypes.length
  const userTypesSeenWithColors = userTypesSeen.map(userType => ({
    ...userType,
    textColor: userTypes.find(ut => ut.id === userType.id)?.textColor,
    bgColor: userTypes.find(ut => ut.id === userType.id)?.bgColor
  }))
  const userTypesUnseen = userTypes.filter(
    userType => !userTypesSeen.find(seen => seen.id === userType.id)
  )
  const htmlAssets = assets.filter((asset: any) => asset.asset_type === 'html')

  const analyticInsightData = {
    insight_id: insightId,
    insight_title: title,
    insight_channel: type,
    insight_focus_area: focusArea
  }

  const handleCopy = (text: string) => () => {
    copyToClipboard(text)
  }

  const handleTranslate = (htmlAsset: any) => {
    if (htmlAsset) {
      window.open(
        `https://translate.google.com/translate?hl=en&ie=UTF-8&u=${htmlAsset.url_annotated}&sl=auto&tl=en`,
        '_blank'
      )
    } else {
      return
    }
  }

  const handleOpenInNewTab = () => {
    trackMarketingEvent('modal_opened_in_new_tab', analyticInsightData)

    window.open(window.location.href, '_blank')
  }

  const handleOpenInFullScreen = () => {
    trackMarketingEvent('modal_opened_in_full_screen', analyticInsightData)

    window.open(assets[0].url_annotated, '_blank')
  }

  const userDis = userDiscoveries?.find(
    (uD: any) => uD.insight_id === insightId
  )
  const isLiked = userDis
    ? (userDis.liked_at && !userDis.unliked_at) ||
      (userDis.unliked_at &&
        moment(userDis.unliked_at).isBefore(moment(userDis.liked_at)))
    : false

  const handleStarClick = () => {
    trackMarketingEvent('modal_save_clicked', analyticInsightData)

    if (isLiked) {
      unlikeInsight?.({ insightID: insightId })
    } else {
      likeInsight?.({ insightID: insightId })
    }
  }

  const handleDownload = async (format: string) => {
    const storage = new storageService()
    const token = storage.getItem('usertoken')
    const hideLoader = message.loading({
      content: `Rendering and downloading ${format}...`,
      duration: 0,
      icon: (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                color: 'white'
              }}
              spin
            />
          }
        />
      )
    })

    const res = await fetch(
      `${config.dataApi.backendUrl}/insight/${insightId}/${format}/None/None`,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    if (res.ok) {
      const link = document.createElement('a')
      let blobUrl = ''
      if (format !== 'pdf') {
        trackMarketingEvent('modal_download_pdf', analyticInsightData)

        const blob = await res.blob()
        const newBlob = new Blob([blob])

        blobUrl = window.URL.createObjectURL(newBlob)
      } else {
        trackMarketingEvent('modal_download_jpg', analyticInsightData)

        const body = await res.json()
        blobUrl = body.url
      }

      link.href = blobUrl
      link.setAttribute('download', `${title}.${format}`)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    } else {
      const modal = Modal.info({
        title: <h2>There was an error downloading your file</h2>,
        className: 'discovery-error-loading',
        centered: true,
        width: 370,
        content: (
          <div>
            <p>Please try again later</p>
            <div className='error-download-img'>
              <img src={dis_error_img_ico} />
            </div>
          </div>
        ),
        onOk () {
          modal.destroy()
        },
        okText: 'Close',
        okType: 'default'
      })
    }
    setTimeout(hideLoader, 2500)
  }

  useEffect(() => {
    if (!open || !insightId) return

    trackMarketingEvent('insight_clicked', analyticInsightData)
  }, [insightId, open])

  const renderUserTypes = (
    title: string,
    isSeenInAll: boolean,
    userTypes: UserType[],
    tooltipText?: string
  ) => (
    <div className='mkc-preview-content-box'>
      <div className='mkc-preview-content-box-head'>
        <div className={isSeenInAll ? 'mkc-seen-in-all' : ''}>{title}</div>
        <Tooltip
          overlayClassName='info-header-tooltip'
          placement='top'
          title={tooltipText}
        >
          <img src={info_ico} />
        </Tooltip>
      </div>
      <div className='mkc-preview-content-box-body'>
        {userTypes.map(userType => (
          <div
            className='mkc-preview-content-box-user-type-container'
            style={{ backgroundColor: userType.bgColor }}
            key={userType.id}
          >
            <div
              className='mkc-preview-content-box-user-type-text'
              style={{ color: userType.textColor }}
            >
              {getPersonaTitle(userType)}
            </div>
            <div className='mkc-preview-content-box-user-type-icon-container'>
              <Tooltip
                overlayClassName='info-header-tooltip'
                placement='top'
                title={userType?.description || 'No description'}
              >
                <InfoIco fill={userType.textColor} />
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const renderAssets = (
    insAssets: any[],
    onClickableIFrameMouseOverOut?: () => void
  ) => {
    const onAssetClick = (asset: any) => () => {
      window.open(asset.url_annotated, '_blank')
    }

    return (
      <>
        {insAssets.map((asset: any) => (
          <div key={asset.id}>
            {asset.asset_type === 'image' && (
              <img
                onClick={onAssetClick(asset)}
                className='mkc-preview-asset-image'
                src={asset.url_annotated}
              />
            )}
            {asset.asset_type === 'html' && (
              <ClickableIFrame
                onMouseOut={onClickableIFrameMouseOverOut}
                onMouseOver={onClickableIFrameMouseOverOut}
                className='mkc-preview-asset-iframe'
                src={asset.url_annotated}
              />
            )}
          </div>
        ))}
      </>
    )
  }

  return (
    <Modal
      centered
      closeIcon={null}
      footer={null}
      title={null}
      closable={false}
      open={open}
      destroyOnClose
      onCancel={onClose}
      width={1300}
      className='mkc-modal-container'
    >
      <Row justify='space-between' className='mkc-insight-preview-container'>
        <Col span={14} className='mkc-preview-left'>
          <div className='mkc-preview-left-top'>
            <div className={type}>{TypeToLabel[type]}</div>
          </div>
          <h3 className='mkc-preview-title'>{title}</h3>
          <div className='mkc-date-priority'>
            <h5 className='mkc-preview-date'>{date}</h5>
            <div className={`priority-${getPriority(priority)}`}>
              {priority} Priority
            </div>
          </div>
          <div className='mkc-preview-list'>
            <div className='mkc-preview-list-head'>Tags</div>
            {labels.map(label => (
              <div className='mkc-preview-list-item' key={label}>
                {label}
              </div>
            ))}
          </div>
          {focusArea && (
            <div className='mkc-preview-list s-mt'>
              <div className='mkc-preview-list-head'>Focus Area</div>
              <div className='mkc-preview-list-item'>{focusArea}</div>
            </div>
          )}
          {messageContent && (
            <div className='mkc-preview-content-box'>
              <div className='mkc-preview-content-box-head'>
                <div>Message Content</div>
                <Tooltip
                  overlayClassName='info-header-tooltip'
                  trigger='click'
                  title='Copied to clipboard...'
                >
                  <img src={copy_ico} onClick={handleCopy(messageContent)} />
                </Tooltip>
              </div>
              <div className='mkc-preview-content-box-body'>
                {messageContent}
              </div>
            </div>
          )}
          {isAllUserTypesSeen ? (
            renderUserTypes(
              'Seen In All User Types',
              true,
              userTypesSeenWithColors,
              'All user types who received the campaign.'
            )
          ) : (
            <div className='mkc-preview-double-box-container'>
              {renderUserTypes(
                'User Types Seen',
                false,
                userTypesSeenWithColors,
                'All user types who received the campaign.'
              )}
              {renderUserTypes(
                'User Types Unseen',
                false,
                userTypesUnseen,
                'All user types who did not receive the campaign.'
              )}
            </div>
          )}
          {insightDescription && (
            <div className='mkc-preview-content-box'>
              <div className='mkc-preview-content-box-head'>
                <div>Insights</div>
                <Tooltip
                  overlayClassName='info-header-tooltip'
                  trigger='click'
                  title='Copied to clipboard...'
                >
                  <img
                    src={copy_ico}
                    onClick={handleCopy(insightDescription)}
                  />
                </Tooltip>
              </div>
              <pre className='mkc-preview-content-box-body'>
                {insightDescription}
              </pre>
            </div>
          )}
        </Col>
        <Col span={9} className='mkc-preview-right'>
          <div className='mkc-preview-right-header'>
            {type === MARKETING_INSIGHT_TYPES.email_campaign && (
              <Tooltip overlayClassName='info-header-tooltip' title='Translate'>
                <div
                  className='mkc-preview-btn'
                  onClick={() =>
                    handleTranslate(
                      assets.find((asset: any) => asset.asset_type === 'html')
                    )
                  }
                >
                  <img style={{ width: '21px' }} src={translate_ico} />
                </div>
              </Tooltip>
            )}
            <Tooltip
              overlayClassName='info-header-tooltip'
              title='Open In Full Screen'
            >
              <div
                className='mkc-preview-btn right'
                onClick={handleOpenInFullScreen}
              >
                <img src={fullscreen_ico} />
              </div>
            </Tooltip>
            <Tooltip
              overlayClassName='info-header-tooltip'
              title='Open In New Tab'
            >
              <div className='mkc-preview-btn' onClick={handleOpenInNewTab}>
                <img src={browsers_ico} />
              </div>
            </Tooltip>
            <Tooltip
              overlayClassName='info-header-tooltip'
              title='Save To Favorites'
            >
              <div
                className='mkc-preview-btn'
                style={{ padding: '8px 10px' }}
                onClick={handleStarClick}
              >
                <img
                  style={{ maxWidth: '16px' }}
                  src={isLiked ? favorite_filled_ico : favorite_ico}
                />
              </div>
            </Tooltip>
            <Tooltip overlayClassName='info-header-tooltip' title='Share'>
              <div>
                <ViewCopyButton
                  insightId={insightId}
                  metadata={{
                    id: appId,
                    platform: null,
                    name: appName
                  }}
                  analytics={{ analytic: () => {} }}
                  title={title}
                  id={appId}
                  page='campaigns'
                  insightType={type}
                />
              </div>
            </Tooltip>
            <Tooltip
              overlayClassName='info-header-tooltip'
              title='More Options'
            >
              <div>
                <MoreOptions
                  page='campaigns'
                  downloadCallback={handleDownload}
                  insightID={insightId}
                  app={{ name: null, platform: null }}
                  discovery={{ title: null, type: null }}
                  defaultEmail={user?.email}
                  currentInsightURL={
                    window.location.pathname + window.location.search
                  }
                  metadata={{}}
                />
              </div>
            </Tooltip>
            <Tooltip overlayClassName='info-header-tooltip' title='Close'>
              <div className='mkc-preview-btn no-bg' onClick={onClose}>
                <img src={x_circle_ico} />
              </div>
            </Tooltip>
          </div>
          <div
            className='mkc-preview-right-images'
            onScrollCapture={() => {
              debouncedTrackMarketingEvent(
                'modal_scroll_media',
                analyticInsightData
              )
            }}
          >
            {type === MARKETING_INSIGHT_TYPES.email_campaign &&
            htmlAssets.length > 0
              ? renderAssets(htmlAssets, () => {
                  debouncedTrackMarketingEvent(
                    'modal_scroll_media',
                    analyticInsightData
                  )
                })
              : renderAssets(assets)}
            {assets.length === 0 && (
              <div>
                <img className='mkc-preview-asset-image' src={thumbnail} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

const mapStateToProps = ({ appData, userDiscoveries }: any) => {
  return {
    user: appData.user,
    userDiscoveries: userDiscoveries.discoveries
  }
}

export const MarketingInsightPreviewModal = connect(mapStateToProps, {
  likeInsight,
  unlikeInsight
})(MarketingInsightPreviewModalBase)
