// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cput-contianer {
  display: flex;
  gap: 1.6rem;
  height: 400px;
}
.cput-contianer .cput-legend {
  padding-right: 0.8rem;
  border-right: 1px solid #eceef5;
  width: 29rem;
}
.cput-contianer .cput-chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  background: #fdfdff;
  overflow-y: auto;
}
.cput-contianer .cput-chart::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
}
.cput-contianer .cput-chart::-webkit-scrollbar-track {
  background: #e9e9e9;
}
.cput-contianer .cput-chart::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/containers/MarketingOverview/components/charts/CampaignPerUserType/CampaignPerUserType.scss","webpack://./src/containers/MarketingOverview/_mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,WAAA;EACA,aAAA;AAFF;AAIE;EACE,qBAAA;EACA,+BAAA;EACA,YAAA;AAFJ;AAKE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;AAHJ;ACjBE;EACE,wBAAA;EACA,YAAA;EACA,WAAA;ADmBJ;AChBE;EACE,mBAAA;ADkBJ;ACfE;EACE,mBAAA;EACA,oCAAA;EACA,oDAAA;ADiBJ","sourcesContent":["@import '../../../mixins';\n@import '../../../../../sass-config/abstracts/variables.scss';\n\n.cput-contianer {\n  display: flex;\n  gap: 1.6rem;\n  height: 400px;\n\n  .cput-legend {\n    padding-right: 0.8rem;\n    border-right: 1px solid $color-border-layout;\n    width: 29rem;\n  }\n\n  .cput-chart {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 24px;\n    background: #fdfdff;\n    overflow-y: auto;\n\n    @include custom-scrollbar;\n  }\n}\n","@mixin custom-scrollbar {\n  &::-webkit-scrollbar {\n    -webkit-appearance: none;\n    height: 10px;\n    width: 10px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: #e9e9e9;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    background-color: rgba(0, 0, 0, 0.3);\n    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
