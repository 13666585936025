import './styles.scss'

import { Row } from 'antd'
import React, { useEffect } from 'react'

import { Header } from './components/header'
import { Main } from './components/main'
import { Sidebar } from './components/sidebar'
import { ContextTimelineProvider } from './context'

const ContextTimelinePageBase = () => {
  // to remove global styling
  useEffect(() => {
    const dashboardContainer = document.querySelector('.dashboard__container')
    const searchAccount = document.querySelector('.search-account')

    if (!dashboardContainer || !searchAccount) return

    dashboardContainer.classList.add('dashboard__container--context-timeline')
    searchAccount.classList.add('searchAccount--context-timeline')

    return () => {
      dashboardContainer.classList.remove(
        'dashboard__container--context-timeline'
      )
      searchAccount.classList.remove('searchAccount--context-timeline')
    }
  }, [])

  return (
    <ContextTimelineProvider>
      <Row>
        <Header />
        <Sidebar />
        <Main />
      </Row>
    </ContextTimelineProvider>
  )
}

export const ContextTimelinePage = ContextTimelinePageBase
