import IntelligenceProductPage from '../containers/products-discoveries/IntelligenceProductPage'
import { pagesTypes } from './consts'
import BenchmarkRanks from '../containers/benchmark/BenchmarkRanks/BenchmarkRanks'
import IntelligenceMarketing from '../containers/intelligence/Marketing/Marketing'
import DashOverview from '../containers/dash-overview/'

import MassMarket from '../containers/marketing/mass_market/MassMarket'
import ReportView from '../containers/reports/ReportsView'
import ReportsList from '../containers/reports/ReportsList'
import SubscriptionsList from '../containers/subscriptions/SubscriptionsList'
import Eula from '../components/legal/EULA'
import Privacy from '../components/legal/Privacy'
import DashDiscoveries from '../containers/dash-discoveries'
import ABTestsPage from '../containers/ABTests/ABTestsPage'
import TopicsPage from '../containers/topics/TopicsPage'

import home_ico from '../assets/images/icons/newSideBar/Home.svg'

import overview_ico from '../assets/images/icons/newSideBar/Overview.svg'
import campaigns_ico from '../assets/images/icons/newSideBar/Campaigns.svg'
import context_timeline_ico from '../assets/images/icons/newSideBar/ContextTimeline.svg'
import promo_charts_icon from '../assets/images/icons/newSideBar/PromoCharts.svg'
import promo_table_icon from '../assets/images/icons/newSideBar/PromoTable.svg'

import compare_ico from '../assets/images/icons/newSideBar/Compare.svg'
import ranks_ico from '../assets/images/icons/newSideBar/Ranks.svg'

import discoveries_ico from '../assets/images/icons/newSideBar/Discoveries.svg'
import hash_ico from '../assets/images/icons/newSideBar/Hash.svg'
import user_journeys_ico from '../assets/images/icons/newSideBar/UserJourneys.svg'

import nav_intel_ab_ico from '../assets/images/new_design/nav2/nav_intel_ab.svg'

import latest_insights_ico from '../assets/images/icons/newSideBar/LatestInsights.svg'
import { UserJourneysPage } from '../containers/UserJourneys/UserJourneysPage'
import { ContextTimelinePage } from 'pages/ContextTimeline'
import DeliveryOrdersOverviewPage from "../containers/DeliveryOrders/DeliveryOrdersOverviewPage";
import DeliveryOrdersTablePage from "../containers/DeliveryOrders/DeliveryOrdersTablePage";

const {
  AB_TESTS,
  INTELLIGENCE,
  BENCHMARK,
  BENCHMARK_COMPARE,
  BENCHMARK_RANKS,
  MARKETING,
  DELIVERY_ORDERS,
  DASH_OVERVIEW,
  ALL_DISCOVERIES,
  MARKETING_MASS_MARKETING,
  MARKETING_CAMPAIGNS,
  REPORTSLIST,
  REPORTSVIEW,
  SUBSCRIPTIONS,
  EULA,
  PRIVACY,
  PRODUCT_INTELLIGENCE,
  TOPICS,
  USER_JOURNEYS,
  PROMOTIONS_TABLE,
  DELIVERY_ORDERS_TABLE,
  DELIVERY_ORDERS_OVERVIEW,
  PROMOTIONS_CHARTS,
  CONTEXT_TIMELINE,
  ALL_COMMUNICATIONS
} = pagesTypes

const routesData = {
  routesType: {
    [DASH_OVERVIEW]: {
      icon: home_ico,
      component: DashOverview,
      url: '/overview',
      label: 'Home',
      name: 'overview',
      access_key: 'dash_overview'
    },

    [PRODUCT_INTELLIGENCE]: {
      icon: discoveries_ico,
      component: IntelligenceProductPage,
      label: 'Discoveries',
      name: 'product_intelligence',
      page: 'intelligence',
      url: '/product_intelligence',
      access_key: 'versions'
    },
    [AB_TESTS]: {
      icon: nav_intel_ab_ico,
      component: ABTestsPage,
      label: 'Experiments & Indications',
      name: 'ab_tests',
      url: '/product_intelligence/ab-tests/',
      access_key: 'versions'
    },
    [TOPICS]: {
      icon: hash_ico,
      component: TopicsPage,
      label: 'Topics',
      name: 'topics',
      url: '/product_intelligence/topics/',
      access_key: 'versions'
    },
    [USER_JOURNEYS]: {
      icon: user_journeys_ico,
      component: UserJourneysPage,
      label: 'User Journeys',
      name: 'user_journeys',
      url: '/product_intelligence/user_journeys/',
      access_key: 'user_journeys'
    },

    [MARKETING_CAMPAIGNS]: {
      icon: campaigns_ico,
      component: IntelligenceMarketing,
      label: 'Campaigns',
      name: 'campaigns',
      url: '/marketing/campaigns',
      access_key: 'marketing'
    },
    [MARKETING_MASS_MARKETING]: {
      icon: overview_ico,
      component: MassMarket,
      label: 'Overview',
      name: 'mass_market',
      page: 'marketing',
      url: '/marketing/mass_market',
      access_key: 'marketing'
    },
    [ALL_COMMUNICATIONS]: {
      icon: campaigns_ico,
      component: MassMarket,
      label: 'All Communications',
      name: 'all_communications',
      page: 'marketing',
      url: '/marketing/all_communications',
      access_key: 'marketing'
    },
    [PROMOTIONS_CHARTS]: {
      icon: promo_charts_icon,
      component: MassMarket,
      label: 'Overview & Trends',
      name: 'promotions_charts',
      page: 'marketing',
      url: '/marketing/promotions/charts',
      access_key: 'marketing'
    },
    [PROMOTIONS_TABLE]: {
      icon: promo_table_icon,
      component: IntelligenceMarketing,
      label: 'Campaigns Repository',
      name: 'promotions_table',
      page: 'marketing',
      url: '/marketing/promotions/table',
      access_key: 'marketing'
     },
    [DELIVERY_ORDERS_TABLE]: {
      icon: promo_table_icon,
      component: DeliveryOrdersTablePage,
      label: 'Data Repository',
      name: 'orders_table',
      page: 'delivery_orders',
      url: '/delivery/orders/table',
      access_key: 'delivery_orders'
     },
    [DELIVERY_ORDERS_OVERVIEW]: {
      icon: promo_charts_icon,
      component: DeliveryOrdersOverviewPage,
      label: 'Overview',
      name: 'orders_overview',
      page: 'delivery_orders',
      url: '/delivery/orders',
      access_key: 'delivery_orders'
     },
    [CONTEXT_TIMELINE]: {
      icon: context_timeline_ico,
      component: ContextTimelinePage,
      label: 'Context Timeline',
      name: 'context_timeline',
      page: 'marketing',
      url: '/marketing/context_timeline',
      access_key: 'marketing'
    },
    [BENCHMARK_COMPARE]: {
      icon: compare_ico,
      component: Performance,
      label: 'Compare',
      name: 'compare',
      url: '/benchmark/compare',
      access_key: 'benchmark_compare'
    },
    [BENCHMARK_RANKS]: {
      icon: ranks_ico,
      component: BenchmarkRanks,
      label: 'Ranks',
      name: 'ranks',
      url: '/benchmark/ranks',
      access_key: 'benchmark_ranks'
    },

    [ALL_DISCOVERIES]: {
      icon: latest_insights_ico,
      component: DashDiscoveries,
      label: 'All discoveries',
      name: 'all-discoveries',
      url: '/all-discoveries',
      access_key: 'intelligence'
    },

    [REPORTSVIEW]: { component: ReportView, url: '/reports' },
    [REPORTSLIST]: { component: ReportsList, url: '/reports' },

    [SUBSCRIPTIONS]: { component: SubscriptionsList, url: '/subscriptions' },
    [EULA]: { component: Eula, url: '/eula' },
    [PRIVACY]: { component: Privacy, url: '/privacy' }
  }
}

export default routesData

export const menuItems = [
  {
    label: 'Menu',
    name: DASH_OVERVIEW,
    access_key: 'dash_overview',
    items: [routesData.routesType.dash_overview]
  },
  {
    label: 'All Discoveries',
    name: ALL_DISCOVERIES,
    access_key: 'intelligence',
    routeData: routesData.routesType.all_discoveries
  },
  {
    label: 'Product UI/UX',
    name: INTELLIGENCE,
    access_key: 'intelligence',
    items: [
      routesData.routesType.product_intelligence,
      routesData.routesType[TOPICS],
      routesData.routesType.ab_tests,
      routesData.routesType[USER_JOURNEYS]
    ]
  },
  {
    label: 'Marketing Promotions',
    name: MARKETING,
    access_key: 'marketing',
    items: [
      routesData.routesType[PROMOTIONS_CHARTS],
      routesData.routesType[PROMOTIONS_TABLE]
    ]
  },
  {
    label: 'Benchmarking',
    name: BENCHMARK,
    access_key: 'benchmark_compare',
    items: [
      routesData.routesType.benchmark_compare,
      routesData.routesType.benchmark_ranks
    ]
  },
  {
    label: 'All Discoveries',
    name: ALL_DISCOVERIES,
    access_key: 'intelligence',
    routeData: routesData.routesType.all_discoveries
  }
]

export const menuItemsWoutABTest = [
  {
    label: 'Menu',
    name: DASH_OVERVIEW,
    access_key: 'dash_overview',
    items: [routesData.routesType.dash_overview]
  },
  {
    label: 'All Discoveries',
    name: ALL_DISCOVERIES,
    access_key: 'intelligence',
    routeData: routesData.routesType.all_discoveries
  },
  {
    label: 'Product UI/UX',
    name: INTELLIGENCE,
    access_key: 'intelligence',
    items: [
      routesData.routesType.product_intelligence,
      routesData.routesType[TOPICS],
      routesData.routesType[USER_JOURNEYS]
      //routesData.routesType.ab_tests,
    ]
  },
  {
    label: 'Marketing Promotions',
    name: MARKETING,
    access_key: 'marketing',
    items: [
      routesData.routesType[PROMOTIONS_CHARTS],
      routesData.routesType[PROMOTIONS_TABLE]
    ]
  },
  {
    label: 'Delivery Orders',
    name: DELIVERY_ORDERS,
    access_key: 'delivery_orders',
    items: [
      routesData.routesType[DELIVERY_ORDERS_OVERVIEW],
      routesData.routesType[DELIVERY_ORDERS_TABLE],
    ]
  },
  {
    label: 'Benchmarking',
    name: BENCHMARK,
    access_key: 'benchmark_compare',
    items: [
      routesData.routesType.benchmark_compare,
      routesData.routesType.benchmark_ranks
    ]
  }
]
