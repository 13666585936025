import { Modal } from 'antd'
import { AppDispatch, RootState } from 'index'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

// @ts-ignore
import bgVideo from '../../../assets/bg-video.mp4'
import expired_ico from '../../../assets/images/login/Expired.svg'
import logo from '../../../assets/images/login/logo.png'
import expirationMailSent_ico from '../../../assets/images/login/sentExpirationMail.svg'
import { InputText } from '../../../components/inputText/InputText'
import {
  changePasswordFirstLogin,
  checkNonceExpired,
  sendNewChangePWDMail
} from '../../../redux/actions/dashboardActions'
import { setUserPassSuccessReset } from '../../../redux/actions/userActions'

export const USER_CREATION_EXPIRED = 'Your user creation token has expired'

const ChangePWDOnFirstLogin: FC = (): ReactElement => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const errorChangePass = useSelector(
    (state: RootState) => state.userData?.errorChangePass
  )
  const successPassChange = useSelector(
    (state: RootState) => state.userData?.successPassChange
  )
  const tokenExpireMailSent = useSelector(
    (state: RootState) => state.userData?.tokenExpireMailSent
  )

  const [email, setEmail] = useState<string | undefined>('')
  const [nonce, setNonce] = useState<string | undefined>('')
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [userCreationExpired, setUserCreationExpired] = useState(false)

  useEffect(() => {
    if (successPassChange) {
      navigate('/')

      dispatch(setUserPassSuccessReset())
    }
  }, [successPassChange])

  useEffect(() => {
    if (errorChangePass) {
      setIsLoading(false)
      if (USER_CREATION_EXPIRED === errorChangePass) {
        setUserCreationExpired(true)
      }
      setErrorMsg(errorChangePass)
    }
  }, [errorChangePass])

  useEffect(() => {
    if (params) {
      setNonce(params.nonce)
      setEmail(params.email)

      dispatch(
        checkNonceExpired({
          email: params.email,
          nonce: params.nonce
        })
      )
    }
  }, [params])

  const handleChange = (type: string) => (event: any) => {
    const { value } = event.target
    switch (type) {
      case 'password':
        setPassword(value)
        break
      case 'confirmedPassword':
        setConfirmedPassword(value)
        break
    }
  }

  const handleCanplaythrough = (e: any) => {
    e.target.playbackRate = 0.7
  }

  const handleChangePWD = () => {
    if (password !== confirmedPassword) {
      setErrorMsg(
        "Passwords don't match! Make sure to repeat passwords correctly."
      )
      return
    }
    setIsLoading(true)
    setErrorMsg('')

    dispatch(
      changePasswordFirstLogin({
        email,
        password,
        nonce
      })
    )
  }
  const resendMailAfterExpiration = () => {
    dispatch(
      sendNewChangePWDMail({
        email
      })
    )
  }
  const header = tokenExpireMailSent ? (
    <h2
      className={'expired-text'}
      style={{ marginLeft: 0, fontSize: '2.3rem' }}
    >
      We sent you a <b>new email</b>
    </h2>
  ) : (
    <h2 className={'expired-text'}>
      Your user token has <b>expired.</b>
    </h2>
  )
  const changePWDOrExpired = userCreationExpired ? (
    <div className='side-block login-card'>
      {header}
      <span className={'email'}>{email}</span>

      {tokenExpireMailSent ? (
        <img
          className={'expired expiration-img'}
          src={expirationMailSent_ico}
          alt='expiration mail sent'
        />
      ) : (
        <div>
          <img className={'expired'} src={expired_ico} />
          <div className='btn-wrapper'>
            <button
              tabIndex={4}
              className='red-button'
              disabled={isLoading || !!tokenExpireMailSent}
              onClick={resendMailAfterExpiration}
            >
              Get New Email
            </button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className='side-block login-card'>
      <h2>Please, set a new password</h2>
      <span className='email'>{email}</span>
      <InputText
        label='Create a new password'
        placeholder='New password'
        value={password}
        onChange={handleChange('password')}
        autoFocus={true}
        password
        disabled={isLoading}
        data-testid={'password'}
      />
      <InputText
        label='Repeat password'
        placeholder='Repeat the new password'
        value={confirmedPassword}
        onChange={handleChange('confirmedPassword')}
        autoFocus={false}
        password
        disabled={isLoading}
        data-testid={'confirmedPassword'}
      />

      <div className='login__form-field login__form-field-error'>
        <span>{errorMsg}</span>
      </div>
      <div className='btn-wrapper'>
        <button
          tabIndex={4}
          className='red-button'
          disabled={
            confirmedPassword === '' ||
            confirmedPassword.length < 6 ||
            password === '' ||
            password.length < 6 ||
            isLoading ||
            userCreationExpired
          }
          onClick={handleChangePWD}
          data-testid={'confirmButton'}
        >
          Confirm
        </button>
      </div>
    </div>
  )
  return (
    <div className='login' id='login-page'>
      <Modal
        open={isModalOpen}
        onOk={() => setIsModalOpen(!isModalOpen)}
        onCancel={() => setIsModalOpen(!isModalOpen)}
      ></Modal>
      <div className='video-wrapper'>
        <video
          autoPlay
          muted
          loop
          id='bg-video'
          onCanPlayThrough={handleCanplaythrough}
        >
          <source src={bgVideo} type='video/mp4' />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <div className='video-overlay' />
      </div>
      <div className='login-wrapper'>
        <div className='side-block login-intro'>
          <img src={logo} alt='logo' />
          <h3>Welcome to</h3>
          <h1>
            Watchful <strong>Platform</strong>
          </h1>
          <p>
            The product intelligence solution trusted by{' '}
            <span className='cond-bold'>FORTUNE</span> ®{' '}
            <span className='cond-bold'>500</span> teams to stay ahead of their
            competition.{' '}
          </p>
        </div>

        {changePWDOrExpired}
      </div>
    </div>
  )
}

export default ChangePWDOnFirstLogin
