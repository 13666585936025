import './ABTestTimeLine.scss'

import moment from 'moment'
import React, { FC, ReactElement, useState } from 'react'
import { useNavigate } from 'react-router'
import Slider from 'react-slick'

import ab_test_abandoned_ico from '../../../assets/images/new_design/abtest-tl/ab-test-abandoned.svg'
import ab_test_integrated_ico from '../../../assets/images/new_design/abtest-tl/ab-test-integrated.svg'
import ab_test_ongoing_ico from '../../../assets/images/new_design/abtest-tl/ab-test-ongoing.svg'
import ab_tl_start_ico from '../../../assets/images/new_design/abtest-tl/ab-tl-start.svg'
import scroll_shveron_ico from '../../../assets/images/new_design/scroll-shveron.svg'
import { Discovery, IAppWithMetadata } from '../../../interfaces/Product'
import ViewExperimentArrowSlick from '../viewExperiment/viewExperimentArrowSlick/ViewExperimentArrowSlick'

const slickSettings = {
  speed: 500,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 4,
  adaptiveHeight: true,
  dots: false,
  variableWidth: true,

  //@ts-ignore
  nextArrow: <ViewExperimentArrowSlick direction='next' page='marketing' />,
  //@ts-ignore
  prevArrow: <ViewExperimentArrowSlick direction='prv' page='marketing' />
}

interface IABTestTimeLineStep {
  discovery: Discovery | null
  index: number
  app: IAppWithMetadata
  timeline: any
  ongoing: boolean
  integrated?: boolean
  abandoned?: boolean
  isCurrentStep?: boolean
}
interface IABTestTimeLine {
  timeline: any
  app: IAppWithMetadata
  currentDisID: number
}

const getTitleForStep = (timeline: any, index: number): string => {
  const integrated =
    index + 1 === timeline.insights.length && timeline.status === 'integrated'
  const abandoned =
    index + 1 === timeline.insights.length && timeline.status === 'abandoned'
  const ongoing =
    index === timeline.insights.length && timeline.status === 'active'

  if (index === 0) {
    return 'Start'
  }
  if (index !== 0 && !ongoing && !abandoned && !integrated) {
    return `Version ${index + 1}`
  }
  if (ongoing) {
    return 'On Going'
  }
  if (integrated) {
    return 'Integrated'
  }
  if (abandoned) {
    return 'Abandoned'
  }
  return ''
}

const ABTestTimeLineStep: FC<IABTestTimeLineStep> = ({
  discovery,
  index,
  app,
  ongoing,
  integrated,
  abandoned,
  timeline,
  isCurrentStep = false
}): ReactElement => {
  const navigate = useNavigate()

  return (
    <div
      className={`abtest-dis-wrap ${
        ongoing ? 'ongoing' : `step step-${index}`
      }  ${integrated ? 'integ' : ''} ${abandoned ? 'aband' : ''} ${
        isCurrentStep ? 'current' : ''
      }`}
    >
      {index !== 0 && (
        <div className='abtest-dis-dummy abtest-dis-left-dummy'>
          <div className='abtest-dis-line'></div>
        </div>
      )}
      <div
        className='abtest-dis'
        onClick={() =>
          discovery &&
          navigate(
            `/intelligence/versions/${app.metadata.id}/${discovery.release_id}/${discovery.id}`
          )
        }
      >
        <div className={`abtest-dis-title`}>
          {getTitleForStep(timeline, index)}

          {index === 0 && (
            <div className='abtest-dis-title-ico'>
              <img src={ab_tl_start_ico} />
            </div>
          )}
          {ongoing && (
            <div className='abtest-dis-title-ico'>
              <img src={ab_test_ongoing_ico} />
            </div>
          )}
          {integrated && (
            <div className='abtest-dis-title-ico'>
              <img src={ab_test_integrated_ico} />
            </div>
          )}
          {abandoned && (
            <div className='abtest-dis-title-ico'>
              <img src={ab_test_abandoned_ico} />
            </div>
          )}
        </div>
        <div className='abtest-dis-timeline'>
          <div className='abtest-dis-line'></div>
          {!ongoing && !abandoned && !integrated && (
            <div className={`abtest-dis-dot`}></div>
          )}
          {ongoing && <div className='abtest-dis-dot-ongoing'>{`>>`}</div>}
          {integrated && <div className='abtest-dis-dot-stopline'>{`|`}</div>}
          {abandoned && <div className='abtest-dis-dot-stopline'>{`|`}</div>}
        </div>
        <div className='abtest-dis-time'>
          {discovery && moment(discovery.start_time).format('ll')}
        </div>
      </div>
      {!ongoing && !integrated && !abandoned && (
        <div className='abtest-dis-dummy abtest-dis-right-dummy'>
          <div className='abtest-dis-line'></div>
        </div>
      )}
    </div>
  )
}

const ABTestTimeLine: FC<IABTestTimeLine> = ({
  timeline,
  app,
  currentDisID
}) => {
  const [sliderIsOpen, sliderIsOpenChange] = useState(false)
  const index = timeline.insights.findIndex(
    (ins: Discovery) => ins.id === currentDisID
  )
  const needSlider =
    timeline.insights.length + (timeline.status === 'active' ? 1 : 0) > 4

  const getStepsForTimeLine = () =>
    timeline.insights.map((discovery: Discovery, i: number) => (
      <ABTestTimeLineStep
        key={i}
        discovery={discovery}
        index={i}
        app={app}
        ongoing={false}
        integrated={
          i + 1 === timeline.insights.length && timeline.status === 'integrated'
        }
        abandoned={
          i + 1 === timeline.insights.length && timeline.status === 'abandoned'
        }
        timeline={timeline}
        isCurrentStep={i === index}
      />
    ))

  const getExtraStepSeparator = () => (
    <ABTestTimeLineStep
      key={timeline.insights.length + 1}
      discovery={null}
      index={timeline.insights.length}
      app={app}
      ongoing={true}
      timeline={timeline}
    />
  )

  return (
    <div className='abtest-timeline'>
      <div
        className='abtest-timeline-select-wrap'
        onClick={() => {
          sliderIsOpenChange(!sliderIsOpen)
        }}
      >
        Experiment tracking:
        <div
          className={`abtest-timeline-select ${
            sliderIsOpen ? 'opened' : 'closed'
          } `}
        >
          {getTitleForStep(timeline, index)}
          <img src={scroll_shveron_ico} />
        </div>
      </div>

      {sliderIsOpen &&
        (needSlider ? (
          <div className='abtest-tracking-slider'>
            {/* https://github.com/akiran/react-slick/issues/2336 */}
            {/* @ts-ignore */}
            <Slider {...slickSettings}>
              {getStepsForTimeLine()}
              {timeline.status === 'active' && getExtraStepSeparator()}
            </Slider>
          </div>
        ) : (
          <div className='abtest-tracking-non-slider'>
            {getStepsForTimeLine()}
            {timeline.status === 'active' && (
              <div className='sep-timeline'>
                <div className='abtest-dis-line'></div>
              </div>
            )}
            {timeline.status === 'active' && getExtraStepSeparator()}
          </div>
        ))}
    </div>
  )
}

export default ABTestTimeLine
