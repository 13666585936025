// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite-page .logo_wrapper {
  width: 29rem;
  padding: 2.5rem 4.2rem;
  box-sizing: border-box;
  display: flex;
  margin: 4rem auto 6.8rem;
}
.invite-page .logo_wrapper a {
  width: 21rem;
}
.invite-page .title-h1 {
  color: #1a1515;
  font-family: "Roboto Slab";
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0.19px;
  line-height: 66px;
  text-align: center;
  margin-bottom: 4.8rem;
}
.invite-page .title-h3 {
  color: #1a1515;
  font-family: Roboto;
  font-size: 2.3rem;
  letter-spacing: 0.09px;
  line-height: 3rem;
  text-align: center;
  width: 56rem;
  margin: auto;
  margin-bottom: 5.5rem;
}
.invite-page .descr {
  opacity: 0.5;
  color: #1a1515;
  font-family: Roboto;
  font-size: 1.5rem;
  letter-spacing: 0.06px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/containers/AcceptingInvite/styles.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,sBAAA;EACA,sBAAA;EACA,aAAA;EACA,wBAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAIE;EACE,cAAA;EACA,0BAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,qBAAA;AAFJ;AAKE;EACE,cAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,qBAAA;AAHJ;AAME;EACE,YAAA;EACA,cAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AAJJ","sourcesContent":[".invite-page {\n  .logo_wrapper {\n    width: 29rem;\n    padding: 2.5rem 4.2rem;\n    box-sizing: border-box;\n    display: flex;\n    margin: 4rem auto 6.8rem;\n  }\n\n  .logo_wrapper a {\n    width: 21rem;\n  }\n\n  .title-h1 {\n    color: #1a1515;\n    font-family: \"Roboto Slab\";\n    font-size: 50px;\n    font-weight: bold;\n    letter-spacing: 0.19px;\n    line-height: 66px;\n    text-align: center;\n    margin-bottom: 4.8rem;\n  }\n\n  .title-h3 {\n    color: #1a1515;\n    font-family: Roboto;\n    font-size: 2.3rem;\n    letter-spacing: 0.09px;\n    line-height: 3rem;\n    text-align: center;\n    width: 56rem;\n    margin: auto;\n    margin-bottom: 5.5rem;\n  }\n\n  .descr {\n    opacity: 0.5;\n    color: #1a1515;\n    font-family: Roboto;\n    font-size: 1.5rem;\n    letter-spacing: 0.06px;\n    line-height: 30px;\n    text-align: center;\n    margin-bottom: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
