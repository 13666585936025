import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message, Modal, Popover, Tooltip, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import config from '../../../shared/config'
import { compareStrings } from '../../../services/stringsService'
import {
  marketingOnlyType,
  siteVersions,
  typeInfo,
  intelDiscoveryTypeDictionary
} from '../../../shared/consts'
import ViewCopyButton from '../viewCopyButton/viewCopyButton'
import SeeAllABTests from '../../../components/SeeAllABTestsBTN/SeeAllABTests'
import {
  getDiscoveriesStartTime,
  PRIORITY_TYPES
} from '../../../helpers/generalHelpers'
import storageService from '../../../services/storageService'
import SubscriptionModal from '../../../containers/subscriptions/subscriptionsModal/SubscriptionModal'
import dis_error_img_ico from '../../../assets/images/new_design/dis-error-img.svg'
import { setSharedInsightData } from '../../../redux/actions/dashboardActions'
import { getUserLicence } from '../../../helpers/usersHelper'
import { createSubscription } from '../../../redux/acts/subscriptions'
import { getDiscoveriesLabels } from '../../../redux/acts/labels'
import { setDiscoveryRate } from '../../../redux/actions/productActions'
import ab_test_ongoing_ico from '../../../assets/images/new_design/abtest-tl/ab-test-ongoing.svg'
import ab_test_integrated_ico from '../../../assets/images/new_design/abtest-tl/ab-test-integrated.svg'
import ab_test_abandoned_ico from '../../../assets/images/new_design/abtest-tl/ab-test-abandoned.svg'
import rate_star_ico from '../../../assets/images/new_design/rate-dis-star.svg'
import rate_star_fill_ico from '../../../assets/images/new_design/rate-dis-star-fill.svg'
import rate_count_person_ico from '../../../assets/images/new_design/rate-person.svg'
import { insightURL } from '../../../helpers/appsHelper'
import { countries } from '../../../shared/countries'
import wwf from '../../../assets/images/world-wide-flag.svg'
import Info from '../../../assets/images/icons/info.svg'
import MoreOptions from './MoreOptions'

const typeDict = {
  inapp_campaign: 'In-App',
  email_campaign: 'Email',
  push_notification_campaign: 'Push Notification'
}

const ABTestStatusIco = {
  active: { ico: ab_test_ongoing_ico, label: 'Active' },
  integrated: { ico: ab_test_integrated_ico, label: 'Integrated' },
  abandoned: { ico: ab_test_abandoned_ico, label: 'Abandoned' },
  abandoned_no_data: {
    ico: ab_test_abandoned_ico,
    label: 'Abandoned (no data)'
  }
}

export function ViewHeader ({
  data: { id, release_name, priority },
  data,
  app: { metadata, platform },
  demoUser,
  analytics,
  siteVersion,
  email,
  isMarketingDiscovery,
  marketingPrevNextList,
  isSneakPeak,
  ABTestData,
  product,
  source,
  version,
  disRatingIsEnable
}) {
  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()

  const userData = useSelector(state => state.userData)
  const products = useSelector(state => state.overview.products)
  const tagsList = useSelector(state => state.labels.tagsList)
  const userDiscoveries = useSelector(
    state => state.userDiscoveries.discoveries
  )

  const [title, setTitle] = useState('')
  const [type, setType] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false)
  const [hoveringOver, setHoveringOver] = useState(null)

  useEffect(() => {
    if (isSneakPeak) {
      return
    }

    let mpBody = {
      insight_name: title,
      insight_type: type,
      app_name: metadata.app_name,
      platform: metadata.platform,
      source,
      insight_url: insightURL({
        isMarketing: Object.keys(marketingOnlyType).includes(type),
        insightID: id,
        versionID: data.current_release_id,
        appID: metadata.id
      })
    }

    if (!tagsList && !demoUser) {
      dispatch(getDiscoveriesLabels())
    }
    if (source === 'shared_link') {
      if (compareStrings(siteVersion, siteVersions.FULL) && userData) {
        mpBody.user = userData.name
        analytics.analytic(`Validated guest opened direct link`, mpBody)
      } else {
        mpBody.generated_by = demoUser.email
        analytics.analytic(`Non-validated guest opened direct link`, mpBody)
      }
    } else if (source !== 'sneak_peak') {
      analytics.analytic(
        `View insight (${
          metadata.platform === 'Marketing' ? 'Marketing' : 'Intel'
        })`,
        mpBody
      )
    } else if (source === 'sneak_peak') {
      analytics.analytic(
        `Expanded insight preview to full (${
          metadata.platform === 'Marketing' ? 'Marketing' : 'Intel'
        })`,
        mpBody
      )
    }

    dispatch(setSharedInsightData(mpBody))
  }, [])

  useEffect(() => {
    if (data) {
      setTitle(data.title)
      setStartDate(getStartDate(data))
      setEndDate(data.endDate ? getEndDate(data) : null)

      setType(data.type)
      setHoveringOver(
        userDiscoveries.find(uD => uD.insight_id === data.id)?.rating
      )
    }
  }, [data])

  const getStartDate = data => {
    const text = getDiscoveriesStartTime(data),
      header = isMarketingDiscovery ? 'Start Date' : 'Date'

    return { text, header }
  }

  const getEndDate = data => {
    const {
      type,
      ended_date,
      content: { end_time }
    } = data
    let header = 'Date'
    let endDate = null
    if (compareStrings(type, 'ab_test') && ended_date) {
      endDate = moment(ended_date).format('ll')
    }
    if (compareStrings(type, 'ab_test') && end_time) {
      header = 'End Date:'
      endDate = moment(end_time).format('ll')
    }
    if (compareStrings(type, 'hashflag')) {
      let { endTimestamp } = data
      endTimestamp = moment(endTimestamp).format('ll')
      endDate = endTimestamp
    }
    return { text: endDate, header }
  }

  const downloadInsight = async (format, abTestStepDiff, abTestStatus) => {
    const insight = data
    const storage = new storageService()
    const token = storage.getItem('usertoken')
    const hideLoader = message.loading({
      content: `Rendering and downloading ${format}...`,
      duration: 0,
      icon: (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                color: 'white'
              }}
              spin
            />
          }
        />
      )
    })

    const res = await fetch(
      `${config.dataApi.backendUrl}/insight/${data.id}/${format}/${abTestStatus}/${abTestStepDiff}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    analytics.analytic(`Download insight`, {
      name: insight.title,
      insight_type: insight.type,
      format,
      platform: metadata.platform,
      app: metadata.name,
      insight_url: insightURL({
        isMarketing: Object.keys(marketingOnlyType).includes(insight.type),
        appID: metadata.id,
        versionID: insight.release_id,
        insightID: insight.id
      })
    })
    if (res.ok) {
      const link = document.createElement('a')
      let blobUrl = ''
      if (format !== 'pdf') {
        const blob = await res.blob()
        const newBlob = new Blob([blob])

        blobUrl = window.URL.createObjectURL(newBlob)
      } else {
        const body = await res.json()
        blobUrl = body.url
      }

      link.href = blobUrl
      link.setAttribute('download', `${title}.${format}`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      const modal = Modal.info({
        title: <h2>There was an error downloading your file</h2>,
        className: 'discovery-error-loading',
        centered: true,
        width: 370,
        content: (
          <div>
            <p>Please try again later</p>
            <div className='error-download-img'>
              <img src={dis_error_img_ico} />
            </div>
          </div>
        ),
        onOk () {
          modal.destroy()
        },
        okText: 'Close',
        okType: 'default'
      })
    }
    setTimeout(hideLoader, 2500)
  }

  const fullVersion = compareStrings(siteVersion, siteVersions.FULL)
  let currentVersion, currentVersionInsights

  if (version) {
    currentVersion = version

    currentVersionInsights = [
      ...currentVersion.ab_tests,
      ...currentVersion.indications,
      ...currentVersion.new_features,
      ...currentVersion.screen_changes,
      ...currentVersion.removed_features
    ]
  } else if (isMarketingDiscovery && !isSneakPeak) {
    currentVersionInsights = marketingPrevNextList.list
  }

  let abTestStepDiff = ''

  if (ABTestData) {
    let index = ABTestData.insights.findIndex(ins => ins.id === id)
    if (index === -1) {
      abTestStepDiff = moment().diff(moment(data.start_time), 'days') + ' days'
    } else if (ABTestData.status === 'active') {
      abTestStepDiff =
        moment().diff(moment(ABTestData.insights[0].start_time), 'days') +
        ' days'
    } else if (
      ABTestData.status === 'integrated' &&
      ABTestData.insights[0].start_time ===
        ABTestData.insights[ABTestData.insights.length - 1].start_time
    ) {
      // if test is marked as integrated but the first and the last start_time are the same ->
      // analyst marked the test as integrated on the same day which is an analyst mistake and we show it as N/A
      abTestStepDiff = 'N/A'
    } else if (
      ABTestData.insights.filter(
        ins => ins.type === 'ab_test' || ins.type === 'indication'
      )
    ) {
      abTestStepDiff =
        moment(
          ABTestData.insights[ABTestData.insights.length - 1].start_time
        ).diff(
          moment(
            ABTestData.insights.filter(
              ins => ins.type === 'ab_test' || ins.type === 'indication'
            )[0].start_time
          ),
          'days'
        ) + ' days'
    }
  }

  const handleChangeUserRating = rating => {
    analytics.analytic('Rate an insight', {
      rating,
      insight_name: title,
      insight_type: data.type,
      platform: data.platform,
      app: data.app_name
    })

    dispatch(
      setDiscoveryRate({
        insight_id: data.id,
        rate: rating
      })
    )
  }

  const getRateLayout = () => {
    const data = data

    // const rt_gb =
    //   userDiscoveries.find(uD => uD.insight_id === data.id)?.rating_global ||
    //   data.rating_global ||
    //   0
    const rt_usr =
      userDiscoveries.find(uD => uD.insight_id === data.id)?.rating || 0
    const rt_cnt =
      userDiscoveries.find(uD => uD.insight_id === data.id)?.rating_count ||
      data.rating_count ||
      0

    const stars = (
      <span
        className='view__header-item-data rating'
        onMouseLeave={() => {
          setHoveringOver(rt_usr)
        }}
      >
        {[1, 2, 3, 4, 5].map(index => (
          <img
            src={hoveringOver >= index ? rate_star_fill_ico : rate_star_ico}
            onMouseEnter={() => {
              setHoveringOver(index)
            }}
            onClick={() => handleChangeUserRating(index)}
            key={index}
          />
        ))}
        <span className='rating-count'>{rt_cnt}</span>
        <img src={rate_count_person_ico} />
      </span>
    )

    return (
      <div className='view__header-item'>
        <span className='view__header-item-header'>Was it meaningful?</span>
        {email && rt_usr ? (
          <Popover
            placement='bottom'
            overlayClassName='user-rate-modal'
            content={
              <div>
                <h2>Your rate</h2>
                <div className='user-rating'>
                  {[1, 2, 3, 4, 5].map(index => (
                    <img
                      key={index}
                      src={rt_usr < index ? rate_star_ico : rate_star_fill_ico}
                    />
                  ))}
                </div>
              </div>
            }
          >
            {stars}
          </Popover>
        ) : (
          stars
        )}
      </div>
    )
  }

  const getPersonaLocations = () => {
    const allCountries = [
      {
        name: 'World Wide',
        code: 'WW'
      },
      ...countries
    ]
    return (
      <div className='view__header-item'>
        <span className='view__header-item-header'>Seen in geo</span>
        <div className='countries'>
          {[...new Set(data.persona_locations)].map((loc, i) => (
            <Tooltip
              title={`${
                allCountries.find(c => c.code === loc.toUpperCase()).name
              }`}
              placement={'top'}
              key={i}
            >
              <div className='country'>
                {loc === 'ww' ? (
                  <img src={wwf} />
                ) : (
                  <span
                    className={`flag-icon fi fi-${loc.toLowerCase()}`}
                  />
                )}
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    )
  }

  const priorityUI = priority && (
    <div className='view__header-item'>
      <span className='view__header-item-header'>Priority</span>
      <span className={`view__header-item-data`}>
        <span className='view__header-item-data'>
          {PRIORITY_TYPES[priority]}
        </span>
      </span>
    </div>
  )
  const foundPlatform = metadata?.platform || platform

  // This assumes your real component includes more functionalities that we might not cover here.
  return (
    <div
      className={`view__header ${!fullVersion ? 'view__header-demo' : ''} ${
        isSneakPeak ? 'sneak-peak' : ''
      }`}
    >
      <div className='view__header__primary'>
        <h3
          className={`view__header-title ${
            fullVersion ? 'view__header-title-full' : ''
          }`}
        >
          {title}
        </h3>

        {fullVersion && !isSneakPeak && (
          <div className='view__header__buttons'>
            <ViewCopyButton
              insightId={id}
              metadata={metadata}
              analytics={analytics}
              title={title}
              id={id}
              page='overview'
              insightType={data.type}
            />

            <MoreOptions
              downloadCallback={val =>
                downloadInsight(
                  val,
                  abTestStepDiff ? abTestStepDiff : 'None',
                  ABTestData ? ABTestStatusIco[ABTestData.status].label : 'None'
                )
              }
              insightID={id}
              app={metadata}
              discovery={data}
              defaultEmail={email}
              currentInsightURL={location.pathname}
              metadata={metadata}
            />
          </div>
        )}
        {fullVersion &&
          !isSneakPeak &&
          !params.type &&
          currentVersionInsights &&
          currentVersionInsights.length > 0 && (
            <div className='view__header__btns'>
              <div className='view__header__btns-create-see'>
                {['ab_test', 'indication'].includes(data.type) && product && (
                  <SeeAllABTests
                    link={`/product_intelligence/ab-tests/?daysAgo=60&types=${
                      data.type === 'ab_test' ? 'ab_test' : 'indication'
                    }&platforms=&activeTab=1&product_ids=${product.id}`}
                    text={
                      data.type === 'ab_test'
                        ? 'See All Experiments'
                        : 'See All Indications'
                    }
                    user={{ email }}
                  />
                )}
              </div>
            </div>
          )}
      </div>

      <div className='view__header-details'>
        {isMarketingDiscovery ? (
          <div className={`view__header-row ${isSneakPeak ? 'wide' : ''}`}>
            <div className='view__header-item'>
              <span className='view__header-item-header'>Type</span>
              <span className='view__header-item-data'>
                {typeDict[data.type]}
              </span>
            </div>

            <div className='view__header-item'>
              <span className='view__header-item-header'>Discovery Date</span>
              <span className='view__header-item-data'>
                {getDiscoveriesStartTime(data)}
              </span>
            </div>
            {disRatingIsEnable && getRateLayout()}
            {priorityUI}
            {data.persona_locations &&
              data.persona_locations.length > 0 &&
              getPersonaLocations()}
          </div>
        ) : (
          <div className={`view__header-row ${isSneakPeak ? 'wide' : ''}`}>
            {!isMarketingDiscovery && (
              <div className='view__header-item'>
                <span className='view__header-item-header'>
                  Type
                  <Popover
                    content={typeInfo[data.type]}
                    overlayStyle={{ maxWidth: 500 }}
                    overlayClassName='antd-popover-black'
                  >
                    <img alt='type-info' src={Info} />
                  </Popover>
                </span>
                <span className='view__header-item-data'>
                  {intelDiscoveryTypeDictionary[data.type]}
                </span>
              </div>
            )}

            {startDate && (
              <div className='view__header-item'>
                <span className='view__header-item-header'>
                  {startDate.header}
                </span>
                <span className='view__header-item-data'>{startDate.text}</span>
              </div>
            )}
            {endDate && endDate.text && (
              <div className='view__header-item'>
                <span className='view__header-item-header'>
                  {endDate.header}
                </span>
                <span className='view__header-item-data'>{endDate.text}</span>
              </div>
            )}
            {ABTestData && (
              <div className='view__header-item'>
                <span className='view__header-item-header'>
                  Experiment duration
                </span>
                <span className='view__header-item-data  abtest-roles'>
                  {abTestStepDiff}
                </span>
              </div>
            )}
            {ABTestData && (
              <div className='view__header-item'>
                <span className='view__header-item-header'>
                  Experiment tracking
                </span>
                <span
                  className={`view__header-item-data  abtest-roles-status ${ABTestData.status}`}
                >
                  <div className='abtest-dis-title-ico'>
                    <img src={ABTestStatusIco[ABTestData.status].ico} />{' '}
                    {ABTestStatusIco[ABTestData.status].label}{' '}
                  </div>
                </span>
              </div>
            )}
            {release_name && foundPlatform !== 'Web' && (
              <div className='view__header-item'>
                <span className='view__header-item-header'>Release</span>
                <span className='view__header-item-data'>{release_name}</span>
              </div>
            )}
            {disRatingIsEnable && getRateLayout()}
            {priorityUI}
            {data.persona_locations &&
              data.persona_locations.length > 0 &&
              getPersonaLocations()}
          </div>
        )}

        {!isSneakPeak && isSubscriptionOpen && (
          <SubscriptionModal
            analytics={analytics}
            tagsList={[{ id: -1, name: 'critical insight' }, ...tagsList]}
            products={products}
            licences={getUserLicence(userData)}
            windowIsOpen={isSubscriptionOpen}
            closeWindow={() => {
              setIsSubscriptionOpen(false)
            }}
            subscription={{
              name: '',
              labels: [],
              tag_filter: 'any',
              products: products.filter(prod => metadata.product_id === prod.id)
            }}
            createSubscription={() => dispatch(createSubscription())}
          />
        )}
      </div>
    </div>
  )
}

export default ViewHeader
