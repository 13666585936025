// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-btn:not([disabled]).btn-create-subscription {
  height: 3.2rem;
  border: 1px solid rgba(121, 121, 121, 0.6784313725) !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
.ant-btn:not([disabled]).btn-create-subscription img {
  width: 1.2rem;
  margin-right: 1rem;
}

.ant-btn:hover:not([disabled]).btn-create-subscription {
  background-color: white !important;
  border: 1px solid #000;
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/containers/subscriptions/subscriptionButton/subscriptionButton.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,8DAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,kBAAA;AAEJ;;AAEA;EACE,kCAAA;EACA,sBAAA;EACA,uBAAA;AACF","sourcesContent":[".ant-btn:not([disabled]).btn-create-subscription {\n  height: 3.2rem;\n  border: 1px solid #797979ad !important;\n  box-sizing: border-box;\n  border-radius: 4px;\n  font-size: 1.2rem;\n  display: flex;\n  align-items: center;\n  img {\n    width: 1.2rem;\n    margin-right: 1rem;\n  }\n}\n\n.ant-btn:hover:not([disabled]).btn-create-subscription {\n  background-color: white !important;\n  border: 1px solid #000;\n  color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
