import React from 'react'
import Linkify from 'react-linkify'

const ViewOverview = ({ longText }) => {
  if (!longText) return <React.Fragment />

  return (
    <div className='view__overview'>
      <Linkify tagName='p' className='view__overview-text'>
        {longText.split('\\n').join('\n')}
      </Linkify>
    </div>
  )
}

export default ViewOverview
