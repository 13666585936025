import React from 'react'
import { connect } from 'react-redux'
import { getPerformanceEventsData } from '../../../redux/actions/performanceActions'
import BenchmarkCompareView from './BenchmarkCompareView'

export const BenchmarkCompare = ({
  performanceApps,
  eventsData,
  perfDeviceModels,
  perfInfrastructure,
  isFetchingPerfData,

  getPerformanceEventsData,
  analytics,
  user
}) => {
  const filteredApps = performanceApps.filter(
    a => a.performance_events.length > 0 && a.device_models.length > 0
  )
  filteredApps.sort((a1, a2) =>
    a1.name.toLowerCase() < a2.name.toLowerCase() ? -1 : 1
  )

  return (
    <div className='benchmark-compare-page'>
      <BenchmarkCompareView
        performanceApps={filteredApps}
        eventsData={eventsData}
        perfDeviceModels={perfDeviceModels}
        perfInfrastructure={perfInfrastructure}
        isFetchingPerfData={isFetchingPerfData}
        getPerformancePageData={getPerformanceEventsData}
        analytics={analytics}
        user={user}
      />
    </div>
  )
}

const mapStateToProps = ({ performance, appData, userData }) => ({
  performanceApps: performance.apps,
  perfDeviceModels: performance.deviceModels,
  perfInfrastructure: performance.infrastructure,
  isFetchingPerfData: performance.isFetchingPerformancePageData,
  user: appData.user,
  userData: userData,
  eventsData: performance.currentEvents,
  analytics: appData.user.analytics
})

const mapDispatchToProps = {
  getPerformanceEventsData
}

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkCompare)
