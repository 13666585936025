import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router'

import VersionsHeader from './versionsHeader/VersionsHeader'
import VersionsSlider from './versionsSlider/VersionsSlider'
import VersionsList from './versionsList/VersionsList'
import VersionsEmpty from './versionsEmpty/versionsEmpty'
import ProductsSmallList from '../products/ProductsSmallList'

import SeeAllABTests from '../../components/SeeAllABTestsBTN/SeeAllABTests'

import {
  pagesTypes,
  Consts,
  RESTRICTED_DOORDASH_EMAILS
} from '../../shared/consts'
import {
  PL_WEB,
  PL_ANDROID,
  PL_IOS,
  PL_MARKETING
} from '../../shared/consts.js'

import { escapeJSONString } from '../../helpers/generalHelpers'
import OverviewAnalyzedVersions from '../intelligence/Overview/OverviewAnalyzedVersions'
import AppDrawer from '../../common/appDrawer/AppDrawer'

import NewLoader from '../../common/NewLoader/NewLoader'

import graph_ico from '../../assets/images/new_design/icons/graph_ico.svg'
import { ClickableVersionTags } from '../../components/discoveryView/viewTags/ViewTags'

import FilterPlatform from './FilterPlatform'
import { Tabs, Select, Popover, Button } from 'antd'
import moment from 'moment'

import android_logo from '../../assets/images/icons/playstore_icon.png'
import ios_logo from '../../assets/images/icons/appstore_icon.png'
import web_logo from '../../assets/images/icons/web_logo.svg'

import './IntelligenceProduct.scss'

const { TabPane } = Tabs

const SLIDER_ITEMS = {
  desktop: 6,
  tab_port: 3,
  phone: 2
}

const versionType = [
  pagesTypes.AB_TESTS,
  pagesTypes.INDICATIONS,
  pagesTypes.NEW_FEATURES,
  pagesTypes.SCREEN_CHANGES,
  pagesTypes.FEATURE_REMOVED
]

const GROUPBY = {
  DATE: 'date',
  RELEASE: 'release'
}

// const PLATFORMLIST = [
//   PL_WEB,
//   PL_ANDROID,
//   PL_IOS,
//   PL_WINDOWS,
//   PL_CHROME,
//   PL_MARKETING
// ]

const BASE_URL_PAGE = '/product_intelligence'

export const IntelligenceProductView = ({
  analytics,
  user,
  products,
  unSeenCriticalInsights,

  relevantInsights,

  allInsights,
  getGraphsByApp,
  appsWithGraphs,
  product,
  productCommonData,
  product_id,
  appGraphAccess,
  userDiscoveries
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const initValues = new URLSearchParams(location.search)

  const [app, appChange] = useState(null)

  const [releasePlatformID, releasePlatformIDChange] = useState(
    initValues.get('releasePlatformID')
      ? Number(initValues.get('releasePlatformID'))
      : ''
  )
  const [datePlatformIDS, datePlatformIDSChange] = useState(
    initValues.get('datePlatformIDS')
      ? initValues
          .get('datePlatformIDS')
          .split(',')
          .map(id => Number(id))
      : []
  )
  const [productCalcData, productCalcDataChange] = useState({})

  const [versionID, versionIDChange] = useState(
    initValues.get('versionID') ? Number(initValues.get('versionID')) : ''
  )
  const [version, versionChange] = useState(null)

  const [dataIsReady, dataIsReadyChange] = useState(false)
  const [selectedTags, selectedTagsChange] = useState([])

  const [groupBy, groupByChange] = useState(
    initValues.get('groupBy') === GROUPBY.RELEASE
      ? GROUPBY.RELEASE
      : GROUPBY.DATE
  )
  const [activeTabGroupDate, activeTabGroupDateChange] = useState(
    initValues.get('activeTab')
  )

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    if (product && Object.keys(product).length > 0) {
      let versions = Object.values(product)
        .map(app => app.versions)
        .flat()
      let filteredVersions = versions.filter(ver => ver.is_visible)

      let allDiscoveriesProduct
      let filteredDiscoveriesProduct
      let last14d
      let last30d
      let last60d

      allDiscoveriesProduct = filteredVersions
        .map(version => [
          ...version.ab_tests.map(d => ({
            ...d,
            app_id: version.app_id,
            version_id: version.id
          })),
          ...version.screen_changes.map(d => ({
            ...d,
            app_id: version.app_id,
            version_id: version.id
          })),
          ...version.new_features.map(d => ({
            ...d,
            app_id: version.app_id,
            version_id: version.id
          })),
          ...version.indications.map(d => ({
            ...d,
            app_id: version.app_id,
            version_id: version.id
          })),
          ...version.removed_features.map(d => ({
            ...d,
            app_id: version.app_id,
            version_id: version.id
          }))
        ])
        .flat()

      if (datePlatformIDS.length > 0) {
        filteredDiscoveriesProduct = allDiscoveriesProduct.filter(dis =>
          datePlatformIDS.includes(dis.app_id)
        )
      } else {
        filteredDiscoveriesProduct = [...allDiscoveriesProduct]
      }

      last14d = filteredDiscoveriesProduct.filter(d =>
        moment(d.start_time).isAfter(moment().subtract(14, 'days'))
      )
      last30d = filteredDiscoveriesProduct.filter(d =>
        moment(d.start_time).isAfter(moment().subtract(30, 'days'))
      )
      last60d = filteredDiscoveriesProduct.filter(d =>
        moment(d.start_time).isAfter(moment().subtract(60, 'days'))
      )
      if (!activeTabGroupDate) {
        if (last14d.length > 0) {
          activeTabGroupDateChange('1')
        } else if (last30d.length > 0) {
          activeTabGroupDateChange('2')
        } else if (last60d.length > 0) {
          activeTabGroupDateChange('3')
        } else {
          activeTabGroupDateChange('4')
        }
      }

      let sortedVersions = versions.sort((v1, v2) =>
        moment(v1.release_date).isAfter(v2.release_date) ? -1 : 1
      )

      let lastVersion = sortedVersions[0]
      let trackedSince = moment(
        sortedVersions[sortedVersions.length - 1].release_date
      ).format('LL')
      let countCriticalInsights = relevantInsights
        ? relevantInsights.filter(dis => product[dis.app_id]).length
        : 0

      productCalcDataChange({
        ...productCalcData,
        allDiscoveriesProduct,
        filteredDiscoveriesProduct,
        last14d,
        last30d,
        last60d,
        lastVersion,
        trackedSince,
        countCriticalInsights,
        productVersions: filteredVersions,
        firstReleasePlatform: product[lastVersion.app_id].metadata.platform
      })
      let curApp = releasePlatformID
        ? product[releasePlatformID]
        : Object.values(product)[0]
      let curAppVersions = curApp.versions.filter(v => v.is_visible)
      appChange({
        ...curApp,
        versions: curAppVersions
      })
      if (versionID) {
        let ver = versions.find(v => v.id === versionID)
        versionChange(ver)
      } else {
        versionChange(curAppVersions[0])
        versionIDChange(curAppVersions[0] ? curAppVersions.id : '')
      }

      if (
        releasePlatformID &&
        !appsWithGraphs[releasePlatformID] &&
        appGraphAccess &&
        groupBy === GROUPBY.RELEASE
      ) {
        dispatch(getGraphsByApp(releasePlatformID))
      }
      dataIsReadyChange(true)
    }
  }, [product, releasePlatformID, datePlatformIDS])

  useEffect(() => {
    if (product_id) {
      navigate(
        `${BASE_URL_PAGE}/${product_id}/?groupBy=${groupBy}&activeTab=${activeTabGroupDate}&releasePlatformID=${releasePlatformID}&versionID=${versionID}&datePlatformIDS=${datePlatformIDS}`,
        { replace: true }
      )
    }
  }, [
    groupBy,
    activeTabGroupDate,
    releasePlatformID,
    versionID,
    datePlatformIDS
  ])

  // useEffect(() => {
  //   if (product) {
  //     let versions = Object.values(product)
  //       .map(app => app.versions)
  //       .flat()
  //     let filteredVersions = versions.filter(ver => ver.is_visible)

  //     let allDiscoveriesProduct = filteredVersions
  //       .map(version => [
  //         ...version.ab_tests.map(d => ({
  //           ...d,
  //           app_id: version.app_id,
  //           version_id: version.id
  //         })),
  //         ...version.screen_changes.map(d => ({
  //           ...d,
  //           app_id: version.app_id,
  //           version_id: version.id
  //         })),
  //         ...version.new_features.map(d => ({
  //           ...d,
  //           app_id: version.app_id,
  //           version_id: version.id
  //         })),
  //         ...version.indications.map(d => ({
  //           ...d,
  //           app_id: version.app_id,
  //           version_id: version.id
  //         })),
  //         ...version.removed_features.map(d => ({
  //           ...d,
  //           app_id: version.app_id,
  //           version_id: version.id
  //         }))
  //       ])
  //       .flat()
  //   }
  // }, [product])

  const handleChangeGroupBy = type => {
    groupByChange(type)
    if (type === GROUPBY.RELEASE) {
      const releasePlatformID = Number(
        initValues.get('releasePlatformID') || Object.keys(product)[0]
      )
      releasePlatformIDChange(releasePlatformID)
      let app = product[releasePlatformID]
      let versions = app.versions.filter(v => v.is_visible)
      appChange({
        ...app,
        versions: app.versions.filter(v => v.is_visible)
      })
      let lastVersion = versions[0]
      versionIDChange(lastVersion.id)
      versionChange(lastVersion)
    }
  }

  const handlePlatformIDChange = releasePlatformID => {
    releasePlatformIDChange(releasePlatformID)
    if (groupBy === GROUPBY.RELEASE) {
      let versions = product[releasePlatformID].versions.filter(
        v => v.is_visible
      )
      appChange({
        ...product[releasePlatformID],
        versions
      })
      let lastVersion = versions[0]
      if (lastVersion) {
        versionIDChange(lastVersion.id)
        versionChange(lastVersion)
      } else {
        versionIDChange('')
        versionChange(null)
      }
    }
  }

  const productChangeHandler = product => {
    dataIsReadyChange(false)
    activeTabGroupDateChange(null)
    groupByChange(GROUPBY.DATE)
    releasePlatformIDChange('')
    datePlatformIDSChange([])
    productCalcDataChange({})
    appChange(null)
    versionChange(null)
    versionIDChange('')
    selectedTagsChange([])
    navigate(
      `${BASE_URL_PAGE}/${product.id}/?groupBy=&activeTab=&releasePlatformID=`,
      { replace: true }
    )
  }

  const sliderItemClicked = slide => {
    let { index, versionID } = slide
    let ver = productCalcData.productVersions.find(
      v => v.id == Number(versionID)
    )
    versionIDChange(versionID)
    versionChange(ver)
    analyticsAction(index)
  }

  const analyticsAction = index => {
    if (!app) {
      return
    }
    const version = productCalcData.productVersions[index]
    if (version) {
      const analyticText = `${app.metadata.id} - Choose Version: ${version.release_name} (${version.version_code})`
      analytics.analytic(analyticText)
    }
  }

  const handleWindowSizeChange = () => {
    const windowSize = window.innerWidth
    slidesPerRow =
      windowSize <= Consts.tabPortSize
        ? SLIDER_ITEMS.tab_port
        : SLIDER_ITEMS.desktop
    slidesPerRow =
      windowSize <= Consts.mobileSize ? SLIDER_ITEMS.phone : slidesPerRow
  }

  const checkIfVersionIsEmpty = version => {
    let isEmpty = true
    versionType.forEach(type => {
      if (version[type].length !== 0) {
        isEmpty = false
      }
    })
    return isEmpty
  }

  const getLayoutForTab = arrOfDis => {
    let versionInsightTagsMap = {}
    let inProgress = false
    arrOfDis.forEach(insight => {
      if (insight && insight.labels) {
        insight.labels.forEach(label => {
          if (!versionInsightTagsMap[label]) {
            versionInsightTagsMap[label] = 0
          }
          versionInsightTagsMap[label] += 1
        })
      }
      if (
        productCalcData.productVersions.find(
          v => v.id === insight.version_id
        ) &&
        productCalcData.productVersions.find(v => v.id === insight.version_id)
          .type === 'analysis_in_progress'
      ) {
        inProgress = true
      }
    })

    return (
      <div>
        <ClickableVersionTags
          tags={versionInsightTagsMap}
          selectedTags={selectedTags}
          tagSelectionHandler={tagSelectionHandler}
        />
        {inProgress && (
          <div className='in-progress-block'>
            <h2>IN PROGRESS</h2>
            <div className='descr'>
              We may find additional discoveries for one of this app’s releases.
              Stay tuned
            </div>
          </div>
        )}
        {versionType.map(name => (
          <VersionsList
            data={arrOfDis.filter(d => name.includes(d.type))}
            type={name}
            key={name}
            selectedTags={selectedTags}
            app={Object.values(product)[0].metadata}
            apps={Object.values(product).map(a => a.metadata)}
            analytics={analytics}
            user={user}
            allInsights={allInsights}
            userDiscoveries={userDiscoveries}
            source={`intel_page_by_${groupBy}`}
          />
        ))}
      </div>
    )
  }

  const tagSelectionHandler = tag => {
    if (selectedTags.includes(tag)) {
      // remove the tag from the selected tags list
      selectedTagsChange(selectedTags.filter(listTag => listTag !== tag))
    } else {
      // add the tag to the selected tags list
      selectedTagsChange(selectedTags.concat(tag))
    }
  }

  const handleChangeTab = key => {
    activeTabGroupDateChange(key)
    navigate(`${location.pathname}?tab=${key}`, { replace: true })
  }

  let versionInsightTagsMap = {}
  let versionInsights
  let slidesPerRow

  if (
    app &&
    productCalcData.productVersions &&
    productCalcData.productVersions.length > 0 &&
    groupBy === 'release' &&
    dataIsReady
  ) {
    if (version) {
      versionInsights = [
        ...version.ab_tests,
        ...version.screen_changes,
        ...version.new_features,
        ...version.indications,
        ...version.removed_features
      ]
      versionInsights.forEach(insight => {
        if (insight && insight.labels) {
          insight.labels.forEach(label => {
            if (!versionInsightTagsMap[label]) {
              versionInsightTagsMap[label] = 0
            }
            versionInsightTagsMap[label] += 1
          })
        }
      })
    }
  }

  const windowSize = window.innerWidth
  slidesPerRow =
    windowSize <= Consts.tabPortSize
      ? SLIDER_ITEMS.tab_port
      : SLIDER_ITEMS.desktop
  slidesPerRow =
    windowSize <= Consts.mobileSize ? SLIDER_ITEMS.phone : slidesPerRow

  if (!product_id) {
    let relevantInsightsByApps = {}

    if (relevantInsights) {
      relevantInsights.forEach(relIns => {
        if (!relevantInsightsByApps[relIns.metadata.id]) {
          relevantInsightsByApps[relIns.metadata.id] = []
        }

        relevantInsightsByApps[relIns.metadata.id].push({
          ...relIns.metadata,
          ...relIns,
          id: relIns.insightId,
          insightId: relIns.insightId
        })
      })
    }

    return (
      <div className='intel-product list'>
        <div className='h1-title'>Discoveries</div>
        <ProductsSmallList
          products={products.map(p => ({
            ...p,
            link: `/product_intelligence/${p.id}`,
            enabled: true
          }))}
          unSeenCriticalInsights={unSeenCriticalInsights}
        />
        <h3 className='title'>Release Radar</h3>
        <div className='release-radar'>
          <OverviewAnalyzedVersions
            relevantInsightsByApps={relevantInsightsByApps}
          />
        </div>
      </div>
    )
  }

  console.log(app)

  return (
    <div className='intel-product product'>
      <h1 className='h1-title'>
        <strong>Intelligence</strong>
      </h1>
      <div className='hr-row' />
      <div className='app-drawer-wrap'>
        <div className='btns'>
          <SeeAllABTests
            link={`/product_intelligence/ab-tests/?daysAgo=60&types=&platforms=&activeTab=1&product_ids=${product_id}`}
            text='See Experiments & Indications'
            user={user}
          />
        </div>
        <div className='product-selector-and-data'>
          {productCommonData && (
            <div className='product-drawer-wrap'>
              <AppDrawer
                appsList={products.map(pr => ({
                  id: pr.id,
                  icon: pr.thumbnail,
                  display_name: pr.name
                }))}
                selectedApp={{
                  id: productCommonData.id,
                  icon: productCommonData.thumbnail,
                  display_name: productCommonData.name,
                  developer:
                    productCommonData.apps && productCommonData.apps[0]
                      ? productCommonData.apps[0].developer
                      : ''
                }}
                appChange={productChangeHandler}
                page='intelligence'
                isIntelligence={true}
                trackedSince={productCalcData.trackedSince}
              />
            </div>
          )}
          {product && productCalcData && Object.keys(product).length > 0 && (
            <div className='product-data'>
              <div className='product-data-item'>
                <div className='product-data-field'>Personas</div>
                <div className='product-data-value'>
                  {Object.values(product)[0].personas_num}
                </div>
              </div>
              <div className='product-data-item'>
                <div className='product-data-field'>Total Releases</div>
                <div className='product-data-value'>
                  {Object.values(product).reduce(
                    (acc, app) => acc + app.versions.length,
                    0
                  )}
                </div>
              </div>
              <div className='product-data-item'>
                <div className='product-data-field'>Last Release</div>
                <div className='product-data-value product-data-value-platform'>
                  {productCalcData.firstReleasePlatform === PL_ANDROID && (
                    <img
                      src={android_logo}
                      alt={productCalcData.firstReleasePlatform}
                    />
                  )}
                  {productCalcData.firstReleasePlatform === PL_IOS && (
                    <img
                      src={ios_logo}
                      alt={productCalcData.firstReleasePlatform}
                    />
                  )}
                  {productCalcData.firstReleasePlatform === PL_WEB && (
                    <img
                      src={web_logo}
                      alt={productCalcData.firstReleasePlatform}
                    />
                  )}
                  {productCalcData.lastVersion
                    ? moment(productCalcData.lastVersion.release_date).format(
                        'LL'
                      )
                    : '...'}
                </div>
              </div>
              <div className='product-data-item'>
                <div className='product-data-field'>Total Discoveries</div>
                <div className='product-data-value'>
                  {productCalcData.allDiscoveriesProduct
                    ? productCalcData.allDiscoveriesProduct.length
                    : '...'}
                </div>
              </div>
              <div className='product-data-item'>
                <div className='product-data-field'>Critical Discoveries</div>
                <div className='product-data-value'>
                  {productCalcData.countCriticalInsights}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <h3 className='title'>
        App Discoveries
        <div className='group-switcher'>
          <div
            className={`switch-group-btn swth-date ${
              groupBy === 'date' ? 'swth-act' : ''
            }`}
            onClick={() => handleChangeGroupBy('date')}
          >
            By Date
          </div>
          <div
            className={`switch-group-btn swth-release ${
              groupBy === 'release' ? 'swth-act' : ''
            }`}
            onClick={() => handleChangeGroupBy('release')}
          >
            By Release
          </div>
        </div>
        {appGraphAccess &&
          appsWithGraphs &&
          releasePlatformID &&
          appsWithGraphs[releasePlatformID] &&
          appsWithGraphs[releasePlatformID][0] && (
            <div>
              <a href={`/app_graph/${appsWithGraphs[releasePlatformID][0].id}`}>
                <div className='last-graph-link'>
                  <div className='graph-ico-wrap'>
                    <img src={graph_ico} />
                  </div>
                  <div className='graph-link-title'>Last Release Map</div>
                </div>
              </a>
            </div>
          )}
      </h3>
      <div className='filter-box'>
        {product && groupBy === GROUPBY.RELEASE && (
          <Select
            className='intel-product-filter intel-product-filter-platform selectbox-style-wout-border'
            value={releasePlatformID}
            onChange={handlePlatformIDChange}
          >
            {groupBy === GROUPBY.DATE && (
              <Select.Option value={''}>All Platforms</Select.Option>
            )}
            {Object.values(product)
              .filter(app => ![PL_MARKETING].includes(app.metadata.platform))
              .map(app => (
                <Select.Option key={app.metadata.id} value={app.metadata.id}>
                  {app.metadata.platform}
                </Select.Option>
              ))}
          </Select>
        )}
        {product && groupBy === GROUPBY.DATE && (
          <Popover
            placement='bottomRight'
            title={null}
            content={
              <FilterPlatform
                filterStatus={datePlatformIDS}
                filterStatusChange={datePlatformIDSChange}
                listProductPlatforms={Object.values(product)
                  .map(a => a.metadata)
                  .filter(a => a.platform !== PL_MARKETING)}
              />
            }
            trigger='click'
            overlayClassName={`filter-wrap all-discoveries all-discoveries-state`}
          >
            <Button className='filter-btn-new platform-mult-selector'>
              {Object.values(product).filter(
                a => a.metadata.platform !== PL_MARKETING
              ).length === datePlatformIDS.length ||
              datePlatformIDS.length === 0
                ? 'All Platforms'
                : datePlatformIDS
                    .map(plID =>
                      product[plID] ? product[plID].metadata.platform : null
                    )
                    .join(', ')}
            </Button>
          </Popover>
        )}
      </div>

      {product_id && productCommonData && !product && (
        <div className='wrapper-loader'>
          <NewLoader />
        </div>
      )}
      {app &&
        releasePlatformID &&
        groupBy === GROUPBY.RELEASE &&
        !version &&
        dataIsReady && (
          <div className='versions__no-content'>
            <h2>This app has no versions</h2>
          </div>
        )}
      {product &&
        groupBy === GROUPBY.DATE &&
        Object.keys(product).length === 0 && (
          <div className='versions__no-content'>
            <h2>This app has no discoveries</h2>
          </div>
        )}
      {app &&
        groupBy === GROUPBY.RELEASE &&
        productCalcData.productVersions.length > 0 && (
          <div>
            <div className='extra-text'>
              All the released versions so far on a timeline
            </div>
            <div className='extra-text-2'>
              Blinking red dots represent Important Discoveries
            </div>
            <VersionsSlider
              isRestrictedUser={
                RESTRICTED_DOORDASH_EMAILS.includes(user.email) &&
                app &&
                app.metadata &&
                app.metadata.id === 3348
                  ? true
                  : false
              }
              versions={product[releasePlatformID].versions.filter(
                v => v.is_visible
              )}
              platform={app.metadata.platform}
              sliderItemClicked={sliderItemClicked}
              currentVersionIndex={
                version
                  ? product[releasePlatformID].versions
                      .filter(v => v.is_visible)
                      .findIndex(ver => ver.id === version.id)
                  : 0
              }
              slidesPerRow={slidesPerRow}
              graph={
                appGraphAccess &&
                appsWithGraphs &&
                releasePlatformID &&
                appsWithGraphs[releasePlatformID]
                  ? appsWithGraphs[releasePlatformID]
                  : null
              }
            />

            {version && product && (
              <div>
                <VersionsHeader
                  versionID={version.id}
                  versionState={version.state}
                  releaseName={
                    app.metadata.platform !== 'Web' ? version.release_name : ''
                  }
                  releaseDate={version.release_date}
                  minorVersionsCounter={version.minorVersionsCounter}
                  versionInsightTags={versionInsightTagsMap}
                  selectedTags={selectedTags}
                  recentChanges={
                    version.json
                      ? JSON.parse(escapeJSONString(version.json)).recentChanges
                      : null
                  }
                  setParentSelectedTags={tags => selectedTagsChange(tags)}
                />
                <div className='versions__content'>
                  {checkIfVersionIsEmpty(version) ? (
                    <VersionsEmpty />
                  ) : (
                    versionType.map(name => (
                      <VersionsList
                        data={version[name]}
                        type={name}
                        key={name}
                        selectedTags={selectedTags}
                        app={app.metadata}
                        analytics={analytics}
                        user={user}
                        allInsights={allInsights}
                        userDiscoveries={userDiscoveries}
                        source={`intel_page_by_${groupBy}`}
                      />
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      {product &&
        groupBy === GROUPBY.DATE &&
        dataIsReady &&
        productCalcData.allDiscoveriesProduct &&
        productCalcData.allDiscoveriesProduct.length > 0 && (
          <div>
            <Tabs
              className='group-date-tabs'
              onChange={handleChangeTab}
              activeKey={activeTabGroupDate}
              animated={{ inkBar: true, tabPane: false }}
            >
              <TabPane
                tab={`Last 14 days (${productCalcData.last14d.length})`}
                key='1'
                disabled={productCalcData.last14d.length === 0}
              >
                {getLayoutForTab(productCalcData.last14d)}
              </TabPane>

              <TabPane
                tab={`Last 30 days (${productCalcData.last30d.length})`}
                key='2'
                disabled={productCalcData.last30d.length === 0}
              >
                {getLayoutForTab(productCalcData.last30d)}
              </TabPane>

              {RESTRICTED_DOORDASH_EMAILS.includes(user.email) &&
              app &&
              app.metadata &&
              app.metadata.id === 3348 ? null : (
                <React.Fragment>
                  <TabPane
                    tab={`Last 60 days (${productCalcData.last60d.length})`}
                    key='3'
                    disabled={productCalcData.last60d.length === 0}
                  >
                    {getLayoutForTab(productCalcData.last60d)}
                  </TabPane>

                  <TabPane
                    tab={`All Time (${productCalcData.filteredDiscoveriesProduct.length})`}
                    key='4'
                    disabled={
                      productCalcData.filteredDiscoveriesProduct.length === 0
                    }
                  >
                    {getLayoutForTab(
                      productCalcData.filteredDiscoveriesProduct
                    )}
                  </TabPane>
                </React.Fragment>
              )}
            </Tabs>
          </div>
        )}
    </div>
  )
}

export default IntelligenceProductView
