// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-menu {
  width: 14rem;
}

.account-menu .ant-list-item {
  cursor: pointer;
  padding: 1rem;
}

.account-menu .ant-list-item:hover {
  background: linear-gradient(270deg, #ffffff 0%, #f6f6f6 100%);
}
`, "",{"version":3,"sources":["webpack://./src/containers/header/AccountMenu/account-menu.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,6DAA6D;AAC/D","sourcesContent":[".account-menu {\n  width: 14rem;\n}\n\n.account-menu .ant-list-item {\n  cursor: pointer;\n  padding: 1rem;\n}\n\n.account-menu .ant-list-item:hover {\n  background: linear-gradient(270deg, #ffffff 0%, #f6f6f6 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
