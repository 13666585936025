import './HeatMapGrid.scss'

import React from 'react'

export interface HeatMapGridProps {
  xLabels: React.ReactElement[] | string[]
  yLabels: string[]
  rgbColor: string // just the numbers such as 0, 0, 0
  data: number[][]
}

export const HeatMapGrid: React.FC<HeatMapGridProps> = ({
  xLabels,
  yLabels,
  rgbColor,
  data
}) => {
  const normalizer =
    Math.max(...data.flatMap(x => x)) - Math.min(...data.flatMap(x => x)) + 0.1

  return (
    <div className='heat-map-container'>
      <div className='heat-map-y-labels'>
        {yLabels.map(label => {
          return (
            <div key={label} className='heat-map-y-label'>
              {label}
            </div>
          )
        })}

        <div className='heat-map-legend'>
          <span>Less</span>
          <div
            className='heat-map-legend-gradient'
            style={{
              background: `linear-gradient(90deg, rgba(${rgbColor}, 0.1), rgba(${rgbColor}, 1))`
            }}
          ></div>
          <span>More</span>
        </div>
      </div>
      <div className='heat-map-data-container'>
        <div
          className='heat-map-data'
          style={{
            gridTemplateColumns: `repeat(${data[0]?.length}, auto)`,
            gridTemplateRows: `repeat(${data.length}, auto)`
          }}
        >
          {data.map((row, rowIndex) => {
            return row.map((value, columnIndex) => {
              const opacity = value / normalizer + 0.1

              return (
                <div
                  key={`${rowIndex}-${columnIndex}`}
                  className={`heat-map-cell ${
                    rowIndex === 0 && columnIndex === row.length - 1
                      ? 'first-row-last-cell'
                      : rowIndex === data.length - 1 && columnIndex === 0
                      ? 'last-row-first-cell'
                      : ''
                  }`}
                  style={{
                    backgroundColor: `rgba(${rgbColor}, ${opacity})`,
                    color: opacity > 0.7 ? '#ffffff' : '#212121'
                  }}
                >
                  {value}
                </div>
              )
            })
          })}
        </div>
        <div className='heat-map-x-labels'>
          {xLabels.map((label, i) => {
            return (
              <div key={i} className='heat-map-x-label'>
                {label}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HeatMapGrid
