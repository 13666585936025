import React, { useState } from 'react'

import { Popover, Button } from 'antd'
import FilterPlatform from '../products-discoveries/FilterPlatform'
import {
  PL_ANDROID,
  PL_IOS,
  PL_WINDOWS,
  PL_CHROME,
  daysAgoList,
  PL_WEB, ALL_TIME
} from '../../shared/consts'
import FilterTags from '../../components/FilterTags/index'
import TopLabelsBar from './TopLabelsBar'
import VersionsEmpty from '../products-discoveries/versionsEmpty/versionsEmpty'
import AppDrawer from '../../common/appDrawer/AppDrawer'
import GalleryDiscoveries from '../dash-discoveries/GalleryDiscoveries'
import NewLoader from '../../common/NewLoader/NewLoader'

import './Topics.scss'
import {TimeFilter} from "../../components/TimeFilter";
import dayjs from "dayjs";
import { convertDaysAgoToCustomRange, getRangeTimeTitle } from "../../helpers/dates";

export const TopicsView = ({
  products,
  analytics,
  user,
  topLabels,
  apps,

  tagsList,
  filterTags,
  filterTagsChange,
  filterTagsMatch,
  setFilterTagsMatch,
  platforms,
  platformsChange,
  customRangeTime,
  customRangeTimeChange,
  currentProducts,
  currentProductsChange,

  loadingFilteredDiscoveries,
  discoveries,
  currentPage,
  currentPageSize,
  onShowSizeChange,
  onPageChange
}) => {
  const [isOpenTimeFilter, isOpenTimeFilterChange] = useState(false)

  const filteredDiscoveries = discoveries ? discoveries.data : null

  const listPlatforms = [PL_ANDROID, PL_IOS, PL_WINDOWS, PL_CHROME, PL_WEB]
    .filter(plat => products.find(p => p.apps.find(a => a.platform === plat)))
    .map(pl => ({
      id: pl,
      platform: pl
    }))

  const handleAppChange = product => {
    if (currentProducts.indexOf(product.id) !== -1) {
      currentProductsChange(currentProducts.filter(ca => ca != product.id))
    } else {
      currentProductsChange([...currentProducts, product.id])
    }
  }

  return (
    <div className='topics-page'>
      <h1 className='h1-title'>Topics</h1>
      <div className='extra-text'>
        Explore the key areas your competitors are investing in
      </div>
      <div className='topics-filters'>
        <div className='topics-filter-platform'>
          <Popover
            placement='bottom'
            title={null}
            content={
              <FilterPlatform
                filterStatus={platforms}
                filterStatusChange={platformsChange}
                listProductPlatforms={listPlatforms}
              />
            }
            trigger='click'
            overlayClassName={`filter-wrap all-discoveries all-discoveries-state`}
          >
            <Button className='filter-btn-new platform-mult-selector'>
              {platforms.length === listPlatforms.length ||
              platforms.length === 0 ? (
                'All Platforms'
              ) : (
                <span>
                  Platforms
                  <span className='filter-count filter-count-padding'>
                    {platforms.length}
                  </span>
                </span>
              )}
            </Button>
          </Popover>
        </div>

        <div className='topics-filter-products'>
          <Popover
            placement='bottomLeft'
            title={null}
            destroyTooltipOnHide={true}
            content={
              <AppDrawer
                appsList={products}
                currentApps={currentProducts}
                currentAppsChange={currentProductsChange}
                isProduct={true}
                page='all-discoveries'
                appChange={handleAppChange}
                clearCurrentApps={() => currentProductsChange([])}
                className='button'
                maxAppsSelected={10}
              />
            }
            trigger='click'
            overlayClassName={`popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps`}
          >
            <Button className='filter-btn-new'>
              Products
              {
                <span
                  className={`filter-count ${
                    currentProducts.length === 0 ? '' : 'filter-count-padding'
                  }`}
                >
                  {currentProducts.length === 0 ||
                  currentProducts.length === apps.length
                    ? null
                    : currentProducts.length}
                </span>
              }
            </Button>
          </Popover>
        </div>

        <div className='topics-filter-time'>
          <Popover
            placement='bottomRight'
            title={null}
            open={isOpenTimeFilter}
            onOpenChange={isOpenTimeFilterChange}
            content={
              <TimeFilter
                daysAgoList={daysAgoList}
                value={customRangeTime.map(x => dayjs(x))}
                onChange={customRangeTimeChange}
                onClose={() => isOpenTimeFilterChange(false)}
              />
            }
            trigger='click'
          >
            <Button
             className='filter-btn-new'
             title={`${customRangeTime[0].format('YYYY-MM-DD')} - ${customRangeTime[1].format('YYYY-MM-DD')}`}
            >
              {customRangeTime === convertDaysAgoToCustomRange(ALL_TIME)
                ? 'All time' : getRangeTimeTitle(customRangeTime) || 'Custom Range'}
            </Button>
          </Popover>
        </div>
      </div>
      {topLabels && (
        <TopLabelsBar
          products={products}
          topLabels={topLabels}
          customRangeTime={customRangeTime}
          currentProducts={currentProducts}
        />
      )}

      <div className='topics-splitter'></div>

      <div className='topics-tags-section'>
        {tagsList && (
          <div className='topics-tags-filter '>
            <FilterTags
              tagsList={tagsList}
              filterTags={filterTags}
              filterTagsChange={filterTagsChange}
              setFilterTagsMatch={setFilterTagsMatch}
              filterTagsMatch={filterTagsMatch}
              maxAllowedTags={10}
            />
          </div>
        )}

        {loadingFilteredDiscoveries && (
          <div className='all-dis-loader'>
            <NewLoader />
          </div>
        )}

        {!loadingFilteredDiscoveries &&
          filteredDiscoveries &&
          filteredDiscoveries.length === 0 && (
            <VersionsEmpty campaignMode={true} />
          )}

        {!loadingFilteredDiscoveries &&
          filteredDiscoveries &&
          filteredDiscoveries.length > 0 && (
            <GalleryDiscoveries
              loadingFilteredDiscoveries={loadingFilteredDiscoveries}
              filteredDiscoveries={filteredDiscoveries}
              apps={apps.map(a => a.metadata)}
              usePagination={true}
              currentPage={currentPage}
              onPageChange={onPageChange}
              currentPageSize={currentPageSize}
              onShowSizeChange={onShowSizeChange}
              totalDiscoveries={discoveries.meta.num_total}
              user={user}
              analytics={analytics}
              productName={null}
              handleClick={null}
              showType={false}
              source={'topics_page'}
            />
          )}
      </div>
    </div>
  )
}

export default TopicsView
