import './ChannelSelect.scss'

import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import x_ico from 'assets/images/icons/x-no-outline.svg'
import { CloseShowResults } from 'components/CloseShowResults'
import {
  Channels,
  ChannelToLabel
} from 'containers/MarketingOverview/utils/consts'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import React from 'react'

const defaultChannels = Object.keys(Channels)

type Props = {
  onChannelSelect: (channels: string[]) => void
  selectedChannels: string[]
  closeChannel: () => void
  availableChannels?: string[]
}

export const ChannelSelect = ({
  onChannelSelect,
  selectedChannels,
  closeChannel,
  availableChannels = defaultChannels
}: Props) => {
  const isMobile = isMobileDevice()

  const handleChannelSelect = (e: CheckboxChangeEvent) => {
    const { checked, value } = e.target

    const newSelectedChannels = checked
      ? [...selectedChannels, value]
      : selectedChannels.filter(channel => channel !== value)

    onChannelSelect(newSelectedChannels)
  }

  return (
    <div className='channel-select-container'>
      <div className='title'>Channels ({selectedChannels.length})</div>
      <div className='items-selected'>
        <div className='items-selected-count'>
          {`${selectedChannels.length} Selected`}
        </div>
        {!!selectedChannels?.length && (
          <div
            className='clear'
            onClick={() => {
              onChannelSelect([])
            }}
          >
            clear selection
          </div>
        )}
      </div>
      {availableChannels.map(channel => {
        const checked = selectedChannels.includes(channel)

        return (
          <Checkbox
            key={channel}
            className={`channel-select-cb filter-checkbox ${
              checked ? 'filter-checked' : ''
            }`}
            checked={checked}
            onChange={handleChannelSelect}
            value={channel}
          >
            {ChannelToLabel[channel]}
          </Checkbox>
        )
      })}
      {isMobile && (
        <div className='channel-select-show-results'>
          <CloseShowResults onClose={closeChannel} />
        </div>
      )}
    </div>
  )
}
