import { Avatar, Button, Modal, notification, Slider, Upload } from 'antd'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import Cropper from 'react-cropper'
import PhoneInput from 'react-phone-input-2'
import { Location, useBlocker, useNavigate } from 'react-router'

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'

import checkmark_ico_green from '../../assets/images/icons/checkmark-pass_green.svg'
import warning_ico from '../../assets/images/icons/warning.svg'
import { InputText } from '../../components/inputText/InputText'
import { getBase64 } from '../../helpers/generalHelpers'
import { getNameFromEmail } from '../../helpers/usersHelper'
import { IUserData } from '../../interfaces/User'

interface IProps {
  user: any
  userData: IUserData
  setUserName: (
    first_name: string,
    last_name: string,
    picture_url: string,
    role: string,
    department: string,
    phone: string
  ) => void
  setUserPass: (oldPass: string, newPass: string) => void
  setUserPic: (image_url: string) => void
  deleteUserPic: () => void
  setUserPassSuccessReset: () => void
  setUserDataSuccessReset: () => void
  analytics: any
}

const UserProfile: FC<IProps> = ({
  user,
  userData,
  setUserName,
  setUserPass,
  setUserPic,
  deleteUserPic,
  setUserPassSuccessReset,
  setUserDataSuccessReset,
  analytics
}): ReactElement => {
  const navigate = useNavigate()

  const blocker = useBlocker(({ nextLocation }: { nextLocation: Location }) => {
    if (getFormModified()) {
      urlForRedirectChange(nextLocation.pathname)
      modalConfirmExitChange(true)
      return true
    }

    return false
  })

  console.log(blocker)

  const [modalChangePassVis, modalChangePassVisChange] = useState(false)
  const [modalConfirmExitVis, modalConfirmExitChange] = useState(false)
  const [modalUploadImageVis, modalUploadImageVisChange] = useState(false)

  const [firstName, firstNameChange] = useState(userData.first_name)
  const [lastName, lastNameChange] = useState(userData.last_name)
  const [pictureUrl, pictureUrlChange] = useState(userData.picture_url)
  const [role, roleChange] = useState(userData.role)
  const [department, departmentChange] = useState(userData.department)
  const [phone, phoneChange] = useState(userData.phone)

  const [image, setImage] = useState('')
  const [cropper, setCropper] = useState(null)
  const [scaleSlider, scaleSliderChange] = useState(1)

  const [oldPass, oldPassChange] = useState('')
  const [newPass, newPassChange] = useState('')
  const [confPass, confPassChange] = useState('')
  const [passNotMatch, passNotMatchChange] = useState(false)
  const [formModified, formModifiedChange] = useState(false)
  const [urlForRedirect, urlForRedirectChange] = useState('')

  const UPLOAD_PROPS = {
    customRequest: (data: any) => {
      if (!modalUploadImageVis) {
        modalUploadImageVisChange(true)
      }
      getBase64(data.file, (imageUrl: string): void => {
        setImage(imageUrl)
        data.onSuccess()
      })
    }
  }

  useEffect(() => {
    pictureUrlChange(userData.picture_url)
  }, [userData.picture_url])

  useEffect(() => {
    firstNameChange(userData.first_name)
    lastNameChange(userData.last_name)
    roleChange(userData.role)
    departmentChange(userData.department)
    phoneChange(userData.phone)
    if (
      userData.new_preview_picture_url &&
      userData.new_preview_picture_url !== userData.picture_url
    ) {
      pictureUrlChange(userData.new_preview_picture_url)
    }
    checkFormModified(
      userData.first_name,
      userData.last_name,
      userData.new_preview_picture_url || userData.picture_url,
      userData.role,
      userData.department,
      userData.phone
    )
  }, [userData])

  useEffect(() => {
    if (userData.successPassChange === true) {
      modalChangePassVisChange(false)
      setTimeout(() => {
        setUserPassSuccessReset()
        oldPassChange('')
        newPassChange('')
        confPassChange('')
      }, 4000)
    }
  }, [userData.successPassChange])

  useEffect(() => {
    if (userData.successUserDataChange === 'changed') {
      notification['success']({
        message: 'User details was saved'
      })
      setUserDataSuccessReset()
    }
  }, [userData.successUserDataChange])

  const getFormModified = () => {
    return formModified
  }

  const redirectForward = () => {
    navigate(urlForRedirect)
    setUserDataSuccessReset()
    modalConfirmExitChange(false)
  }

  const redirectForwardWithSave = () => {
    saveUser()
    redirectForward()
  }
  const redirectForwardWithCancel = () => {
    formModifiedChange(false)
    modalConfirmExitChange(false)
  }

  const saveUser = () => {
    setUserName(firstName, lastName, pictureUrl, role, department, phone)
    analytics.analytic(`Change user data`, {
      PreviousFullName: `${userData.first_name} - ${userData.last_name}`,
      CurrentFullName: `${firstName} - ${lastName}`,
      pictureUrl,
      role,
      department,
      phone
    })
  }

  const removeUserPic = () => {
    deleteUserPic()
    analytics.analytic(`Remove avatar`)
  }

  const changeUserPass = () => {
    if (newPass !== confPass) {
      passNotMatchChange(true)
      setTimeout(() => {
        passNotMatchChange(false)
      }, 2000)
      return
    }
    setUserPass(oldPass, newPass)
    analytics.analytic(`Change password`)
  }

  const checkFormModified = (
    firstName: string,
    lastName: string,
    pictureUrl: string,
    role: string,
    department: string,
    phone: string
  ): void => {
    const isAnythingChanged =
      firstName !== userData.first_name ||
      lastName !== userData.last_name ||
      pictureUrl !== userData.picture_url ||
      role !== userData.role ||
      department !== userData.department ||
      phone !== userData.phone
    formModifiedChange(isAnythingChanged)
  }

  const cropDataAndSave = () => {
    // @ts-ignore
    const imageUrl = cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.82)
    setUserPic(imageUrl)
    analytics.analytic(`Remove avatar`)

    modalUploadImageVisChange(false)
  }

  const changeScale = (value: number) => {
    // @ts-ignore
    cropper.scale(1 + value / 10, 1 + value / 10)
    // @ts-ignore
    scaleSliderChange(value)
  }

  // @ts-ignore
  return (
    <div>
      <h3 className='title'>Personal Details</h3>
      <div className='extra-text'>
        Settings to help you keep your account up-to-date & secure
      </div>

      <div className='account-form'>
        <div className='left-part-text'>
          <div className='row row-name'>
            <InputText
              placeholder='First Name'
              label='First Name'
              value={firstName}
              password={null}
              className='account-firstname'
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                firstNameChange(e.currentTarget.value)
                checkFormModified(
                  e.currentTarget.value,
                  lastName,
                  pictureUrl,
                  role,
                  department,
                  phone
                )
              }}
            />
            <InputText
              label='Last Name'
              placeholder='Last Name'
              value={lastName}
              password={null}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                lastNameChange(e.currentTarget.value)
                checkFormModified(
                  firstName,
                  e.currentTarget.value,
                  pictureUrl,
                  role,
                  department,
                  phone
                )
              }}
            />
          </div>
          <div className='row row-email'>
            <InputText
              placeholder='Email Address'
              label='Email Address'
              type='email'
              password={null}
              value={user.email}
              disabled={true}
            />
          </div>
          <div className='row row-name'>
            <InputText
              label='Role'
              placeholder='Role'
              value={role}
              className='account-firstname'
              password={null}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                roleChange(e.currentTarget.value)
                checkFormModified(
                  firstName,
                  lastName,
                  pictureUrl,
                  e.currentTarget.value,
                  department,
                  phone
                )
              }}
            />
            <InputText
              label='Department'
              placeholder='Department'
              value={department}
              password={null}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                departmentChange(e.currentTarget.value)
                checkFormModified(
                  firstName,
                  lastName,
                  pictureUrl,
                  role,
                  e.currentTarget.value,
                  phone
                )
              }}
            />
          </div>
          <div className='row phone'>
            <PhoneInput
              country={'us'}
              value={phone}
              onChange={(value: string) => {
                phoneChange(value)
                checkFormModified(
                  firstName,
                  lastName,
                  pictureUrl,
                  role,
                  department,
                  value
                )
              }}
            />
          </div>
          <div className='row row-buttons'>
            {/* <Button size="large" className="btn-cancel">Cancel</Button> */}
            <Button
              size='large'
              className='btn-save'
              onClick={saveUser}
              disabled={!formModified}
            >
              Save
            </Button>
          </div>
        </div>
        <div className='right-part-avatar'>
          <h6>Profile Image</h6>
          <div className='image'>
            {pictureUrl ? (
              <Avatar size={125} src={pictureUrl} className='profile-avatar' />
            ) : (
              <Avatar size={125} className='def-avatar'>
                {userData.first_name && userData.last_name
                  ? `${userData.first_name[0].toUpperCase()}${userData.last_name[0].toUpperCase()}`
                  : getNameFromEmail(user)[0].toUpperCase()}
              </Avatar>
            )}
          </div>
          <div className='avatar-buttons'>
            <Upload {...UPLOAD_PROPS}>
              <Button icon={<UploadOutlined />}>Change </Button>
            </Upload>

            <Button
              icon={<DeleteOutlined />}
              className='btn-remove-avatar'
              disabled={!userData.picture_url}
              onClick={removeUserPic}
            />
          </div>
        </div>
      </div>
      <div className='hr-row bottom-hr' />
      <div className='row row-pass'>
        <InputText
          label='Password'
          placeholder='Password'
          value='·······'
          password={null}
          onChange={() => {}}
          disabled={true}
        />
        <Button
          className='open-modal-change-pass'
          onClick={() => {
            modalChangePassVisChange(true)
          }}
        >
          Change
        </Button>
      </div>
      {userData.successPassChange && (
        <div className='row pass-changed'>
          <img src={checkmark_ico_green} />
          Your Password Was Successfully Changed!
        </div>
      )}

      <Modal
        title={
          <div className='change-pass-titles'>
            <h1>Change Your Password</h1>
            <div className='change-pass-descr'>
              Fill in the fields below In order to change your password. Please
              make sure your password is <strong>larger than 7 letters</strong>
            </div>
          </div>
        }
        open={modalChangePassVis}
        className='modal-change-pass'
        width={400}
        footer={[
          <Button key='changePassword' onClick={changeUserPass}>
            Change Password
          </Button>
        ]}
        onCancel={() => modalChangePassVisChange(false)}
        // defaultValue={null}
      >
        <div className='change-pass-content'>
          <InputText
            label='Current Password'
            placeholder='Current Password'
            value={oldPass}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              oldPassChange(e.currentTarget.value)
            }}
            password
          />
          <InputText
            label='New Password'
            placeholder='New Password'
            value={newPass}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              newPassChange(e.currentTarget.value)
            }}
            password
          />
          <InputText
            label='Confirm Password'
            placeholder='Confirm Password'
            value={confPass}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              confPassChange(e.currentTarget.value)
            }}
            password
          />
          {userData.errorChangePass && (
            <div className='pass-error'>{userData.errorChangePass.error}</div>
          )}
          {passNotMatch && (
            <div className='pass-not-match'>
              New and confirm passwords don't match
            </div>
          )}
        </div>
      </Modal>

      <Modal
        open={modalConfirmExitVis}
        className='modal-confirm-exit-pass'
        width={665}
        style={{ top: 250 }}
        closable={false}
      >
        <div className='confirm-exit-pass-header'>
          <div className='warn-ico'>
            <img src={warning_ico} />
          </div>
          <div className='confirm-exit-pass-titles'>
            <h1>Quit Editing?</h1>
            <div className='confirm-exit-pass-descr'>
              Changes you made so far will not be saved
            </div>
            <div className='buttons'>
              <Button onClick={redirectForward} className='dont-save'>
                Don't Save
              </Button>
              <Button onClick={redirectForwardWithCancel} className='cancel'>
                Cancel
              </Button>
              <Button onClick={redirectForwardWithSave} className='save'>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={modalUploadImageVis}
        className='modal-upload-image'
        width={670}
        onCancel={() => modalUploadImageVisChange(false)}
        // defaultValue={null}
        footer={
          <div className='buttons'>
            <Upload {...UPLOAD_PROPS}>
              <Button icon={<UploadOutlined />}>Change Image</Button>
            </Upload>
            <Button
              onClick={() => modalUploadImageVisChange(false)}
              className='cancel'
            >
              Cancel
            </Button>
            <Button onClick={cropDataAndSave} className='save'>
              Save
            </Button>
          </div>
        }
      >
        <div>
          <Cropper
            style={{ height: 400, width: '100%', opacity: 1 }}
            // Cropper.js options
            src={image}
            scaleX={scaleSlider}
            scaleY={scaleSlider}
            initialAspectRatio={16 / 9}
            viewMode={1}
            guides={true}
            aspectRatio={1}
            minCropBoxHeight={40}
            minCropBoxWidth={40}
            background={false}
            responsive={true}
            zoomable={false}
            dragMode='move'
            onInitialized={(instance: Cropper) => {
              // @ts-ignore
              setCropper(instance)
            }}
          />
          <div className='scaler-image'>
            <Slider
              min={1}
              max={100}
              onChange={changeScale}
              value={scaleSlider}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UserProfile
