import './CampaignCalendar.scss'

import { Segmented, Switch } from 'antd'
import ChartLegend from 'components/charts/ChartLegend'
import {
  generateRandomBrightColor,
  isMobileDevice
} from 'containers/MarketingOverview/utils/helpers'
import { App } from 'containers/MarketingOverview/utils/types'
import dayjs, { Dayjs, OpUnitType, QUnitType } from 'dayjs'
import { datasetIndexToColor } from 'helpers/colorHelpers'
import { groupBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'

import { HeadingWithTooltip } from '../../HeadingWithTooltip'

type AppByDate = {
  app: App
  date: string
  totalInsights: number
  email_campaign?: number
  push_notification_campaign?: number
  inapp_campaign?: number
}

enum SegmentationType {
  Day = 'day',
  Week = 'week',
  Month = 'month'
}

type Props = {
  appsByDate: AppByDate[]
  customRangeTime: Dayjs[]
  showLines?: boolean
}

type SegmentedData = {
  [key: number]: {
    [date: string]: AppByDate
  }
}

const DATE_FORMAT = 'DD/MM'

const SEGMENTATION_OPTIONS = [
  { label: 'Daily', value: SegmentationType.Day },
  { label: 'Weekly', value: SegmentationType.Week },
  { label: 'Monthly', value: SegmentationType.Month }
]

const segmentToDataLabels = (appsByDate: AppByDate[], dateLabels: Dayjs[]) => {
  return appsByDate.reduce((acc: SegmentedData, next: AppByDate) => {
    // Sometimes we didn't get the apps yet from the server, so wait for them
    if (!next.app) {
      return acc
    }

    // We will segment forward, meaning if we have a week starting on 20.6, 16.6 will be merged into this point
    const currentDate = dayjs(next.date)

    const foundLabelIndex = dateLabels.findIndex(date =>
      // Always check day since it is always good enough
      currentDate.isSameOrBefore(date, 'day')
    )

    const dateToUse = dayjs(dateLabels[foundLabelIndex] || next.date).format(
      DATE_FORMAT
    )

    if (!acc[next.app.id]?.[dateToUse]) {
      acc[next.app.id] = acc[next.app.id] || {}

      acc[next.app.id][dateToUse] = {
        app: next.app,
        date: dateToUse,
        totalInsights: 0
      }
    }

    acc[next.app.id][dateToUse].totalInsights += next.totalInsights

    return acc
  }, {})
}

export const CampaignCalendar = ({
  appsByDate,
  customRangeTime,
  showLines
}: Props) => {
  const chartRef = useRef<Line>(null)
  const [segmentationType, setSegmentationType] = useState<SegmentationType>(
    SegmentationType.Day
  )

  const dateLabels = useMemo(() => {
    const startDate = customRangeTime[0]
    const endDate = customRangeTime[1]
    const dates = []

    for (
      let date = startDate;
      date.isSameOrBefore(endDate, segmentationType);
      date = date.add(1, segmentationType)
    ) {
      dates.push(date)
    }

    return dates
  }, [customRangeTime[0], customRangeTime[1], segmentationType])

  const datasets = useMemo(() => {
    const segmentedData = segmentToDataLabels(appsByDate, dateLabels)

    return Object.values(segmentedData).map((value, index) => {
      const values = Object.values(value)

      return {
        pointRadius: 0,
        pointBackgroundColor: 'transparent',
        pointHoverRadius: 0,
        hitRadius: 15,
        pointRotation: false,
        fill: false,
        lineTension: 0,
        borderColor: datasetIndexToColor(index),
        label: values[0].app.displayName,
        data: values.map(dataPoint => {
          return {
            ...dataPoint.app,
            x: dataPoint.date,
            y: dataPoint.totalInsights,
            r: 2
          }
        })
      }
    })
  }, [appsByDate, dateLabels])

  const isMobile = isMobileDevice()

  const labels = useMemo(
    () => dateLabels.map(date => date.format(DATE_FORMAT)),
    [dateLabels]
  )

  return (
    <div className='promotions-calendar'>
      <HeadingWithTooltip
        title='Promotions Calendar'
        subtitle='Explore Promotions volume & trends across selected period'
      >
        <div className='options-wrapper'>
          <div className='segmentation-options'>
            <Segmented
              size='large'
              value={segmentationType}
              onChange={setSegmentationType}
              options={SEGMENTATION_OPTIONS}
            />
          </div>
          {/* <div className='compare'>
            <Switch /> Compare
          </div> */}
        </div>
      </HeadingWithTooltip>
      <div className='cc-container'>
        <div className='cc-container-body'>
          <Line
            ref={chartRef}
            width={isMobile ? 800 : undefined}
            height={isMobile ? 400 : undefined}
            data={{
              labels,
              datasets: datasets as any
            }}
            options={{
              layout: {
                padding: {
                  bottom: 30
                }
              },
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: function (value: any) {
                        const val = Number(value)
                        return Math.floor(val) === val ? value : null
                      },
                      min: 0,
                      fontSize: 14,
                      padding: 14
                    },
                    gridLines: {
                      drawTicks: false
                    }
                  }
                ],
                xAxes: [
                  {
                    gridLines: {
                      drawOnChartArea: false,
                      drawBorder: false
                    },
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#9AA1A9',
                      fontSize: 14,
                      padding: 14,
                      stepSize: 2
                    }
                  }
                ]
              },
              tooltips: {
                enabled: true,
                mode: 'nearest',
                intersect: false
              },
              showLines,
              legend: {
                display: false
              }
            }}
          />
        </div>
        <ChartLegend chart={chartRef.current?.chartInstance} />
      </div>
    </div>
  )
}
