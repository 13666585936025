import * as aT from '../actions/_actionsTypes'
import * as commentsAct from '../acts/userDiscoveries'
import moment from 'moment/moment'
import { marketingCampaignsTypes } from '../../shared/consts'

const INIT_STATE = {
  relevantInsights: null,
  relevantInsightsFetched: false,
  campaignsFetched: false,
  unSeenCriticalInsights: null,
  marketingApps: null,
  marketingLastUpdate: null,
  filteredDiscoveries: null,
  loadingFilteredDiscoveries: false,
  products: null,
  productStats: null,
  markAllAsSeenRequestStatus: null,
  productTrackedSince: '',
  searchedDiscoveries: null,
  loadingSearchDiscoveries: false
}

const marketingCampaignsList = Object.keys(marketingCampaignsTypes)

marketingCampaignsList.forEach(marketingType => {
  INIT_STATE[marketingType] = null
})

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case aT.GET_RELEVANT_APPS_INSIGHTS: {
      return {
        ...state,
        relevantInsights: action.payload || [],
        relevantInsightsFetched: true
      }
    }
    case aT.GET_MARKETING_CAMPAIGNS_SUCCESS: {
      let marketingApps = Object.keys(action.payload.apps).map(appID => ({
        ...action.payload.apps[appID].metadata
      }))
      let campaigns = {}
      marketingCampaignsList.forEach(mC => {
        campaigns[mC] = action.payload[mC] || []
      })
      return {
        ...state,
        ...campaigns,
        marketingApps: marketingApps,
        campaignsFetched: true,
        marketingLastUpdate: action.payload.lastUpdate
      }
    }

    case aT.GET_DISCOVERY_DATA_SUCCESS: {
      const { id } = action.payload.data
      const campaigns = {}
      marketingCampaignsList.forEach(mC => {
        if (!state[mC]) return
        campaigns[mC] = state[mC].map(el => {
          if (el.cam.id != id) {
            return el
          }
          return {
            app: el.app,
            cam: {
              ...el.cam,
              ...action.payload.data,
              fullLoaded: true
            }
          }
        })
      })

      return {
        ...state,
        ...campaigns
      }
    }

    case aT.SET_DISCOVERY_RATE_SUCCESS: {
      const { id } = action.payload.data
      const campaigns = {}
      marketingCampaignsList.forEach(mC => {
        if (!state[mC]) return
        campaigns[mC] = state[mC].map(el => {
          if (el.cam.id !== id) {
            return el
          }
          return {
            app: el.app,
            cam: {
              ...el.cam,
              ...action.payload.data
            }
          }
        })
      })

      let filteredDiscoveriesCopy
      if (state.filteredDiscoveries) {
        filteredDiscoveriesCopy = {
          data: [...state.filteredDiscoveries.data].map(fd => {
            if (fd.id === id) {
              return {
                ...fd,
                ...action.payload.data
              }
            } else return fd
          }),
          meta: state.filteredDiscoveries.meta
        }
      }
      return {
        ...state,
        ...campaigns,
        filteredDiscoveries: filteredDiscoveriesCopy
      }
    }

    case aT.REMOVE_RELEVANT_READ_INSIGHTS: {
      const relevantInsightsCopy = [...(state.relevantInsights || [])]

      return {
        ...state,
        relevantInsights: relevantInsightsCopy.filter(
          ins => !ins.insight.readAt
        )
      }
    }

    case aT.GET_UNSEEN_CRITICAL_INSIGHTS_SUCCESS: {
      return {
        ...state,
        unSeenCriticalInsights: action.payload.data.unSeenCriticalInsights
      }
    }

    case aT.GET_DISCOVERIES: {
      return {
        ...state,
        loadingFilteredDiscoveries: true
      }
    }

    case aT.GET_DISCOVERIES_SEARCH: {
      return {
        ...state,
        loadingSearchDiscoveries: true
      }
    }

    case aT.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: Object.values(action.payload.data)
      }
    }

    case aT.GET_TRACKED_SINCE: {
      return {
        ...state,
        productTrackedSince: ''
      }
    }
    case aT.GET_TRACKED_SINCE_SUCCESS: {
      return {
        ...state,
        productTrackedSince: moment(
          action.payload.data.first_release_date
        ).format('MMM DD, YYYY')
      }
    }

    case aT.GET_DISCOVERIES_SUCCESS: {
      return {
        ...state,
        filteredDiscoveries: {
          meta: action.payload.meta,
          data: action.payload.data.map(item => ({
            ...Object.values(item)[0]
          }))
        },
        loadingFilteredDiscoveries: false
      }
    }

    case aT.GET_DISCOVERIES_SEARCH_SUCCESS: {
      return {
        ...state,
        searchedDiscoveries: {
          meta: action.payload.meta,
          data: action.payload.data.map(item => ({
            ...Object.values(item)[0]
          }))
        },
        loadingSearchDiscoveries: false
      }
    }

    case aT.GET_PRODUCTS_STATS_SUCCESS: {
      return {
        ...state,
        productStats: action.payload.data
      }
    }

    case aT.SET_CAMPAIGN_DEMO_MODE: {
      let campaigns = {}
      campaigns[`${action.payload.data.type}s`] = [
        {
          app: action.payload.data.metadata,
          cam: {
            ...action.payload.data,
            fullLoaded: true
          }
        }
      ]
      return {
        ...state,
        ...campaigns
      }
    }

    case aT.SET_ALL_DISCOVERIES_AS_SEEN: {
      return {
        ...state,
        markAllAsSeenRequestStatus: 'loading'
      }
    }

    case aT.SET_ALL_DISCOVERIES_AS_SEEN_SUCCESS: {
      return {
        ...state,
        markAllAsSeenRequestStatus: 'completed'
      }
    }

    //COMMENTS
    case commentsAct.POST_USER_DISCOVERY_COMMENT_SUCCESS: {
      let { insight_id } = action.payload
      const campaigns = {}
      marketingCampaignsList.forEach(mC => {
        if (!state[mC]) return
        campaigns[mC] = state[mC].map(el => {
          if (el.cam.id != insight_id) {
            return el
          }
          return {
            app: el.app,
            cam: {
              ...el.cam,
              comments: [...el.cam.comments, action.payload],
              lastCommentUpdate: new Date().getTime()
            }
          }
        })
      })
      return {
        ...state,
        ...campaigns
      }
    }
    case commentsAct.DELETE_USER_DISCOVERY_COMMENT_SUCCESS: {
      let { insight_id, comment_id } = action.payload
      const campaigns = {}
      marketingCampaignsList.forEach(mC => {
        if (!state[mC]) return
        campaigns[mC] = state[mC].map(el => {
          if (el.cam.id != insight_id) {
            return el
          }
          return {
            app: el.app,
            cam: {
              ...el.cam,
              comments: el.cam.comments.filter(c => c.id !== comment_id),
              lastCommentUpdate: new Date().getTime()
            }
          }
        })
      })
      return {
        ...state,
        ...campaigns
      }
    }
    case commentsAct.EDIT_USER_DISCOVERY_COMMENT_SUCCESS: {
      let { comment, insight_id, comment_id } = action.payload
      const campaigns = {}
      marketingCampaignsList.forEach(mC => {
        if (!state[mC]) return
        campaigns[mC] = state[mC].map(el => {
          if (el.cam.id != insight_id) {
            return el
          }
          return {
            app: el.app,
            cam: {
              ...el.cam,
              comments: el.cam.comments.map(c =>
                c.id !== comment_id ? c : comment.data
              ),
              lastCommentUpdate: new Date().getTime()
            }
          }
        })
      })

      return {
        ...state,
        ...campaigns
      }
    }

    default:
      return state
  }
}
