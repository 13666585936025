import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getNotifications } from '../../redux/acts/notifications'
import { getReports } from '../../redux/acts/reports'

import { useNavigate } from 'react-router-dom'
import SearchBar from './searchBar/SearchBar'

import NotificationCenter from './notifications/NotificationCenter'
import ReportsCenter from './reportsCenter/ReportsCenter'

import { siteVersions } from '../../shared/consts'

import { compareStrings } from '../../services/stringsService'

import {
  getProducts,
  getUnseenCriticalInsights
} from '../../redux/actions/overviewActions'

import { getNameFromEmail } from '../../helpers/usersHelper'

import watchful_logo from '../../assets/images/wf-new-logo.svg'
import watchful_logo_small from '../../assets/images/watchful_icon.svg'
import { ReactComponent as notification_bell_ico } from '../../assets/images/new_design/icons/notification_bell.svg'
import reports_ico from '../../assets/images/new_design/icons/report-ico.svg'
import { getSortedProducts } from '../products/ProductsSelector'

import { Popover, Badge, Button, Avatar } from 'antd'
import Icon from '@ant-design/icons'
import DaysToTrialExpiration from '../../components/trialLicense/DaysToTrialExpiration'
import AccessRequestModal from './AccessRequestModal'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import { MenuToggle } from './menu-toggle'
import AccountMenu from './AccountMenu'

export const Header = ({ showSearch, className }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector(state => state.appData.user)
  const apps = useSelector(state => state.appData.apps)
  const sharedInsightData = useSelector(
    state => state.appData.sharedInsightData
  )
  const analytics = useSelector(state => state.appData.user.analytics)
  const userData = useSelector(state => state.userData)
  const guest = useSelector(state => state.linkData.guest)
  const linkReferrer = useSelector(state => state.linkData.user)
  const products = useSelector(getSortedProducts)
  const notifications = useSelector(
    state => state.notifications?.notifications || []
  )
  const reports = useSelector(state => state.reports.reports)
  const siteVersion = useSelector(state => state.appData.siteVersion)

  const [notificationCenterOpened, notificationCenterOpenedChange] =
    useState(false)
  const [accountPopoverOpen, setAccountPopupoverOpen] = useState(false)
  const [reportsCenterOpened, reportsCenterOpenedChange] = useState(false)
  const [searchWord, searchWordChange] = useState()
  const [isRequestModalOpen, isRequestModalOpenChange] = useState(false)

  useEffect(() => {
    //make request only for authorized user
    if (!products && user.email) {
      dispatch(getProducts())
      dispatch(getUnseenCriticalInsights())
      dispatch(getNotifications())
      dispatch(getReports())
    }
  }, [products, user])

  const handleVisibleChange = visible => {
    notificationCenterOpenedChange(visible)
    if (visible) {
      analytics.analytic('User opened notification center')
    }
  }

  const handleVisibleReportChange = visible => {
    reportsCenterOpenedChange(visible)
    if (visible) {
      analytics.analytic('User opened reports center')
    }
  }

  const handleOpenRequestModal = () => {
    isRequestModalOpenChange(true)
    analytics.analytic('Viewer opened request access dialog', {
      location: 'shared-insight',
      insight_name: sharedInsightData.insight_name,
      insight_type: sharedInsightData.insight_type,
      product: sharedInsightData.app_name,
      platform: sharedInsightData.platform
    })
  }

  const fullVersion = compareStrings(siteVersion, siteVersions.FULL)
  const isMobile = isMobileDevice()

  return (
    <header className={`header__wrap ${className}`}>
      <div className={`header-logo`}>
        <img
          src={watchful_logo}
          alt='watchful'
          className={`header__icons-logo large ${
            !user.email ? 'not-point' : ''
          }`}
          onClick={() => navigate('/')}
        />
        <img
          src={watchful_logo_small}
          alt='watchful'
          className={`header__icons-logo normal ${
            !user.email ? 'not-point' : ''
          }`}
          onClick={() => navigate('/')}
        />
      </div>
      <div className='search-account'>
        <SearchBar
          className='header__search-bar'
          newline={false}
          apps={apps}
          analytics={analytics}
          disabled={!fullVersion}
          searchWord={searchWord}
          searchWordChange={searchWordChange}
        />
        <div className='search-flex'>
          {reports && fullVersion && (
            <Popover
              content={
                <ReportsCenter
                  closeReportCenter={() => reportsCenterOpenedChange(false)}
                />
              }
              trigger='click'
              placement='bottom'
              overlayClassName='report-center-wrapper'
              open={reportsCenterOpened}
              onOpenChange={handleVisibleReportChange}
            >
              <div className='reports-center'>
                <Badge count={reports.filter(n => !n.app_seen_at).length}>
                  <img src={reports_ico} />
                </Badge>
              </div>
            </Popover>
          )}
      {/* 
          {fullVersion && (
            <Popover
              content={
                <NotificationCenter
                  closeNotCenter={() => notificationCenterOpenedChange(false)}
                />
              }
              trigger='click'
              placement='bottom'
              overlayClassName='notification-center-wrapper'
              open={notificationCenterOpened}
              onOpenChange={handleVisibleChange}
            >
              <div className='notification-bell'>
                <Badge
                  count={
                    notifications.filter(
                      n =>
                        !n.app_seen_at &&
                        (n.display_place === 'center' ||
                          n.display_place === 'both')
                    ).length
                  }
                >
                  <Icon component={notification_bell_ico} />
                </Badge>
              </div>
            </Popover>
          )} */}

          <div
            className={showSearch ? 'header__dropdown' : 'big header__dropdown'}
          >
            <Popover
              content={<AccountMenu user={user} userData={userData} />}
              trigger='click'
              placement='bottom'
              overlayClassName='account-popover-wrapper'
              open={accountPopoverOpen}
              onOpenChange={setAccountPopupoverOpen}
            >
              <Avatar className='header__avatar' size='large'>
                {userData.first_name && userData.last_name
                  ? `${userData.first_name[0].toUpperCase()}${userData.last_name[0].toUpperCase()}`
                  : (getNameFromEmail(user)?.[0], 'U').toUpperCase()}
              </Avatar>
            </Popover>
            {!user.email && (
              <div className='request-btn-wrap'>
                <Button onClick={handleOpenRequestModal}>Request Access</Button>
              </div>
            )}
            {userData.days_to_trial_expiration && (
              <div className={'trial-licence-wrapper'}>
                <DaysToTrialExpiration
                  daysLeft={userData.days_to_trial_expiration}
                />
              </div>
            )}
          </div>
          {isMobile && (
            <div>
              <MenuToggle />
            </div>
          )}
        </div>
      </div>

      <div className='right-space' />

      <AccessRequestModal
        isRequestModalOpen={isRequestModalOpen}
        isRequestModalOpenChange={isRequestModalOpenChange}
        analytics={analytics}
        sharedInsightData={sharedInsightData}
        guest={guest}
        linkReferrer={linkReferrer}
      />
    </header>
  )
}

export default Header
