import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Tooltip, Select, Popover, Button } from 'antd'
import Icon from '@ant-design/icons'
import { BenchmarkRanksFastestApp } from '../BenchmarkOverview/BenchmarkOverviewFastestApp'
import BenchmarkTable from './BenchmarkTable'
import { LOCATIONS_EXTRA } from '../../../shared/consts'

import { checkAvailLocations } from '../../../helpers/usersHelper'

import { getPerformanceRanks } from '../../../redux/actions/performanceActions'
import { daysAgoList } from '../../../shared/consts'
import { TimeFilter } from "../../../components/TimeFilter";
import { EXCLUDED_EVENT_NAMES } from '../constants'
import { useLocation, useNavigate } from 'react-router'
import calendar_ico from "../../../assets/images/icons/calendar.svg";
import caret_ico from "../../../assets/images/icons/caret-down.svg";
import dayjs from "dayjs";


function BenchmarkRanks (props) {
  const location = useLocation()
  const navigate = useNavigate()

  const {
    apps,
    deviceModels,
    categories,
    eventTypes,
    userData,
    infrastructure,
    categoriesEvents,
    analytics
  } = props
  const { Option } = Select
  const [ranks, updateRanks] = useState([])
  const [isSticky, setSticky] = useState(false)
  const [isOpenTimeFilter, isOpenTimeFilterChange] = useState(false)

  const ref = useRef(null)

  const handleScroll = () => {
    if (ref.current) {
      if (window.pageYOffset >= 225 || window.scrollY >= 225) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  const locations = checkAvailLocations(userData).map(loc => {
    return {
      loc: loc,
      ...LOCATIONS_EXTRA[loc || 'def']
    }
  })

  useEffect(() => {
    // Removing duplicates that appears when an app has multiple categories
    const uniqRanks = props.ranks.reduce(
      (prev, cur) =>
        prev.some(x => cur.app_id === x.app_id) ? prev : [...prev, cur],
      []
    )
    updateRanks(
      uniqRanks.map(rank => {
        return {
          ...apps.find(app => app.package === rank.package_name),
          rank: rank
        }
      })
    )
  }, [props.ranks])

  const initValues = new URLSearchParams(location.search)

  const [currentCategory, changeCurrentCategory] = useState(
    initValues.get('category_in') || ''
  )
  const [currentLocation, changeCurrentLocation] = useState(
    initValues.get('loc_in') || (locations.length >= 1 ? locations[0].loc : '')
  )

  const curLocInfr = infrastructure.find(loc => loc.loc === currentLocation)

  const filteredDeviceModels = deviceModels.filter(device => {
    if (!currentLocation || !curLocInfr) {
      return true
    }
    return (
      curLocInfr.origData.filter(
        ([locDeviceModel, locDevicePlatform, locDeviceOsVer]) =>
          locDeviceModel === device.model &&
          locDevicePlatform === device.platform &&
          locDeviceOsVer === device.os_version
      ).length > 0
    )
  })

  let filEventTypes = []

  if (currentCategory === '') {
    filEventTypes = eventTypes
  } else if (!categoriesEvents[currentCategory]) {
    filEventTypes = []
  } else {
    filEventTypes = eventTypes.filter(
      event => categoriesEvents[currentCategory].indexOf(event.name) !== -1
    )
  }

  filEventTypes = filEventTypes.filter(
    ev => !EXCLUDED_EVENT_NAMES.includes(ev.name)
  )

  const urlDeviceModel = initValues.get('model_in')
  const urlDeviceOSVer = initValues.get('os_version_in')

  const deviceModelIndex =
    urlDeviceModel && urlDeviceOSVer && currentLocation
      ? filteredDeviceModels.findIndex(
          model =>
            model.model === urlDeviceModel &&
            model.os_version === urlDeviceOSVer
        )
      : 0

  const [currentDeviceIndex, changeCurrentDeviceIndex] =
    useState(deviceModelIndex)
  const [currentEvent, changeCurrentEvent] = useState(
    initValues.get('event_in') ||
      (filEventTypes[0] && filEventTypes[0].name) ||
      ''
  )

  const [daysAgo, daysAgoChange] = useState(initValues.get('days_ago') || '60')
  const [customRange, сustomRangeСhange] = useState(true)

  const [customRangeTime, customRangeTimeChange] = useState(
    initValues.get('customRangeTime')
      ? [
          dayjs(initValues.get('customRangeTime').split(',')[0]),

          dayjs(initValues.get('customRangeTime').split(',')[1])
        ]
      : [dayjs().subtract(60, 'day'), dayjs(),]
  )
  const [timeFrameOpen, setTimeFrameOpen] = useState(false)

  useEffect(() => {
    const filters = {
      model_in: filteredDeviceModels[currentDeviceIndex].model,
      os_version_in: filteredDeviceModels[currentDeviceIndex].os_version,
      loc_in: currentLocation,
      event_in: currentEvent,
      category_in: currentCategory,
      days_ago: daysAgo,
      customRange: customRange ? '1' : ''
    }
    if (customRange) {
      filters['customRangeTime'] = customRangeTime.map(date =>
        date.format('YYYY-MM-DD')
      )
    } else {
      filters['days_ago'] = daysAgo
    }

    const gets = new URLSearchParams(filters).toString()
    navigate(`${location.pathname}?${gets}`, { replace: true })
    delete filters.customRange
    delete filters.customRangeTime
    delete filters.days_ago
    if (customRange) {
      filters.from_date = customRangeTime[0].format('YYYY-MM-DD')
      filters.until_date = customRangeTime[1].format('YYYY-MM-DD')
    } else {
      filters.from_date = moment()
        .subtract(daysAgo, 'days')
        .format('YYYY-MM-DD')
    }
    props.getPerformanceRanks(filters, true)
    analytics.analytic(`Ranks Page`, {
      category: currentCategory,
      event: currentEvent,
      device: filteredDeviceModels[currentDeviceIndex].model,
      location: currentLocation,
      daysAgo
    })
  }, [
    currentCategory,
    currentDeviceIndex,
    currentEvent,
    currentLocation,
    daysAgo,
    customRangeTime
  ])

  useEffect(() => {
    const newValues = new URLSearchParams(location.search)
    let filteredDevicesByNewLocations = filteredDeviceModels
    if (
      currentCategory != newValues.get('category_in') &&
      newValues.get('category_in')
    ) {
      changeCurrentCategory(newValues.get('category_in'))
    }

    if (
      currentEvent != newValues.get('event_in') &&
      newValues.get('event_in')
    ) {
      changeCurrentEvent(newValues.get('event_in'))
    }

    if (daysAgo != newValues.get('days_ago') && newValues.get('days_ago')) {
      daysAgoChange(newValues.get('days_ago'))
    }

    if (
      newValues.get('from_date') &&
      newValues.get('until_date') &&
      customRangeTime[0] != newValues.get('from_date') &&
      customRangeTime[0] != newValues.get('until_date')
    ) {
      сustomRangeСhange(true)
      customRangeTimeChange([
        moment(newValues.get('from_date')),
        moment(newValues.get('until_date'))
      ])
    }

    if (currentLocation != newValues.get('loc_in') && newValues.get('loc_in')) {
      changeCurrentLocation(newValues.get('loc_in'))
      filteredDevicesByNewLocations = deviceModels.filter(device => {
        const curLocInfr = infrastructure.find(
          loc => loc.loc === newValues.get('loc_in')
        )
        return (
          curLocInfr.origData.filter(
            ([locDeviceModel, locDevicePlatform, locDeviceOsVer]) =>
              locDeviceModel === device.model &&
              locDevicePlatform === device.platform &&
              locDeviceOsVer === device.os_version
          ).length > 0
        )
      })
    }

    if (
      (filteredDevicesByNewLocations[currentDeviceIndex].model !=
        newValues.get('model_in') ||
        filteredDevicesByNewLocations[currentDeviceIndex].os_version !=
          newValues.get('os_version_in')) &&
      newValues.get('model_in') &&
      newValues.get('os_version_in')
    ) {
      changeCurrentDeviceIndex(
        filteredDevicesByNewLocations.findIndex(
          model =>
            model.model === newValues.get('model_in') &&
            model.os_version === newValues.get('os_version_in')
        )
      )
    }
  }, [location.search])

  const getModelData = device_model => {
    if (!device_model) {
      return null
    }
    const device = deviceModels.find(dev => dev.model === device_model)
    return (
      <div className='performance-tooltip'>
        <div>
          <span>Category:</span> {device.category}
        </div>
        <div>
          <span>Manufacturer:</span> {device.manufacturer}
        </div>
        <div>
          <span>Model:</span> {device.model}
        </div>
        <div>
          <span>CPU:</span> {device.cpu}
        </div>
        <div>
          <span>RAM:</span> {device.ram}
        </div>
      </div>
    )
  }

  const currentDevice = filteredDeviceModels[currentDeviceIndex]
  return (
    <div className='benchmark-ranks-page'>
      <h1 className='h1-title'>
        <div className='header'>
          <div className='medium'>Benchmark</div> Ranks
        </div>
      </h1>
      <div className='benchmark__sub-header'>
        <span className='benchmark-select-label'>Showing data from</span>
        <Popover
          placement='bottom'
          title={null}
          destroyTooltipOnHide={true}
          open={timeFrameOpen}
          onOpenChange={setTimeFrameOpen}
          content={
            <TimeFilter
              daysAgoList={daysAgoList}
              value={customRangeTime.map(x => dayjs(x))}
              onChange={customRangeTimeChange}
              onClose={() => setTimeFrameOpen(false)}
            />
          }
          trigger='click'
          overlayClassName='filter-wrap all-discoveries all-discoveries-time'
        >
          <div className='select-filter'>
            <div className='select-body'>
              <img src={calendar_ico}/>
              <span>
            {`${customRangeTime[0].format(
                'MMM DD, YYYY'
            )} - ${customRangeTime[1].format('MMM DD, YYYY')}`}
          </span>
              <img src={caret_ico} style={{marginLeft: '10px'}}/>
            </div>
          </div>
        </Popover>
      </div>
      <div className='hr-row' />

      <h3 className='title'>Event Leaderboard</h3>

      <div className={`ranks-filters ${isSticky ? 'fixed' : ''}`} ref={ref}>
        <div>
          <div className='ranks-filter ranks-filter-category'>
            <div className='filter-label'>Category</div>
            <Select
              style={{ width: '18rem' }}
              onChange={val => {
                changeCurrentCategory(val)
                if (categoriesEvents[val]) {
                  changeCurrentEvent(
                    categoriesEvents[val].indexOf(eventTypes[0].name) !== -1
                      ? eventTypes[0].name
                      : categoriesEvents[val][0]
                  )
                } else {
                  changeCurrentEvent('')
                }
              }}
              value={currentCategory}
            >
              <Option value=''>All</Option>
              {categories.map(cat => (
                <Option value={cat.name} key={cat.name}>
                  {cat.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className='ranks-filter ranks-filter-event'>
            <div className='filter-label'>Event</div>

            <div className='select-wrapper'>
              <Select
                style={{ width: '18rem' }}
                value={currentEvent}
                onChange={changeCurrentEvent}
              >
                {filEventTypes.map(event => (
                  <Option value={event.name} key={event.name}>
                    {event.title}
                  </Option>
                ))}
              </Select>

              {filEventTypes.find(ev => ev.name === currentEvent) && (
                <Tooltip
                  placement='top'
                  title={
                    filEventTypes.find(ev => ev.name === currentEvent)
                      .description
                  }
                >
                  <Icon type='info-circle' className='ranks-filter-info' />
                </Tooltip>
              )}
            </div>
          </div>

          <div className='ranks-filter ranks-filter-device'>
            <div className='filter-label'>Device</div>
            <div className='select-wrapper'>
              <Select
                style={{ width: '23.5rem' }}
                labelInValue
                value={{
                  value: `${
                    currentDevice.product_name || currentDevice.model
                  } (${currentDevice.platform} ${currentDevice.os_version}`,
                  label: (
                    <div>
                      {currentDevice.product_name || currentDevice.model}
                      <span className='device-version-os'>
                        (
                        {currentDevice.platform === 'Android'
                          ? 'Andr'
                          : currentDevice.platform}{' '}
                        {currentDevice.os_version})
                      </span>
                    </div>
                  )
                }}
                onChange={data => changeCurrentDeviceIndex(data.key)}
              >
                {filteredDeviceModels.map((device, index) => (
                  <Option value={index} key={index}>
                    <>
                      {device.product_name}
                      <span>
                        {' '}
                        (
                        {device.platform === 'Android'
                          ? 'Andr'
                          : device.platform}{' '}
                        {device.os_version})
                      </span>
                    </>
                    <Tooltip placement='top' title={getModelData(device.model)}>
                      <Icon type='info-circle' className='ranks-filter-info' />
                    </Tooltip>
                  </Option>
                ))}
              </Select>
              {currentDeviceIndex !== -1 && (
                <Tooltip
                  placement='top'
                  title={getModelData(
                    filteredDeviceModels[currentDeviceIndex].model
                  )}
                >
                  <Icon type='info-circle' className='ranks-filter-info' />
                </Tooltip>
              )}
            </div>
          </div>

          <div className='ranks-filter ranks-filter-location'>
            <div className='filter-label'>Location</div>
            <Select
              style={{ width: '18rem' }}
              value={currentLocation}
              onChange={changeCurrentLocation}
            >
              {locations.map(loc => (
                <Option value={loc.loc} key={loc.loc}>
                  <span
                    className={`flag-icon fi fi-${loc.loc.toLowerCase()}`}
                    style={{ marginRight: '1rem' }}
                  />
                  {loc.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      {ranks && ranks.length > 0 && (
        <>
          <h3 className='title top-3'>Top 3</h3>
          <div className='ranks-top-3'>
            {ranks[0] && (
              <BenchmarkRanksFastestApp
                app={ranks[0]}
                ranks
                winner
                hideLoc={true}
                place={
                  <div className='ranks-top-place'>
                    <strong>#1st</strong> Place
                  </div>
                }
                apps={apps}
                lastRes={ranks[ranks.length - 1].rank.duration_s}
              />
            )}
            {ranks[1] && (
              <BenchmarkRanksFastestApp
                app={ranks[1]}
                ranks
                hideLoc={true}
                place={
                  <div className='ranks-top-place'>
                    <strong>#2nd</strong> Place
                  </div>
                }
                apps={apps}
                lastRes={ranks[ranks.length - 1].rank.duration_s}
              />
            )}
            {ranks[2] && (
              <BenchmarkRanksFastestApp
                app={ranks[2]}
                ranks
                hideLoc={true}
                place={
                  <div className='ranks-top-place'>
                    <strong>#3rd</strong> Place
                  </div>
                }
                apps={apps}
                lastRes={ranks[ranks.length - 1].rank.duration_s}
              />
            )}
          </div>
        </>
      )}

      <BenchmarkTable
        apps={ranks}
        daysAgo={daysAgo}
        customRangeTime={customRangeTime.map(x => moment(x.format("YYYY-MM-DD")))}
        customRange={customRange}
        currentDevice={filteredDeviceModels[currentDeviceIndex].model}
        currentOSVersion={filteredDeviceModels[currentDeviceIndex].os_version}
      />
    </div>
  )
}

export default connect(
  ({ appData, performance, userData }) => ({
    user: appData.user,
    userData: userData,
    apps: performance.apps || [],
    deviceModels: performance.deviceModels || [],
    categories: performance.categories || [],
    locations: performance.locations || [],
    eventTypes: performance.eventTypes || [],
    infrastructure: performance.infrastructure,
    categoriesEvents: performance.categoriesEvents,
    ranks: performance.ranks || [],
    analytics: appData.user.analytics
  }),
  { getPerformanceRanks }
)(BenchmarkRanks)
