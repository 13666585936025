import './WGroupedBarChart.scss'

import { Tooltip } from 'antd'
import { merge } from 'lodash'
import React, { ReactElement } from 'react'

import { GroupedBarChart } from '@carbon/charts-react'

import { IDataPoint } from '../../../interfaces/Product'

export const namesDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

export interface Iimage {
  icon: string | undefined
  text: string
}

interface IProps {
  data: IDataPoint[]
  options?: any
  images?: Iimage[]
  groupLegend?: string[]
  isMaxPerGroup?: boolean
}

const WGroupedBarChart = ({
  data,
  options = {},
  images = [],
  groupLegend,
  isMaxPerGroup = true
}: IProps): ReactElement => {
  let k = 0
  const defOptions = {
    axes: {
      bottom: {
        title: '',
        mapsTo: 'key',
        scaleType: 'labels'
      },
      left: {
        mapsTo: 'value',
        title: '',
        margins: {
          left: 100
        },

        scaleType: 'linear'
      }
    },
    grid: {
      x: {
        enabled: false
      }
    },
    legend: {
      enabled: false
    },
    bars: {
      maxWidth: 26
    },
    color: {
      scale: {}
    },
    getFillColor: () => {
      return maxValueArray.includes(k++) ? '#000000' : '#16161680'
    },
    tooltip: {
      customHTML: (obj: any) => {
        return `<div class="g-bar-tooltip">${obj[0].value}</div>`
      }
    },
    height: '40rem'
  }

  const fullOptions = merge(defOptions, options)

  //@ts-ignore
  const setOfGroup = [...new Set(data.map(d => d.key))]

  // Hello CarbonCharts team, your options.getFillColor doesn't work properly (get 1 arg for data point instead 3)
  const maxValueArray: Array<number> = []
  data
    .sort((a1, a2) =>
      setOfGroup.indexOf(a1.key) < setOfGroup.indexOf(a2.key) ? -1 : 1
    )
    .forEach((d, i) => {
      const setOfCurKey = isMaxPerGroup
        ? data.filter(d2 => d2.key === d.key)
        : data
      const maxFromCurKeySet = setOfCurKey.reduce(
        (acc, d3) => (d3.value > acc ? d3.value : acc),
        0
      )
      if (maxFromCurKeySet === d.value) {
        maxValueArray.push(i)
      }
    })

  return (
    <div className='g-bar-chart-wrapper'>
      <GroupedBarChart
        data={data}
        //@ts-ignore
        options={fullOptions}
      />
      <div className='legend'>
        {setOfGroup.map((g, i) => (
          <div className='legend-item' key={i}>
            {groupLegend && (
              <div className='legend-days'>
                {groupLegend.map((d: string) => (
                  <span className='days-week' key={d}>
                    {d}
                  </span>
                ))}
              </div>
            )}
            <div className='legend-app-icon'>
              {images[i] && (
                <Tooltip title={images[i].text}>
                  <img src={images[i].icon} alt={images[i].text} />
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WGroupedBarChart
