import './AppDrawer.scss'

import { Button, Checkbox, Input } from 'antd'
import x_ico from 'assets/images/icons/x-no-outline.svg'
import { CloseShowResults } from 'components/CloseShowResults'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import React, { useMemo, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { SELECTED_APPS_MAX_NUM } from "../../shared/consts";

type App = {
  id: number
  name: string
  thumbnail: string
  count?: number
}

type Props = {
  appsList: App[]
  currentAppsIds: number[]
  onAppsSelected: (appIds: number[]) => void
  closeDrawer?: () => void
}

const MAX_APP_NAME = 20

const fitToMaxLength = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str
  }

  return `${str.substring(0, maxLength)}...`
}

export const AppDrawer = ({
  appsList,
  currentAppsIds,
  onAppsSelected,
  closeDrawer
}: Props) => {
  const isMobile = isMobileDevice()
  const [searchApp, setSearchApp] = useState('')

  const filteredApps = useMemo(
    () =>
      appsList
        .filter(app => app.name.toLowerCase().includes(searchApp.toLowerCase()))
        .map(app => ({
          ...app,
          isSelected: currentAppsIds.includes(app.id)
        })),
    [appsList, searchApp, currentAppsIds]
  )

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchApp(e.target.value)
  }

  const handleAppSelection = (selectedApp: App) => {
    const isAlreadyChecked = currentAppsIds.some(
      appId => appId === selectedApp.id
    )

    if (!isAlreadyChecked && currentAppsIds.length >= SELECTED_APPS_MAX_NUM) {
      return
    }

    if (isAlreadyChecked) {
      onAppsSelected(currentAppsIds.filter(appId => appId !== selectedApp.id))
    } else {
      onAppsSelected(currentAppsIds.concat(selectedApp.id))
    }
  }

  const handleSetToDefault = () => onAppsSelected(appsList.slice(0, SELECTED_APPS_MAX_NUM).map(x => x.id))

  return (
    <div className='app-drawer-container'>
      <div className='app-drawer-top-section'>
        {isMobile && (
          <div className='app-drawer-close' onClick={closeDrawer}>
            <img src={x_ico} />
          </div>
        )}
        <div className='apps-drawer-title'>
          <div className='apps-drawer-title-main'>
            Apps ({currentAppsIds.length})
          </div>
          <div className='max-allowed'>
            You can select up to {SELECTED_APPS_MAX_NUM} competitors
          </div>
        </div>
        <div className='apps-drawer-selected-count'>
          <div>{`${currentAppsIds.length} Selected`}</div>
          <div className="apps-drawer-set-to-default">
            <Button type="link" color="default" onClick={handleSetToDefault}>Reset to default</Button >
          </div>
        </div>
        <div className='apps-search filters-search'>
          <Input
            size='large'
            placeholder='Search For App'
            suffix={<SearchOutlined />}
            onChange={onSearchChange}
            value={searchApp}
          />
        </div>
      </div>
      <div
        className='apps-selection'
        style={isMobile ? { paddingBottom: '100px' } : {}}
      >
        {filteredApps.map(app => (
          <div
            key={app.id}
            className={`app-selection-item filter-checkbox ${
              app.isSelected ? 'filter-checked' : ''
            }`}
            onClick={() => {
              handleAppSelection(app)
            }}
          >
            <div className='app-selection-item-left'>
              <Checkbox checked={app.isSelected} value={app.id} />
              <img src={app.thumbnail} />
              <div className='app-name'>
                {fitToMaxLength(app.name, MAX_APP_NAME)}
              </div>
            </div>
            {!!app.count && <div className='app-count'>({app.count})</div>}
          </div>
        ))}
      </div>
      {isMobile && closeDrawer && (
        <div className='apps-close-mobile'>
          <CloseShowResults onClose={closeDrawer} />
        </div>
      )}
    </div>
  )
}
