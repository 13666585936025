// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard__container .dashboard__main .mkc-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  height: 100%;
}
.dashboard__container .dashboard__main .mkc-container .breadcrumb-disabled {
  color: rgba(0, 0, 0, 0.45) !important;
}`, "",{"version":3,"sources":["webpack://./src/containers/MarketingCampaings/MarketingCampaignsPage.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;AADF;AAGE;EACE,qCAAA;AADJ","sourcesContent":["@import '../../sass-config/abstracts/variables.scss';\n\n.dashboard__container .dashboard__main .mkc-container {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  padding: 0;\n  height: 100%;\n\n  .breadcrumb-disabled {\n    color: rgba(0, 0, 0, 0.45) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
