// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promo-days-of-week .promo-days-chart-container {
  height: 46rem;
  margin-bottom: 2.4rem;
}`, "",{"version":3,"sources":["webpack://./src/containers/MarketingOverview/components/charts/PromoDays/PromoDays.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,qBAAA;AAFJ","sourcesContent":["@import '../../../mixins.scss';\n\n.promo-days-of-week {\n  .promo-days-chart-container {\n    height: 46rem;\n    margin-bottom: 2.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
