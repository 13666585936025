import * as actionTypes from './_actionsTypes'

export const getUserName = email => ({
  type: actionTypes.GET_USER_NAME,
  payload: {
    email: email
  }
})

export const getUserNameSuccess = ({ data }) => ({
  type: actionTypes.GET_USER_NAME_SUCCESS,
  payload: data
})

export const setUserName = (
  first_name,
  last_name,
  picture_url,
  role,
  department,
  phone
) => {
  return {
    type: actionTypes.SET_USER_NAME,
    payload: {
      first_name,
      last_name,
      picture_url,
      role,
      department,
      phone
    }
  }
}

export const setUserPass = (oldPassword, newPassword) => ({
  type: actionTypes.SET_USER_PASS,
  payload: {
    oldPassword,
    newPassword
  }
})

export const setUserPassSuccessReset = () => ({
  type: actionTypes.SET_USER_PASS_SUCCESS_RESET
})

export const setUserPassError = ({ data }) => ({
  type: actionTypes.SET_USER_PASS_ERROR,
  payload: data
})

export const setUserPassSuccess = () => ({
  type: actionTypes.SET_USER_PASS_SUCCESS
})

export const setUserDataSuccessReset = () => ({
  type: actionTypes.SET_USER_NAME_SUCCESS_RESET
})

export const setUserPic = pic => ({
  type: actionTypes.SET_USER_PIC,
  payload: {
    pic
  }
})

export const setUserPicSuccess = ({ data }) => ({
  type: actionTypes.SET_USER_PIC_SUCCESS,
  payload: data
})

export const deleteUserPic = () => ({
  type: actionTypes.DELETE_USER_PIC
})

export const deleteUserPicSuccess = ({ data }) => ({
  type: actionTypes.DELETE_USER_PIC_SUCCESS,
  payload: data
})

export const emailPrefSet = subscriptions => ({
  type: actionTypes.SET_EMAIL_PREFERENCES,
  payload: { subscriptions }
})

export const emailPrefSetSuccess = subscriptions => ({
  type: actionTypes.SET_EMAIL_PREFERENCES_SUCCESS,
  payload: { subscriptions }
})

export const getAccessRequests = () => ({
  type: actionTypes.GET_ACCESS_REQUESTS
})

export const getAccessRequestsSuccess = requests => ({
  type: actionTypes.GET_ACCESS_REQUESTS_SUCCESS,
  payload: { requests }
})

export const setAccessRequestStatus = data => ({
  type: actionTypes.SET_ACCESS_REQUEST_STATUS,
  payload: data
})

export const setAccessRequestStatusSuccess = request => ({
  type: actionTypes.SET_ACCESS_REQUEST_STATUS_SUCCESS,
  payload: { request }
})
