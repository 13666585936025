import React, { Component } from 'react'
import { isEqual } from 'lodash'
import { connect } from 'react-redux'
import { askInsightQuestion } from '../../../../redux/actions/dashboardActions'
import ViewHeaderChatModal from '../viewHeaderChatModal/viewHeaderChatModal'
import { message, Tooltip, Spin, Button } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import chatIcon from '../../../../assets/images/icons/view/chat.svg'

export class ViewHeaderChat extends Component {
  constructor (props) {
    super(props)

    this.state = {
      modalShow: false,
      question: '',
      email: this.props.email
    }
  }

  submitQuestion = () => {
    const { insightId, metadata, currentInsightURL } = this.props
    const { question, email } = this.state
    // const analyticMsg = `* Question - ${metadata.package_name} - Card ${insightId}: ${title} :* email:${email} question:${question}`;
    // analytics.analytic(analyticMsg);
    this.props.askInsightQuestion({
      appName: (metadata && metadata.display_name) || '',
      insightID: insightId,
      insightURL: `https://app.watchful.ai${currentInsightURL}`,
      userEmail: email,
      question
    })
    message.loading({ content: 'Sending question', icon: <div /> })
    this.props.analytics.analytic(
      `Send question from marketing insight page - ${question}`
    )
  }

  componentDidUpdate (prevProps) {
    if (
      !isEqual(
        this.props.insightQuestionSuccess,
        prevProps.insightQuestionSuccess
      )
    ) {
      if (
        typeof this.props.insightQuestionSuccess == 'boolean' &&
        !this.props.isSendingInsightQuestion
      ) {
        if (this.props.insightQuestionSuccess) {
          message.success('Question sent successfully!')
        } else {
          message.error(
            'Unknown error occurred while sending the question. Please try again.'
          )
        }
      }
    }
  }

  showModal = () => {
    this.setState({
      modalShow: true,
      question: '',
      email: this.props.email
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  closeModal = () => {
    this.setState({ modalShow: false })
  }

  render () {
    const { modalShow, question, email } = this.state
    const { page } = this.props
    const tooltipMsg = this.props.isSendingInsightQuestion
      ? 'Sending question'
      : 'Ask a question'
    return (
      <div className='view__header__chat'>
        {page === 'overview' ? (
          <Button icon={<QuestionCircleOutlined />} onClick={this.showModal}>
            Ask
          </Button>
        ) : (
          <Tooltip title={tooltipMsg}>
            <Spin size='small' spinning={this.props.isSendingInsightQuestion}>
              <img
                src={chatIcon}
                alt='chat'
                onClick={this.showModal}
                data-testid='showMadal'
              />
            </Spin>
          </Tooltip>
        )}
        <ViewHeaderChatModal
          closeModal={this.closeModal}
          modalShow={modalShow}
          submitQuestion={this.submitQuestion.bind(this)}
          question={question}
          email={email}
          handleChange={this.handleChange}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ appData }) => {
  return {
    insightQuestionSuccess: appData.insightQuestionSuccess,
    isSendingInsightQuestion: appData.isSendingInsightQuestion,
    analytics: appData.user.analytics
  }
}

export default connect(mapStateToProps, { askInsightQuestion })(ViewHeaderChat)
