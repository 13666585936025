import { createSelector } from 'reselect';

import { INotification } from '../../../interfaces/Notification';
import { IDisWithApp, IState } from '../../../interfaces/Product';

const getNotifications = (state: IState): INotification[] => state.notifications.notifications;
const getAllInsights = (state: IState): IDisWithApp[] => state.appData.allInsights;
const getAllVersions = (state: IState): IDisWithApp[] => state.appData.allVersions;

export const notificationsSelector = (place: string) => createSelector(
  [getNotifications, getAllInsights, getAllVersions],
  (notifications: INotification[]): INotification[] => {
    return notifications.
      filter((n: INotification): boolean => n.display_place === place || n.display_place === 'both')
  });

