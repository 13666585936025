import './MarketingCampaignsTable.scss'

import { Button, Popover, Tooltip } from 'antd'
import { ReactComponent as InfoIco } from 'assets/images/icons/info-new-dark.svg'
import info_ico from 'assets/images/icons/info-new.svg'
import useTableHeight from 'containers/MarketingCampaings/hooks/useTableHeight'
import { trackMarketingEvent } from 'containers/MarketingCampaings/utils/analytics'
import {
  getPriority,
  getShortenedString
} from 'containers/MarketingCampaings/utils/helpers'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import {
  MaterialReactTable,
  MRT_Column,
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_SortingState,
  MRT_ToggleGlobalFilterButton,
  MRT_Virtualizer,
  useMaterialReactTable
} from 'material-react-table'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { DownloadOutlined } from '@ant-design/icons'
import { IconButton } from '@material-ui/core'
import { LinearProgress } from '@mui/material'

import { ChangeCampaignsFilterType } from '../../hooks/useCampaignsFilters'
import {
  CAMPAIGNS_FILTERS,
  MARKETING_INSIGHT_TYPES,
  TABLE_SORT_FIELDS,
  TYPE_TO_CHANNEL
} from '../../utils/conts'
import {
  App,
  Channel,
  Insight,
  UserRoleType,
  UserType
} from '../../utils/types'
import { MarketingInsightPreviewModal } from './MarketingInsightPreviewModal'

type TableDataRow = Insight & {
  app: App
  personasAndRoles: {
    personas: UserType[]
    personaRoles: UserRoleType[]
  }
}

type Props = {
  handleChangeFilter: ChangeCampaignsFilterType
  tableData: TableDataRow[]
  isFetching: boolean
  totalFetched: number
  totalDBRowCount: number
  fetchNextPage: () => void
  userTypes: UserType[]
  userRoleTypes: UserRoleType[]
  selectedAppIds: string[]
  selectedInsight: any
  currentApp: App
}

const MAX_USER_LEN = 35

const getPersonasAndRoles = (renderedCellValue: any) => {
  const personas = (renderedCellValue.personas ?? []).filter(
    (persona: any) => persona.roles == null || persona.roles.length === 0
  )
  const roles = renderedCellValue.personaRoles ?? []
  const personasAndRoles = roles.concat(personas)
  return personasAndRoles
}

const formatDataForExport = (tableData: TableDataRow[]) => {
  return tableData?.map((row: TableDataRow) => {
    const personaTitles = row.personas.map(persona => persona.title)
    const roleNames = row.personaRoles.map(role => role.name)

    return {
      app: row.app.displayName,
      date: row.startTime,
      channel: TYPE_TO_CHANNEL[row.type],
      users: personaTitles.concat(roleNames).join(';'),
      message: row.title,
      priority: row.priority,
      media: row.thumbnail
    }
  })
}

export const MarketingCampaignsTable = ({
  tableData,
  handleChangeFilter,
  isFetching,
  totalDBRowCount,
  totalFetched,
  fetchNextPage,
  selectedInsight,
  userTypes,
  userRoleTypes,
  selectedAppIds,
  currentApp
}: Props) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const divContainerRef = useRef<HTMLDivElement>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null)

  const handlePreviewToggle = (insightId: string) => () => {
    handleChangeFilter(CAMPAIGNS_FILTERS.insight_id, insightId)
  }

  const renderTooltipHeader = (
    tooltipText: string,
    column: MRT_Column<Insight, unknown>
  ) => {
    return (
      <div className='info-header'>
        {column.columnDef.header}
        <Tooltip
          overlayClassName='info-header-tooltip'
          placement='top'
          title={tooltipText}
          onOpenChange={() => {
            trackMarketingEvent('information_clicked', {
              column_name: column.columnDef.header
            })
          }}
        >
          <img src={info_ico} />
        </Tooltip>
      </div>
    )
  }

  const handleExportData = () => {
    const csvConfig = mkConfig({
      filename: `promotions_repository_${Date.now()}`,
      fieldSeparator: ',',
      decimalSeparator: '.',
      useKeysAsHeaders: true
    })

    const csv = generateCsv(csvConfig)(formatDataForExport(tableData))

    download(csvConfig)(csv)
  }

  const columns = useMemo<MRT_ColumnDef<Insight>[]>(
    () => [
      {
        accessorKey: 'app',
        header: 'App',
        size: 120,
        grow: false,
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }: any) => {
          return (
            <div className='app-icon'>
              <img src={renderedCellValue.icon} />
            </div>
          )
        }
      },
      {
        accessorKey: 'thumbnail',
        header: 'Media',
        size: 150,
        enableSorting: false,
        grow: false,
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue, row }: any) => {
          const isPush =
            row.original.type ===
            MARKETING_INSIGHT_TYPES.push_notification_campaign

          const imageSrc = isPush
            ? row.original.thumbnailOriginal || row.original.thumbnail
            : renderedCellValue

          return imageSrc ? (
            <Popover
              title={null}
              placement='left'
              content={
                <div>
                  <img
                    style={{
                      maxWidth: '40vw',
                      maxHeight: '40vw',
                      overflow: 'scroll'
                    }}
                    src={imageSrc}
                  />
                </div>
              }
            >
              <div className={`image-container ${isPush ? 'contained' : ''}`}>
                <img src={imageSrc} />
              </div>
            </Popover>
          ) : null
        },
        Header: ({ column }) =>
          renderTooltipHeader('Sample image from the campaign.', column)
      },
      {
        accessorKey: 'startTime',
        header: 'Date',
        size: 140,
        grow: false,
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }: any) => {
          const currentDate = new Date(renderedCellValue)
          const userLocale = navigator.language || 'en-US'
          const formattedDate = new Intl.DateTimeFormat(userLocale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'UTC'
          }).format(currentDate)

          return <span>{formattedDate}</span>
        },
        Header: ({ column }) =>
          renderTooltipHeader('Date the campaign was delivered', column)
      },
      {
        accessorKey: 'title',
        enableSorting: false,
        header: 'Promo title',
        size: 340,
        enableGlobalFilter: true,
        Cell: ({ renderedCellValue }: any) => {
          return <span className='message-content'>{renderedCellValue}</span>
        }
      },
      {
        accessorKey: 'focusArea',
        enableSorting: false,
        header: 'Promo type',
        size: 140,
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }: any) => {
          return <span className='message-content'>{renderedCellValue}</span>
        }
      },
      {
        accessorKey: 'type',
        header: 'Channel',
        size: 200,
        grow: false,
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }: any) => (
          <span>{TYPE_TO_CHANNEL[renderedCellValue]}</span>
        ),
        Header: ({ column }) =>
          renderTooltipHeader(
            'Marketing channel used (i.e. email, push, in-app banner)',
            column
          )
      },
      {
        accessorKey: 'personasAndRoles',
        header: 'User Types Seen',
        id: 'userTypes',
        size: 350,
        enableSorting: false,
        grow: false,
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }: any) => {
          const personasAndRoles = getPersonasAndRoles(renderedCellValue)

          return (
            <div className='list-container'>
              {personasAndRoles.slice(0, 1).map((type: any) => (
                <div
                  key={type.title ?? type.name}
                  className='item'
                  style={{
                    backgroundColor: type.bgColor,
                    color: type.textColor
                  }}
                >
                  <div className='item-text'>
                    {getShortenedString(type?.title ?? type.name, MAX_USER_LEN)}
                  </div>
                  <div className='item-icon'>
                    <Tooltip
                      overlayClassName='info-header-tooltip'
                      placement='top'
                      title={type?.description || 'No description'}
                    >
                      <InfoIco fill={type.textColor} />
                    </Tooltip>
                  </div>
                </div>
              ))}
              {personasAndRoles.length > 1 && (
                <Popover
                  title={null}
                  placement='left'
                  content={
                    <div className='marketing-list-container-preview'>
                      {personasAndRoles.map((type: any) => (
                        <div
                          key={type.title ?? type.name}
                          className='item'
                          style={{
                            backgroundColor: type.bgColor,
                            color: type.textColor
                          }}
                        >
                          <div className='item-text'>
                            {type?.title ?? type.name}
                          </div>
                          <div className='item-icon'>
                            <Tooltip
                              overlayClassName='info-header-tooltip'
                              placement='top'
                              title={type?.description || 'No description'}
                            >
                              <InfoIco fill={type.textColor} />
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div className='view-more'>
                    +{personasAndRoles.length - 1}
                  </div>
                </Popover>
              )}
            </div>
          )
        },
        Header: ({ column }) =>
          renderTooltipHeader(
            "All user types who received the campaign. To see which user segments were not targeted, click on the 'insight' row.",
            column
          )
      },
      {
        accessorKey: 'personasAndRoles',
        id: 'mergedCommunication',
        header: 'Occurrence',
        size: 180,
        enableGlobalFilter: false,
        grow: false,
        Cell: ({ renderedCellValue }: any) => {
          const personasAndRoles = getPersonasAndRoles(renderedCellValue)

          return (
            <span className='merged-communication'>
              {personasAndRoles.length}
            </span>
          )
        },
        Header: ({ column }) =>
          renderTooltipHeader(
            'Number of occurrences of this communication to different user types.',
            column
          )
      }
    ],
    []
  )

  const fetchMoreOnBottomReached = (
    containerRefElement?: HTMLDivElement | null
  ) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement
      //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !isFetching &&
        totalFetched < totalDBRowCount
      ) {
        fetchNextPage()
      }
    }
  }

  const tableHeight = useTableHeight(divContainerRef.current)

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableColumnOrdering: false,
    enableFullScreenToggle: false,
    enableExpandAll: false,
    enableExpanding: false,
    enableStickyHeader: true,
    enableRowVirtualization: true,
    rowVirtualizerInstanceRef: rowVirtualizerInstanceRef,
    rowVirtualizerOptions: { overscan: 4 },
    state: {
      showProgressBars: isFetching,
      sorting
    },
    localization: { noRecordsToDisplay: '' },
    renderTopToolbar: () => isFetching && <LinearProgress />,
    onSortingChange: setSorting,
    muiTableContainerProps: {
      id: 'mkc-table',
      sx: {
        height: tableHeight
      },
      ref: tableContainerRef,
      onScroll: (
        event: any //add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target)
    },
    initialState: {
      columnVisibility: {
        'mrt-row-expand': false
      },
      showGlobalFilter: false
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        '&:hover td': {
          cursor: 'pointer',
          backgroundColor: '#F0F4FF !important'
        }
      },
      onClick: handlePreviewToggle(row.original.id.toString())
    }),
    muiTableBodyCellProps: {
      sx: {
        color: '#616065',
        fontSize: '1.4rem',
        fontFamily: 'Inter',
        fontWeight: 400,
        textTransform: 'capitalize',
        maxHeight: '120px !important',
        padding: '1.6rem !important',
        display: 'flex',
        alignItems: 'center',
        borderBottom: 0
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '1.4rem',
        display: 'flex',
        justifyContent: 'center',
        padding: '0.8rem 1.6rem !important',
        backgroundColor: '#f5f5f5',
        color: '#44434A',
        fontWeight: 400,
        fontFamily: 'Inter',
        textTransform: 'capitalize',
        borderBottom: 0,
        borderRight: '1px solid #FFFFFF',
        height: '4rem'
      }
    },
    enableFilterMatchHighlighting: true,
    enableGlobalFilterRankedResults: true
  })

  useEffect(() => {
    const sort = sorting[0]

    if (sort) {
      const { id, desc } = sort
      const currentSortStr = desc
        ? TABLE_SORT_FIELDS.desc[id]
        : TABLE_SORT_FIELDS.asc[id]

      trackMarketingEvent('sort_column_clicked', {
        sort_column: id
      })

      handleChangeFilter(CAMPAIGNS_FILTERS.sort, currentSortStr)
    } else {
      handleChangeFilter(CAMPAIGNS_FILTERS.sort, '')
    }
  }, [sorting])

  useEffect(() => {
    const tableContainer = document.getElementById('mkc-table')
    const topScrollContainer = document.getElementById('mkc-table-top-scroll')
    const childScroll = document.getElementById('mkc-table-top-scroll-content')

    // connect the bottom scroll to top scroll
    if (tableContainer && topScrollContainer && childScroll) {
      // Decrease the scrollbar margins
      childScroll.style.width = `${tableContainer.scrollWidth - 64}px`

      topScrollContainer.onscroll = () => {
        tableContainer.scrollLeft = topScrollContainer.scrollLeft
      }

      tableContainer.onscroll = () => {
        topScrollContainer.scrollLeft = tableContainer.scrollLeft
      }
    }

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  return (
    <div className='mck-table-container' ref={divContainerRef}>
      <div className='mck-top-control'>
        <div className='mck-table-actions'>
          <MRT_GlobalFilterTextField table={table} />
          <Tooltip title='Search in table'>
            <MRT_ToggleGlobalFilterButton table={table} />
          </Tooltip>
          <Tooltip title='Export to CSV'>
            <IconButton onClick={handleExportData}>
              <DownloadOutlined />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className='mkc-table-container'>
        <MaterialReactTable table={table} />
      </div>
      <MarketingInsightPreviewModal
        appId={currentApp?.id.toString()}
        appName={currentApp?.displayName}
        open={selectedInsight ? true : false}
        insightId={selectedInsight?.id}
        type={selectedInsight?.type}
        title={selectedInsight?.title}
        date={selectedInsight?.start_time}
        priority={selectedInsight?.priority || ''}
        onClose={handlePreviewToggle('')}
        focusArea={selectedInsight?.focusArea || ''}
        messageContent=''
        userTypes={userTypes.filter(
          userType =>
            !Array.isArray(userType.roles) || userType.roles.length === 0
        )}
        userRoleTypes={userRoleTypes}
        userTypesSeen={
          selectedInsight?.personas.filter(
            (persona: any) =>
              !Array.isArray(persona.roles) || persona.roles.length === 0
          ) || []
        }
        userRoleTypesSeen={selectedInsight?.personaRoles || []}
        insightDescription={selectedInsight?.description}
        assets={
          selectedInsight?.assets?.toSorted(
            (a: any, b: any) => a.order_index - b.order_index
          ) || []
        }
        thumbnail={selectedInsight?.thumbnail}
        metaData={selectedInsight?.metaData}
      />
      <div className='mkc-table-top-scroll' id='mkc-table-top-scroll'>
        <div id='mkc-table-top-scroll-content'>&nbsp;</div>
      </div>
    </div>
  )
}
