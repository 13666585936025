import axios from 'axios'
import config from '../../shared/config'

import moment from 'moment'

const l = (...a) => console.log('performanceApi', ...a)
const checkUrl = url => {
  if (url.endsWith('undefined')) {
    l('BAD URL', url)
    throw new Error('undfeined url')
  }
}

export const getPerformanceEventHistoryData = (
  eventID,
  filters,
  allMetrics
) => {
  const { backendUrl } = config.dataApi
  // TODO: Change to the events history api
  let url = `${backendUrl}/performance/events/${eventID}/history?${
    allMetrics ? '' : 'metric=duration_s'
  }`
  if (filters.length > 0) {
    filters.forEach(filter => {
      url += `&${filter.filterType}=${filter.filterValue}`
    })
  }
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceApps = () => {
  const { backendUrl } = config.dataApi
  const url = `${backendUrl}/performance/apps`
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceEventsList = () => {
  const { backendUrl } = config.dataApi
  const url = `${backendUrl}/performance/list_events`
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceDeviceModels = () => {
  const { backendUrl } = config.dataApi
  const url = `${backendUrl}/performance/device_models`
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceConfiguration = () => {
  const { backendUrl } = config.dataApi
  const date2MonthAgo = moment().subtract(2, 'months').format('YYYY-MM-DD')
  const url = `${backendUrl}/performance/configuration?from_date=${date2MonthAgo}`
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceConfigurationInfrastructure = ({
  monthsAgo,
  without_num_of_samples
}) => {
  const { backendUrl } = config.dataApi
  const dateMonthAgo = moment()
    .subtract(monthsAgo, 'months')
    .format('YYYY-MM-DD')
  const url = `${backendUrl}/performance/configuration/infrastructure?from_date=${dateMonthAgo}&without_num_of_samples=${without_num_of_samples} `
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceConfigurationCategoriesDevices = () => {
  const { backendUrl } = config.dataApi
  const date2MonthAgo = moment().subtract(2, 'months').format('YYYY-MM-DD')
  const url = `${backendUrl}/performance/configuration/categories_devices?from_date=${date2MonthAgo}`
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceConfigurationCategoriesEvents = () => {
  const { backendUrl } = config.dataApi
  const date2MonthAgo = moment().subtract(2, 'months').format('YYYY-MM-DD')
  const url = `${backendUrl}/performance/configuration/categories_events?from_date=${date2MonthAgo}`
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceVersionsForApp = package_name => {
  const { backendUrl } = config.dataApi
  const url = `${backendUrl}/releases/versions?package_name=${package_name}`
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceRanks = data => {
  const { backendUrl } = config.dataApi
  const gets = new URLSearchParams(data.filters).toString()
  const url = `${backendUrl}/performance/categorization/ranks?${gets}`
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceRanksCompare = (
  dates,
  currentDevice,
  currentOSVersion,
  app_ids = ''
) => {
  const { backendUrl } = config.dataApi
  const url = `${backendUrl}/performance/categorization/ranks/compare?app_id_in=${app_ids}&model_in=${currentDevice}&os_version_in=${currentOSVersion}&1st_from_date=${dates.begin1range}&1st_until_date=${dates.end1range}&2nd_from_date=${dates.begin2range}&2nd_until_date=${dates.end2range}`
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceEventDataAverages = (eventID, filters) => {
  const { backendUrl } = config.dataApi
  let url = `${backendUrl}/performance/events/${eventID}/subevents/averages?metric=duration_s`
  if (filters.length > 0) {
    filters.forEach(filter => {
      url += `&${filter.filterType}=${filter.filterValue}`
    })
  }
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceEventDataReprSample = (eventID, filters) => {
  const { backendUrl } = config.dataApi
  let url = `${backendUrl}/performance/events/${eventID}/representative-sample?`
  if (filters.length > 0) {
    filters.forEach(filter => {
      url += `&${filter.filterType}=${filter.filterValue}`
    })
  }
  checkUrl(url)
  return axios.get(url)
}

export const getPerformanceConfigurationAppDeviceEvents = (appId, filters) => {
  const { backendUrl } = config.dataApi
  let url = `${backendUrl}/performance/configuration/apps/${appId}/device-events?`
  if (filters.length > 0) {
    filters.filter(x => ['from_date', 'until_date'].includes(x.filterType)).forEach(filter => {
      url += `&${filter.filterType}=${filter.filterValue}`
    })
  } else {
    const date2MonthAgo = moment().subtract(2, 'months').format('YYYY-MM-DD')
    url += `from_date=${date2MonthAgo}`
  }
  checkUrl(url)
  return axios.get(url)
}
