// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard__container--context-timeline {
  grid-template-columns: 25.6rem auto !important;
}
.dashboard__container--context-timeline .dashboard__main {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  padding: 0 !important;
}

.searchAccount--context-timeline {
  padding: 1rem 0 1rem 2rem !important;
}

@media only screen and (max-width: 1400px) and (min-width: 600px) {
  .dashboard__container--context-timeline {
    grid-template-columns: 5rem auto !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/ContextTimeline/styles.scss"],"names":[],"mappings":"AAAA;EACE,8CAAA;AACF;AACE;EACE,eAAA;EACA,eAAA;EACA,WAAA;EACA,qBAAA;AACJ;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE;IACE,2CAAA;EAAF;AACF","sourcesContent":[".dashboard__container--context-timeline {\n  grid-template-columns: 25.6rem auto !important;\n\n  .dashboard__main {\n    min-width: 100%;\n    max-width: 100%;\n    width: 100%;\n    padding: 0 !important;\n  }\n}\n\n.searchAccount--context-timeline {\n  padding: 1rem 0 1rem 2rem !important;\n}\n\n@media only screen and (max-width: 1400px) and (min-width: 600px) {\n  .dashboard__container--context-timeline {\n    grid-template-columns: 5rem auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
