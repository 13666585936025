import './MarketingCampaignsFilters.scss'

import { Popover } from 'antd'
import calendar_ico from 'assets/images/icons/calendar.svg'
import caret_ico from 'assets/images/icons/caret-down.svg'
import device_ico from 'assets/images/icons/device.svg'
import persona_ico from 'assets/images/icons/persona.svg'
import share_ico from 'assets/images/icons/share-network.svg'
import focus_area_icon from 'assets/images/icons/focus_area_icon.svg'
import clsx from 'clsx'
import { AppDrawer } from 'components/AppDrawer'
import { TimeFilter } from 'components/TimeFilter'
import { ChangeCampaignsFilterType } from 'containers/MarketingCampaings/hooks/useCampaignsFilters'
import { trackMarketingEvent } from 'containers/MarketingCampaings/utils/analytics'
import { CAMPAIGNS_FILTERS } from 'containers/MarketingCampaings/utils/conts'
import {
  App,
  Channel,
  FocusArea,
  UserRoleType,
  UserType
} from 'containers/MarketingCampaings/utils/types'
import { ChannelSelect } from 'containers/MarketingOverview/components/MarketingOverviewFilters/ChannelSelect'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import dayjs, { Dayjs } from 'dayjs'
import { Moment } from 'moment'
import React, { useState } from 'react'
import { daysAgoList } from 'shared/consts'

import { HeaderWithFilters } from '../../../../components/HeaderWithFilters'
import { UserTypeFilter } from '../../../../components/UserTypeFilter'
import { FocusAreaFilter } from "../../../../components/FocusAreaFilter";

type Props = {
  apps: App[]
  selectedAppIds: string[]
  daysAgo: number
  customRange: boolean
  userTypes: UserType[]
  userRoleTypes: UserRoleType[]
  selectedUserTypes: string[]
  selectedUserRoleTypes: string[]
  handleChangeFilter: ChangeCampaignsFilterType
  customRangeTime: Moment[]
  selectedFocusAreas: string[]
  focusAreas: FocusArea[]
  channels: Channel[]
  selectedChannels: string[]
}

export const MarketingCampaignsFilters = ({
  selectedAppIds,
  handleChangeFilter,
  daysAgo,
  userTypes,
  userRoleTypes,
  apps,
  selectedUserTypes,
  selectedUserRoleTypes,
  customRange,
  customRangeTime,
  channels,
  selectedChannels,
  focusAreas,
  selectedFocusAreas
}: Props) => {
  const isMobile = isMobileDevice()
  const [timeFrameOpen, setTimeFrameOpen] = useState(false)
  const [channelOpen, setChannelOpen] = useState(false)
  const [isFocusAreaDrawerOpen, setIsFocusAreaDrawerOpen] = React.useState(false)

  const timeFrameStartDate = customRange
    ? dayjs(customRangeTime[0].toDate())
    : dayjs().subtract(daysAgo, 'day')
  const timeFrameEndDate = customRange
    ? dayjs(customRangeTime[1].toDate())
    : dayjs()

  const handleAppsSelected = (selectedIds: number[]) => {
    const formattedAppIds = selectedIds.map(id => id.toString())

    handleChangeFilter(CAMPAIGNS_FILTERS.app_ids, formattedAppIds)
    handleChangeFilter(CAMPAIGNS_FILTERS.channels, [])
    handleChangeFilter(CAMPAIGNS_FILTERS.user_types, [])
    handleChangeFilter(CAMPAIGNS_FILTERS.user_role_types, [])

    document.querySelector('#mkc-table')?.scrollTo(0, 0)
    trackMarketingEvent('app_filter', { selected_app_ids: formattedAppIds })
  }

  const handleUserOrRoleTypeSelect = (
    userTypesIds: number[],
    userRoleTypeIds: number[]
  ) => {
    const userTypesSelected: UserType[] = userTypesIds
      .map(id => userTypes.find(userType => userType.id === id))
      .filter(userType => userType != undefined) as UserType[]

    const userRoleTypesSelected = userRoleTypeIds
      .map(id => userRoleTypes.find(userRoleType => userRoleType.id === id))
      .filter(userRoleType => userRoleType != undefined) as UserRoleType[]

    trackMarketingEvent('user_type_filter', {
      user_types_selected: userTypesSelected.map(userType => userType.title),
      user_role_types_selected: userRoleTypesSelected.map(
        userRoleType => userRoleType.name
      )
    })

    handleChangeFilter(
      CAMPAIGNS_FILTERS.user_types,
      userTypesIds.map(id => id.toString())
    )
    handleChangeFilter(
      CAMPAIGNS_FILTERS.user_role_types,
      userRoleTypeIds.map(id => id.toString())
    )
  }

  const handleChannelSelect = (channels: string[]) => {
    trackMarketingEvent('channel_changed', {
      channel_names: channels
    })

    handleChangeFilter(CAMPAIGNS_FILTERS.channels, channels)
  }

  const handleFocusAreaSelect = (focusAreaIds: number[]) => {
    handleChangeFilter(CAMPAIGNS_FILTERS.focus_areas, focusAreaIds.map(x => String(x)))
  }

  const handleCustomRangeChange = (dates: [Dayjs, Dayjs] | null) => {
    if (!dates?.length) {
      handleChangeFilter(CAMPAIGNS_FILTERS.custom_range_time, [])
    } else {
      trackMarketingEvent('time_interval_filter', {
        number_of_days: dates[0].diff(dates[1], 'day')
      })

      handleChangeFilter(
        CAMPAIGNS_FILTERS.custom_range_time,
        dates.map(date => date.toISOString())
      )
    }

    setTimeFrameOpen(false)
  }

  const appsList = apps.map(app => ({
    id: app.id,
    thumbnail: app.icon,
    name: app.displayName,
    count: app.insightsCnt
  }))

  const selectedUserTypesObjects = userTypes.filter(userType =>
    selectedUserTypes.includes(userType.id.toString())
  )

  const selectedUserRoleTypesObject = userRoleTypes.filter(userRoleType =>
    selectedUserRoleTypes.includes(userRoleType.id.toString())
  )

  const totalTypesSelected =
    selectedUserTypes.length + selectedUserRoleTypes.length

  const [isAppDrawerOpen, setIsAppDrawerOpen] = React.useState(false)

  return (
    <HeaderWithFilters title='Campaigns Repository'>
      <div className='mkc-campaigns-app'>
        <Popover
          placement='bottom'
          title={null}
          destroyTooltipOnHide={true}
          open={isAppDrawerOpen}
          content={
            <AppDrawer
              appsList={appsList}
              currentAppsIds={selectedAppIds.map(id => +id)}
              onAppsSelected={handleAppsSelected}
            />
          }
          trigger='click'
          onOpenChange={newOpen => setIsAppDrawerOpen(newOpen)}
          overlayClassName={`popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps`}
        >
          <div className='select-filter'>
            <div className='select-body'>
              <img src={device_ico} />
              <span>Apps ({selectedAppIds?.length})</span>
              <img src={caret_ico} />
            </div>
          </div>
        </Popover>
      </div>
      <div className='mkc-user-type'>
        <Popover
          placement='bottom'
          title={null}
          destroyTooltipOnHide={true}
          content={
            <UserTypeFilter
              onSelect={handleUserOrRoleTypeSelect}
              selectedUserTypes={selectedUserTypesObjects}
              selectedUserRoleTypes={selectedUserRoleTypesObject}
              userTypes={userTypes}
              userRoleTypes={userRoleTypes}
            />
          }
          trigger='click'
        >
          <div className='select-filter' style={{ minWidth: '150px' }}>
            <div className='select-body'>
              <img src={persona_ico} />
              <span>{`User Types (${totalTypesSelected})`}</span>
              <img src={caret_ico} style={{ marginLeft: '6px' }} />
            </div>
          </div>
        </Popover>
      </div>
      <div className='mck-channel-type'>
        <Popover
          placement='bottom'
          title={null}
          destroyTooltipOnHide={true}
          content={
            <ChannelSelect
              onChannelSelect={handleChannelSelect}
              selectedChannels={selectedChannels}
              closeChannel={() => setChannelOpen(false)}
              availableChannels={channels.map(channel => channel.id)}
            />
          }
          open={channelOpen}
          onOpenChange={open => setChannelOpen(open)}
          trigger='click'
          overlayClassName={clsx(
            'filter-wrap all-discoveries all-discoveries-time fixed-filters',
            {
              'mobile-full-screen': isMobile
            }
          )}
          overlayStyle={{ width: '23rem' }}
        >
          <div
            className='select-filter'
            style={{ minWidth: isMobile ? '26%' : '105px' }}
          >
            <div className='select-body'>
              {!isMobile ? (
                <>
                  <img src={share_ico} />
                  <span>{`Channels (${selectedChannels.length})`}</span>
                  <img src={caret_ico} />
                </>
              ) : (
                <>
                  <span>Channels</span>
                  {selectedChannels.length > 0 && (
                    <div className='select-body-apps-count'>
                      {selectedChannels.length}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Popover>
      </div>
      <div className='mkc-focus-areas'>
        <Popover
          placement='bottom'
          title={null}
          destroyTooltipOnHide={true}
          open={isFocusAreaDrawerOpen}
          content={
            <FocusAreaFilter
              focusAreas={focusAreas}
              currentFocusAreaIds={selectedFocusAreas.map(x => Number(x))}
              onSelect={handleFocusAreaSelect}
            />
          }
          trigger='click'
          onOpenChange={newOpen => setIsFocusAreaDrawerOpen(newOpen)}
          overlayClassName={`popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps`}
        >
          <div className='select-filter'>
            <div className='select-body'>
              <img src={focus_area_icon} />
              <span>Promo Types ({selectedFocusAreas?.length})</span>
              <img src={caret_ico} />
            </div>
          </div>
        </Popover>
      </div>
      <div className='mkc-user-type'>
        <Popover
          placement='bottom'
          title={null}
          destroyTooltipOnHide={true}
          open={timeFrameOpen}
          onOpenChange={setTimeFrameOpen}
          content={
            <TimeFilter
              daysAgoList={daysAgoList}
              value={[timeFrameStartDate, timeFrameEndDate]}
              onChange={handleCustomRangeChange}
              onClose={() => setTimeFrameOpen(false)}
            />
          }
          trigger='click'
          overlayClassName='filter-wrap all-discoveries all-discoveries-time'
        >
          <div className='select-filter'>
            <div className='select-body'>
              <img src={calendar_ico} />
              <span>
                {`${timeFrameStartDate.format(
                  'MMM DD, YYYY'
                )} - ${timeFrameEndDate.format('MMM DD, YYYY')}`}
              </span>
              <img src={caret_ico} style={{ marginLeft: '10px' }} />
            </div>
          </div>
        </Popover>
      </div>
    </HeaderWithFilters>
  )
}
