import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { invoke, debounce } from 'lodash'
import { AutoComplete, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import SearchResultRow from './SearchResultRow'

import { clearFilterByApp } from '../../../redux/actions/dashboardActions'

import { getDiscoveriesSearch } from '../../../redux/actions/overviewActions'

import { ALL_TIME, marketingOnlyType } from '../../../shared/consts'
import { insightURL } from '../../../helpers/appsHelper'

const MINIMUM_SEARCH_QUERY_LENGTH = 2
const MAX_RESULTS = 30

export const SearchBar = ({
  searchWord,
  className,
  newline,
  analytics,
  disabled
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const apps = useSelector(state => state.appData.apps)
  const onlyPerformanceMode = useSelector(
    state => state.appData.onlyPerformanceMode
  )
  const filterByApps = useSelector(state => state.appData.filterByApps)
  const discoveries = useSelector(
    state => state.overview.searchedDiscoveries
  ) || { data: [] }
  const loadingSearchDiscoveries = useSelector(
    state => state.overview.loadingSearchDiscoveries
  )

  const searchInputRef = React.useRef()
  const [canSearch, setCanSearch] = useState(true)
  const [searchActive, setSearchActive] = useState(false)
  const [currentQuery, setCurrentQuery] = useState(searchWord || '')
  // TODO do we need this?
  const [selectedTags] = useState([])

  const handleOpenSearch = () => {
    if (!searchActive && canSearch) {
      setSearchActive(true)
    }

    if (searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 100)
    }
  }

  const handleCloseSearch = () => {
    const pageBackdrop = document.getElementById('page-backdrop-portal')

    if (searchActive && pageBackdrop) {
      pageBackdrop.classList.remove('backdrop-enabled')
      pageBackdrop.classList.add('backdrop-disable')
      document.body.style.overflowY = 'scroll'
      searchInputRef.current?.blur()

      dispatch(clearFilterByApp())
      setSearchActive(false)
      setCurrentQuery('')
    }
  }

  useEffect(() => {
    const onTransitionEnd = e => {
      if (e.propertyName === 'width') {
        searchInputRef.current?.focus()
      }
    }

    const onKeyUp = e => {
      e.preventDefault()
      e.stopPropagation()
      let key = e.keyCode

      if (key === 191 && e.shiftKey) {
        handleOpenSearch()
      }

      if (key === 'Escape' || key === 'Esc' || key === 27) {
        handleCloseSearch()
      }
    }

    const onClick = e => {
      if (
        searchActive &&
        document.querySelector('#auto-suggest') &&
        // Click outside of autocomplette
        !invoke(
          document.querySelector('#auto-suggest'),
          'contains',
          e.target
        ) &&
        // Tricky moment: node element contains method doesn't work for path or svg tag
        !['svg', 'path'].includes(e.target.tagName) &&
        !invoke(
          document.querySelector(
            '.header__search-bar-dropdown .ant-select-dropdown-menu-root'
          ),
          'contains',
          e.target
        )
      ) {
        handleCloseSearch()
      }
    }

    document
      .getElementById('auto-suggest')
      .addEventListener('transitionend', onTransitionEnd)

    document.addEventListener('keyup', onKeyUp)

    window.addEventListener('click', onClick)

    return () => {
      document
        .getElementById('auto-suggest')
        ?.removeEventListener('transitionend', onTransitionEnd)

      document.removeEventListener('keyup', onKeyUp)

      window.removeEventListener('click', onClick)
    }
  }, [searchActive])

  useEffect(() => {
    if (!apps.some(app => !app.loaded)) {
      setCanSearch(true)
    }
  }, [apps])

  const debHandleSearch = debounce(inputText => {
    if (
      inputText?.trim() !== '' &&
      inputText?.trim().length > MINIMUM_SEARCH_QUERY_LENGTH
    ) {
      const vars = {
        query: currentQuery,
        days_ago: ALL_TIME
      }

      const gets = new URLSearchParams(vars).toString()

      dispatch(
        getDiscoveriesSearch({
          gets
        })
      )
    }
  }, 700)

  const handleInputChange = inputText => {
    setCurrentQuery(inputText)
    debHandleSearch(inputText)
  }

  const handleSearchRowSelect = (value, selectedSearchRow) => {
    const searchRowData = dataSource[+selectedSearchRow.value]

    if (!isNaN(value) && searchRowData) {
      let insightAppVersion = searchRowData.insight.current_release_id
      let app_id = searchRowData.metadata.id

      let insightTitle = searchRowData.insight.title
      const insightType = searchRowData.insight.type
      const resultApp = apps.find(app => app.metadata.id === app_id)

      if (searchRowData.insight.other_release_id) {
        const otherVersion = resultApp.versions.find(
          version => version.id === searchRowData.insight.other_release_id
        )

        if (!otherVersion) {
          insightAppVersion = searchRowData.insight.current_release_id
        } else {
          const otherVersionInsights = [
            ...otherVersion.ab_tests,
            ...otherVersion.screen_changes,
            ...otherVersion.new_features,
            ...otherVersion.indications
          ]

          if (otherVersionInsights.find(insight => insight.id === value)) {
            insightAppVersion = otherVersion.id
          } else {
            insightAppVersion = searchRowData.insight.current_release_id
          }
        }
      }

      analytics.analytic('Click search result', {
        insight_name: insightTitle,
        insight_type: insightType,
        platform: resultApp.platform,
        insight_URL: insightURL({
          appID: app_id,
          versionID: insightAppVersion,
          insightID: value,
          isMarketing: Object.keys(marketingOnlyType).includes(insightType)
        })
      })

      let url

      if (insightAppVersion) {
        url = `/intelligence/versions/${app_id}/${insightAppVersion}/${searchRowData.insight.id}`
      } else {
        url = `/marketing/${value}`
      }

      navigate(url, { replace: true })
    }
  }

  if (onlyPerformanceMode) {
    return null
  }

  const dataSource = discoveries?.data.map(dis => ({
    insight: dis,
    metadata: apps.find(a => a.metadata.id == dis.app_id)
      ? apps.find(a => a.metadata.id == dis.app_id).metadata
      : {}
  }))

  const renderRow = (row, rowIndex) => ({
    label: (
      <SearchResultRow
        searchRowData={row}
        currentQuery={currentQuery}
        analytics={analytics}
      />
    ),
    // Value needs to be string for antd's autocomplete, so we will reverse it later
    value: rowIndex.toString()
  })

  const renderDataSource = () => {
    const infoRow = (label, key, top = 0) => ({
      label: (
        <div style={{ top: top }} className='header__search-bar-info'>
          {label}
        </div>
      ),
      value: '',
      disabled: true
    })

    let results = []

    if (loadingSearchDiscoveries) {
      return [infoRow('Loading....')]
    }

    if (
      dataSource &&
      dataSource.length > 0 &&
      (currentQuery !== '' || selectedTags.length > 0 || filterByApps.length)
    ) {
      results = dataSource.slice(0, MAX_RESULTS).map(renderRow)

      results.unshift(
        infoRow(
          `Showing ${
            results.length === 1
              ? `${results.length} Result`
              : `${results.length} Results`
          } out of ${dataSource.length} Results`,
          'number-of-results'
        )
      )
    } else if (
      !loadingSearchDiscoveries &&
      (currentQuery.length > MINIMUM_SEARCH_QUERY_LENGTH ||
        selectedTags.length > 0)
    ) {
      // no results
      results = [infoRow('No results found.')]
    }

    return results
  }
  return (
    <div
      className={`${className} ${disabled ? 'dis' : ''}`}
      onClick={handleOpenSearch}
    >
      <AutoComplete
        open={searchActive}
        id='auto-suggest'
        onSearch={handleInputChange}
        value={currentQuery}
        defaultActiveFirstOption={false}
        disabled={disabled}
        className={`search-auto-complete ${searchActive ? 'active' : ''}`}
        popupClassName={
          newline
            ? 'header__search-bar-dropdown-newline'
            : 'header__search-bar-dropdown'
        }
        onSelect={(value, option) => handleSearchRowSelect(value, option)}
        options={renderDataSource()}
        data-testid={'search-bar'}
      >
        <Input
          className='search-input'
          disabled={disabled}
          ref={searchInputRef}
          placeholder='Search...'
          suffix={<SearchOutlined />}
          data-testid={'text-bar'}
        />
      </AutoComplete>
    </div>
  )
}

export default SearchBar
