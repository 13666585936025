import { gql } from "@apollo/client";

export const GET_INSIGHT_BY_ID_QUERY = gql`
  query GetMarketingInsightById($insightId: Int!) {
    insight(id: $insightId) @rest(type: "Insight", path: "insight/{args.id}") {
      data {
        id
        title
        description
        type
        platform
        priority
        assets
        labels
        start_time
        thumbnail
        app_id
        variants
        platform
        release_name
        ab_test_id
        persona_locations
      }
    }
  }
`;

export const GET_AB_TEST_DATA_ID_QUERY = gql`
  query GetAbTestDataById($abTestId: Int!) {
    ab_test(id: $abTestId) @rest(type: "Insight", path: "ab-tests/{args.id}") {
      data {
        id
        insights
        status
      }
    }
  }
`;

export const GET_RELEASES = gql`
  query GetReleases($appId: Int) {
    releases(app_id: $appId)
      @rest(type: "Release", path: "releases?app_ids={args.app_id}") {
      data {
        id
        insight_cnt
        release_date
        release_name
        state
        is_visible
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($hasInsights: Boolean) {
    intel {
      products(hasInsights: $hasInsights) {
        id
        name
        icon
        apps {
          id
          platform
          insightsCnt
        }
      }
    }
  }
`;

export const GET_PERSONAS = gql`
  query GetPersonas($appIds: [Int]!) {
    intel {
      personas(appIds: $appIds) {
        id
        title
        roles
      }
    }
  }
`;

export const GET_INSIGHTS = gql`
  query GetInsights(
    $appIds: [Int]
    $personaIds: [Int]
    $startTimeGte: Date
    $startTimeLt: Date
    $types: [String]
    $abTestStatuses: [String]
    $offset: Int
    $limit: Int
    $releaseIds: [Int]
  ) {
    intel {
      insightPage(
        appIds: $appIds
        startTimeGte: $startTimeGte
        startTimeLt: $startTimeLt
        personaIds: $personaIds
        releaseIds: $releaseIds
      ) {
        insights(
          types: $types
          abTestStatuses: $abTestStatuses
          offset: $offset
          limit: $limit
        ) {
          id
          title
          description
          startTime
          type
          thumbnailOriginal
          abTest {
            status
            variantsCount
          }
          app {
            id
            displayName
            platform
            icon
          }
          personas {
            id
            title
            description
            roles
          }
        }
        summary {
          totalCount
          byType {
            type
            count
          }
          byAbTestStatus {
            abTestStatus
            count
          }
        }
      }
    }
  }
`;

export const GET_INSIGHT_DATA_BY_ID = gql`
  query GetInsightPersonasById($insightId: Int!) {
    intel {
      insight(id: $insightId) {
        app {
          id
          displayName
          platform
          icon
        }
        personas {
          id
          title
          description
          roles
        }
      }
    }
  }
`;
