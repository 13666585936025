import './UserJourneysPage.scss'

import { Col, Row } from 'antd'
import { AppDispatch } from 'index'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import x_no_outline_ico from '../../assets/images/icons/x-no-outline.svg'
import { UserFlow, UserJourneyApp } from '../../interfaces/UserJourneys'
import {
  getUserFlowHistory,
  getUserFlows,
  getUserFlowsSet,
  getUserFlowsSets,
  getUserJourneysApps,
  UserJourneysState
} from '../../redux/acts/userJourenys'
import { PersonaSelect } from './components/common/PersonaSelect'
import { VersionSelect } from './components/common/VersionSelect'
import { UserFlowsFilter } from './components/UserFlowsFilter'
import { UserJourneyCarousel } from './components/UserJourneyCarousel'
import { UserJourneyFilters } from './components/UserJourneyFilters'
import { FilterKeys, Persona, Version } from './utils/consts'

type State = {
  userJourneys: UserJourneysState
}

export const UserJourneysPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const userJourneys = useSelector((state: State) => state.userJourneys)
  const { userJourneysApps, userFlows, userFlowsSets } = userJourneys
  const url = new URLSearchParams(location.search)
  const selectedApps = url.getAll(FilterKeys.Apps)
  const selectedFlow = url.get(FilterKeys.Flow)
  const [prevApps, setPrevApps] = useState<string[] | null>(null)
  const [prevFlow, setPrevFlow] = useState<string | null>(null)
  const [flowHistoryApp, setFlowHistoryApp] = useState<UserJourneyApp | null>(
    null
  )
  const [historyPersona, setHistoryPersona] = useState<Persona | null>(null)
  const [historyVersions, setHistoryVersions] = useState<Version[]>([])

  const userFlowsSetsWithApps = userFlowsSets
    .map((set: any) => ({
      ...set,
      app: userJourneysApps.find(({ id }) => id === set.app_id)
    }))
    .filter(({ release }) =>
      historyVersions.length > 0
        ? historyVersions.find(({ id }) => id === release.id)
        : true
    )

  const userFlowsVersionsHistory = flowHistoryApp
    ? userFlowsSets
        .map((flow: any) => flow.release)
        .filter(
          (obj, index, self) => self.findIndex(o => o.id === obj.id) === index
        )
    : []

  const userFlowsRoots = userFlows.filter(({ parent_id }) => !parent_id)
  const userFlowsChildren = userFlows.filter(
    ({ parent_id }) => parent_id !== null
  )
  const sortedUserFlows: UserFlow[] = []

  userFlowsRoots.forEach(root => {
    const children = userFlows.filter(({ parent_id }) => parent_id === root.id)
    sortedUserFlows.push(root, ...children)
  })

  const updateUrl = (searchParams: string) => {
    navigate(`${location.pathname}?${searchParams}`)
  }

  const handleHistoryClose = () => {
    setFlowHistoryApp(null)
    setHistoryPersona(null)
    setHistoryVersions([])
  }

  const appSelect = (selectedAppIds: number[]) => {
    handleHistoryClose()

    const searchParams = new URLSearchParams(location.search)

    searchParams.delete(FilterKeys.Apps)

    selectedAppIds.forEach(appId => {
      searchParams.append(FilterKeys.Apps, appId.toString())
    })

    searchParams.delete(FilterKeys.Personas)
    searchParams.delete(FilterKeys.Flow)
    updateUrl(searchParams.toString())
  }

  const clearSelectedApps = () => {
    handleHistoryClose()
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete(FilterKeys.Apps)
    searchParams.delete(FilterKeys.Personas)
    searchParams.delete(FilterKeys.Flow)
    updateUrl(searchParams.toString())
  }

  const userFlowSelect = (userFlowId: number) => {
    handleHistoryClose()
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(FilterKeys.Flow, userFlowId.toString())
    updateUrl(searchParams.toString())
  }

  const onUserFlowHistory = (appId: number) => (personaId: number) => {
    const app = userJourneysApps.find(({ id }) => id === appId)

    if (!selectedFlow || !app) return

    const personasRec = app.user_flows.find(
      ({ id }: any) => id === Number(selectedFlow)
    )

    if (!personasRec) return

    dispatch(
      getUserFlowHistory({
        app_id: appId.toString(),
        persona_id: personaId.toString(),
        user_flow_id: selectedFlow.toString()
      })
    )

    const searchParams = new URLSearchParams(location.search)

    if (selectedApps?.includes(appId.toString())) {
      searchParams.delete(FilterKeys.Apps)

      const newSelectedApps = selectedApps.filter(
        appId => appId !== appId.toString()
      )

      newSelectedApps.forEach(appId => {
        searchParams.append(FilterKeys.Apps, appId)
      })
    } else {
      searchParams.append(FilterKeys.Apps, appId.toString())
    }

    updateUrl(searchParams.toString())
    setFlowHistoryApp(app)
    setHistoryPersona(personasRec.personas[0])
  }

  const handleFlowHistoryPersonaSelect = (personaId: number) => {
    if (!flowHistoryApp || !selectedFlow) return

    const personasRec = flowHistoryApp.user_flows.find(
      ({ id }: any) => id === Number(selectedFlow)
    )

    if (!personasRec) return

    const persona = personasRec.personas.find(({ id }) => id === personaId)

    if (!persona) return

    setHistoryPersona(persona)
    dispatch(
      getUserFlowHistory({
        app_id: flowHistoryApp.id.toString(),
        persona_id: personaId.toString(),
        user_flow_id: selectedFlow.toString()
      })
    )
  }

  const handleHistoryVersionToggle = (version: Version) => {
    if (historyVersions.find(({ id }) => id === version.id)) {
      setHistoryVersions(historyVersions.filter(({ id }) => id !== version.id))
    } else {
      setHistoryVersions([...historyVersions, version])
    }
  }

  const handleDeleteVersionClick = (version: Version) => () => {
    setHistoryVersions(historyVersions.filter(({ id }) => id !== version.id))
  }

  useEffect(() => {
    dispatch(getUserJourneysApps())
  }, [])

  useEffect(() => {
    if (JSON.stringify(prevApps) !== JSON.stringify(selectedApps)) {
      setPrevApps(selectedApps)
      dispatch(
        getUserFlows({
          app_ids: selectedApps
        })
      )
    }
  }, [selectedApps, prevApps])

  useEffect(() => {
    if (userFlowsChildren.length && !selectedFlow) {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set(FilterKeys.Flow, userFlowsChildren[0].id.toString())
      updateUrl(searchParams.toString())
    }
  }, [userFlowsChildren])

  useEffect(() => {
    if (selectedFlow && prevFlow !== selectedFlow) {
      dispatch(
        getUserFlowsSets({
          app_ids: selectedApps,
          user_flow_id: selectedFlow.toString()
        })
      )

      setPrevFlow(selectedFlow)
    }
  }, [userFlowsSets, selectedFlow, selectedApps])

  return (
    <div className='uj-page'>
      <div className='uj-heading'>
        <h1 className='uj-title'>User Journeys</h1>
        <div className='uj-subtitle'>Show Results For: </div>
        <UserJourneyFilters
          appsChange={appSelect}
          appsList={userJourneysApps.map(app => ({
            id: app.id,
            thumbnail: app.thumbnail,
            name: app.display_name
          }))}
          currentApps={selectedApps.map(appId => Number(appId))}
          clearSelectedApps={clearSelectedApps}
        />
      </div>
      <Row className='uj-body'>
        <Col span={24}>
          <div className='uj-flows'>
            <h3 className='uj-flows-heading'>Flows</h3>
            {userFlowsChildren.length > 0 && (
              <UserFlowsFilter
                flows={userFlowsChildren}
                selectUserFlow={userFlowSelect}
                selectedFlowId={Number(selectedFlow)}
              />
            )}
          </div>
        </Col>
        {flowHistoryApp && (
          <Col span={24}>
            <div className='uj-history'>
              <PersonaSelect
                personas={
                  flowHistoryApp.user_flows.find(
                    ({ id }: any) => id === Number(selectedFlow)
                  )?.personas || []
                }
                handlePersonaFilterSelect={handleFlowHistoryPersonaSelect}
                persona={historyPersona}
              />
              <VersionSelect
                onVersionSelect={handleHistoryVersionToggle}
                selectedVersions={historyVersions}
                versions={userFlowsVersionsHistory}
              />
              {historyVersions.length > 0 && (
                <div className='uj-history-selected-versions'>
                  {historyVersions.map(version => (
                    <div className='uj-history-version' key={version.id}>
                      <img
                        src={x_no_outline_ico}
                        onClick={handleDeleteVersionClick(version)}
                      />
                      <div>{version.release_name}</div>
                      <div className='uj-history-version-date'>
                        {moment(version.release_date).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Col>
        )}
        <Col span={24} className='uj-main'>
          {userFlowsSetsWithApps.map(
            ({ id, assets, user_flow_id, app, persona_id, release }) => (
              <UserJourneyCarousel
                userFlowPersonaId={persona_id}
                isHistoryOpen={!!flowHistoryApp}
                key={id}
                anchorId={user_flow_id.toString()}
                appName={app?.display_name}
                images={assets}
                appLogo={app?.thumbnail}
                personas={
                  app?.user_flows.find(({ id }: any) => id === user_flow_id)
                    .personas
                }
                onUserFlowHistory={onUserFlowHistory(app?.id)}
                getUserFlowsSet={getUserFlowsSet}
                appId={app?.id}
                userFlowSetId={id}
                version={release}
              />
            )
          )}
        </Col>
      </Row>
    </div>
  )
}

export default UserJourneysPage
