// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.performance-no-data {
  margin-top: 7rem;
  text-align: center;
  padding: 0rem 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35.5rem;
  margin-top: 2rem !important;
}
.performance-no-data img {
  width: 15rem;
  opacity: 0.2;
}`, "",{"version":3,"sources":["webpack://./src/containers/benchmark/BenchmarkCompare/PerformanceSummary/performanceNoData.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,6BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;AACF;AACE;EACE,YAAA;EACA,YAAA;AACJ","sourcesContent":[".performance-no-data {\n  margin-top: 7rem;\n  text-align: center;\n  padding: 0rem 1rem !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 35.5rem;\n  margin-top: 2rem !important;\n\n  img {\n    width: 15rem;\n    opacity: 0.2;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
