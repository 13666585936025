import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Img } from 'react-image'
import moment from 'moment'
import Icon from '@ant-design/icons'

import defaultLogo from '../../../../assets/images/watchful_icon_gray.svg'

const VersionsListItem = ({ item, transparent, noRedirect }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { thumbnail, id, relevancy, read_at, icon, title } = item
  const url = location.pathname

  const image = thumbnail ? thumbnail : defaultLogo
  const handleClick = useCallback(() => {
    if (!noRedirect) {
      navigate(`${url}/${id}`)
    }
  }, [navigate, id, noRedirect, url])

  return (
    <div
      onClick={handleClick}
      className={`versions__list__item ${transparent && 'transparent'} ${
        read_at ? 'fade-out' : ''
      }`}
    >
      <div className='versions__list__item-top'>
        {!read_at && Number(relevancy) > 0 && (
          <div className='versions__list__item-importance-indicator importance-indicator-animating' />
        )}
        <h4 className='versions__list__item-header'>
          <div>{title}</div>
        </h4>
      </div>
      {icon && (
        <div className='versions__list__item-app-icon'>
          <Img
            src={icon}
            alt='app-icon'
            loader={<div className='versions__list__item-loader' />}
          />
        </div>
      )}
      <div className='versions__list__item-thumbnail'>
        <Img
          src={image}
          alt='thumbnail'
          className={thumbnail ? '' : 'default'}
          loader={<div className='versions__list__item-loader' />}
        />
      </div>
      {read_at && (
        <div className='insight-read'>
          <div>
            <Icon type='eye' />
          </div>
          <h3>Discovery Viewed</h3>
          <div className='insight-read-date'>
            {moment(read_at).format('MMMM DD, YYYY')}
          </div>
        </div>
      )}
    </div>
  )
}

export default VersionsListItem
