import './InsightPreviewModal.scss'

import { Col, Empty, Modal, Radio, Row } from 'antd'
import android_ico from 'assets/images/icons/newIntel/android.svg'
import caret_left_ico from 'assets/images/icons/newIntel/caret-left.svg'
import ios_ico from 'assets/images/icons/newIntel/ios.svg'
import web_ico from 'assets/images/icons/newIntel/web.svg'
import clsx from 'clsx'
import ViewExperimentArrowSlickBase from 'components/discoveryView/viewExperiment/viewExperimentArrowSlick/ViewExperimentArrowSlick'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import moment from 'moment'
import {
  INTEL_INSIGHT_TYPES,
  INTEL_INSIGHT_TYPES_PROPS
} from 'pages/Intel/utils/consts'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'

import { ModalActions } from './ModalActions'
import { ModalInfo } from './ModalInfo'

const ViewExperimentArrowSlick: any = ViewExperimentArrowSlickBase

const PLATFORM_TO_IMAGE = {
  ios: ios_ico,
  android: android_ico,
  web: web_ico
}

type StateProps = {
  user?: any
  userDiscoveries?: any[]
}

type Props = {
  open: boolean
  onClose: () => void
  type: string
  insightId: number
  title: string
  date: string
  priority: string
  labels: string[]
  focusArea: string
  /* Temporary disable user types filter SQUAD2-1329 */
  // userTypes: UserType[];
  // userTypesSeen: UserType[];
  insightDescription: string
  assets: any[]
  appId: string
  appIcon: string
  platform: string
  appName: string
  releaseName?: string
  variants: any[]
  personaLocations?: any[]
  abTestData: {
    status: string
    insights: any[]
    id: number
  }
} & StateProps
const slickGallerySettings = {
  speed: 500,
  infinite: false,
  slidesToScroll: 1,
  dots: true,
  adaptiveHeight: true,
  nextArrow: <ViewExperimentArrowSlick direction='next' page='image-gallery' />,
  prevArrow: <ViewExperimentArrowSlick direction='prv' page='image-gallery' />
}

const getModalWidth = ({
  isNoImages,
  isLandscapeImages,
  isOnlyCurrentAssets
}: {
  isNoImages: boolean
  isLandscapeImages: boolean
  isOnlyCurrentAssets: boolean
}) => {
  if (isNoImages) {
    return 1100
  }

  if (isOnlyCurrentAssets) {
    return 850
  }

  if (isLandscapeImages) {
    return 1180
  }

  return 1300
}

const ASSETS_TYPES = {
  PREVIOUS: 'previous',
  CURRENT: 'current'
}

const InsightPreviewModalBase = ({
  onClose,
  appId,
  appName,
  open,
  insightId,
  type,
  title,
  date,
  priority,
  labels,
  focusArea,
  /* Temporary disable user types filter SQUAD2-1329 */
  // userTypes,
  // userTypesSeen,
  insightDescription,
  assets,
  user,
  appIcon,
  platform,
  releaseName,
  variants,
  personaLocations,
  abTestData
}: Props) => {
  const isMobile = isMobileDevice()
  const [currentAssetsType, setCurrentAssetsType] = useState(
    ASSETS_TYPES.PREVIOUS
  )
  const [currentVariant, setCurrentVariant] = useState(0)
  const isABTest = type === INTEL_INSIGHT_TYPES.ab_test
  const controlVariant = variants?.find(v => v.control)
  const otherVariants = variants?.filter(v => !v.control)
  const typeProps = INTEL_INSIGHT_TYPES_PROPS[type]
  const isNewFeature = type === INTEL_INSIGHT_TYPES.new_feature

  const shouldShowPrevAssets = isMobile
    ? currentAssetsType === ASSETS_TYPES.PREVIOUS
    : true
  const shouldShowCurrentAssets = isMobile
    ? currentAssetsType === ASSETS_TYPES.CURRENT
    : true

  const isNoImages = assets.length === 0 && !isABTest

  const isLandscapeImages =
    (assets.length > 0 &&
      assets.every(({ orientation }): any => orientation === 'landscape')) ||
    (variants.length > 0 &&
      variants?.every(variant =>
        variant.assets.every(
          ({ orientation }: any) => orientation === 'landscape'
        )
      ))

  const previousAssets = assets.filter(({ type }) => type === 'previous')
  const currentAssets = assets.filter(({ type }) => type === 'current')

  const isOnlyCurrentAssets =
    previousAssets.length === 0 && !isABTest && !isLandscapeImages

  const modalWidth = getModalWidth({
    isNoImages,
    isLandscapeImages,
    isOnlyCurrentAssets
  })

  useEffect(() => {
    if (!open || !insightId) return

    // set li count for slick dots if more than 4
    const slickDots: any = document.querySelector('.slick-dots')

    if (slickDots && slickDots.children.length > 4) {
      const childrenCount = slickDots.children.length - 4
      slickDots.style.setProperty('--li-count', '"+' + childrenCount + '"')
    }

    setCurrentVariant(0)
  }, [insightId, open])

  const handleAssetTypeChange = () => {
    setCurrentAssetsType(prev =>
      prev === ASSETS_TYPES.CURRENT
        ? ASSETS_TYPES.PREVIOUS
        : ASSETS_TYPES.CURRENT
    )
  }

  const assetTypeOptions = [
    {
      label: isABTest ? 'Control' : 'Previous',
      value: ASSETS_TYPES.PREVIOUS
    },
    {
      label: isABTest ? `Variants (${otherVariants.length})` : 'Current',
      value: ASSETS_TYPES.CURRENT
    }
  ]

  const renderAsset = (asset: any) => {
    const onAssetClick = (asset: any) => () => {
      window.open(asset.url_annotated, '_blank')
    }

    return (
      <>
        {asset.format === 'image' && (
          <img
            onClick={onAssetClick(asset)}
            className={clsx('ipm-preview-asset-image', {
              landscape: asset.orientation === 'landscape'
            })}
            src={asset.url_annotated}
          />
        )}
        {asset.format === 'video' && (
          <video
            onClick={onAssetClick(asset)}
            className={clsx('ipm-preview-asset-image', {
              landscape: asset.orientation === 'landscape'
            })}
            src={asset.url_annotated}
            controls
          />
        )}
      </>
    )
  }

  let modalSpans = [13, 11]

  if (isNoImages || isOnlyCurrentAssets) {
    modalSpans = [16, 8]
  }

  if (isMobile) {
    modalSpans = [24, 24]
  }

  return (
    <Modal
      centered
      closeIcon={null}
      footer={null}
      title={null}
      closable={false}
      open={open}
      onCancel={onClose}
      width={modalWidth}
      className={clsx('ipm-modal-container', {
        mobile: isMobile
      })}
    >
      <Row
        gutter={[24, 0]}
        style={isMobile ? { flexDirection: 'column-reverse' } : {}}
        className='ipm-insight-preview-container'
      >
        <Col span={modalSpans[0]} className='ipm-preview-left'>
          <ModalInfo
            abTestData={abTestData}
            appIcon={appIcon}
            date={date}
            focusArea={focusArea}
            insightDescription={insightDescription}
            insightId={insightId}
            labels={labels}
            platform={platform}
            priority={priority}
            releaseName={releaseName}
            title={title}
            type={type}
            personaLocations={personaLocations}
          />
        </Col>
        <Col span={modalSpans[1]} className='ipm-preview-right'>
          <Row
            gutter={[0, isLandscapeImages || isMobile ? 0 : 48]}
            style={{ height: '100%' }}
          >
            <Col
              span={24}
              style={
                isMobile
                  ? {
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }
                  : {}
              }
            >
              {isMobile && (
                <div className='mobile-top-left'>
                  <img src={caret_left_ico} onClick={onClose} />
                  <img src={appIcon} />
                  <img
                    src={(PLATFORM_TO_IMAGE as any)[platform.toLowerCase()]}
                  />
                  <div
                    className='ipm-type'
                    style={{
                      color: typeProps?.textColor,
                      backgroundColor: typeProps?.bgColor
                    }}
                  >
                    {typeProps?.label}
                  </div>
                  <h5 className='ipm-preview-date'>
                    {moment(date).format('MMM D, YYYY')}
                  </h5>
                </div>
              )}
              <ModalActions
                appId={appId}
                appName={appName}
                insightId={insightId}
                onClose={onClose}
                title={title}
                type={type}
                user={user}
              />
            </Col>
            {isMobile && (
              <Col span={24}>
                <div className='ipm-preview-title'>{title}</div>
              </Col>
            )}
            <Col span={24}>
              {isABTest && controlVariant ? (
                <Row
                  justify={
                    (isLandscapeImages && otherVariants) || isMobile
                      ? 'center'
                      : 'start'
                  }
                  className={clsx('ipm-ab-tests-container', {
                    landscape: isLandscapeImages
                  })}
                  gutter={[0, 16]}
                >
                  {shouldShowPrevAssets && (
                    <Col
                      span={isLandscapeImages || isMobile ? 24 : 11}
                      style={
                        !isLandscapeImages && !isMobile
                          ? {
                              marginRight: '20px'
                            }
                          : {}
                      }
                    >
                      <div className='ipm-ab-test-control'>
                        <div>Control</div>
                        {renderAsset(controlVariant.assets[0])}
                      </div>
                    </Col>
                  )}
                  {shouldShowCurrentAssets && (
                    <Col
                      span={isLandscapeImages || isMobile ? 24 : 11}
                      style={
                        !isLandscapeImages && !isMobile
                          ? {
                              paddingLeft: '24px',
                              borderLeft: '1px dashed #979797'
                            }
                          : {}
                      }
                    >
                      {otherVariants && (
                        <div className='ipm-variants'>
                          <div className='variants-heading'>
                            Variants({otherVariants.length})
                          </div>
                          <div className='imp-variants-select'>
                            {otherVariants.map((variant, index) => (
                              <div
                                key={variant.type}
                                className={clsx('variant', {
                                  selected: index === currentVariant
                                })}
                                onClick={() => setCurrentVariant(index)}
                              >
                                Variant {variant.type}
                              </div>
                            ))}
                          </div>
                          <div className='imp-variants-container'>
                            {otherVariants.map((variant, idx) =>
                              currentVariant === idx ? (
                                // @ts-ignore
                                <Slider
                                  key={idx}
                                  {...slickGallerySettings}
                                  className={clsx({
                                    landscape: isLandscapeImages
                                  })}
                                >
                                  {variant.assets.map((asset: any) => (
                                    <>{renderAsset(asset)}</>
                                  ))}
                                </Slider>
                              ) : null
                            )}
                          </div>
                        </div>
                      )}
                    </Col>
                  )}
                  {isMobile && otherVariants && (
                    <Col span={24}>
                      <div className='variants-switch'>
                        <Radio.Group
                          value={currentAssetsType}
                          options={assetTypeOptions}
                          optionType='button'
                          buttonStyle='solid'
                          onChange={handleAssetTypeChange}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              ) : !isNoImages ? (
                <Row
                  className={clsx('ipm-assets-container', {
                    landscape: isLandscapeImages
                  })}
                  justify={
                    (isLandscapeImages && previousAssets.length > 0) || isMobile
                      ? 'center'
                      : 'start'
                  }
                  gutter={[0, 16]}
                >
                  {previousAssets.length > 0 && shouldShowPrevAssets && (
                    <Col
                      span={isLandscapeImages || isMobile ? 24 : 11}
                      style={
                        !isLandscapeImages && !isMobile
                          ? {
                              marginRight: '20px'
                            }
                          : {}
                      }
                    >
                      <div className='ipm-assets-container-images'>
                        <div className='ipm-preview-images-heading'>
                          Previous
                        </div>
                        {/* https://github.com/akiran/react-slick/issues/2336 */}
                        {/* @ts-ignore */}
                        <Slider
                          {...slickGallerySettings}
                          className={clsx({
                            landscape: isLandscapeImages
                          })}
                        >
                          {previousAssets.map(renderAsset)}
                        </Slider>
                      </div>
                    </Col>
                  )}
                  {(previousAssets.length === 0 || shouldShowCurrentAssets) && (
                    <Col
                      span={isLandscapeImages || isMobile ? 24 : 11}
                      className={!previousAssets.length ? 'no-previous' : ''}
                      style={
                        previousAssets.length > 0 &&
                        !isLandscapeImages &&
                        !isMobile
                          ? {
                              paddingLeft: '24px',
                              borderLeft: '1px dashed #979797'
                            }
                          : {}
                      }
                    >
                      <div className='ipm-assets-container-images'>
                        {!isNewFeature && (
                          <div className='ipm-preview-images-heading'>
                            Current
                          </div>
                        )}
                        {/* https://github.com/akiran/react-slick/issues/2336 */}
                        {/* @ts-ignore */}
                        <Slider
                          {...slickGallerySettings}
                          className={clsx({
                            landscape: isLandscapeImages
                          })}
                        >
                          {currentAssets.map(renderAsset)}
                        </Slider>
                      </div>
                    </Col>
                  )}
                  {isMobile && previousAssets.length > 0 && (
                    <Col span={24}>
                      <div className='variants-switch'>
                        <Radio.Group
                          value={currentAssetsType}
                          options={assetTypeOptions}
                          optionType='button'
                          buttonStyle='solid'
                          onChange={handleAssetTypeChange}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              ) : (
                <div className='imp-empty'>
                  <Empty description='No Images For This Insight' />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

const mapStateToProps = ({ appData, userDiscoveries }: any) => {
  return {
    user: appData.user,
    userDiscoveries: userDiscoveries.discoveries
  }
}

export const InsightPreviewModal = connect(mapStateToProps, {})(InsightPreviewModalBase)
