import dayjs, { Dayjs } from 'dayjs'

// @ts-ignore
import holidays from '@date/holidays-us'
import { ALL_TIME, daysAgoList } from "../shared/consts";

export const checkDate = (date: string): any => {
  return holidays.getHoliday(new Date(date))
}

export const convertDaysAgoToCustomRange = (
  daysAgo: number
): [Dayjs, Dayjs] | null => {
  switch (daysAgo) {
    case 7: {
      return [dayjs().subtract(7, 'day'), dayjs()]
    }
    case 30: {
      return [dayjs().subtract(30, 'day'), dayjs()]
    }
    case 60: {
      return [dayjs().subtract(60, 'day'), dayjs()]
    }
    case ALL_TIME: {
      return [dayjs().subtract(ALL_TIME, 'day'), dayjs()]
    }
    default: {
      return null
    }
  }
}

export const isToday = (date: Dayjs) =>  date.isSame(dayjs(), 'day')
export const getRangeTimeDiffDays = (timeRange: [Dayjs, Dayjs]) =>  timeRange[1].diff(timeRange[0], 'd')
export const getRangeTimeTitle = (timeRange: [Dayjs, Dayjs]) => daysAgoList.find(
  d => isToday(timeRange[1])  && d.value === getRangeTimeDiffDays(timeRange)
)?.title


export default checkDate
