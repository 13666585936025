import React, { useEffect } from 'react'
import { isEqual } from 'lodash'
import ViewExperimentArrowSlick from '../../../components/discoveryView/viewExperiment/viewExperimentArrowSlick/ViewExperimentArrowSlick'
import Slider from 'react-slick'
import moment from 'moment'
import { useParams } from 'react-router'

import graph_ico from '../../../assets/images/new_design/icons/graph_ico.svg'

export const STATE_LABEL = {
  new: 'BEING ANALYZED',
  analysis_pending: 'BEING ANALYZED',
  analysis_in_progress: 'IN PROGRESS',
  in_review: 'COMPLETED',
  completed: 'COMPLETED',
  published: 'COMPLETED'
}

export const VersionsSlider = props => {
  const slider = React.useRef()
  const prevVersions = React.useRef()
  const params = useParams()

  const { versions } = props

  const getInitialSlide = () => {
    // this piece of code requires an explanation

    // each react slick slide has an index, from left to right
    // eg. 0, 1, 2, 3, 4, 5, 6, 7, 8, ....., n
    // versions from the BE/JSON file ordered from newest to oldest
    // react slick-slider in rtl mode renders the version list from right to left.
    // so in the version slider they are rendered as:
    // april 19 (index 3 in the version list, 0 in the slider), may 19 (index 2 in the version list, 1 in the slider), june 19 (index 1 in the version list, 2 in the slider), july 19 (index 0 in the version list, 3 in the slider),
    // slick initial slide is the first viewable slide in the left side.
    // so in order to show the selected version in right side (as the first one)
    // we need to reverse the version list, find the selected version index and then add NUMBER_OF_SLIDES + 1 to it;

    let calculatedSlide = [...props.versions]
      .reverse()
      .findIndex(version => version.id === Number(params.release_id))

    if (calculatedSlide === -1) {
      calculatedSlide = props.versions.length - 1
    }

    if (
      calculatedSlide > props.versions.length - props.slidesPerRow &&
      calculatedSlide > 6
    ) {
      // edge case, last element
      return props.versions.length - props.slidesPerRow
    }

    return calculatedSlide - ((calculatedSlide + 1) % props.slidesPerRow)
  }

  useEffect(() => {
    if (slider.current) {
      if (props.versions && props.versions.length > 0) {
        slider.current.slickGoTo(getInitialSlide(), true)
      }
    }

    prevVersions.current = versions
  }, [])

  useEffect(() => {
    if (slider.current) {
      if (!isEqual(versions, prevVersions.current))
        setTimeout(() => slider.current.slickGoTo(getInitialSlide()), 200)
    }

    prevVersions.current = versions
  }, [versions])

  const renderVersion = ({ version, itemDisabled, index }) => {
    const {
      release_name,
      release_date,
      ab_tests,
      new_features,
      indications,
      screen_changes,
      state,
      removed_features
    } = version
    const { slidesPerRow, platform } = props
    const date = moment(release_date).format('ll')
    const versionInsights = [
      ...ab_tests,
      ...new_features,
      ...screen_changes,
      ...indications,
      ...removed_features
    ]
    const changes = versionInsights.length
    const active = props.currentVersionIndex === index ? 'active' : ''
    const extraLast = slidesPerRow % 2 === 0 ? 1 : 0
    const lastOnRow = (index + extraLast) % slidesPerRow === 0
    const versionHasImportantInsights = versionInsights.some(insight => {
      // version has important insights if it includes an unread insight with importance of more than 0
      return !!(
        !insight.read_at &&
        insight.relevancy &&
        Number(insight.relevancy) > 0
      )
    })

    //const isVersionLatest = index === 0

    return (
      <div
        className={`versions__slider__item ${
          itemDisabled && 'slider-disabled'
        } ${active && `versions__slider__item-${active}`} ${state}`}
        // onClick={() => props.sliderItemClicked({ index })}
        onClick={() => {
          if (state !== 'analysis_pending' && !itemDisabled) {
            props.sliderItemClicked({ index, versionID: version.id })
          }
        }}
        key={index}
      >
        <div className='versions__slider__item__box'>
          {/* {isVersionLatest && (
            <span
              className={`versions__slider__item__box__latest ${active ? "active" : ""
                }`}
            >
              Latest Version
            </span>
          )} */}
          {state && (
            <div className={`state state-${state}`}>
              <span>{STATE_LABEL[state] || state}</span>
            </div>
          )}
          <div
            className={`versions__slider__item__bottom ${
              active && `versions__slider__item__bottom-${active}`
            }`}
          >
            <span
              className={`versions__slider__item-date ${
                active && `versions__slider__item-date-${active}`
              }`}
            >
              {date}
            </span>
          </div>
          {platform !== 'Web' && (
            <span
              className={`versions__slider__item-version ${
                active && `versions__slider__item-version-${active}`
              }`}
            >
              V.{release_name}
            </span>
          )}
          <div
            className={`versions__slider__item__line ${
              active && `versions__slider__item__line-${active}`
            } ${lastOnRow && `versions__slider__item__line-last`}`}
          >
            <span
              className={`versions__slider__item__line-dot ${
                versionHasImportantInsights
                  ? 'importance-indicator-animating'
                  : ''
              } ${active && `versions__slider__item__line-dot-${active}`} ${
                lastOnRow && `versions__slider__item__line-dot-last`
              }
                ${version.unseen_count ? 'has_digits' : ''}
                `}
            >
              {version.unseen_count}
            </span>
            <span
              className={`versions__slider__item__line-border ${
                active && `versions__slider__item__line-border-${active} `
              } ${lastOnRow && `versions__slider__item__line-border-last`}`}
            />
          </div>
          {(Number(changes) > 0 ||
            ['analysis_pending'].indexOf(state) === -1) && (
            <div
              className={`versions__slider__item-changes ${
                active && `versions__slider__item-changes-${active}`
              }`}
            >
              {`${changes} ${
                Number(changes) === 1 ? 'Discovery' : 'Discoveries'
              }`}
              {['analysis_in_progress'].indexOf(state) !== -1 &&
                Number(changes) > 0 && <span className='so-far'>so far</span>}
            </div>
          )}
          {props.graph && props.graph.find(g => g.release_id === version.id) && (
            <div>
              <a
                href={`/app_graph/${
                  props.graph.find(g => g.release_id === version.id).id
                }`}
              >
                <div className='graph-link'>
                  <div className='graph-ico-wrap'>
                    <img src={graph_ico} />
                  </div>
                  <div className='graph-link-title'>View Map</div>
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    )
  }

  if (!versions || versions.length === 0) {
    return null
  }

  const versionsItems = versions.map((version, index) =>
    renderVersion({
      version,
      index,
      itemDisabled: props.isRestrictedUser && index > 2 ? true : false
    })
  )
  const sliderSettings = {
    dots: false,
    speed: 200,
    infinite: false,
    slidesToShow: props.slidesPerRow,
    slidesToScroll: 1,
    rtl: true,
    initialSlide: 1,
    nextArrow: <ViewExperimentArrowSlick direction='next' page='versions' />,
    prevArrow: <ViewExperimentArrowSlick direction='prv' page='versions' />
  }

  return (
    <div className='versions__slider__wrapper'>
      <div className='versions__slider'>
        <Slider {...sliderSettings} ref={slider}>
          {versionsItems}
        </Slider>
      </div>
    </div>
  )
}

export default VersionsSlider
