import _ from 'lodash'
import analyticsService from 'services/analyticsService'

type InsightAnalytic = {
  insight_id: number
  insight_title: string
  insight_channel: string
  insight_focus_area: string
}

type MarketingEvents = {
  screen_enter: null
  app_filter: {
    selected_app_ids: string[]
  }
  user_type_filter: {
    user_types_selected: string[]
    user_role_types_selected: string[]
  }
  time_interval_filter: {
    number_of_days: number
  }
  insight_clicked: InsightAnalytic
  insight_row_viewed: InsightAnalytic & {
    selected_app_name: string
  }
  sort_column_clicked: {
    sort_column: string
  }
  channel_changed: {
    channel_names: string[]
  }
  information_clicked: {
    column_name: string
  }
  modal_save_clicked: InsightAnalytic
  modal_opened_in_new_tab: InsightAnalytic
  modal_download_pdf: InsightAnalytic
  modal_download_jpg: InsightAnalytic
  modal_scroll_media: InsightAnalytic
  modal_opened_in_full_screen: InsightAnalytic
}

export const trackMarketingEvent = <T extends keyof MarketingEvents>(
  event: T,
  data: MarketingEvents[T]
) => {
  analyticsService.getInstance().analytic(event, data)
}

const TRACK_DEBOUNCE = 500

export const debouncedTrackMarketingEvent = _.debounce(
  trackMarketingEvent,
  TRACK_DEBOUNCE
)
