import React, { useState } from 'react'
import { Badge } from 'antd'
import android_logo from '../../assets/images/icons/playstore_icon_big.png'
import ios_logo from '../../assets/images/icons/appstore_icon_big.png'
import web_logo from '../../assets/images/icons/web_logo_black.png'
import benchmark_icon from '../../assets/images/icons/benchmark_black_ico.svg'
import marketing_icon from '../../assets/images/icons/marketing_black_ico.svg'
import intelligence_icon from '../../assets/images/icons/intelligence_black_ico.svg'
import {
  getExistingPlatforms,
  isMarketingForProduct,
  isBenchmarkForProduct
} from '../../helpers/productHelper'

import chrome_ico from '../../assets/images/new_design/icons/ic-chrome-selected.svg'
import win_ico from '../../assets/images/new_design/icons/ic-windows-selected.svg'

import {
  PL_WEB,
  PL_ANDROID,
  PL_IOS,
  PL_WINDOWS,
  PL_CHROME
} from '../../shared/consts'
import { useNavigate } from 'react-router'

export const IntelligenceCard = ({
  product,
  intelligenceHover,
  numToShow,
  clickOnPlatform
}) => {
  const existingPlatforms = getExistingPlatforms(product)

  const logos = [android_logo, ios_logo, web_logo, win_ico, chrome_ico]
  const platforms = [PL_ANDROID, PL_IOS, PL_WEB, PL_WINDOWS, PL_CHROME]

  return (
    <div className='platform-icons'>
      {platforms.map((platform, index) => {
        return (
          <div
            className={`platform-icon ${platform
              .replace(' ', '_')
              .toLowerCase()} ${existingPlatforms[index] ? 'on' : 'off'}`}
            key={platform}
          >
            <Badger addBadge={intelligenceHover} numToShow={numToShow[index]}>
              <img
                src={logos[index]}
                alt={platform}
                className={`intelligence-icon ${
                  existingPlatforms[index] ? 'on' : 'off'
                }`}
                onClick={() =>
                  existingPlatforms[index]
                    ? clickOnPlatform(platform, product)
                    : null
                }
              />
            </Badger>
          </div>
        )
      })}
    </div>
  )
}
const Badger = ({ children, addBadge, numToShow }) => {
  return (
    <Badge count={addBadge ? numToShow : 0} className={'white-badge'}>
      {children}
    </Badge>
  )
}

const ActiveCard = ({
  product,
  isActive,
  text,
  icon,
  insightsNum,
  perfApps,
  isIntelligence,
  unSeenCriticalInsights
}) => {
  const navigate = useNavigate()

  const [isIntelligenceHover, setIntelligenceHover] = useState(false)
  const filteredAndroidApp = product.apps.find(
    app => app.platform.toLowerCase() === 'android'
  )

  const numOfAndroid =
    filteredAndroidApp && unSeenCriticalInsights[filteredAndroidApp.id]
      ? unSeenCriticalInsights[filteredAndroidApp.id].length
      : 0

  const filteredIOSApp = product.apps.find(
    app => app.platform.toLowerCase() === 'ios'
  )
  const numOfIOS =
    filteredIOSApp && unSeenCriticalInsights[filteredIOSApp.id]
      ? unSeenCriticalInsights[filteredIOSApp.id].length
      : 0

  const filteredWebApp = product.apps.find(
    app => app.platform.toLowerCase() === 'web'
  )
  const numOfWeb =
    filteredWebApp && unSeenCriticalInsights[filteredWebApp.id]
      ? unSeenCriticalInsights[filteredWebApp.id].length
      : 0

  const clickOnProduct = () => {
    let url = ''
    if (text.props.text1 === `Marketing`) {
      url = `/marketing?app=${
        product.apps.find(a => a.platform === text.props.text1).id
      }`
    } else {
      const benchApp = perfApps.find(
        app =>
          app.id ===
          product.apps.find(
            a =>
              a.has_performance === true &&
              ['iOS', 'Android'].includes(a.platform)
          ).id
      )
      if (benchApp.performance_events && benchApp.performance_events[0]) {
        const benchmarkID = benchApp.performance_events[0].id
        url = `/benchmark/compare/${benchmarkID}`
      } else {
        console.log("There isn't app with performace event")
        return
      }
    }

    navigate(url)
  }

  const clickOnPlatform = (platform, product) => {
    const url = `/product_intelligence/${product.id}?releasePlatformID=${
      product.apps.find(app => app.platform === platform).id
    }`

    navigate(url)
  }

  insightsNum = isIntelligence
    ? numOfAndroid + numOfIOS + numOfWeb
    : insightsNum
  return (
    <Badger numToShow={insightsNum} addBadge={!isIntelligenceHover}>
      <div
        className={`active-card ${isActive ? 'on' : 'off'}`}
        onClick={isActive && !isIntelligence ? () => clickOnProduct() : null}
        onMouseEnter={() => setIntelligenceHover(true)}
        onMouseLeave={() => setIntelligenceHover(false)}
        data-testid={isActive && !isIntelligence ? 'active-card' : ''}
      >
        <img
          src={icon}
          alt={text}
          className={` image ${isIntelligence ? 'cursorOff' : ''} `}
        />

        {isActive && isIntelligence && isIntelligenceHover ? (
          <IntelligenceCard
            product={product}
            numToShow={[numOfAndroid, numOfIOS, numOfWeb]}
            intelligenceHover={isIntelligenceHover}
            clickOnPlatform={clickOnPlatform}
          />
        ) : (
          <div className={'active'}>
            <div>{text}</div>
            <div className={isActive ? 'active-green' : 'active-grey'}>
              <div className={'text'}>{isActive ? 'ACTIVE' : 'INACTIVE'}</div>
            </div>
          </div>
        )}
      </div>
    </Badger>
  )
}

const ProductStatusBar = ({ product, perfApps, unSeenCriticalInsights }) => {
  const BenchmarkText = ({ text1, text2 }) => (
    <div className={'text-div'}>
      <span className={'product'}>{text1}</span>
      <span> {text2}</span>
    </div>
  )

  const marketingApp = product.apps.find(
    app => app.platform.toLowerCase() === 'marketing'
  )
  const numOfMarketing =
    marketingApp && unSeenCriticalInsights[marketingApp.id]
      ? unSeenCriticalInsights[marketingApp.id].length
      : 0

  return (
    <div className='products'>
      <ActiveCard
        product={product}
        isActive={getExistingPlatforms(product).some(el => el)}
        text={<BenchmarkText text1={'Product'} text2={'Intelligence'} />}
        icon={intelligence_icon}
        insightsNum={0}
        perfApps={perfApps}
        isIntelligence={true}
        unSeenCriticalInsights={unSeenCriticalInsights}
      />
      <ActiveCard
        product={product}
        isActive={isBenchmarkForProduct(product)}
        text={<BenchmarkText text1={'Product'} text2={'Benchmark'} />}
        icon={benchmark_icon}
        insightsNum={0}
        perfApps={perfApps}
        isIntelligence={false}
        unSeenCriticalInsights={unSeenCriticalInsights}
      />
      <ActiveCard
        product={product}
        isActive={isMarketingForProduct(product)}
        text={<BenchmarkText text1={'Marketing'} text2={'Campaigns'} />}
        icon={marketing_icon}
        insightsNum={numOfMarketing}
        perfApps={perfApps}
        isIntelligence={false}
        unSeenCriticalInsights={unSeenCriticalInsights}
      />
    </div>
  )
}

export default ProductStatusBar
