// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promo-days-of-week .promo-days-chart-container {
  height: 46rem;
}
.promo-days-of-week .app-icon-label {
  width: 3.2rem;
  height: 3.2rem;
}`, "",{"version":3,"sources":["webpack://./src/containers/MarketingOverview/components/charts/PromoTimeOfDay/PromoTimeOfDay.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;AAFJ;AAKE;EACE,aAAA;EACA,cAAA;AAHJ","sourcesContent":["@import '../../../mixins.scss';\n\n.promo-days-of-week {\n  .promo-days-chart-container {\n    height: 46rem;\n  }\n\n  .app-icon-label {\n    width: 3.2rem;\n    height: 3.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
