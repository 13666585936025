import { createAction, createReducer } from 'redux-act';

import {
  UserFlow, UserFlowPersona, UserFlowSet, UserJourneyApp
} from '../../interfaces/UserJourneys';

export const USER_JOURNEYS_ACTIONS = {
  GET_USER_JOURNEYS_APPS: "GET_USER_JOURNEYS_APPS",
  GET_USER_JOURNEYS_APPS_SUCCESS: "GET_USER_JOURNEYS_APPS_SUCCESS",

  GET_USER_FLOWS: "GET_USER_FLOWS",
  GET_USER_FLOWS_SUCCESS: "GET_USER_FLOWS_SUCCESS",

  GET_USER_FLOW_PERSONAS: "GET_USER_FLOW_PERSONAS",
  GET_USER_FLOW_PERSONAS_SUCCESS: "GET_USER_FLOW_PERSONAS_SUCCESS",

  GET_USER_FLOWS_SETS: "GET_USER_FLOWS_SETS",
  GET_USER_FLOWS_SETS_SUCCESS: "GET_USER_FLOWS_SETS_SUCCESS",

  GET_USER_FLOWS_SET: "GET_USER_FLOWS_SET",
  GET_USER_FLOWS_SET_SUCCESS: "GET_USER_FLOWS_SET_SUCCESS",

  GET_USER_FLOW_HISTORY: "GET_USER_FLOW_HISTORY",
  GET_USER_FLOW_HISTORY_SUCCESS: "GET_USER_FLOW_HISTORY_SUCCESS",
};

export const getUserJourneysApps = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_JOURNEYS_APPS
);

export const getUserJourneysAppsSucceess = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_JOURNEYS_APPS_SUCCESS,
  (data: any) => data
);

type GetUserFlowsProps = {
  app_id?: string;
  persona_id?: string;
  app_ids?: string[];
};

export const getUserFlows = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOWS,
  (data: GetUserFlowsProps) => data
);

export const getUserFlowsSucceess = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SUCCESS,
  (data: any) => data
);

type GetUserFlowPersonasProps = {
  app_id: string;
};

export const getUserFlowPersonas = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOW_PERSONAS,
  (data: GetUserFlowPersonasProps) => data
);

export const getUserFlowPersonasSuccess = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOW_PERSONAS_SUCCESS,
  (data: any) => data
);

type GetUserFlows = {
  user_flow_id: string;
  app_ids?: string[];
  persona_id?: string;
};

export const getUserFlowsSets = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SETS,
  (data: GetUserFlows) => data
);

export const getUserFlowsSetsSuccess = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SETS_SUCCESS,
  (data: any) => data
);

type GetUserFlowSet = {
  app_ids: string[];
  user_flow_id: string;
  persona_id: string;
  user_flow_set_id: string;
};

export const getUserFlowsSet = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SET,
  ({ app_ids, user_flow_id, persona_id }: GetUserFlowSet) => ({
    app_ids,
    user_flow_id,
    persona_id,
  }),
  ({ user_flow_set_id }: GetUserFlowSet) => ({ user_flow_set_id })
);

export const getUserFlowsSetSuccess = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SET_SUCCESS,
  (data: any) => data
);

export type GetUserFlowHistory = {
  user_flow_id: string;
  app_id: string;
  persona_id: string;
};

export const getUserFlowHistory = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOW_HISTORY,
  (data: GetUserFlowHistory) => data
);

export const getUserFlowHistorySuccess = createAction(
  USER_JOURNEYS_ACTIONS.GET_USER_FLOW_HISTORY_SUCCESS,
  (data: any) => data
);

export type UserJourneysState = {
  userJourneysApps: UserJourneyApp[];
  userFlows: UserFlow[];
  userFlowsSets: UserFlowSet[];
  userFlowPersonas: UserFlowPersona[];
};

const initState = {
  userJourneysApps: [],
  userFlows: [],
  userFlowsSets: [],
  userFlowPersonas: [],
};

export const userJourneysReducer = createReducer(
  {
    [USER_JOURNEYS_ACTIONS.GET_USER_JOURNEYS_APPS_SUCCESS]: (
      state: UserJourneysState,
      userJourneysApps: UserJourneyApp[]
    ): UserJourneysState => {
      return {
        ...state,
        userJourneysApps,
      };
    },
    [USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SUCCESS]: (
      state: UserJourneysState,
      { userFlows }
    ): UserJourneysState => {
      return {
        ...state,
        userFlows,
      };
    },
    [USER_JOURNEYS_ACTIONS.GET_USER_FLOW_PERSONAS_SUCCESS]: (
      state: UserJourneysState,
      userFlowPersonas: UserFlowPersona[]
    ): UserJourneysState => {
      return {
        ...state,
        userFlowPersonas,
      };
    },
    [USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SETS_SUCCESS]: (
      state: UserJourneysState,
      userFlowsSets: UserFlowSet[]
    ): UserJourneysState => {
      return {
        ...state,
        userFlowsSets,
      };
    },
    [USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SET_SUCCESS]: (
      state: UserJourneysState,
      { user_flow_set, user_flow_set_id }: any
    ): UserJourneysState => {
      return {
        ...state,
        userFlowsSets: state.userFlowsSets.map((userFlowSet) => {
          if (userFlowSet.id === Number(user_flow_set_id)) {
            if (user_flow_set) {
              return user_flow_set;
            }

            return { ...userFlowSet, assets: [] };
          }

          return userFlowSet;
        }),
      };
    },
    [USER_JOURNEYS_ACTIONS.GET_USER_FLOW_HISTORY_SUCCESS]: (
      state: UserJourneysState,
      userFlowsSets: UserFlowSet[]
    ): UserJourneysState => {
      return {
        ...state,
        userFlowsSets,
      };
    },
  },
  initState
);
