import { gql } from '@apollo/client'

export const GET_MARKETING_APPS_QUERY = gql`
  query GetMarketingApps (
    $startTimeGte: Date
    $startTimeLt: Date
  ) {
    marketing {
      apps (
        startTimeGte: $startTimeGte,
        startTimeLt: $startTimeLt,
        subType: "promotional"
      ) {
        id
        displayName
        icon
        insightsCnt
      }
    }
  }
`

export const GET_MARKETING_PERSONAS_QUERY = gql`
  query GetMarketingPersonas($appIds: [Int]!) {
    marketing {
      personas(appIds: $appIds) {
        id
        title
        roles {
          id
          name
        }
        description
      }
    }
  }
`

export const GET_MARKETING_FOCUS_AREAS_QUERY = gql`
  query GetMarketingFocusAreas($appIds: [Int]!) {
    marketing {
      focusAreas(appIds: $appIds) {
        id
        name
      }
    }
  }
`

export const GET_MARKETING_INSIGHTS_QUERY = gql`
  query GetMarketingInsights(
    $appIds: [Int]!
    $offset: Int
    $limit: Int
    $sort: String
    $type: String
    $personaIds: [Int]
    $startTimeGte: Date
    $startTimeLt: Date
    $insightId: Int
    $focusAreaIds: [Int]
    $personaRoleIds: [Int]
  ) {
    marketing {
      insightPage(
        appId: $appId
        personaIds: $personaIds
        personaRoleIds: $personaRoleIds
        startTimeGte: $startTimeGte
        startTimeLt: $startTimeLt
        insightId: $insightId
        focusAreaIds: $focusAreaIds
      ) {
        summary {
          byType {
            type
            count
          }
          totalCount
        }
        insights(offset: $offset, limit: $limit, sort: $sort, type: $type) {
          id
          type
          subType
          startTime
          title
          description
          priority
          thumbnail
          thumbnailOriginal
          focusArea
          labels {
            name
          }
          personas {
            id
            title
            roles {
              id
              name
            }
            description
          }
        }
      }
    }
  }
`

export const GET_MARKETING_INSIGHT_BY_ID_QUERY = gql`
  query GetMarketingInsightById($insightId: Int!) {
    insight(id: $insightId) @rest(type: "Insight", path: "insight/{args.id}") {
      data {
        id
        title
        description
        type
        platform
        priority
        assets
        labels
        start_time
        thumbnail
        app_id
      }
    }
  }
`

export const GET_MARKETING_INSIGHT_PERSONAS_AND_FOCUS_AREA_QUERY = gql`
  query GetMarketingInsights($appIds: [Int]!, $insightId: Int!) {
    marketing {
      insightPage(appIds: $appIds, insightId: $insightId) {
        insights {
          personas {
            id
            title
            description
            roles {
              id
              name
            }
          }
          focusArea
          metaData
        }
      }
    }
  }
`

export const EXPORT_TO_CSV_QUERY = gql`
  query ExportToCsv($appIds: [Int]!, $type: String) {
    marketing {
      insightPage(appIds: $appIds) {
        csvUrl(type: $type)
      }
    }
  }
`

export const PROMOTIONS_REPOSITORY_QUERY = gql`
  query GetPromotionsRepository(
    $appIds: [Int]
    $offset: Int
    $limit: Int
    $sort: String
    $types: [String]
    $personaIds: [Int]
    $startTimeGte: Date
    $startTimeLt: Date
    $insightId: Int
    $focusAreaIds: [Int]
    $personaRoleIds: [Int]
    $subType: String
  ) {
    marketing {
      insightPage(
        appIds: $appIds
        personaIds: $personaIds
        personaRoleIds: $personaRoleIds
        startTimeGte: $startTimeGte
        startTimeLt: $startTimeLt
        insightId: $insightId
        focusAreaIds: $focusAreaIds
        subType: $subType
      ) {
        summary {
          byType {
            type
            count
          }
          totalCount
        }
        insights(offset: $offset, limit: $limit, sort: $sort, types: $types) {
          id
          type
          subType
          startTime
          title
          description
          priority
          thumbnail
          thumbnailOriginal
          focusArea
          labels {
            name
          }
          personas {
            id
            title
            roles {
              id
              name
            }
            description
          }
          app {
            id
            displayName
            icon
          }
        }
      }
    }
  }
`
