// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.segment-container .extra-header {
  position: absolute;
  top: 0.5rem;
  right: 3.8rem;
  display: flex;
  align-items: center;
}
.segment-container .extra-header .label {
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 11px;
  letter-spacing: 0.09px;
  line-height: 13px;
  margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/containers/marketing/mass_market/userSegment/UserSegment.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EAEA,mBAAA;AADJ;AAEI;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;AAAN","sourcesContent":[".segment-container {\n  .extra-header {\n    position: absolute;\n    top: 0.5rem;\n    right: 3.8rem;\n    display: flex;\n    \n    align-items: center;\n    .label{\n      color: #FFFFFF;\n      font-family: Roboto;\n      font-size: 11px;\n      letter-spacing: 0.09px;\n      line-height: 13px;\n      margin-right: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
