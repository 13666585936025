import { Release } from "pages/Intel/utils/types";
import React, { useEffect, useRef } from "react";
import card_separator_ico from "assets/images/icons/context-timeline/card-separator.svg";
import moment from "moment";
import clsx from "clsx";

type Props = {
  release: Release;
  selected: boolean;
  onSelect: () => void;
};

const StateMap = {
  completed: "completed",
  analysis_included: "completed",
  in_review: "In Review",
  new: "new",
} as const;

export const ReleaseCard = ({ release, selected, onSelect }: Props) => {
  const state = StateMap[release.state as keyof typeof StateMap] || "completed";

  const isEmpty = release.is_visible === null || release.insight_cnt < 1;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && selected) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [selected]);

  return (
    <div
      ref={ref}
      className={clsx("card", {
        selected,
      })}
      onClick={onSelect}
    >
      <div className="card-heading">
        <div className="card-heading-container">
          <div className="date">
            {moment(release.release_date).format("DD MMM YYYY")}
          </div>
          <div className="version">Ver. {release.release_name}</div>
          <div
            className={clsx("status", {
              [state]: true,
              empty: isEmpty,
            })}
          >
            {isEmpty ? "No Discoveries" : state}
          </div>
        </div>
        <img src={card_separator_ico} />
      </div>
      <div className="card-content">
        <div className="insights-count">{release.insight_cnt} Discoveries</div>
      </div>
    </div>
  );
};
