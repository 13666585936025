import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation, Outlet } from 'react-router'

import { jwtDecode } from 'jwt-decode'
import { message } from 'antd'
import { FlagsContext } from 'react-unleash-flags'
import { isNull } from 'lodash'

//ACTIONS
import {
  setUser,
  getAppsMetadata,
  createNewUser
} from '../../redux/actions/dashboardActions'
import { getUserDiscoveries } from '../../redux/acts/userDiscoveries'
import { getUserName } from '../../redux/actions/userActions'
import { getPerformancePageMetadata } from '../../redux/actions/performanceActions'
import {
  USER_CREATION_EXPIRED_TOKEN,
  USER_CREATION_FAILED_MESSAGE
} from '../../redux/sagas/userSagas'
import { getSubscriptions } from '../../redux/acts/subscriptions'

// SERVICES && HELPERS
import analyticsService from '../../services/analyticsService'
import storageService from '../../services/storageService'
import awsService from '../../services/awsService'
import { siteVersions } from '../../shared/consts'
import { updateAxiosToken } from '../../App'

//COMPONENTS
import Header from '../header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import Footer from '../footer/Footer'
import SupportButton from '../../common/supportButton/SupportButton'

// ASSETS
import FBWorkspaceLogo from '../../assets/images/icons/fbWorkspace_logo.png'
import JiraLogo from '../../assets/images/icons/jira-svgrepo-com.svg'
import scroll_shveron_svg from '../../assets/images/new_design/scroll-shveron.svg'
import { transformOldMarketingUrlToNewMarketingUrl } from 'containers/MarketingCampaings/utils/helpers'

const supportButtonParams = {
  fb: {
    icon: FBWorkspaceLogo,
    link: 'https://my.workplace.com/groups/2449581048421783/'
  },
  yjp: {
    icon: JiraLogo,
    link: 'https://watchful-tech.atlassian.net/servicedesk/customer/portal/3'
  }
}

const yjpEmails = ['@lycorp.co.jp', '@yahoo-corp.jp', '@ycorp.co.jp']

export function Dashboard () {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()

  const appData = useSelector(state => state.appData)
  const products = useSelector(state => state.overview.products)
  const performanceApps = useSelector(state => state.performance.apps)
  const isFetchingPerformancePageMetadata = useSelector(
    state => state.performance.isFetchingPerformancePageMetadata
  )
  const apps = useSelector(state => state.appData.apps)
  const appsLoaded = useSelector(state => state.appData.appsLoaded)
  const onlyPerformanceMode = useSelector(
    state => state.appData.onlyPerformanceMode
  )
  const appSelectedDemo = useSelector(state => state.appData.appSelectedDemo)
  const user = useSelector(state => state.appData.user)
  const siteVersion = useSelector(state => state.appData.siteVersion)
  const currentRequests = useSelector(state => state.appData.currentRequests)
  const userData = useSelector(state => state.userData)
  const unSeenCriticalInsights = useSelector(
    state => state.overview.unSeenCriticalInsights
  )

  const aws = new awsService()
  const analytics = analyticsService.getInstance()
  const storage = new storageService()

  const [siteSize, siteSizeChange] = useState('')

  const [apiReady, apiReadyChange] = useState(false)
  const [scrollTopShowing, scrollTopShowingChange] = useState(false)

  const flagsClient = useContext(FlagsContext)

  useEffect(() => {
    const currentUser = aws.getCurrentUser()
    if (siteVersion === siteVersions.DEMO) {
      return
    }
    const regex = new RegExp('(?:id_token=)(.*?)(?=&)')
    const hash = location.hash
    let token = ''
    if (hash) {
      token = hash.match(regex)[0].substring(9)
    }

    if (!token) {
      token = storage.getItem('usertoken')
    }
    if (token) {
      let cognitoUser = jwtDecode(token)
      analytics.email = cognitoUser.email
      analytics.username = cognitoUser.sub
      aws.setCredentials(token)
      storage.setItem('usertoken', token)
      updateAxiosToken(token)
      dispatch(createNewUser())

      return
    }

    if (!currentUser || params.packageName === 'link') {
      // There's no logged in user, send to login
      navigate('/login', {
        state: {
          previousLocation: location.pathname
        }
      })

      return
    }

    currentUser.getSession((err, session) => {
      if (err || !session || !session.idToken) {
        storage.clear()
        navigate('/login', {
          state: {
            previousLocation: location.pathname
          }
        })
        return
      }
      const cognitoUser = session.idToken.payload
      analytics.email = cognitoUser.email
      analytics.username = cognitoUser.sub
      aws.setCredentials(session.idToken.jwtToken)
      storage.setItem('usertoken', session.idToken.jwtToken)
      updateAxiosToken(session.idToken.jwtToken)
      dispatch(createNewUser())
      dispatch(setUser({ ...cognitoUser, analytics }))
      dispatch(getAppsMetadata(cognitoUser))
      dispatch(getUserName(cognitoUser.email))
      apiReadyChange(true)
    })
  }, [])

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    window.addEventListener('scroll', scrollTopShowingHandle)
    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
      window.removeEventListener('scroll', scrollTopShowingHandle)
    }
  }, [])

  //HELPERS && HANDLERS
  const updateWindowDimensions = () => {
    if (window.innerWidth >= 1280) {
      return siteSizeChange('large')
    }

    // TODO: remove mobile fix
    if (window.screen.width <= 768) {
      return siteSizeChange('large')
    }

    return siteSizeChange('normal')
  }
  const scrollTopShowingHandle = () => {
    scrollTopShowingChange(window.pageYOffset > 100)
  }
  const scrollTopHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  //EFFECTS
  useEffect(() => {
    if (apiReady && !performanceApps && !isFetchingPerformancePageMetadata) {
      dispatch(
        getPerformancePageMetadata({
          monthsAgo: 6,
          without_num_of_samples: 1
        })
      )
    }
  }, [apiReady, performanceApps, isFetchingPerformancePageMetadata])

  useEffect(() => {
    if (
      !isNull(userData.days_to_trial_expiration) &&
      userData.days_to_trial_expiration <= 0
    ) {
      window.location.href = '/410.html'
      return
    }
  }, [userData.days_to_trial_expiration])

  useEffect(() => {
    if (userData.userNotCreatedMessage === USER_CREATION_FAILED_MESSAGE) {
      // this is temp, remove the next lines once yjp signs contract
      message.loading({
        content: 'Allocated user amount exceeded, please consult your admin',
        duration: 0,
        icon: <div />
      })
      storage.setSessionItem('usertoken', null)
      setTimeout(() => message.destroy(), 5000)
      navigate('/login', {
        state: {
          previousLocation: location.pathname
        }
      })
    } else if (userData.userNotCreatedMessage === USER_CREATION_EXPIRED_TOKEN) {
      storage.clear()
      setUser({})

      navigate('/login', {
        state: {
          previousLocation: location.pathname
        }
      })
    }
  }, [userData.userNotCreatedMessage, location.pathname])

  useEffect(() => {
    if (userData) {
      analytics.first_name = userData.first_name
      analytics.last_name = userData.last_name
      analytics.role = userData.role
      analytics.department = userData.department
      analytics.isAdmin = userData.is_org_admin
      analytics.user_type = userData.first_name
        ? userData.is_org_admin
          ? 'Admin'
          : 'User'
        : 'Guest'
    }
  }, [userData])

  useEffect(() => {
    // has has no experiments apps
    // try performance
    if (
      !location.pathname.includes('/performance') &&
      apps.length === 1 &&
      apps.find(app => app.metadata.id === -1)
    ) {
      navigate('/benchmark/compare')
    }
  }, [apps])

  useEffect(() => {
    const token = storage.getItem('usertoken')
    if (userData.successUserDataChange && token) {
      let cognitoUser = jwtDecode(token)

      analytics.email = cognitoUser.email
      analytics.username = cognitoUser.sub

      dispatch(setUser({ ...cognitoUser, analytics }))
      dispatch(getAppsMetadata(cognitoUser))
      dispatch(getSubscriptions())
      dispatch(getUserName(cognitoUser.email))
      dispatch(getUserDiscoveries())
      apiReadyChange(true)
    }
  }, [userData.successUserDataChange])

  useEffect(() => {
    const newMarketingUrl = transformOldMarketingUrlToNewMarketingUrl(
      location.pathname,
      location.search
    )

    if (newMarketingUrl) {
      console.log(newMarketingUrl)
      navigate(newMarketingUrl, { replace: true })
    }
  }, [location.pathname, location.search])

  const demoMode = siteVersion === siteVersions.DEMO

  if (!apiReady && !demoMode) {
    console.log('Dashboard.render() - waiting for user session...')
    return null
  }

  const isFB = user?.email?.includes('@fb.com')

  const isYjp =
    user?.email && yjpEmails.some(yjpEmail => user.email.includes(yjpEmail))
  const isUsualFlow = user && performanceApps && appsLoaded && products
  const perfModeOnlyFlow =
    onlyPerformanceMode &&
    performanceApps &&
    appData.siteVersion === siteVersions.FULL
  const demoFlow = appSelectedDemo || appData.siteVersion === 'demo'

  return (
    <div className='dashboard'>
      <div id='page-backdrop-portal' className={`backdrop backdrop-disable`} />
      <Header
        showSearch={
          !demoMode &&
          apps.length > 1 &&
          !apps.find(app => app.metadata.id === -1) &&
          location &&
          !location.pathname.includes('/performance') // hide search in the performance page
        }
      />
      <div
        className={`dashboard__container ${
          currentRequests.length > 0 ? 'blurry' : ''
        }`}
      >
        <Sidebar
          siteSize={siteSize}
          products={products}
          unSeenCriticalInsights={unSeenCriticalInsights}
        />
        <main className='dashboard__main'>
          {(isUsualFlow || perfModeOnlyFlow || demoFlow) && flagsClient && (
            <>
              <Outlet />
            </>
          )}
          {isFB && (
            <SupportButton
              supportLink={supportButtonParams['fb'].link}
              supportIcon={supportButtonParams['fb'].icon}
            />
          )}
          {isYjp && (
            <SupportButton
              supportLink={supportButtonParams['yjp'].link}
              supportIcon={supportButtonParams['yjp'].icon}
            />
          )}
        </main>
        <div className='right-space' />
      </div>

      {scrollTopShowing && (
        <div className='scroll-top' onClick={scrollTopHandler}>
          TO TOP
          <div className='scroll-top-img'>
            <img src={scroll_shveron_svg} />
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default Dashboard
