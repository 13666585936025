import './IntelPage.scss'

import { Col, Empty, Row } from 'antd'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import React, {useEffect, useState} from 'react'

import { CardsList } from './components/CardsList'
import { InsightPreviewModal } from './components/InsightPreviewModal'
import { IntelFilters } from './components/IntelFilters/IntelFilters'
import { ReleasesList } from './components/ReleasesList'
import { SidebarFilters } from './components/SidebarFilters'
import { useIntelData } from './hooks/useIntelData'
import { useIntelFilters } from './hooks/useIntelFilters'
import { trackIntelEvent } from './utils/analytics'
import Loader from "../../common/NewLoader/NewLoader";

export const IntelPage = () => {
  const {
    productIds,
    customRange,
    customRangeTime,
    daysAgo,
    experiments,
    features,
    handleChangeFilter,
    platforms,
    type,
    insightId,
    personaIds,
    startTimeGte,
    startTimeLt,
    limit,
    offset,
    mode,
    releaseId
  } = useIntelFilters()

  const {
    insights,
    selectedInsight,
    abTest,
    products,
    personas,
    summaryByType,
    experimentsFilterCounts,
    featuresFilterCounts,
    fetchMoreInsights,
    hasMoreInsights,
    releases,
    isLoading
  } = useIntelData({
    handleChangeFilter,
    filters: {
      releaseId,
      mode,
      insightId,
      platforms,
      productIds,
      startTimeGte,
      startTimeLt,
      abTestStatuses: experiments,
      type,
      features,
      personaIds,
      limit,
      offset
    }
  })

  useEffect(() => {
    trackIntelEvent('intel_enter', null)
  }, [])

  const handleInsightModalClose = () => {
    handleChangeFilter('insight_id', '')
  }

  const isMobile = isMobileDevice()

  const [isNoData, setIsNoData] = useState(false)
  useEffect(() => {
    setIsNoData(!isLoading && insights.length === 0)
  }, [products, personas, summaryByType, experimentsFilterCounts, featuresFilterCounts]);

  return (
    <div className='i-container'>
      <IntelFilters
        platforms={platforms}
        customRange={customRange}
        customRangeTime={customRangeTime}
        daysAgo={daysAgo}
        products={products}
        selectedProductIds={productIds}
        personas={personas}
        selectedPersonaIds={personaIds}
        handleUpdateFilter={handleChangeFilter}
        mode={mode}
        totalReleases={releases.length}
      />
      <Row wrap={isMobile ? true : false} gutter={[24, 24]}>
        <Col xs={24} flex={!isMobile ? '200px' : undefined}>
          <SidebarFilters
            types={summaryByType}
            selectedExperiments={experiments}
            selectedFeatures={features}
            selectedType={type}
            handleChangeFilter={handleChangeFilter}
            experimentsFilterCounts={experimentsFilterCounts}
            featuresFilterCounts={featuresFilterCounts}
          />
        </Col>
        <Col xs={24} flex={!isMobile ? 'auto' : undefined}>
          {mode === 'by_release' && (
            <ReleasesList
              releaseId={releaseId}
              releases={releases}
              handleChangeFilter={handleChangeFilter}
            />
          )}
          {isLoading ? <div style={{ paddingBottom: "20rem" }}><Loader /></div> : !isNoData ? (
            <CardsList
              insights={insights}
              handleChangeFilter={handleChangeFilter}
              fetchMoreInsights={fetchMoreInsights}
              hasMoreInsights={hasMoreInsights}
            />
          ) : (
            <Empty
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 500px)'
              }}
            />
          )}
        </Col>
      </Row>
      {selectedInsight && (
        <InsightPreviewModal
          open={!!selectedInsight}
          onClose={handleInsightModalClose}
          appId={selectedInsight.app.id.toString()}
          appName={selectedInsight.app.displayName}
          assets={selectedInsight.assets}
          date={selectedInsight.start_time}
          focusArea={selectedInsight.focus_area || ''}
          insightDescription={selectedInsight.description || ''}
          insightId={selectedInsight.id}
          labels={selectedInsight.labels || []}
          priority={selectedInsight.priority}
          title={selectedInsight.title}
          type={selectedInsight.type}
          /* Temporary disable user types filter SQUAD2-1329 */
          // userTypes={personas}
          // userTypesSeen={selectedInsight.personas}
          appIcon={selectedInsight.app.icon || ''}
          platform={selectedInsight.platform || ''}
          releaseName={selectedInsight.release_name || ''}
          variants={selectedInsight.variants || []}
          personaLocations={selectedInsight.persona_locations || []}
          abTestData={abTest}
        />
      )}
    </div>
  )
}

export default IntelPage
