// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctl-timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.ctl-timeline img {
  cursor: pointer;
}
.ctl-timeline .ctl-timeline-start-date {
  color: #2f3337;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 18.6px */
  text-transform: capitalize;
}
.ctl-timeline .ctl-timeline-end-date {
  color: #2f3337;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 18.6px */
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/pages/ContextTimeline/components/main/timeline/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,0BAAA;AAAJ;AAGE;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,0BAAA;AADJ","sourcesContent":[".ctl-timeline {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 4px;\n\n  img {\n    cursor: pointer;\n  }\n\n  .ctl-timeline-start-date {\n    color: #2f3337;\n    font-family: Inter;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 155%; /* 18.6px */\n    text-transform: capitalize;\n  }\n\n  .ctl-timeline-end-date {\n    color: #2f3337;\n    font-family: Inter;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 155%; /* 18.6px */\n    text-transform: capitalize;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
