import React, { FC } from 'react'

import ChartContainer from '../../../../components/chartContainer/ChartContainer'
import WGroupedBarChart from '../../../../components/charts/GroupedBarChart/WGroupedBarChart'
import { IDataPoint, Ipersona, Product } from '../../../../interfaces/Product'

interface IProps {
  products: Product[]
  personas: Ipersona[]
}

const PersonaState: FC<IProps> = ({ personas, products }) => {
  const getOptions = () => {
    return {
      axes: {
        left: {
          ticks: {
            formatter: (tick: string) => {
              // show only integer ticks
              const tickNumber = parseFloat(tick)
              return Math.round(tickNumber) === tickNumber ? tick : ''
            }
          }
        }
      },
      tooltip: {
        customHTML: (points: IDataPoint[]): string => {
          return `<div class="text"><div class="competitor-name">${
            points[0].group
          }: </div><div class="personalized-value">${points[0].value.toFixed(
            0
          )}</div></div>`
        }
      },
      bars: {
        maxWidth: 40
      },
      getFillColor: null,
      height: '30rem',
      color: {
        scale: { Active: '#000000', Inactive: '#6929C4' }
      }
    }
  }

  const groupedData: IDataPoint[] = []
  personas.forEach(per => {
    const product = products.find(p => per.product_ids.includes(p.id))
    //@ts-ignore
    const i = groupedData.findIndex(
      (d: IDataPoint) => d.key === product?.name && d.active === per.active
    )
    if (i === -1) {
      groupedData.push({
        group: per.active ? 'Active' : 'Inactive',
        key: product?.name,
        value: 1,
        active: per.active
      })
    } else {
      groupedData[i].value++
    }
  })

  const setOfProductId = Array.from(
    new Set(
      personas.map(
        per => products.find(p => per.product_ids.includes(p.id))?.id
      )
    )
  )

  //@ts-ignore
  const uniqueApps = setOfProductId.map((id: number) => {
    const pr = products.find(p => p.id === id)
    return {
      //@ts-ignore
      text: pr.name,
      //@ts-ignore
      icon: pr.thumbnail
    }
  })

  return (
    <div className={'chart-container persona-container'}>
      <ChartContainer title={'PERSONA STATE'} titleCentered={false}>
        <WGroupedBarChart
          data={groupedData}
          images={uniqueApps}
          options={getOptions()}
          isMaxPerGroup={false}
        />
        <div className='legend index'>
          <div className='legend-item'>
            <div
              className='legend-color'
              style={{ backgroundColor: '#000000' }}
            ></div>
            <div className='legend-value'>Active</div>
          </div>
          <div className='legend-item'>
            <div
              className='legend-color'
              style={{ backgroundColor: '#6929C4' }}
            ></div>
            <div className='legend-value'>Inactive</div>
          </div>
        </div>
      </ChartContainer>
    </div>
  )
}

export default PersonaState
