// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-toggle {
  margin-top: 3px;
  padding: 6px 5px 2px 5px;
  border-radius: 4px;
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/containers/header/menu-toggle/menu-toggle.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,wBAAA;EACA,kBAAA;EACA,gBAAA;AACF","sourcesContent":[".menu-toggle {\n  margin-top: 3px;\n  padding: 6px 5px 2px 5px;\n  border-radius: 4px;\n  background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
