import React, { useEffect, useState } from 'react'
import {
  compareStrings,
  compareStringsArray
} from '../../../../services/stringsService'
import { Img } from 'react-image'
import { Consts, abTestsTypes } from '../../../../shared/consts'

const ZOOM_Q = 2

const ViewExperimentImage = ({
  imageSrc,
  videoSrc,
  className,
  onClick,
  experimentType,
  type,
  status,
  isOneEmpty,
  modal,
  commonZoom,
  commonZoomChanged,
  blurredImg,
  currentModalSlides
}) => {
  const imageClassName = getImageClassName({
    experimentType,
    status,
    isOneEmpty
  })
  const [zoomed, zoomedChange] = useState(false)
  const [zoomCoords, zoomCoordsChange] = useState({})

  const onZoomHandle = e => {
    if (!modal) {
      onClick()
    } else {
      let [calcX, calcY] = calcCoords(e)

      zoomCoordsChange({
        left: calcX,
        top: calcY
      })
      zoomedChange(!zoomed)
      commonZoomChanged(!zoomed, calcX, calcY)
    }
  }

  const calcCoords = e => {
    let imgCoords = e.currentTarget.getBoundingClientRect()
    let xImg = e.clientX - imgCoords.left,
      yImg = e.clientY - imgCoords.top

    let calcX = -(
      (imgCoords.width * ZOOM_Q - imgCoords.width) / 2 +
      ((xImg - imgCoords.width / 2) * ZOOM_Q) / 2
    )
    let calcY = -(
      (imgCoords.height * ZOOM_Q - imgCoords.height) / 2 +
      ((yImg - imgCoords.height / 2) * ZOOM_Q) / 2
    )

    return [calcX, calcY]
  }

  const handleMouseMove = e => {
    if (modal && zoomed) {
      let [calcX, calcY] = calcCoords(e)
      commonZoomChanged(true, calcX, calcY)
    }
  }

  const handleVideoClick = e => {
    if (!modal) {
      onClick()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  useEffect(() => {
    if (!commonZoom) {
      return
    }
    if (commonZoom.enable) {
      zoomedChange(true)
      zoomCoordsChange({
        left: commonZoom.x,
        top: commonZoom.y
      })
    } else {
      zoomedChange(false)
    }
  }, [commonZoom])

  useEffect(() => {
    zoomedChange(false)
    zoomCoordsChange({})
  }, [currentModalSlides])

  return (
    <div className='image-wrapper'>
      {type === 'image' ? (
        <div>
          {modal && (
            <div
              className='image-zoom'
              onClick={onZoomHandle}
              onMouseMove={handleMouseMove}
              style={{
                backgroundImage: `url(${imageSrc})`,
                display: zoomed ? 'block' : 'none',
                backgroundPositionX: `${zoomCoords.left - 1}px`,
                backgroundPositionY: `${zoomCoords.top}px`,
                backgroundSize: `${100 * ZOOM_Q}%`,
                backgroundColor: 'black'
              }}
            />
          )}
          <Img
            src={imageSrc}
            className={`view__experiment-image ${className} ${imageClassName}`}
            alt='experiment'
            onClick={onZoomHandle}
          />
          {modal && blurredImg && (
            <div className='cache-blurred-img'>
              <img src={blurredImg} alt={'blurred image'} />
            </div>
          )}
        </div>
      ) : (
        <div
          className={`view__experiment-image view__experiment-image-video ${className} ${imageClassName}`}
          onClick={handleVideoClick}
        >
          <video
            className='view__experiment-video'
            src={videoSrc}
            controls
            controlsList='nodownload'
            autoPlay={modal}
          />
        </div>
      )}
    </div>
  )
}

const getImageClassName = ({ experimentType, status, isOneEmpty }) => {
  let className = ''
  if (
    !isOneEmpty &&
    compareStrings(experimentType, Consts.experimentTypes.OFF)
  ) {
    if (
      compareStringsArray(status, [
        abTestsTypes.ABANDONED,
        abTestsTypes.ABANDONED_IN_INTEGRATED
      ])
    ) {
      className = 'view__experiment-image-abandoned'
    }
    if (compareStrings(status, abTestsTypes.INTEGRATED)) {
      className = 'view__experiment-image-integrated'
    }
  }
  return className
}

export default ViewExperimentImage
