// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-message {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #f3f3f3;
}
.s-message span {
  color: #3f3f3f;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
}
.s-message .got-it-btn {
  cursor: pointer;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 200px;
  background: #2e5fff;
}`, "",{"version":3,"sources":["webpack://./src/components/SimpleMessage/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;EACA,6CAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AACJ;AAEE;EACE,eAAA;EACA,WAAA;EACA,6CAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".s-message {\n  display: flex;\n  padding: 4px 8px;\n  align-items: center;\n  gap: 4px;\n  border-radius: 8px;\n  background: #f3f3f3;\n\n  span {\n    color: #3f3f3f;\n    font-feature-settings: \"clig\" off, \"liga\" off;\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 160%; /* 22.4px */\n  }\n\n  .got-it-btn {\n    cursor: pointer;\n    color: #fff;\n    font-feature-settings: \"clig\" off, \"liga\" off;\n    font-family: Inter;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 160%; /* 19.2px */\n    display: flex;\n    padding: 2px 8px;\n    align-items: center;\n    border-radius: 200px;\n    background: #2e5fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
