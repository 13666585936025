import React, { useCallback, useState } from 'react'
import awsService from '../../../../services/awsService'
import { Button, notification, Form } from 'antd'
import Icon from '@ant-design/icons'
import { InputText } from '../../../../components/inputText/InputText'

export const SetNewPasswordView = ({
  form,
  next,
  confirmationCode,
  username,
  setPassword
}) => {
  const [loading, setLoading] = useState(false)
  const confirmDirty = false
  const handleSubmit = useCallback(values => {
    setLoading(true)
    const aws = new awsService()
    aws
      .confirmPassword(confirmationCode, username, values.password)
      .then(() => {
        setPassword(values.password)
        next()
      })
      .catch(err => {
        notification.open({
          duration: 7,
          message: 'Something went wrong',
          description: err.message,
          icon: <Icon type='exception' style={{ color: 'red' }} />
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // const compareToFirstPassword = useCallback(
  //   (rule, value, callback) => {
  //     if (value && value !== form.getFieldValue("password")) {
  //       callback("Two passwords that you enter is inconsistent!");
  //     } else {
  //       callback();
  //     }
  //   },
  //   [form]
  // );

  const validateToNextPassword = useCallback(
    (rule, value, callback) => {
      if (value && confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    [confirmDirty, form]
  )

  return (
    <div className='set-new-password-view'>
      <div className='set-new-password-view__text'>
        <div className='head'>
          In order to <span className='bold'>Protect your account</span>, make
          sure your password:
        </div>
        <ul className='requirements'>
          <li>
            Is <span className='bold'>longer</span> than 7 characters
          </li>
        </ul>
      </div>
      <Form name='SetNewPasswordForm' onFinish={handleSubmit}>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            },
            {
              message: 'Password must be at least 8 characters long',
              min: 8
            },
            {
              validator: validateToNextPassword
            }
          ]}
        >
          <InputText
            label='New Password'
            type='password'
            password
            placeholder='Enter Your Password'
          />
        </Form.Item>
        <Form.Item
          name='confirm'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('The two passwords you entered do not match!')
                )
              }
            })
          ]}
        >
          <InputText
            label='Re-enter Your Password'
            type='password'
            password
            placeholder='Re-enter Your Password'
          />
        </Form.Item>
        <Button block loading={loading} type='primary' htmlType='submit'>
          Change password
        </Button>
      </Form>
    </div>
  )
}
