import React, { useState } from 'react'
import { Button, Modal, Input, message } from 'antd'
import { sendAccessRequest } from '../../services/simpleApiRequest'

export default function AccessRequestModal ({
  isRequestModalOpen,
  isRequestModalOpenChange,
  sharedInsightData,
  analytics,
  guest,
  linkReferrer
}) {
  const [firstName, firstNameChange] = useState('')
  const [lastName, lastNameChange] = useState('')
  const [email, emailChange] = useState(guest ? guest.email : '')
  const [comment, commentChange] = useState(
    'Hey there! I would like to access the Watchful platform'
  )

  const handleSendRequest = () => {
    if (firstName === '') {
      message.info({ content: 'First Name is required field', icon: <div /> })
      return
    }

    if (lastName === '') {
      message.info({ content: 'Last Name is required field', icon: <div /> })
      return
    }

    if (email === '') {
      message.info({ content: 'Email is required field', icon: <div /> })
      return
    }

    if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      message.info({ content: 'Email is incorrect', icon: <div /> })
      return
    }
    analytics.analytic('Viewer requested access', {
      first_name: firstName,
      last_name: lastName,
      email,
      comment,
      location: sharedInsightData.location,
      url: sharedInsightData.url,
      insight_name: sharedInsightData.insight_name
        ? sharedInsightData.insight_name
        : '',
      insight_type: sharedInsightData.insight_type
        ? sharedInsightData.insight_type
        : '',
      app_name: sharedInsightData.app_name ? sharedInsightData.app_name : '',
      platform: sharedInsightData.platform ? sharedInsightData.platform : ''
    })
    sendAccessRequest(
      firstName,
      lastName,
      email,
      comment,
      linkReferrer.organization_id
    )
      .then(res => {
        message.success('Access request was sent')
        console.log('ok', res)
        isRequestModalOpenChange(false)
      })
      .catch(err => {
        console.log('errr', err)
        message.error(err.response?.data?.msg)
      })
  }

  return (
    <Modal
      title={
        <div className='title'>
          Full access!
          <div className='sub-title'>
            Request now full access to Watchful platform
          </div>
        </div>
      }
      open={isRequestModalOpen}
      className='access-request-modal'
      width={580}
      footer={[]}
      onCancel={() => isRequestModalOpenChange(false)}
    >
      <div className='input-wrap'>
        <span className={'star first-name'}>*</span>
        <span className={'star last-name'}>*</span>
        <Input
          placeholder='First Name'
          value={firstName}
          onChange={e => firstNameChange(e.target.value)}
        />
        <Input
          placeholder='Last Name'
          value={lastName}
          onChange={e => lastNameChange(e.target.value)}
        />
      </div>
      <div className='input-wrap'>
        <Input
          placeholder='Email'
          value={email}
          onChange={e => emailChange(e.target.value)}
        />
        <span className={'star email'}>*</span>
      </div>
      <div className='input-wrap'>
        <Input.TextArea
          placeholder='comment'
          value={comment}
          onChange={e => commentChange(e.target.value)}
        />
      </div>

      <div>
        <Button className='rqst-btn-send' onClick={handleSendRequest}>
          Request Access
        </Button>
      </div>
      <div className={'required-wrapper'}>
        <span className={'star'}>*</span>
        <span className={'text'}>Required fields</span>
      </div>
    </Modal>
  )
}
