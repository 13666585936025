import './ShareOfVoice.scss'

import ChartDataLabels from 'chartjs-plugin-datalabels'
import clsx from 'clsx'
import ChartLegend from 'components/charts/ChartLegend'
import useGoToPromotionsTable from 'containers/MarketingOverview/hooks/useGoToPromotionsTable'
import { trackMarketingOverviewEvent } from 'containers/MarketingOverview/utils/analytics'
import {
  calculateExactPercentages,
  isMobileDevice
} from 'containers/MarketingOverview/utils/helpers'
import { App } from 'containers/MarketingOverview/utils/types'
import { onHoverSetCursor } from 'helpers/chartHelpers'
import { luminanceFromHex } from 'helpers/colorHelpers'
import React, { useEffect, useRef, useState } from 'react'
import { Pie } from 'react-chartjs-2'

import { Entry } from '../common/MultiSelect/MultiSelect'
import { MarketingOverviewFiltersParams } from "containers/MarketingOverview/hooks/useOverviewFilters"

type AppInsightsByFocusArea = {
  app: App
  appId: number
  byFocusArea: {
    id: number
    name: string
    count: number
    color: string
    textColor: string
    ids: number[]
  }[]
}

type FocusArea = {
  id: number
  name: string
  color: string

  ids: number[]
}

type Props = {
  focusAreas: FocusArea[]
  appInsightsByFocusAreas: AppInsightsByFocusArea[]
}

type ShareOfVoiceDoughnutProps = {
  appInsightsByFocusArea: AppInsightsByFocusArea
  isMobile: boolean
}

const ShareOfVoiceDoughnut: React.FC<ShareOfVoiceDoughnutProps> = ({
  appInsightsByFocusArea,
  isMobile
}) => {
  const goToTable = useGoToPromotionsTable()

  const chartRef = useRef<Pie>(null)

  const onClick = (event: any, element: any) => {
    const chart = chartRef.current?.chartInstance

    if (!chart) {
      return
    }

    const params = new URLSearchParams(location.search)
    const channels = params.getAll(MarketingOverviewFiltersParams.channel_id)
    const focusAreaId = appInsightsByFocusArea.byFocusArea[element[0]?._index]?.id
    goToTable([appInsightsByFocusArea.appId], channels, focusAreaId ? [focusAreaId] : undefined)
  }

  return (
    <div className='sov-app-container'>
      <div className='sov-app-title'>
        <img src={appInsightsByFocusArea.app?.icon} />
        <span>{appInsightsByFocusArea.app?.displayName}</span>
      </div>
      <div
        className={clsx('sov-chart-container', {
          'customer-app': appInsightsByFocusArea.app?.isCustomerOwnApp
        })}
        key={appInsightsByFocusArea.app?.id}
      >
        <div className='left-part'>
          <ChartLegend
            chart={chartRef.current?.chartInstance}
            vertical={true}
            counts={appInsightsByFocusArea.byFocusArea.map(
              ({ count }) => count
            )}
          />
        </div>
        <div className='right-part'>
          {/* @ts-ignore */}
          <Pie
            ref={chartRef}
            width={isMobile ? 250 : 355}
            height={isMobile ? 250 : 300}
            plugins={[ChartDataLabels]}
            options={{
              tooltips: {
                enabled: true
              },
              legend: {
                display: false
              },
              plugins: {
                datalabels: {
                  align: 'end',
                  offset: 30,
                  color: (context: any) => {
                    const color =
                      context.dataset?.backgroundColor[context.dataIndex]

                    const luminance = luminanceFromHex(color)

                    return luminance < 0.5 ? '#ffffff' : '#000000'
                  },
                  font: {
                    size: 14
                  },
                  formatter: (value: number, ctx: any) => {
                    const meta = ctx.chart.getDatasetMeta(0)

                    const counts = ctx.chart.data.datasets[0].data.filter(
                      (_: any, i: number) => !meta.data[i].hidden
                    )

                    const exactPercentages = calculateExactPercentages(counts)
                    const percentage =
                      exactPercentages[
                        // If if we find a different index of data but the count is the same
                        // it is perfectly fine as the percent will be the same anyways
                        counts.findIndex((count: number) => count === value)
                      ]

                    return `${percentage}%`
                  }
                }
              },
              onHover: onHoverSetCursor,
              onClick
            }}
            data={{
              labels: appInsightsByFocusArea.byFocusArea.map(
                byFocusArea => byFocusArea.name
              ),
              datasets: [
                {
                  data: appInsightsByFocusArea.byFocusArea.map(byFocusArea => byFocusArea.count),
                  backgroundColor: appInsightsByFocusArea.byFocusArea.map(
                    byFocusArea => byFocusArea.color
                  )
                }
              ]
            }}
          />
        </div>
      </div>
    </div>
  )
}

export const ShareOfVoice = ({
  focusAreas,
  appInsightsByFocusAreas
}: Props) => {
  const [currentFocusAreas, setCurrentFocusAreas] = useState<
    AppInsightsByFocusArea[]
  >([])
  const isMobile = isMobileDevice()

  useEffect(() => {
    const initialFocusAreas = focusAreas
      .map(focusArea => ({
        ...focusArea,
        isSelected: true
      }))

    handleMultiSelectChange(initialFocusAreas)
  }, [appInsightsByFocusAreas])

  const handleMultiSelectChange = (entries: Entry[]) => {
    const selectedFocusAreas = entries
      .filter(entry => entry.isSelected)
      .reduce<number[]>((acc, curr) => [...acc, ...(curr.ids || [])], [])

    // analytics for selection
    entries.forEach(entry => {
      const isEntryAlreadySelected = currentFocusAreas.some(currentFocusArea =>
        currentFocusArea.byFocusArea.some(({ id }) => id === entry.id)
      )

      if (entry.isSelected === isEntryAlreadySelected) {
        return
      }

      const appsWithFocusArea = appInsightsByFocusAreas.filter(
        appInsightsByFocusArea =>
          appInsightsByFocusArea.byFocusArea.find(({ id }) => id === entry.id)
      )

      trackMarketingOverviewEvent('chart_category_clicked', {
        app_names: appsWithFocusArea.map(({ app }) => app?.displayName),
        chart_name: 'Promo Type',
        category_name: entry.name,
        is_selected: !!entry.isSelected
      })
    })

    const newAppInsightsByFocusAreas = appInsightsByFocusAreas.map(
      appInsightsByFocusArea => ({
        ...appInsightsByFocusArea,
        byFocusArea: appInsightsByFocusArea.byFocusArea.filter(byFocusArea =>
          selectedFocusAreas.includes(byFocusArea.id)
        )
      })
    )

    const sortedAppInsightsByFocusAreas = newAppInsightsByFocusAreas.sort(
      (a, b) => {
        const aTotal = a.byFocusArea.reduce((acc, curr) => acc + curr.count, 0)
        const bTotal = b.byFocusArea.reduce((acc, curr) => acc + curr.count, 0)

        return bTotal - aTotal
      }
    )

    setCurrentFocusAreas(sortedAppInsightsByFocusAreas)
  }

  // plugin to center text in doughnut chart

  const isAllEmpty = currentFocusAreas.every(
    appInsightsByFocusArea => appInsightsByFocusArea.byFocusArea.length === 0
  )

  return (
    <div className='sov-container'>
      <div className='sov-charts-container'>
        {isAllEmpty && (
          <div className='sov-chart-container'>
            <div style={{ height: isMobile ? 200 : 350 }} />
          </div>
        )}
        {currentFocusAreas.map(
          (appInsightsByFocusArea, i) =>
            appInsightsByFocusArea.byFocusArea.length > 0 && (
              <ShareOfVoiceDoughnut
                key={appInsightsByFocusArea.app?.id || i}
                appInsightsByFocusArea={appInsightsByFocusArea}
                isMobile={isMobile}
              />
            )
        )}
      </div>
    </div>
  )
}
