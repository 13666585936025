import './account-menu.css'

import { List } from 'antd'
import { IUserData } from 'interfaces/User'
import React, { useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router'
import storageService from 'services/storageService'

import headerAccount from '../../../assets/images/new_design/header/header_acc.svg'
import headerLogout from '../../../assets/images/new_design/header/header_logout.svg'
import headerSubscription from '../../../assets/images/new_design/header/header_subs.svg'

export interface AccountMenuProps {
  user: any // TODO need to type this from the state down
  userData: IUserData
}

export const AccountMenu: React.FC<AccountMenuProps> = ({ user, userData }) => {
  const navigate = useNavigate()
  const storage = useRef(new storageService())

  const menuItems = useMemo(() => {
    const result = []

    if (user.sub && user.email) {
      result.push({
        path: '/account',
        label: 'My Account',
        icon: <img src={headerAccount} />
      })
      result.push({
        path: '/subscriptions',
        label: 'My Subscriptions',
        icon: <img src={headerSubscription} />
      })
      result.push({
        path: '/login',
        label: 'Log Out',
        icon: <img src={headerLogout} />
      })
    } else {
      result.push({ path: '/login', label: 'Log In' })
    }
    return result
  }, [user, userData])

  const onNavigate = useCallback(
    (path: string) => {
      if (path == '/login') {
        storage.current?.clear()
      }

      navigate(path)
    },
    [navigate]
  )

  return (
    <List
      className='account-menu'
      itemLayout='horizontal'
      dataSource={menuItems}
      renderItem={item => {
        return (
          <List.Item onClick={() => onNavigate(item.path)}>
            <List.Item.Meta avatar={item.icon} title={item.label} />
          </List.Item>
        )
      }}
    />
  )
}

export default AccountMenu
