// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eula-wrapper {
  margin: 3rem 0rem;
  font-family: "Roboto";
  font-size: 1.6rem;
  line-height: 1.875rem;
  z-index: 1000000;
}
.eula-wrapper .lean {
  font-weight: 300;
  font-family: "Roboto Slab";
}
.eula-wrapper .fat {
  font-weight: 500;
}
.eula-wrapper h1 {
  margin-bottom: 4rem;
}
.eula-wrapper .header {
  margin: 2rem 0rem;
  font-weight: 500;
}
.eula-wrapper .header .large-font {
  font-size: 2rem;
}
.eula-wrapper .place-holder {
  height: 0.4rem;
  width: 100%;
}
.eula-wrapper p {
  font-weight: 300;
  margin: 1.5rem 0rem;
  font-size: 1.6rem;
  text-align: justify;
  text-justify: inter-word;
}
.eula-wrapper p.move-right {
  margin-left: 5rem;
  display: flex;
}
.eula-wrapper p.move-right .letter-margin {
  margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/legal/legal.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,qBAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;AACF;AAAE;EACE,gBAAA;EACA,0BAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ;AAAE;EACE,iBAAA;EACA,gBAAA;AAEJ;AADI;EACE,eAAA;AAGN;AAAE;EACE,cAAA;EACA,WAAA;AAEJ;AAAE;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,wBAAA;AAEJ;AADI;EACE,iBAAA;EACA,aAAA;AAGN;AAFM;EACE,kBAAA;AAIR","sourcesContent":[".eula-wrapper {\n  margin: 3rem 0rem;\n  font-family: \"Roboto\";\n  font-size: 1.6rem;\n  line-height: 1.875rem;\n  z-index: 1000000;\n  .lean {\n    font-weight: 300;\n    font-family: \"Roboto Slab\";\n  }\n  .fat {\n    font-weight: 500;\n  }\n  h1 {\n    margin-bottom: 4rem;\n  }\n  .header {\n    margin: 2rem 0rem;\n    font-weight: 500;\n    .large-font {\n      font-size: 2rem;\n    }\n  }\n  .place-holder {\n    height: 0.4rem;\n    width: 100%;\n  }\n  p {\n    font-weight: 300;\n    margin: 1.5rem 0rem;\n    font-size: 1.6rem;\n    text-align: justify;\n    text-justify: inter-word;\n    &.move-right {\n      margin-left: 5rem;\n      display: flex;\n      .letter-margin {\n        margin-right: 1rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
