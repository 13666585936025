import './styles.scss'

import { Col, Empty, Row } from 'antd'
import moment from 'moment'
import { useContextTimeline } from 'pages/ContextTimeline/context'
import React from 'react'

import { MarketingInsightPreviewModal } from '../../marketing-insight-preview-modal'

export const InsightsList = () => {
  const {
    insightsByCurDateList,
    selectedApp,
    selectedInsight,
    handleChangeFilter,
    personasList
  } = useContextTimeline()

  if (!insightsByCurDateList) return null

  const insightsListEmailPush = insightsByCurDateList.filter(
    ({ type }: any) => type.includes('email') || type.includes('push')
  )

  return (
    <Row style={{ width: '100%' }} gutter={[24, 24]}>
      {insightsListEmailPush.map((insight, idx) => (
        <Col key={insight.id} span={24}>
          <div
            className='ctl-insight'
            onClick={() =>
              handleChangeFilter?.('insight_id', insight.id.toString())
            }
            style={
              idx === insightsListEmailPush.length - 1
                ? { paddingBottom: '40px' }
                : {}
            }
          >
            <div className='container'>
              {idx === 0 && (
                <div className='insight-date'>
                  {moment(insight.startTime).format('MMM DD/YY')}
                </div>
              )}
              <div
                className='insight-type'
                style={{
                  backgroundColor: insight.type.includes('email')
                    ? '#DDE4F9'
                    : '#FEC0FF',
                  color: insight.type.includes('email') ? '#4367DD' : '#B20362'
                }}
              >
                {insight.type.includes('email')
                  ? 'Email Campaign'
                  : 'Push Notification'}
              </div>
              <div className='insight-title'>{insight.title}</div>
              <div className='insight-thumbnail'>
                <img src={insight.thumbnailOriginal} />
              </div>
            </div>
          </div>
        </Col>
      ))}
      {insightsByCurDateList.length === 0 && (
        <Col span={24}>
          <div className='ctl-insight'>
            <Empty />
          </div>
        </Col>
      )}
      {selectedApp && selectedInsight && (
        <MarketingInsightPreviewModal
          appId={selectedApp?.id.toString()}
          appName={selectedApp?.displayName}
          open={selectedInsight ? true : false}
          insightId={selectedInsight?.id}
          type={selectedInsight?.type}
          title={selectedInsight?.title}
          date={selectedInsight?.start_time}
          priority={selectedInsight?.priority || ''}
          labels={selectedInsight?.labels || []}
          onClose={() => handleChangeFilter?.('insight_id', '')}
          focusArea={selectedInsight?.focusArea || ''}
          messageContent=''
          userTypes={
            personasList?.map(p => ({
              id: p.id,
              roles: [],
              title: p.title,
              bgColor: p.bgColor,
              textColor: p.textColor,
              description: p.description
            })) || []
          }
          userTypesSeen={selectedInsight?.personas || []}
          insightDescription={selectedInsight?.description}
          assets={
            selectedInsight?.assets?.toSorted(
              (a: any, b: any) => a.order_index - b.order_index
            ) || []
          }
          thumbnail={selectedInsight?.thumbnail}
        />
      )}
    </Row>
  )
}
