// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uj-filter-personas .uj-personas {
  background-color: rgba(0, 0, 0, 0.0235294118) !important;
}`, "",{"version":3,"sources":["webpack://./src/containers/UserJourneys/components/common/PersonaSelect/PersonaSelect.scss"],"names":[],"mappings":"AACE;EACE,wDAAA;AAAJ","sourcesContent":[".uj-filter-personas {\n  .uj-personas {\n    background-color: #00000006 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
