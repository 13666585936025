import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Pagination } from 'antd'
import DiscoveryCard from '../../components/discoveryCard/discoveryCard'

import SneakPeakInsightModal from '../../components/sneakPeakDiscoveryModal/SneakPeakInsightModal'

export function GalleryDiscoveries ({
  loadingFilteredDiscoveries,
  filteredDiscoveries,
  apps,
  currentPage,
  onPageChange,
  currentPageSize,
  onShowSizeChange,
  totalDiscoveries,
  user,
  productName,
  handleClick,
  usePagination = false,
  showType = true,
  closeModalHandler,
  userDiscoveries,
  hideAppName,
  source
}) {
  const [currentItem, currentItemChange] = useState(null)
  const [visibilitySneakPeak, visibilitySneakPeakChange] = useState(false)
  const [updatedDiscoveries, updatedDiscoveriesChange] =
    useState(filteredDiscoveries)

  const changeItem = (discovery, i) => {
    let app = apps.find(app => app.id === discovery.app_id)
    visibilitySneakPeakChange(true)
    currentItemChange({
      item: {
        ...discovery,
        ...app,
        metadata: app,
        app_id: app.id,
        id: discovery.id,
        insightId: discovery.id
      },
      insightOrReport: 'insight',
      key: i
    })
  }

  useEffect(() => {
    updatedDiscoveriesChange(
      filteredDiscoveries.map(dis => {
        if (!userDiscoveries) return dis
        let uD = userDiscoveries.find(d => d.insight_id === dis.id)
        if (uD) {
          return {
            ...dis,
            ...uD
          }
        } else {
          return dis
        }
      })
    )
  }, [filteredDiscoveries, userDiscoveries])

  useEffect(() => {
    if (!currentItem) {
      return
    }
    let discovery = updatedDiscoveries.find(fd => fd.id === currentItem.item.id)
    if (
      visibilitySneakPeak &&
      discovery.rating_user !== currentItem.item.rating_user
    ) {
      let app = apps.find(app => app.id === discovery.app_id)
      currentItemChange({
        item: {
          ...discovery,
          ...app,
          metadata: app,
          app_id: app.id,
          id: discovery.id,
          insightId: discovery.id
        },
        insightOrReport: 'insight',
        key: currentItem.key
      })
    }
  }, [updatedDiscoveries])

  return (
    <div className='gallery-discovery-cards'>
      {!loadingFilteredDiscoveries && updatedDiscoveries && (
        <div className='discoveries-list'>
          {updatedDiscoveries.map((item, index) => {
            let app = apps.find(app => app.id === item.app_id)
            if (!app) {
              return null
            }
            return (
              <DiscoveryCard
                data={item}
                key={index}
                app={app}
                onClick={() => changeItem(item, index)}
                showType={showType}
                hideAppName={hideAppName}
                user={user}
              />
            )
          })}
        </div>
      )}
      {!loadingFilteredDiscoveries && updatedDiscoveries && productName && (
        <div className='center-of-row'>
          <Button className='btn-link black' onClick={handleClick}>
            See All Discoveries on {productName}
          </Button>
        </div>
      )}
      {!loadingFilteredDiscoveries && updatedDiscoveries && usePagination && (
        <div className='pagination'>
          <div className='label'>Total {totalDiscoveries} discoveries </div>
          <Pagination
            showSizeChanger
            current={currentPage}
            onShowSizeChange={onShowSizeChange}
            onChange={onPageChange}
            total={totalDiscoveries}
            pageSize={currentPageSize}
            pageSizeOptions={['40', '80', '200', '500']}
          />
        </div>
      )}

      {currentItem && currentItem.item && (
        <SneakPeakInsightModal
          closeModalAndRemoveViewedInsights={() => {
            visibilitySneakPeakChange(false)
            closeModalHandler && closeModalHandler()
          }}
          currentItem={currentItem}
          changeItem={changeItem}
          insights={updatedDiscoveries}
          visibility={visibilitySneakPeak}
          user={user}
          isMarketingDiscovery={true}
          source={source}
        />
      )}
    </div>
  )
}

const mapStateToProps = ({ userDiscoveries }) => ({
  userDiscoveries: userDiscoveries.discoveries
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryDiscoveries)
