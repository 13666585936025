import React, { useState, useEffect, useCallback } from 'react'
import { Popover } from 'antd'
import Info from '../../../../assets/images/icons/info.svg'
import DownBlack from '../../../../assets/images/icons/down-black.svg'

import './performanceDrawer.scss'

const PerformanceDrawer = ({
  drawerName = '',
  selectionCallback = () => {},
  selectedItem,
  optionList = [],
  isActive,
  shortLabel,
  allVariant,
  isMatched
}) => {
  const drawerRef = React.createRef()
  const [isOpen, setIsOpen] = useState(false)

  const handleOutsideClick = useCallback(
    event => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        if (isOpen) {
          setIsOpen(false)
        }
      }
    },
    [drawerRef, isOpen]
  )

  useEffect(() => {
    // Handle outside click
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleOutsideClick])

  const toggleIsOpen = () => {
    if (optionList.length > 1) {
      setIsOpen(!isOpen)
    }
  }

  const openedHeight =
    (optionList.length - (selectedItem ? 1 : 0) + (allVariant ? 1 : 0)) * 40 +
    16
  return (
    <div
      className={`performance-drawer ${isActive ? '' : 'disabled'} ${
        isOpen ? 'opened' : ''
      }`}
      ref={drawerRef}
    >
      <div
        className={`performance-drawer__top ${isOpen ? 'opened' : ''}`}
        style={{ cursor: `${optionList.length > 1 ? 'pointer' : 'default'}` }}
        onClick={() => isActive && optionList.length > 0 && toggleIsOpen()}
      >
        <div className='performance-drawer__top__name'>
          {drawerName}
          {isMatched && selectedItem !== null && (
            <strong className='matched'>(Matched)</strong>
          )}
        </div>
        <div
          className={`performance-drawer__top__selected-item  ${
            isActive ? '' : 'disabled'
          } `}
        >
          {selectedItem && selectedItem.label
            ? selectedItem.label
            : shortLabel || optionList.length === 0
            ? allVariant
              ? 'All'
              : '-'
            : allVariant
            ? 'All'
            : `Select ${drawerName}`}
          {selectedItem && selectedItem.info && (
            <Popover
              content={selectedItem.info}
              overlayStyle={{ maxWidth: 500 }}
              overlayClassName='antd-popover-black'
            >
              <img alt='device-info' src={Info} style={{ cursor: 'pointer' }} />
            </Popover>
          )}
        </div>
        <img
          className={`performance-drawer__top__chevron ${
            isOpen ? 'opened' : ''
          }`}
          style={{
            visibility: `${
              isActive && optionList.length - 1 > 0 ? 'visible' : 'hidden'
            }`
          }}
          alt='black-chevron'
          src={DownBlack}
          onClick={() => toggleIsOpen()}
        />
      </div>
      {isActive && (
        <div
          className={`performance-drawer__list ${isOpen ? 'opened' : ''} ${
            openedHeight < 350 ? 'hide-scroll' : ''
          }`}
          style={{ height: `${isOpen ? openedHeight : 0}px` }}
        >
          {optionList
            .filter(opt => !selectedItem || selectedItem.value !== opt.value)
            .map((option, i) => (
              <div
                key={i}
                className={`performance-drawer__list-item ${
                  option.disabled ? 'disabled' : ''
                }`}
                onClick={() => {
                  if (!option.disabled) {
                    selectionCallback(option)
                    setIsOpen(false)
                  }
                }}
              >
                {option && option.label}
                {option.info && (
                  <Popover
                    placement='right'
                    content={option.info}
                    overlayClassName='antd-popover-black'
                    overlayStyle={{ zIndex: 8000, maxWidth: 500 }}
                  >
                    <img alt='device-info' src={Info} />
                  </Popover>
                )}
              </div>
            ))}
          {allVariant && (
            <div
              key={'all-v'}
              className={`performance-drawer__list-item`}
              onClick={() => {
                selectionCallback('')
                setIsOpen(false)
              }}
            >
              All
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default PerformanceDrawer
