// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctl-content {
  max-width: calc(100% - 366px) !important;
  padding: 0 40px;
}
.ctl-content.closed {
  max-width: calc(100% - 22px) !important;
}
.ctl-content .main-content {
  margin-top: 4px;
  border-radius: 4px;
  border-right: 1px solid #f9f9fb;
  background: #f4f4f4;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: calc(100vh - 530px);
}
.ctl-content .main-content.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/ContextTimeline/components/main/styles.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,eAAA;AACF;AACE;EACE,uCAAA;AACJ;AAEE;EACE,eAAA;EACA,kBAAA;EACA,+BAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EAEA,gBAAA;EACA,2BAAA;AADJ;AAGI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AADN","sourcesContent":[".ctl-content {\n  max-width: calc(100% - 366px) !important;\n  padding: 0 40px;\n\n  &.closed {\n    max-width: calc(100% - 22px) !important;\n  }\n\n  .main-content {\n    margin-top: 4px;\n    border-radius: 4px;\n    border-right: 1px solid #f9f9fb;\n    background: #f4f4f4;\n    padding: 40px 0;\n    display: flex;\n    justify-content: center;\n\n    overflow-y: auto;\n    height: calc(100vh - 530px);\n\n    &.empty {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
