import { Button, Calendar, Popover, Tooltip } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Icon from '@ant-design/icons'

import logo from '../../../assets/images/icons/watchful_white_gray_background.png'
import { ReactComponent as palm_holidays_ico } from '../../../assets/images/new_design/icons/palm-tree.svg'
import persona_ico from '../../../assets/images/new_design/icons/persona-ico.svg'
import watchful_icon_gray from '../../../assets/images/watchful_icon_gray.svg'
import ChartContainer from '../../../components/chartContainer/ChartContainer'
import ViewExperimentArrowSlick from '../../../components/discoveryView/viewExperiment/viewExperimentArrowSlick/ViewExperimentArrowSlick'
import CSelectBox from '../../../components/UICarbonStyleControls/SelectBox'
import checkDate from '../../../helpers/dates'
import { IDisWithApp, Product } from '../../../interfaces/Product'
import { INSIGHT_CAMPAIGN_SUBTYPE } from '../../intelligence/Marketing/configsSubtypes'
import FilterTypes, {
  MATCH_TYPES
} from '../../intelligence/Marketing/FilterTypes'

const now = new Date().getUTCFullYear()
const years = Array(now - (now - 5))
  .fill('')
  .map((v, idx) => now - idx)
const fullMonths = moment.months()

type FCDProps = {
  discoveries: Array<IDisWithApp>
  currentDate: any
  currentDateChange: (args1: dayjs.Dayjs) => void
  products: Array<Product>
  discoveriesData: any[]
}

const FullCalendarDiscoveries: FC<FCDProps> = ({
  discoveries,
  currentDate,
  currentDateChange,
  products,
  discoveriesData
}): ReactElement | null => {
  const [filteredType, filteredTypeChange] = useState<string[]>([])
  const [filteredTags, filteredTagsChange] = useState<string[]>([])

  const [filteredDiscoveries, filteredDiscoveriesChange] = useState<
    IDisWithApp[]
  >([])
  const [matchType, setMatchType] = React.useState<string>(MATCH_TYPES[0])
  useEffect(() => {
    filteredDiscoveriesChange(discoveries.filter(checkFilter))
  }, [filteredType, filteredTags, discoveries, matchType])

  const getHoliday = (name: string) => {
    return (
      <Tooltip placement='top' title={name}>
        <div className='holiday-symbol'>
          <Icon component={palm_holidays_ico} />
        </div>
      </Tooltip>
    )
  }

  const dateCellRender = (value: dayjs.Dayjs) => {
    const dsGrouped = filteredDiscoveries
      .filter(d => value.isSame(dayjs(d.cam.start_time), 'day'))
      .reduce<{
        [key: string]: {
          data: IDisWithApp[]
          count: number
        }
      }>((acc, d: IDisWithApp) => {
        const appID = String(d.app.id)
        if (acc[appID]) {
          acc[appID].data.push(d)
          acc[appID].count++
        } else {
          acc[appID] = {
            data: [d],
            count: 1
          }
        }
        return acc
      }, {})

    const isHoliday = checkDate(value.format('YYYY-MM-DD'))

    if (Object.keys(dsGrouped).length > 0) {
      return (
        <>
          {isHoliday && getHoliday(isHoliday.name)}
          <div
            className={`cam-apps-containers ${
              Object.keys(dsGrouped).length > 2 ? 'apps-2' : ''
            }`}
            onClick={e => e.stopPropagation()}
          >
            {Object.keys(dsGrouped).map((appID: string) => {
              //@ts-ignore
              const dis = dsGrouped[appID].data[0]
              // @ts-ignore
              const productID = products.find(p =>
                p.apps.map(a => a.id).includes(dis.app.id)
              ).id
              const dateFormat = value.format('YYYY-MM-DD')
              const linkToAllDiscoveries = (
                <Link
                  to={`/all-discoveries?product_ids=${productID}&customRangeTime=${dateFormat}%2C${dateFormat}&customRange=1`}
                >
                  <img src={dis.app.icon} />
                </Link>
              )
              //@ts-ignore
              if (dsGrouped[appID].count > 3) {
                //@ts-ignore

                return (
                  <div className='cam-app-icon' key={dis.cam.id}>
                    <div className='count-discoveries'>
                      +
                      {
                        //@ts-ignore
                        dsGrouped[appID].count - 1
                      }
                    </div>
                    {linkToAllDiscoveries}
                  </div>
                )
              } else {
                //@ts-ignore
                const numOfDiscoveries = dsGrouped[appID].data.length

                return (
                  <div className='cam-app-icon' key={dis.cam.id}>
                    <Popover
                      overlayClassName='cam-apps-popover'
                      placement='top'
                      style={{
                        width:
                          (
                            numOfDiscoveries * 11 +
                            (numOfDiscoveries - 1) * 10
                          ).toString() + 'rem'
                      }}
                      content={
                        <div className='cam-apps-popover-content'>
                          <div className='app-data'>
                            <img src={dis.app.icon} />
                            {dis.app.display_name}
                          </div>

                          <div className='todays-discoveries'>
                            {
                              //@ts-ignore
                              dsGrouped[appID].data.map((d, ind) => (
                                <div
                                  key={d.cam.id}
                                  className='todays-discoveries-one'
                                  style={
                                    ind < numOfDiscoveries - 1
                                      ? { borderRight: '1.2px #ECEEF5 solid' }
                                      : {}
                                  }
                                >
                                  <Link to={`/marketing/${d.cam.id}`}>
                                    <h3>{d.cam.title}</h3>
                                    {d.cam.persona_roles &&
                                      d.cam.persona_roles.length > 0 && (
                                        <div className='cam-roles'>
                                          <Tooltip title='Persona'>
                                            <img src={persona_ico} />
                                          </Tooltip>
                                          {d.cam.persona_roles.join(' + ')}
                                        </div>
                                      )}
                                    <div className='cam-longtext'>
                                      {d.cam.description}
                                    </div>
                                    <div className='cam-thumbnail'>
                                      <img src={d.cam.thumbnail || logo} />
                                    </div>
                                  </Link>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      }
                    >
                      {numOfDiscoveries > 1 ? (
                        linkToAllDiscoveries
                      ) : (
                        <Link to={`/marketing/${dis.cam.id}`}>
                          <img src={dis.app.icon} />
                        </Link>
                      )}
                    </Popover>
                  </div>
                )
              }
            })}
          </div>
        </>
      )
    } else {
      return (
        <>
          {isHoliday && getHoliday(isHoliday.name)}
          <div className='empty-day'>
            <img src={watchful_icon_gray} />
          </div>
        </>
      )
    }
  }

  const headerRender = (obj: any) => {
    return (
      <div className='big-calendar-header'>
        <ViewExperimentArrowSlick
          page='new-marketing'
          direction='prev'
          className='new-marketing'
          onClick={() => {
            const nD = currentDate.subtract(1, 'month')
            obj.onChange(nD)
            currentDateChange(nD)
          }}
        />

        {moment(currentDate).day(0).add(1, 'month').isBefore(moment()) && (
          <ViewExperimentArrowSlick
            page='new-marketing'
            direction='next'
            className='new-marketing'
            onClick={() => {
              const nD = currentDate.add(1, 'month')
              obj.onChange(nD)
              currentDateChange(nD)
            }}
          />
        )}

        <div className='dates'>
          <CSelectBox
            black={true}
            style={{ marginRight: '1rem' }}
            value={currentDate.format('MMM')}
            onChange={(month: string) => {
              const nD = currentDate.month(month)
              obj.onChange(nD)
              currentDateChange(nD)
            }}
          >
            {moment
              .monthsShort()
              .filter(m => moment(currentDate).month(m).isBefore(moment()))
              .map((m, i) => (
                <CSelectBox.Option value={m} key={i}>
                  {fullMonths[i].toUpperCase()}
                </CSelectBox.Option>
              ))}
          </CSelectBox>

          <CSelectBox
            black={true}
            value={currentDate.format('YYYY')}
            onChange={(year: any) => {
              const nD = currentDate.year(year)
              if (year == moment().year()) {
                nD.month(moment().month())
              }
              obj.onChange(nD)
              currentDateChange(nD)
            }}
          >
            {years.map((y: number, i: number) => (
              <CSelectBox.Option value={y} key={i}>
                {y}
              </CSelectBox.Option>
            ))}
          </CSelectBox>
        </div>
        <div className='filter-wrapper'>
          <Popover
            placement='bottomRight'
            title={null}
            content={
              <FilterTypes
                filteredTypeChange={filteredTypeChange}
                filteredTagsChange={filteredTagsChange}
                tags={tags}
                filteredTags={filteredTags}
                filteredType={filteredType}
                //@ts-ignore
                currentCampaignsDataSet={currentCampaignsDataSet}
                setMatchType={setMatchType}
                matchType={matchType}
                discoveriesData={discoveriesData}
              />
            }
            trigger='click'
            overlayClassName={`filter-wrap marketing-filter-type-wrapper`}
          >
            <Button className='filter-btn-new black-btn-filter'>
              Filters
              <span className='filter-count'>
                {filteredType.length + filteredTags.length}
              </span>
            </Button>
          </Popover>
        </div>
      </div>
    )
  }

  const disabledDate = (date: dayjs.Dayjs) => !dayjs().isAfter(date)

  const checkFilter = (cam: any) => {
    let passTag = false,
      passType = false

    if (
      filteredTags.length == 0 ||
      (matchType === MATCH_TYPES[0]
        ? cam.cam.labels.filter((x: string) => filteredTags.includes(x))
            .length > 0
        : filteredTags.every(tag => cam.cam.labels.includes(tag)))
    ) {
      passTag = true
    }

    if (
      filteredType.length == 0 ||
      filteredType.includes(
        INSIGHT_CAMPAIGN_SUBTYPE.find(type => type.name == cam.cam.sub_type)
          ?.label || ''
      )
    ) {
      passType = true
    }

    return passTag && passType
  }

  if (!discoveries) {
    return null
  }

  // TEMP. There is bug on server side - each tag can be doubled a lot of times. We take uniq set
  const tags = discoveries.reduce((acc: string[], ins) => {
    if (ins.cam.labels) {
      //@ts-ignore
      return [...new Set([...acc, ...ins.cam.labels])]
    } else {
      return acc
    }
  }, [])

  //@ts-ignore
  const currentCampaignsDataSet = []

  INSIGHT_CAMPAIGN_SUBTYPE.forEach(sT => {
    //debugger
    //@ts-ignore
    const listCampBySubType = discoveries.filter(
      ins => ins.cam.content && ins.cam.sub_type == sT.name
    )

    const subTypeCheckBox = {
      name: sT.name,
      data: listCampBySubType,
      count: listCampBySubType.length,
      ico: sT.icon,
      label: sT.label
    }

    currentCampaignsDataSet.push(subTypeCheckBox)
  })

  return (
    <div className='nmp-wrapper-calendar'>
      <ChartContainer
        title='Calendar View'
        tooltipText='Marketing activity across monthly date range'
      >
        <Calendar
          dateCellRender={dateCellRender}
          headerRender={headerRender}
          onSelect={currentDateChange}
          defaultValue={currentDate}
          disabledDate={disabledDate}
        />
      </ChartContainer>
    </div>
  )
}

export default FullCalendarDiscoveries
