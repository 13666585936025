import './MarketingCampaignsPage.scss'

import React, { useEffect } from 'react'

import { MarketingCampaignsFilters } from './components/MarketingCampaignsFilters'
import { MarketingCampaignsTable } from './components/MarketingCampaignsTable'
import { useCampaigns } from './hooks/useCampaigns'
import { useCampaignsFilters } from './hooks/useCampaignsFilters'
import { trackMarketingEvent } from './utils/analytics'
import { App } from './utils/types'

export const MarketingCampaignsPage = () => {
  const {
    apps,
    channels,
    userTypes,
    userRoleTypes,
    insights,
    totalDbInsights,
    selectedInsight,
    isLoading,
    fetchNextPage,
    focusAreas
  } = useCampaigns()

  const { channelIds, handleChangeFilter, ...rest } = useCampaignsFilters({
    apps,
    userTypes,
    userRoleTypes
  })

  useEffect(() => {
    // layout fixes, js because changing system globals
    const mainContainer: any = document.querySelector('.dashboard__container')

    if (!mainContainer) return

    // remove right spacing from all the pages
    // it caused page to scroll horizontally on smaller screens
    // mainContainer.style.gridTemplateColumns = "27rem auto 120rem";

    // analytics when user enters
    trackMarketingEvent('screen_enter', null)
  }, [])

  return (
    <div className='mkc-container'>
      <MarketingCampaignsFilters
        apps={apps}
        handleChangeFilter={handleChangeFilter}
        userTypes={userTypes}
        userRoleTypes={userRoleTypes}
        focusAreas={focusAreas}
        channels={channels}
        selectedChannels={channelIds}
        {...rest}
      />
      <div className='mkc-table'>
        {rest.currentApps && (
          <MarketingCampaignsTable
            fetchNextPage={fetchNextPage}
            totalDBRowCount={totalDbInsights}
            isFetching={isLoading}
            totalFetched={insights.length}
            // TODO fix insights type
            tableData={insights as any}
            handleChangeFilter={handleChangeFilter}
            selectedInsight={selectedInsight}
            userTypes={userTypes}
            userRoleTypes={userRoleTypes}
            selectedAppIds={rest.selectedAppIds}
            currentApp={
              apps.find(({ id }) => id === selectedInsight?.app_id) as App
            }
          />
        )}
      </div>
    </div>
  )
}

export default MarketingCampaignsPage
