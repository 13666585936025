import React, { useRef, useEffect, useState, useCallback } from 'react'
import PerformanceEventHistoryGraph from '../BenchmarkCompare/PerformanceEventHistoryGraph'
import {
  durationFormatter,
  numberFormatter
} from '../../../helpers/numbersHelper'
import moment from 'moment'

import blackArrowSlider from '../../../assets/images/icons/blackArrowSlider.svg'
import no_metrics_data_ico from '../../../assets/images/new_design/icons/no_metrics_data_ico.svg'

import { ALL_TIME } from '../../../shared/consts'

export default function PerfEventsGraphsCont ({
  currentEvent,
  subEventChange,
  updateURL,
  daysAgo,
  customRange,
  customRangeChange,
  customRangeTime,
  customRangeTimeChange
}) {
  const durGraphRef = useRef(null)
  const memoryGraphRef = useRef(null)
  const dataRecGraphRef = useRef(null)
  const dataTsGraphRef = useRef(null)
  const [relativeXIndicator, relativeXIndicatorChange] = useState(null)

  const [syncedIndicatorPoints, syncedIndicatorPointsChange] = useState([])

  useEffect(() => {
    const barsContent = document.getElementById('metrics-graphics')
    if (barsContent && syncedIndicatorPoints.length > 0) {
      barsContent.addEventListener('mousemove', handleMouseMove)
    }
    return () => {
      if (barsContent) {
        barsContent.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [syncedIndicatorPoints])

  const handleMouseMove = useCallback(
    e => {
      if (e.preventDefault) e.preventDefault()
      if (e.stopPropagation) e.stopPropagation()
      const barsContent = document.getElementById('metrics-graphics')
      const rect = barsContent.getBoundingClientRect()

      let relativeX = e.clientX - rect.left
      if (relativeX <= 0) {
        relativeX = 0
      }

      if (relativeX > rect.width) {
        relativeX = rect.width
      }

      const nearestX = syncedIndicatorPoints.reduce(
        (nearestX, el) =>
          Math.abs(el.x - relativeX) < Math.abs(nearestX - relativeX)
            ? el.x
            : nearestX,
        Number.POSITIVE_INFINITY
      )

      relativeXIndicatorChange(nearestX)
    },
    [syncedIndicatorPoints]
  )

  const syncedGraphs = [
    {
      gr: durGraphRef,
      name: 'duration',
      formatter: durationFormatter
    },
    {
      gr: memoryGraphRef,
      name: 'memory',
      formatter: numberFormatter
    },
    {
      gr: dataRecGraphRef,
      name: 'data-rec',
      formatter: numberFormatter
    },
    {
      gr: dataTsGraphRef,
      name: 'data-ts',
      formatter: numberFormatter
    }
  ]

  const updateSyncedIndicatorPoints = locs => {
    syncedIndicatorPointsChange(locs)
  }

  const changeTimePeriod = dir => {
    if (dir === 'back') {
      if (!customRange) {
        customRangeChange(true)
        customRangeTimeChange([
          moment().subtract(daysAgo * 2, 'days'),
          moment().subtract(daysAgo, 'days')
        ])
      } else {
        let diffDays = customRangeTime[1].diff(customRangeTime[0], 'days')
        customRangeTimeChange([
          customRangeTime[0].subtract(diffDays, 'days'),
          customRangeTime[1].subtract(diffDays, 'days')
        ])
      }
    } else {
      if (!customRange) {
        customRangeChange(true)
        customRangeTimeChange([
          moment().add(daysAgo, 'days'),
          moment().add(daysAgo * 2, 'days')
        ])
      } else {
        let diffDays = Math.abs(
          customRangeTime[1].diff(customRangeTime[0], 'days')
        )
        let futureDay = moment(customRangeTime[1])
          .add(diffDays, 'days')
          .isAfter(moment())
          ? moment()
          : moment(customRangeTime[1]).add(diffDays, 'days')
        customRangeTimeChange([
          customRangeTime[0].add(diffDays, 'days'),
          futureDay
        ])
      }
    }
  }

  const dataIsExist =
    currentEvent.history.duration_s &&
    currentEvent.history.duration_s.length > 0

  const possGoArrow =
    customRange && Math.abs(customRangeTime[1].diff(moment(), 'days')) > 0
  const disArrows = (ALL_TIME === daysAgo && !customRange) || !dataIsExist

  return (
    <div className='metrics-graphics-cont'>
      <div className='metrics-graphics' id='metrics-graphics'>
        {!dataIsExist && (
          <div className='no-metrics-data'>
            <h3 className='no-metrics-data-title'>No Data Found</h3>
            <div className='no-metrics-data-descr'>
              Please try choosing different time period
            </div>
            <div className='no-metrics-data-img'>
              <img src={no_metrics_data_ico} />
            </div>
          </div>
        )}

        {!disArrows && (
          <div
            className={`slider-arrow arrow-left hover-effect`}
            onClick={() => changeTimePeriod('back')}
          >
            <img src={blackArrowSlider} alt='' />
          </div>
        )}

        {!disArrows && possGoArrow && (
          <div
            className={`slider-arrow arrow-right hover-effect`}
            onClick={() => changeTimePeriod('go')}
          >
            <img src={blackArrowSlider} alt='' />
          </div>
        )}

        <div className='new-ver-legend'>
          <span className='new-ver-legend-sign' />
          New Release
        </div>
        {relativeXIndicator ? (
          <div
            className='x-indicator'
            style={{ left: `${relativeXIndicator}px` }}
          />
        ) : null}
        <div className='history-graph-wrapper'>
          <PerformanceEventHistoryGraph
            isCompareMode={true}
            metricName='duration'
            mainEventMetadata={currentEvent.app}
            mainEventHistoryData={
              currentEvent.history && currentEvent.history.duration_s
                ? currentEvent.history.duration_s
                    .map(r => ({ ...r, value: r.value * 1000 }))
                    .filter(r => r.subeventName === 'end_time')
                : []
            }
            selectedSubEvent={'end_time'}
            handleChangeEventGraph={val => {
              subEventChange(val)
              updateURL()
            }}
            showDates={false}
            height={150}
            hideTitles={true}
            selectedDaysAgoValue={daysAgo}
            syncedGraphs={syncedGraphs}
            refLink={durGraphRef}
            formatter={durationFormatter}
            mainGradientColor='rgba(225, 39, 255, 0.5)'
            lineColor='#E127FF'
            updateSyncedIndicatorPoints={updateSyncedIndicatorPoints}
            leftTitle={'Event Duration'}
            backgroudColor={true}
            isMetrics={true}
          />
        </div>

        <div className='history-graph-wrapper'>
          <PerformanceEventHistoryGraph
            key={`${customRange}-${customRangeTime}-${daysAgo}-memory`}
            isCompareMode={true}
            metricName='memory'
            mainEventMetadata={currentEvent.app}
            mainEventHistoryData={
              currentEvent.history && currentEvent.history.duration_s
                ? currentEvent.history.memory_bytes.filter(
                    r => r.subeventName === 'end_time'
                  )
                : []
            }
            selectedSubEvent={'end_time'}
            handleChangeEventGraph={val => {
              subEventChange(val)
              updateURL()
            }}
            showDates={false}
            hideTitles={true}
            height={150}
            selectedDaysAgoValue={daysAgo}
            syncedGraphs={syncedGraphs}
            refLink={memoryGraphRef}
            formatter={numberFormatter}
            mainGradientColor='rgba(95, 39, 255, 0.5)'
            lineColor='#5F27FF'
            leftTitle={'Memory Usage'}
            backgroudColor={true}
            isMetrics={true}
          />
        </div>

        <div className='history-graph-wrapper'>
          <PerformanceEventHistoryGraph
            key={`${customRange}-${customRangeTime}-${daysAgo}-data-ts`}
            isCompareMode={true}
            metricName='data-ts'
            mainEventMetadata={currentEvent.app}
            mainEventHistoryData={
              currentEvent.history && currentEvent.history.duration_s
                ? currentEvent.history.network_rx_bytes.filter(
                    r => r.subeventName === 'end_time'
                  )
                : []
            }
            selectedSubEvent={'end_time'}
            handleChangeEventGraph={val => {
              subEventChange(val)
              updateURL()
            }}
            hideTitles={true}
            height={150}
            selectedDaysAgoValue={daysAgo}
            syncedGraphs={syncedGraphs}
            refLink={dataRecGraphRef}
            formatter={numberFormatter}
            mainGradientColor='rgba(39, 99, 255, 0.6)'
            lineColor='#2763FF'
            leftTitle={'Data Received'}
            backgroudColor={true}
            isMetrics={true}
          />
        </div>

        <div className='history-graph-wrapper' style={{ width: '100.8%' }}>
          <PerformanceEventHistoryGraph
            key={`${customRange}-${customRangeTime}-${daysAgo}-data-rec`}
            isCompareMode={true}
            metricName='data-rec'
            mainEventMetadata={currentEvent.app}
            mainEventHistoryData={
              currentEvent.history && currentEvent.history.duration_s
                ? currentEvent.history.network_tx_bytes.filter(
                    r => r.subeventName === 'end_time'
                  )
                : []
            }
            selectedSubEvent={'end_time'}
            handleChangeEventGraph={val => {
              subEventChange(val)
              updateURL()
            }}
            showDates={true}
            hideTitles={true}
            height={170}
            selectedDaysAgoValue={daysAgo}
            syncedGraphs={syncedGraphs}
            refLink={dataTsGraphRef}
            formatter={numberFormatter}
            mainGradientColor='rgba(39, 216, 255, 0.5)'
            lineColor='#27D8FF'
            leftTitle={'Data Transmitted'}
            backgroudColor={true}
            isMetrics={true}
          />
        </div>
      </div>
    </div>
  )
}
