// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctl-header {
  padding: 16px 24px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}
.ctl-header .ctl-header-title {
  color: #202020;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 190%; /* 45.6px */
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/pages/ContextTimeline/components/header/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gCAAA;EACA,6BAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,0BAAA;AACJ","sourcesContent":[".ctl-header {\n  padding: 16px 24px;\n  border-bottom: 1px solid #dee2e6;\n  border-top: 1px solid #dee2e6;\n\n  .ctl-header-title {\n    color: #202020;\n    font-family: Inter;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 190%; /* 45.6px */\n    text-transform: capitalize;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
