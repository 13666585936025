import { CAMPAIGNS_FILTERS } from 'containers/MarketingCampaings/utils/conts'
import { useLocation, useNavigate } from 'react-router'

const appendArrayToParams = (
  params: URLSearchParams,
  key: string,
  data: string[] | number[]
) => {
  params.delete(key)

  data.forEach(value => {
    params.append(key, value.toString())
  })
}

export const useGoToPromotionsTable = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    appIds?: number[],
    selectedChannels?: string[],
    focusAreas?: number[],
    userTypes?: number[]
  ) => {
    const params = new URLSearchParams(location.search)

    if (appIds?.length) {
      appendArrayToParams(params, CAMPAIGNS_FILTERS.app_ids, appIds)
    }

    if (selectedChannels) {
      appendArrayToParams(params, CAMPAIGNS_FILTERS.channels, selectedChannels)
    }

    if (focusAreas?.length) {
      appendArrayToParams(params, CAMPAIGNS_FILTERS.focus_areas, focusAreas)
    }

    if (userTypes?.length) {
      appendArrayToParams(params, CAMPAIGNS_FILTERS.user_types, userTypes)
    }

    navigate(`/marketing/promotions/table?${params.toString()}`)
  }
}

export default useGoToPromotionsTable
