import React, { useEffect, useMemo } from 'react'

import axios from 'axios'
import './common/mixpanel/Mixpanel'
import { FlagsProvider } from 'react-unleash-flags'
import MainLoader from './common/mainLoader/MainLoader'
import { RouterProvider } from 'react-router-dom'
import './sass-config/main.scss'
import './monitoring/datadog'
import { updateViewportMeta } from 'shared/mobile-fix'
import { hotjar } from 'react-hotjar'
import awsService from 'services/awsService'
import { getRouter } from 'routes'
import { useSelector } from 'react-redux'
import "/node_modules/flag-icons/css/flag-icons.min.css";

const unleashConfig = {
  appName: 'production',
  url: 'https://gitlab.com/api/v4/feature_flags/unleash/9904514',
  instanceId: '56n6ziWU7DMXsudNjw8U'
}

export const updateAxiosToken = userToken => {
  axios.interceptors.request.use(config => {
    if (
      /api(-staging|-dev|-integ-1-6715qvatlq|)\..*watchful/.exec(config.url) ||
      config.url.includes('localhost')
    ) {
      // Attach the token to each of the calls to the watchful api
      config.headers.Authorization = `Bearer ${userToken}`
    }
    return config
  })
}

const initHotJar = () => hotjar.initialize({ id: 2026655, sv: 6 })
const isWatchfulOrTestUser = email =>
  email.endsWith('@watchful.ai') || email.startsWith('test-')
const isTestEnv = !window.location.host.includes('app.watchful.ai')

export const App = () => {
  const user = useSelector(state => state.appData.user)
  const userData = useSelector(state => state.userData)
  const siteVersion = useSelector(state => state.appData.siteVersion)

  // TODO: remove mobile fix
  useEffect(() => {
    window.addEventListener('resize', updateViewportMeta)
    updateViewportMeta()
    setTimeout(() => updateViewportMeta(), 3000)

    const aws = new awsService()
    const awsUser = aws.getCurrentUser()

    if (!isTestEnv) {
        initHotJar()
    }

    awsUser?.getSession((err, session) => {
      if (err || !session || !session.idToken) {
        return
      }
      const cognitoUser = session.idToken.payload
      if (isTestEnv || isWatchfulOrTestUser(cognitoUser.email)) {
        return
      }
      if (!hotjar.initialized()) {
        initHotJar()
      }
      hotjar.identify(cognitoUser.email, { email: cognitoUser.email })
    })

    return window.removeEventListener('resize', updateViewportMeta)
  }, [])

  const router = useMemo(
    () => getRouter(user, userData, siteVersion),
    [user, userData, siteVersion]
  )

  return (
    <FlagsProvider config={unleashConfig}>
      <RouterProvider router={router} />
      <div className='App'>
        <MainLoader global={true} />
      </div>
    </FlagsProvider>
  )
}

export default App
