import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import DiscoveryView from '../../components/discoveryView/DiscoveryView'
import NewLoader from '../../common/NewLoader/NewLoader'
import { setNotificationForInsightRead } from '../../redux/acts/notifications'
import { setInsightAsViewed } from '../../redux/actions/dashboardActions'
import { getDiscoveriesByProduct } from '../../redux/acts/product_intel'
import {
  postUserDiscoveryComment,
  editUserDiscoveryComment,
  deleteUserDiscoveryComment
} from '../../redux/acts/userDiscoveries'
import { getDiscoveryData } from '../../redux/actions/dashboardActions'
import {
  getABTestTimeLine,
  getReachRate
} from '../../redux/actions/abTestsActions'

import { reachRateSelector } from '../../components/discoveryView/viewExperimentTabs/ReachRateSelector'
import { isUserAdmin } from '../../helpers/usersHelper'
import { siteVersions } from '../../shared/consts'

export const Discovery = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const apps = useSelector(state => state.appData.apps)
  const analytics = useSelector(state => state.appData.user.analytics)
  const ABTestData = useSelector(state => state.abTestsData.data)
  const user = useSelector(state => state.appData.user)
  const impersonateUser = useSelector(state => state.appData.impersonateUser)
  const users = useSelector(state => state.appData.users)
  const userData = useSelector(state => state.userData)
  const siteVersion = useSelector(state => state.appData.siteVersion)
  const demoUser = useSelector(state => state.linkData.user)
  const guest = useSelector(state => state.linkData.guest)
  const insightLinkCode = useSelector(state => state.linkData.link_code)
  const allInsights = useSelector(state => state.appData.allInsights)
  const products = useSelector(state => state.overview.products)
  const productsWithDiscoveries = useSelector(
    state => state.productDiscoveries.productsWithDiscoveries
  )

  const reachRate = useSelector(reachRateSelector)

  // Component state
  const [discovery, setDiscovery] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [loadingFullDiscovery, setLoadingFullDiscovery] = useState(false)
  const appIdParam = +params.app_id
  const app = apps.find(a => a.metadata.id === appIdParam)
  const wasFullDiscoveryLoading = useRef(false)

  const checkAdmin = () => {
    let isAdmin = false

    // If we're impersonating a user, check if the impersonated user is an admin
    if (impersonateUser) {
      isAdmin = users.find(u => u.Username === impersonateUser).isAdmin || false
    } else {
      // No impersonated user
      if (isUserAdmin(user)) {
        isAdmin = true
      }
    }

    setIsAdmin(isAdmin)
  }

  useEffect(() => {
    checkAdmin()

    if (siteVersion !== 'demo' && params.id) {
      dispatch(setInsightAsViewed(params.id))
      dispatch(setNotificationForInsightRead(params.id))
    }

    if (!products) {
      return
    }

    const product = products.find(p =>
      p.apps.find(a => a.id === Number(params.app_id))
    )

    if (!!product && !productsWithDiscoveries[product.id]) {
      dispatch(getDiscoveriesByProduct(product.id))
    }
  }, [])

  useEffect(() => {
    const dis = allInsights.find(dis => dis.insight.id === Number(params.id))

    if (
      dis &&
      dis.insight.ab_test_id &&
      wasFullDiscoveryLoading.current &&
      !loadingFullDiscovery
    ) {
      dispatch(getReachRate(dis.insight.ab_test_id))

      if (!ABTestData || !ABTestData.find(d => d.id === dis.ab_test_id)) {
        dispatch(getABTestTimeLine(dis.insight.ab_test_id))
      }
    }

    if (!user.email && dis && !discovery) {
      setDiscovery(dis.insight)
      setLoadingFullDiscovery(false)
      wasFullDiscoveryLoading.current = false
    }

    if (
      dis &&
      dis.insight.fullLoaded &&
      (loadingFullDiscovery ||
        discovery?.id !== Number(params.id) ||
        !discovery)
    ) {
      setDiscovery(dis.insight)
      setLoadingFullDiscovery(false)
      wasFullDiscoveryLoading.current = false
    }

    //Update Comments
    if (
      (dis?.insight &&
        dis.insight.comments &&
        dis?.insight?.comments.length !== discovery?.comments?.length) ||
      dis?.insight?.lastCommentUpdate !== discovery?.lastCommentUpdate
    ) {
      setDiscovery(dis.insight)
    }

    if (
      (!dis && !loadingFullDiscovery) ||
      (dis && !dis.insight.fullLoaded && !loadingFullDiscovery)
    ) {
      setLoadingFullDiscovery(true)
      wasFullDiscoveryLoading.current = true

      if (user) {
        dispatch(getDiscoveryData({ insightID: params.id }))
      }
    }
  }, [
    allInsights,
    params,
    loadingFullDiscovery,
    dispatch,
    ABTestData,
    user,
    discovery
  ])

  const clickedBack = () => {
    const product = products.find(p =>
      p.apps.find(a => a.id === Number(params.app_id))
    )
    if (location.state?.prevPath) {
      console.log(`goback to ${location.state.prevPath}`)
      navigate(-1)
    } else {
      navigate(`/product_intelligence/${product.id}`)
    }
  }

  const getRelatedDiscovery = id => {
    if (!discovery.related_insights) {
      return null
    }
    let ins = discovery.related_insights.find(ins => ins.id === id)
    if (!ins) {
      return null
    }
    return {
      discovery: ins,
      app: apps.find(app => app.metadata.id == ins.app_id)?.metadata
    }
  }

  if (!discovery) {
    return (
      <div className='discovery-loading-wrapper'>
        <NewLoader />
      </div>
    )
  }

  const product = products
    ? products.find(p => p.apps.find(a => a.id === Number(params.app_id)))
    : null

  let version

  if (product) {
    if (
      productsWithDiscoveries &&
      productsWithDiscoveries[product.id] &&
      productsWithDiscoveries[product.id][Number(params.app_id)] &&
      productsWithDiscoveries[product.id][Number(params.app_id)].versions.find(
        ver => ver.id === Number(params.release_id)
      )
    ) {
      version = productsWithDiscoveries[product.id][
        Number(params.app_id)
      ].versions.find(ver => ver.id === Number(params.release_id))
    }
  }

  return (
    <div className='usual-discovery-wrap'>
      <DiscoveryView
        user={user}
        analytics={analytics}
        discovery={discovery}
        forceLoader={loadingFullDiscovery}
        app={app}
        version={version}
        siteVersion={siteVersion}
        demoUser={demoUser}
        demoMode={siteVersion === 'demo'}
        isAdmin={isAdmin}
        ABTestData={
          discovery.ab_test_id
            ? ABTestData.find(d => d.id === discovery.ab_test_id)
            : null
        }
        location={location}
        relatedInsights={
          siteVersion === siteVersions.DEMO
            ? []
            : discovery.related_insight_ids
            ? discovery.related_insight_ids
                .map(rel_id => getRelatedDiscovery(rel_id))
                .filter(rel => rel)
            : null
        }
        clickBack={clickedBack}
        reachRate={reachRate}
        product={product}
        guest={guest}
        insightLinkCode={insightLinkCode}
        postUserDiscoveryComment={postUserDiscoveryComment}
        editUserDiscoveryComment={editUserDiscoveryComment}
        deleteUserDiscoveryComment={deleteUserDiscoveryComment}
        userData={userData}
        relatedCallback={insightID => dispatch(getDiscoveryData({ insightID }))}
      />
    </div>
  )
}

export default Discovery
