// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uj-persona-filter-container {
  padding: 1rem 5rem 1rem 2rem;
}
.uj-persona-filter-container .uj-persona-filter-heading {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}
.uj-persona-filter-container .uj-persona-filter-list {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/containers/UserJourneys/components/common/PersonaSelect/PersonaFilter/PersonaFilter.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;AACE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ","sourcesContent":[".uj-persona-filter-container {\n  padding: 1rem 5rem 1rem 2rem;\n\n  .uj-persona-filter-heading {\n    font-family: Roboto;\n    font-size: 16px;\n    font-weight: 500;\n  }\n\n  .uj-persona-filter-list {\n    margin-top: 1rem;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
