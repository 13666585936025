import React, { useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Popover } from 'antd'

import { menuItems, menuItemsWoutABTest } from '../../shared/routesData'
import { pagesTypes } from '../../shared/consts'
import lock_ico from '../../assets/images/lockIcon.svg'
import './Nav2.scss'
import { useIsFeatureByUserID } from '../../helpers/features'
import { DownOutlined } from '@ant-design/icons'

const getSubMenuItem = (subMenuItem, parentItem, isEnableMenu, siteSize) => {
  const isSelected = location.pathname === subMenuItem.url
  const enabled = isEnableMenu(subMenuItem)
  const hasChildMenu = subMenuItem.sub_routes?.length

  const content = (
    <span className={`${parentItem.name} menu-label`}>
      {subMenuItem.icon && (
        <img
          src={subMenuItem.icon}
          className={`${subMenuItem.name}-ico`}
          alt=''
        />
      )}
      <span>{subMenuItem.label}</span>
      {!enabled && <img src={lock_ico} className='lock-ico' alt='' />}
    </span>
  )

  const subMenuContent = hasChildMenu && (
    <div className='submenu-menu-wrapper'>
      {siteSize === 'normal' && (
        <div className='submenu-menu-title'>{subMenuItem.label}</div>
      )}
      {subMenuItem.sub_routes?.map(item => {
        return getSubMenuItem(item, subMenuItem, isEnableMenu, siteSize)
      })}
    </div>
  )

  const menuContent = (
    <div
      key={subMenuItem.name}
      className={`sub-link ${isSelected ? 'act' : ''} ${
        enabled ? '' : 'disable'
      } ${hasChildMenu ? 'no-link has-child-menu' : ''}`}
    >
      <NavLink to={`${subMenuItem.url}`}>{content}</NavLink>
    </div>
  )

  return (
    <div>
      {(siteSize !== 'normal' || !hasChildMenu) && (
        <>
          {menuContent}
          {subMenuContent}
        </>
      )}
      {siteSize === 'normal' && hasChildMenu && (
        <Popover
          content={subMenuContent}
          trigger='click'
          placement='rightTop'
          overlayClassName='submenu-popover'
          color='#05113f'
        >
          {menuContent}
        </Popover>
      )}
    </div>
  )
}

const Nav2 = ({ siteSize }) => {
  const location = useLocation()
  const user = useSelector(state => state.appData.user)
  const userData = useSelector(state => state.userData)
  const customerApp = useSelector(state =>
    state.appData.ownApps.find(app => app.metadata.is_customer_own_app === true)
  )

  const ABTestPageAccess = useIsFeatureByUserID('ab_tests_page', user.email)
  const contextTimelineAccess = useIsFeatureByUserID(
    'context_timeline',
    user.email
  )
  const deliveryOrdersAccess = useIsFeatureByUserID(
    'delivery_orders',
    user.email
  )

  const curMenuItems = ABTestPageAccess ? menuItems : menuItemsWoutABTest

  const filteredMenuItems = (contextTimelineAccess
    ? curMenuItems
    : curMenuItems.map(item => ({
        ...item,
        items: item?.items?.filter(i => i.name !== 'context_timeline')
      }))).filter(x => deliveryOrdersAccess || x.name !== 'delivery_orders')

  const getLocation = () => {
    const paths = location.pathname.split('/').slice(1)

    if (paths[0] === 'overview' || paths[0] === '') {
      return ['dash_overview']
    } else if (paths[0] === pagesTypes.MARKETING && !paths[1]) {
      return ['marketing', 'campaigns']
    } else if (paths[0] === 'product_intelligence' && !paths[1]) {
      return ['intelligence', 'product_intelligence']
    } else if (paths[0] === 'product_intelligence' && paths[1] === 'ab-tests') {
      return ['intelligence', 'ab_tests']
    } else if (paths[0] === 'product_intelligence' && paths[1] === 'topics') {
      return ['intelligence', 'topics']
    } else if (paths[0] === 'all-discoveries') {
      return ['all_discoveries']
    } else {
      return paths
    }
  }

  const selectedKey = useMemo(() => {
    const keys = getLocation()
    return keys
  }, [location.pathname])

  const isEnableMenu = item => {
    return userData.pages.some(page => page === item.access_key)
  }

  return (
    <nav className={`nav2 ${siteSize}`}>
      {customerApp && (
        <div className='nav2-customer-app'>
          <img src={customerApp.metadata?.icon} alt='' />
          <span>{customerApp.metadata?.display_name?.toLowerCase()}</span>
        </div>
      )}
      {siteSize === 'normal' && (
        <div className='menu-collapsed-title'>Menu</div>
      )}
      {filteredMenuItems.map((topItem, i) =>
        topItem.items ? (
          <div className='top-sub-menu' key={i}>
            <div className='top-menu-title'>
              <span className={`${topItem.name} menu-label`}>
                <img src={topItem.icon} alt='' />
                <span>{topItem.label}</span>
              </span>
            </div>
            <div className='top-menu-items'>
              {topItem.items.map(subMenuItem => {
                return getSubMenuItem(
                  subMenuItem,
                  topItem,
                  isEnableMenu,
                  siteSize
                )
              })}
            </div>
          </div>
        ) : (
          <div className='top-link-container' key={i}>
            <div
              className={`top-link ${
                topItem.name === selectedKey[0] ? 'act' : ''
              }  ${isEnableMenu(topItem) ? '' : 'disable'}`}
            >
              <NavLink to={`${topItem.routeData.url}`}>
                <span className={`${topItem.name} menu-label`}>
                  <img
                    src={topItem.routeData.icon}
                    className={`${topItem.name}-ico`}
                    alt=''
                  />
                  <span>{topItem.label}</span>
                </span>
              </NavLink>
            </div>
          </div>
        )
      )}
    </nav>
  )
}

export default Nav2
