import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { ApolloProvider } from '@apollo/client'

import App from './App'
import ErrorBoundary from './ErrorBoundary'
import reducers from './redux/reducers/_reducers'
import rootSaga from './redux/sagas/_sagas'
import * as serviceWorker from './serviceWorker'
import { apolloClient } from './shared/gql'

dayjs.extend(quarterOfYear)
dayjs.extend(isSameOrBefore)

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

//@ts-ignore
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </ApolloProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
