import React from "react"
import moment from "moment"
import { get } from "lodash";
import { numberFormatter, cpuUnitsFormatter } from "../../../helpers/numbersHelper";

import {
  LOCATIONS_EXTRA
} from "../../../shared/consts";
import { EXCLUDED_EVENT_NAMES } from "../constants";


export const COMPARE_COLOR_1 = "#2763FF"
export const COMPARE_COLOR_2 = "#FF2775"

export const deviceCategoryDictionary = {
  "low-end": "Low End",
  "mid-range": "Mid Range",
  "high-end": "High End"
};

export const buildFormattedEventsList = (events) => {
  events = events.filter(ev => !EXCLUDED_EVENT_NAMES.includes(ev.name))

  return events.map(ev => ({
    label: ev.title,
    value: ev.id,
    info: ev.description,
    disabled: ev.disabled
  }))
}
export const getFormattedEvent = (events, eventID) => {
  const ev = events.find(ev => ev.id === eventID)
  return {
    label: ev.title,
    value: ev.id,
    info: ev.description,
    disabled: ev.disabled
  }
}



export const buildFormattedDevicesList = (devices, perfInfrastructure, loc) => {
  const infData = perfInfrastructure.find(l => l.loc === loc)?.origData
  if (!infData) {
    return []
  }

  let sortedDevices = [...devices].sort((d1, d2) => d1.priority > d2.priority ? 1 : -1)

  return sortedDevices
    // .filter(dev => infData.find(data => data[0] === dev.model && data[2] === dev.os_version))
    .reduce((acc, dev) => acc.find(d => d.model === dev.model) ? acc : [...acc, dev], [])
    .map(dev => ({
      label: dev.product_name,
      value: dev.model,
      info: <div className="performance-tooltip">
        <div className="performance-tooltip-field">
          <span>Category:</span>
          {deviceCategoryDictionary[dev.category]}
        </div>
        <div className="performance-tooltip-field">
          <span>Manufacturer:</span>
          {dev.manufacturer}
        </div>
        <div className="performance-tooltip-field">
          <span>Model:</span>
          {dev.model}
        </div>
        <div className="performance-tooltip-field">
          <span>CPU:</span>
          {dev.cpu}
        </div>
        <div className="performance-tooltip-field">
          <span>RAM:</span>
          {dev.ram}
        </div>
      </div>
    }))
}
export const getFormattedDevice = (devices, selDevice) => {
  const dev = devices.find(dev => dev.model === selDevice.model)
  return {
    label: dev.product_name,
    value: dev.model,
    info: <div className="performance-tooltip">
      <div className="performance-tooltip-field">
        <span>Category:</span>
        {deviceCategoryDictionary[dev.category]}
      </div>
      <div className="performance-tooltip-field">
        <span>Manufacturer:</span>
        {dev.manufacturer}
      </div>
      <div className="performance-tooltip-field">
        <span>Model:</span>
        {dev.model}
      </div>
      <div className="performance-tooltip-field">
        <span>CPU:</span>
        {dev.cpu}
      </div>
      <div className="performance-tooltip-field">
        <span>RAM:</span>
        {dev.ram}
      </div>
    </div>
  }
}



export const buildFormattedOSList = (devices, selDevice) => {
  let devs = devices.filter(d => d.model === selDevice.model)
  if (devs.length > 1) {
    return devices.filter(d => d.model === selDevice.model)
      .map(dev => ({
        label: dev.os_version,
        value: dev.os_version,
      }))
  } else {
    return []
  }
}




export const buildFormattedLocationsList = (locations) => {
  return locations.map(loc => LOCATIONS_EXTRA[loc.loc]).map(loc => ({
    label: <div>
      <span
        className={`flag-icon fi fi-${loc.location.toLowerCase()}`}
        style={{ marginRight: "1rem" }}
      />
      {loc.name}
    </div>,
    value: loc.location,
  }))
}
export const getFormattedLocation = (selLocation) => {
  return {
    label: <div>
      <span
        className={`flag-icon fi fi-${selLocation.toLowerCase()}`}
        style={{ marginRight: "1rem" }}
      />
      {LOCATIONS_EXTRA[selLocation].name}
    </div>,
    value: selLocation,
  }
}

export const toCommonISOFormat = (date) => `${moment(date)
  .startOf("day")
  .format('YYYY-MM-DDT00:00:00.000')}Z`

export const getTimeFrameFilter = (customRange, customRangeTime, selectedDaysAgoValue) => {
  if (customRange) {

    return [{
      filterValue: toCommonISOFormat(customRangeTime[0]),
      filterType: "from_date",
    }, {
      filterValue: toCommonISOFormat(moment(customRangeTime[1]).add(1, 'day')),
      filterType: "until_date",
    }];

  } else {
    const isoDate = toCommonISOFormat(moment()
      .subtract(selectedDaysAgoValue, "days"))
    return [{
      filterValue: isoDate,
      filterType: "from_date",
    }];
  }
};

export const restoreEventFromURL = (initValues, eventID, index, perfApps, perfDeviceModels) => {
  const app = eventID ? perfApps.find(a => a.performance_events.find(ev => ev.id === eventID)) : ''
  const device = initValues.get(`device_${index}`) ? perfDeviceModels.find(dev => dev.model === initValues.get(`device_${index}`)) : (app?.device_models?.[0] || '')
  const restoredEv = {
    id: eventID,
    app: app,
    device: device,
    os_version: initValues.get(`os_version_${index}`) || device?.os_version || '',
    location: initValues.get(`loc_${index}`) || ''
  }
  return restoredEv
}

export const getSummaryValues = allAppData => {
  if (!allAppData) {
    return { isLoading: true, duration: null, stats: [] };
  }

  const energySupply = Math.max(get(allAppData, "energy_joules.average"), 0.0);
  const results = {
    raw_data: allAppData,
    duration: allAppData.end_ts_s_in_event * 1000 || undefined,
    stats: [
      {
        title: "Memory Usage",
        rawValue: allAppData.memory_bytes && allAppData.memory_bytes.average,
        value: numberFormatter(
          allAppData.memory_bytes && allAppData.memory_bytes.average,
          1
        ),
        name: 'memory_bytes',
        info: "Memory Usage is measured by the difference between the baseline memory usage and memory usage at the end of the event."
      },
      {
        title: "Data Received",
        rawValue:
          allAppData.network_rx_bytes && allAppData.network_rx_bytes.average,
        value: numberFormatter(
          allAppData.network_rx_bytes && allAppData.network_rx_bytes.average,
          1
        ),
        name: 'network_rx_bytes',
        info: "Data Received is the amount of bytes the app received over the network during the selected event."
      },
      {
        title: "Data Sent",
        rawValue:
          allAppData.network_tx_bytes && allAppData.network_tx_bytes.average,
        value: numberFormatter(
          allAppData.network_tx_bytes && allAppData.network_tx_bytes.average,
          1
        ),
        name: 'network_tx_bytes',
        info: "Data Sent is the amount of bytes the app sent over the network during the selected event."
      },
      {
        title: "CPU Usage",
        rawValue:
          allAppData.cpu_units && allAppData.cpu_units.average,
        value: cpuUnitsFormatter(
          allAppData.cpu_units && allAppData.cpu_units.average,
          1
        ),
        name: 'cpu_units',
        info: "CPU Usage is measured by the average number of CPU units consumed by the app during the selected event"
      }
    ]
  };
  if (energySupply > 0) {
    results.stats.push({
      title: (
        <>

          <span>Energy</span>
        </>
      ),
      rawValue: energySupply.toFixed(1),
      value: {
        number: energySupply.toFixed(1),
        suffix: "J"
      },
      info: "True Energy Consumption is the amount of energy drawn from the battery during the selected event, when the app is running in isolation and the device is not being charged. Joule is the standard unit of measurement for energy"
    });
  }
  return results;
};