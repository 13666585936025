import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { getAllAppsVersionInfo } from '../../redux/actions/overviewActions'
import { getDiscoveriesByProduct } from '../../redux/acts/product_intel'
import { getGraphsByApp } from '../../redux/acts/graphs'

import { getDiscoveriesLabels } from '../../redux/acts/labels'

import { useIsFeatureByUserID } from '../../helpers/features'

import IntelligenceAppView from './IntelligenceProductView'
import { PL_MARKETING } from '../../shared/consts'

export const IntelligenceProductPage = () => {
  const dispatch = useDispatch()
  const { product_id } = useParams()

  const analytics = useSelector(state => state.appData.user.analytics)
  const user = useSelector(state => state.appData.user)
  const products = useSelector(state => state.overview.products)
  const unSeenCriticalInsights = useSelector(
    state => state.overview.unSeenCriticalInsights
  )
  const relevantInsightsBase = useSelector(
    state => state.overview.relevantInsights
  )
  const tagsList = useSelector(state => state.labels.tagsList)
  const userData = useSelector(state => state.userData)
  const allInsights = useSelector(state => state.appData.allInsights)
  const appsWithGraphs = useSelector(state => state.graphs.appsWithGraphs)
  const productsWithDiscoveries = useSelector(
    state => state.productDiscoveries.productsWithDiscoveries
  )
  const userDiscoveries = useSelector(
    state => state.userDiscoveries.discoveries
  )

  const relevantInsights =
    relevantInsightsBase?.map(el => ({
      ...el.insight,
      ...el.metadata,
      metadata: el.metadata,
      insightId: el.insight.id
    })) || null

  const appGraphAccess = useIsFeatureByUserID('app_graph', user.email)

  useEffect(() => {
    if (!tagsList) {
      dispatch(getDiscoveriesLabels())
    }
    if (!relevantInsights) {
      dispatch(getAllAppsVersionInfo())
    }
  }, [])

  useEffect(() => {
    // TODO getDiscoveriesByProduct is an action create the indexing here is weird to check
    if (product_id && !getDiscoveriesByProduct[Number(product_id)]) {
      dispatch(getDiscoveriesByProduct(product_id))
    }
  }, [product_id])

  const pr = productsWithDiscoveries[product_id]

  return (
    <div className='intel-product-wrapper'>
      <IntelligenceAppView
        products={
          products
            ? products.filter(
                p =>
                  p.apps.find(a => a.has_experiments) &&
                  p.apps.find(a => a.platform !== PL_MARKETING)
              )
            : []
        }
        product={pr}
        //product={pr ? Object.keys(pr).reduce((acc, appID) => pr[appID].versions.find(v => v.is_visible) ? { ...acc, appID: pr[appID] } : acc, {}) : null}
        productCommonData={products.find(p => p.id === Number(product_id))}
        product_id={product_id}
        unSeenCriticalInsights={unSeenCriticalInsights}
        tagsList={tagsList}
        analytics={analytics}
        getGraphsByApp={getGraphsByApp}
        allInsights={allInsights}
        appGraphAccess={appGraphAccess}
        user={user}
        userData={userData}
        appsWithGraphs={appsWithGraphs}
        relevantInsights={relevantInsights}
        userDiscoveries={userDiscoveries}
      />
    </div>
  )
}

export default IntelligenceProductPage
