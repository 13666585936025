import React, { useState } from 'react'
import { message, Popover } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import moment from 'moment'

import './MoreOptions.scss'
import mark_icon from '../../../assets/images/icons/mark.svg'
import download_icon from '../../../assets/images/icons/Download.svg'
import more_options_icon from '../../../assets/images/icons/more-options.svg'
import more_options_icon_white from '../../../assets/images/icons/more-options-white.svg'
import three_dots_icon from 'assets/images/icons/three-dots.svg'

import {
  askInsightQuestion,
  likeInsight,
  unlikeInsight
} from '../../../redux/actions/dashboardActions'

import mark_active_icon from '../../../assets/images/icons/mark_active.svg'
import ViewHeaderChatModal from './viewHeaderChatModal/viewHeaderChatModal'

const MoreOptions = ({
  downloadCallback,
  analytics,
  likeInsight,
  unlikeInsight,
  userDiscoveries,
  insightID,
  app,
  discovery,
  defaultEmail,
  askInsightQuestion,
  currentInsightURL,
  metadata,
  page
}) => {
  const [openModal, openModalChange] = useState(false)
  const [askModal, askModalChange] = useState(false)
  const [question] = useState('')
  const [email] = useState(defaultEmail)
  const [hoverButton, hoverButtonChange] = useState(false)

  const userDis = userDiscoveries.find(uD => uD.insight_id === insightID)
  const isLiked = userDis
    ? (userDis.liked_at && !userDis.unliked_at) ||
      (userDis.unliked_at &&
        moment(userDis.unliked_at).isBefore(moment(userDis.liked_at)))
    : false
  const handleStarClick = () => {
    const currentPath = window && window.location && window.location.pathname
    const txt = `${isLiked ? 'Unmark' : 'Mark'} an insight`
    if (isLiked) {
      unlikeInsight({ insightID })
    } else {
      likeInsight({ insightID })
    }
    analytics.analytic(txt, {
      insight_name: discovery.title,
      insight_type: discovery.type,
      app: app.name,
      insightURL: `https://app.watchful.ai${currentPath}`,
      platform: app.platform
    })
  }

  const submitQuestion = () => {
    askInsightQuestion({
      appName: (metadata && metadata.display_name) || '',
      insightID: insightID,
      insightURL: `https://app.watchful.ai${currentInsightURL}`,
      userEmail: email,
      question
    })
    message.loading({ content: 'Sending question', icon: <div /> })
    analytics.analytic(
      `Send question from marketing insight page - ${question}`
    )
  }

  const handleAskChange = event => {
    const func = `${event.target.name}Change(event.target.value)`
    eval(func)
  }

  const options = () => {
    return (
      <div className='options-wrapper'>
        <div
          className='item'
          onClick={() => {
            downloadCallback('pdf')
            openModalChange(false)
          }}
        >
          <img src={download_icon} />
          <span className='hide'>Download</span> PDF
        </div>
        {/* 
      // INFO: uncomment this if download as JPG is required
      <div className="item" onClick={() => {downloadCallback('jpg'); openModalChange(false)}}>
        <img src={download_icon}/>
        <span className="hide">Download</span> JPG
      </div>
      */}
        {page === 'campaigns' ? null : (
          <div
            className='item'
            onClick={() => {
              handleStarClick()
              openModalChange(false)
            }}
          >
            <img src={isLiked ? mark_active_icon : mark_icon} />
            Mark{isLiked ? 'ed' : ''}
          </div>
        )}
        <div
          className='item'
          onClick={() => {
            askModalChange(true)
            openModalChange(false)
          }}
        >
          <QuestionCircleOutlined />
          Ask
        </div>
      </div>
    )
  }

  return (
    <div className={page === 'campaigns' ? '' : 'more-options-wrapper'}>
      <Popover
        placement='bottomRight'
        title={null}
        visible={openModal}
        content={options()}
        trigger='click'
        destroyTooltipOnHide={true}
        onOpenChange={() => openModalChange(!openModal)}
      >
        {page === 'campaigns' ? (
          <div className='mkc-preview-btn'>
            <img src={three_dots_icon} />
          </div>
        ) : (
          <img
            src={
              openModal || hoverButton
                ? more_options_icon
                : more_options_icon_white
            }
            onClick={() => openModalChange(!openModal)}
            onMouseEnter={() => hoverButtonChange(true)}
            onMouseLeave={() => hoverButtonChange(false)}
          />
        )}
      </Popover>

      <ViewHeaderChatModal
        closeModal={() => askModalChange(false)}
        modalShow={askModal}
        submitQuestion={submitQuestion}
        question={question}
        email={email}
        handleChange={handleAskChange}
      />
    </div>
  )
}
const mapStateToProps = ({ appData, userDiscoveries }) => ({
  analytics: appData.user.analytics,
  userDiscoveries: userDiscoveries.discoveries
})
export default connect(mapStateToProps, {
  likeInsight,
  unlikeInsight,
  askInsightQuestion
})(MoreOptions)
