let requestsLog = []

// Calculate segments accoridng to loader appears or not
export function collectRawSegments (requestsLog) {
  let ncur = false,
    cur = false,
    loadSegments = []
  let curStart,
    lastTs,
    curReqTypes = [],
    curPathNames = []
  let idx = 0,
    idxs = []

  for (let [ts, rl, reqTypes, pathname] of requestsLog) {
    idxs.push(idx)
    ncur = !!rl // what we care about is where num of reqs if 0 or not
    lastTs = ts
    for (let reqType of reqTypes) {
      curReqTypes.push(reqType)
    }
    curPathNames.push(pathname)

    if (ncur !== cur) {
      // loader state changed (appeared <-> gone)
      const end = ts - 1 // We end previous segment 1 ms prior to curr segment
      const dur = (end - curStart) / 1000
      // We store previous segment with loading indication (loading:cur)
      loadSegments.push({
        start: curStart ? curStart.getTime() : null,
        types: cur ? [...new Set(curReqTypes)] : [],
        paths: [...new Set(curPathNames)],
        idxs,
        end,
        dur,
        loading: cur
      })
      idxs = []
      curPathNames = []
      if (cur) curReqTypes = []
      cur = ncur
      curStart = ts
    }
    //else l('not pushing loadSegments',curStart,cur);
    idx++
  }

  // If we have a loading segment without an end we save it at the end of load segments
  // TODO: do we need this?
  if (
    ncur !== loadSegments[loadSegments.length - 1]?.loading ||
    (requestsLog.length && !loadSegments.length)
  ) {
    loadSegments.push({
      start: lastTs ? lastTs.getTime() : null,
      types: ncur ? [...new Set(curReqTypes)] : [],
      paths: [...new Set(curPathNames)],
      idxs: [idx - 1],
      end: null,
      dur: null,
      loading: ncur
    })
  }
  return loadSegments
}

// We need to analyze requests history because MainLoader "flickers"
// so we can't rely on it showing / not-showing to keep track of the time
// it showed for a certain window.location.path
export function analyzeRequestsState (reqs) {
  requestsLog.push([new Date(), reqs.length, reqs, window.location.pathname])
  let loadSegments = collectRawSegments(requestsLog)
  // this is an optional feature to compact adjacent loader events that have short gaps in between.

  let reported = 0
  for (let ls of loadSegments) {
    if (!ls.loading) continue
    if (!ls.end || !ls.dur) continue //  || ls.end<=recent - add this condition if filterRawSegments was applied to make sure we do not report of loader segments that would eventually be reduced into larger segments prematurely

    reported++
    for (let idx of ls.idxs) {
      //l('removing row',idx,'from requestsLog which is currently',requestsLog.length,'items long.');
      requestsLog = requestsLog.splice(idx, 1)
    }
  }
  if (reported) requestsLog = []
}
