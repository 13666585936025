import React, { useState } from 'react'
import marketing_user_ico from '../../../assets/images/icons/marketing_user_ico.svg'
import { Tooltip, Button } from 'antd'
import Icon from '@ant-design/icons'
import { countries } from '../../../shared/countries'

export const Profiles = ({ userQty = {} }) => {
  const [fullView, fullViewChange] = useState(false)
  const countPersonas =
    Object.values(userQty).length > 0
      ? Object.values(userQty).reduce((acc, num) => acc + num, 0)
      : 0
  return (
    <div className='profiles'>
      <Tooltip
        placement='top'
        title='The number of custom profiles/personas setup to capture marketing campaigns across the full customer journey'
      >
        <div className='active-profiles'>
          <div className='active-profiles-count'>
            <img src={marketing_user_ico} />
            {countPersonas}
            {countPersonas > 0 && (
              <div className='active-profiles-online-symbol' />
            )}
          </div>
          <div className='active-profiles-title'>Personas</div>
        </div>
      </Tooltip>
      {countPersonas > 0 && (
        <div className={`country-profiles ${fullView ? 'full' : 'short'}`}>
          <div
            className={`country-profiles-flag ${
              Object.entries(userQty).length < 10 ? 'flex-align-center' : ''
            } `}
          >
            {Object.entries(userQty)
              .slice(0, fullView ? 500 : 17)
              .sort((a, b) => b[1] - a[1])
              .map((q, i) => (
                <Tooltip
                  placement='top'
                  title={`${
                    countries.find(c => c.code == q[0].slice(0, 2)).name
                  }`}
                  key={i}
                >
                  <span
                    className={`flag-icon fi fi-${q[0]
                      .slice(0, 2)
                      .toLowerCase()}`}
                  />
                  <span className='country-profiles-value'>{q[1]} </span>
                </Tooltip>
              ))}
            {Object.entries(userQty).length > 20 && (
              <Button
                className='toggle-view'
                onClick={() => fullViewChange(!fullView)}
              >
                {fullView ? (
                  <Icon type='arrow-up' />
                ) : (
                  <Icon type='arrow-down' />
                )}
                {fullView
                  ? 'View Fewer'
                  : `View All (${Object.entries(userQty).length})`}
              </Button>
            )}
          </div>
          <div className='active-profiles-title'>User Locations</div>
        </div>
      )}
    </div>
  )
}

export default Profiles
