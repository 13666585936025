import React from 'react'
import { Spin, Tooltip, Button } from 'antd'
import Icon from '@ant-design/icons'
import { durationFormatter } from '../../../../helpers/numbersHelper'
import { Link } from 'react-router-dom'

import './performanceSummary.scss'
import { useIsFeatureByUserID } from '../../../../helpers/features'

export default function PerformanceSummary ({
  duration = { value: 0, underline: false },
  aggregatedStats = [
    { title: 'Aggregated Stat 1', value: { number: 0, suffix: 'bytes' } }
  ],
  isLoading,
  isWinner,
  timeLinePerc,
  order,
  dataForCompare,
  comparePeriodText,
  linksParams,
  handleClickAnalytics,
  user
}) {
  const accessToMetrics = useIsFeatureByUserID('metrics_compare', user.email)
  const accessToCpuUsageMetric = useIsFeatureByUserID('performance_metric_cpu_usage', user.email)
  if (!accessToCpuUsageMetric) {
    aggregatedStats = aggregatedStats.filter(x => x.name !== 'cpu_units')
  }

  return (
    <div id='performance-summary' className='performance-summary'>
      {isLoading && <Spin size='large' />}
      {!isLoading && (
        <>
          <div className='timeLine'>
            <div
              className={`t-${order}`}
              style={{ width: `${timeLinePerc}%` }}
            />
          </div>
          <div className='winner-tag'>{isWinner ? 'Winner' : ''}</div>
          {dataForCompare.duration_s && (
            <div className='diff-vs-period duration'>
              {dataForCompare.duration_s * 1000 < duration.value ? (
                <span className='performance-summary-diff-perc plus'>
                  +
                  {Math.abs(
                    (
                      ((dataForCompare.duration_s * 1000) / duration.value) *
                        100 -
                      100
                    ).toFixed(1)
                  )}
                  %{' '}
                </span>
              ) : (
                <span className='performance-summary-diff-perc minus'>
                  -
                  {Math.abs(
                    (
                      ((dataForCompare.duration_s * 1000) / duration.value) *
                        100 -
                      100
                    ).toFixed(1)
                  )}
                  %{' '}
                </span>
              )}
              {comparePeriodText}
            </div>
          )}
          <div className='performance-summary__duration'>
            <div className={`value ${duration.underline ? 'underline' : ''}`}>
              {isNaN(duration.value)
                ? 'N/A'
                : durationFormatter(duration.value, 0).formattedNumber}
              <span>{!isNaN(duration.value) && 'SEC'}</span>
            </div>
            <div className='heading'>Total Event Duration</div>
          </div>
          {Object.keys(dataForCompare).length > 0 && (
            <div className='performance-summary__diffs'>
              {aggregatedStats.map((stat, i) =>
                dataForCompare[stat.name] ? (
                  <div key={i} className='diff-vs-period'>
                    {dataForCompare[stat.name] < stat.rawValue ? (
                      <span className='performance-summary-diff-perc plus'>
                        +
                        {Math.abs(
                          (
                            (stat.rawValue / dataForCompare[stat.name]) * 100 -
                            100
                          ).toFixed(1)
                        )}
                        %
                      </span>
                    ) : (
                      <span className='performance-summary-diff-perc minus'>
                        -
                        {Math.abs(
                          (
                            (dataForCompare[stat.name] / stat.rawValue) * 100 -
                            100
                          ).toFixed(1)
                        )}
                        %
                      </span>
                    )}
                    {comparePeriodText}
                  </div>
                ) : (
                  <div key={i} className='diff-vs-period'>
                    -
                  </div>
                )
              )}
            </div>
          )}
          <div className='performance-summary__stats'>
            {aggregatedStats.map((stat, i) => (
              <Tooltip
                placement='top'
                title={
                  <div className='performance-summary__stats__stat-tooltip'>
                    {stat.info}
                  </div>
                }
                key={i}
              >
                <div
                  key={`${stat.value.number}_${stat.title}`}
                  className='performance-summary__stats__stat'
                >
                  <Icon
                    type='info-circle'
                    className='performance-summary__stats__stat-i'
                  />
                  <div
                    className={`performance-summary__stats__stat-value value ${
                      stat.underline ? 'underline' : ''
                    }`}
                  >
                    {isNaN(stat.value.number) ? 'N/A' : stat.value.number}
                    <span>{stat.value.suffix}</span>
                  </div>

                  <div className='performance-summary__stats__stat-title heading'>
                    {stat.title}
                  </div>
                </div>
              </Tooltip>
            ))}
          </div>
          {accessToMetrics && (
            <div className='mertics-link' onClick={handleClickAnalytics}>
              <Link
                to={`/benchmark/compare/metrics/${linksParams.id}?days_ago=${linksParams.daysAgo}&subEvent=&customRange=${linksParams.customRange}&customRangeTime=${linksParams.customRangeTime}&device_1=${linksParams.device_1}&os_version_1=${linksParams.os_version_1}&loc_1=${linksParams.loc_1}&backURL=${linksParams.backURL}`}
              >
                <Button className='metrics-link ant-btn-default'>
                  View Metric Analysis
                </Button>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  )
}
