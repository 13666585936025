import './IntelFilters.scss'

import { Popover } from 'antd'
import calendar_ico from 'assets/images/icons/calendar.svg'
import caret_ico from 'assets/images/icons/caret-down.svg'
import device_ico from 'assets/images/icons/device.svg'
import dna_ico from 'assets/images/icons/dna.svg'
import x_ico from 'assets/images/icons/x-no-outline.svg'
import clsx from 'clsx'
import { AppDrawer } from 'components/AppDrawer'
import { SimpleMessage } from 'components/SimpleMessage'
import { TimeFilter } from 'components/TimeFilter'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import dayjs, { Dayjs } from 'dayjs'
import { useIsFeatureByUserID } from 'helpers/features'
import moment, { Moment } from 'moment'
import {
  HandleIntelFilterChangeType,
  Modes
} from 'pages/Intel/hooks/useIntelFilters'
import { trackIntelEvent } from 'pages/Intel/utils/analytics'
import { PLATFORM } from 'pages/Intel/utils/consts'
import { Persona, Product } from 'pages/Intel/utils/types'
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { daysAgoList } from 'shared/consts'

import { PlatformDrawer } from './PlatformDrawer'

type Props = {
  daysAgo: number
  customRange: boolean
  customRangeTime: Moment[]
  platforms: string[]
  handleUpdateFilter: HandleIntelFilterChangeType
  products: Product[]
  selectedProductIds: number[]
  personas: Persona[]
  selectedPersonaIds: number[]
  mode: Modes
  totalReleases?: number
  user?: any
}

const IntelFiltersBase = ({
  selectedProductIds,
  customRange,
  customRangeTime,
  daysAgo,
  handleUpdateFilter,
  platforms,
  products,
  mode,
  totalReleases,
  user
}: Props) => {
  const releaseTimeline = useIsFeatureByUserID('release_timeline', user?.email)
  const isMobile = isMobileDevice()
  const [platformsFilterOpen, setPlatformsFilterOpen] = useState(false)
  const [timeFrameOpen, setTimeFrameOpen] = useState(false)
  const [appsOpen, setAppsOpen] = useState(false)
  const timeFrameStartDate = customRange
    ? dayjs(customRangeTime[0].toDate())
    : dayjs().subtract(daysAgo, 'day')
  const timeFrameEndDate = customRange
    ? dayjs(customRangeTime[1].toDate())
    : dayjs()

  const productsList = products.map(product => ({
    id: product.id,
    name: product.name,
    thumbnail: product.icon
  }))
  const selectedProducts =
    selectedProductIds.length > 0
      ? products.filter(app => selectedProductIds.includes(app.id))
      : []

  const platformCounts = useMemo(() => {
    const counts: Record<keyof typeof PLATFORM, number> = {
      ios: 0,
      android: 0,
      web: 0
    }
    const currentProducts = selectedProductIds.length
      ? selectedProducts
      : products

    currentProducts.forEach(({ apps }) => {
      apps.forEach(({ platform, insightsCnt }) => {
        counts[platform.toLowerCase() as keyof typeof PLATFORM] += insightsCnt
      })
    })

    return counts
  }, [selectedProducts])

  const handleSelectProducts = (productIds: number[]) => {
    trackIntelEvent('intel_apps_filter', {
      selected_app_name: products
        .filter(p => productIds.includes(p.id))
        .map(selectedProduct => selectedProduct.name)
    })

    handleUpdateFilter(
      'product_id',
      productIds.map(id => id.toString())
    )
  }

  const handleDaysAgoChange = (daysAgo: number) => {
    trackIntelEvent('intel_time_interval_filter', {
      number_of_days: daysAgoList.find(d => d.value === daysAgo)?.value || 30
    })

    handleUpdateFilter('days_ago', daysAgo.toString())
    handleUpdateFilter('custom_range', '')
    handleUpdateFilter('custom_range_time', [])
    setTimeFrameOpen(false)
  }

  const handleCustomRangeChange = (dates: [Dayjs, Dayjs] | null) => {
    if (!dates?.length) {
      handleUpdateFilter('custom_range', false.toString())
      handleUpdateFilter('custom_range_time', [])
    } else {
      trackIntelEvent('intel_time_interval_filter', {
        number_of_days: dates[0].diff(dates[1], 'day')
      })

      handleUpdateFilter('custom_range', true.toString())
      handleUpdateFilter(
        'custom_range_time',
        dates.map(date => date.toISOString())
      )
    }
  }

  const onPlatformSelect = (selectedPlatforms: string[]) => {
    const newSelectedPlatform = selectedPlatforms.filter(
      platform => platforms.indexOf(platform) === -1
    )

    newSelectedPlatform.forEach(p => {
      trackIntelEvent('intel_platform_filter', { selected_platform: p })
    })

    if (mode === 'by_release') {
      if (newSelectedPlatform.length === 0) return

      return handleUpdateFilter('platform', newSelectedPlatform)
    }

    handleUpdateFilter('platform', selectedPlatforms)
  }

  const onChangeMode = (newMode: Modes) => () => {
    handleUpdateFilter('mode', newMode)

    if (newMode === 'by_release') {
      handleUpdateFilter('product_id', [
        selectedProductIds?.length > 0
          ? selectedProductIds[0].toString()
          : productsList[0]?.id?.toString()
      ])
      handleUpdateFilter('platform', ['android'])
    }
  }

  return (
    <>
      <div className='i-top-section'>
        <div className='i-filters'>
          <div className='i-header-container'>
            <div className='header'>
              <h1 className='i-heading'>Discoveries</h1>
              <div className='i-filters-container'>
                <div
                  className={clsx('select-filter', {
                    selected: mode === 'by_date'
                  })}
                  style={{ minWidth: '70px' }}
                  onClick={onChangeMode('by_date')}
                >
                  <div className='select-body'>
                    <span>By Date</span>
                  </div>
                </div>
                {releaseTimeline && (
                  <div
                    className={clsx('select-filter right-divider', {
                      selected: mode === 'by_release'
                    })}
                    style={{ minWidth: '90px' }}
                    onClick={() => {
                      if (!productsList?.length) return

                      onChangeMode('by_release')()
                    }}
                  >
                    <div className='select-body'>
                      <span>By Release</span>
                    </div>
                  </div>
                )}
                <Popover
                  placement='bottom'
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <AppDrawer
                      appsList={productsList}
                      currentAppsIds={selectedProducts.map(
                        product => product.id
                      )}
                      onAppsSelected={handleSelectProducts}
                      closeDrawer={() => setAppsOpen(false)}
                    />
                  }
                  open={appsOpen}
                  onOpenChange={open => setAppsOpen(open)}
                  trigger='click'
                  overlayClassName={clsx(
                    'popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps fixed-filters',
                    {
                      'mobile-full-screen': isMobile
                    }
                  )}
                >
                  <div
                    className='select-filter'
                    style={{ minWidth: isMobile ? '18%' : '125px' }}
                  >
                    <div className='select-body'>
                      {!isMobile ? (
                        <>
                          <img src={device_ico} />
                          <span>Apps ({selectedProducts.length})</span>
                          <img src={caret_ico} />
                        </>
                      ) : (
                        <>
                          <span>Apps</span>
                          <span className='select-body-count'>
                            {selectedProducts.length}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </Popover>
                <Popover
                  placement='bottom'
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <PlatformDrawer
                      onPlatformSelect={onPlatformSelect}
                      selectedPlatforms={platforms}
                      platformCounts={platformCounts}
                      closeDrawer={() => setPlatformsFilterOpen(false)}
                      isSelectAllDisabled={mode === 'by_release'}
                    />
                  }
                  open={platformsFilterOpen}
                  onOpenChange={open => setPlatformsFilterOpen(open)}
                  overlayClassName={clsx('fixed-filters', {
                    'mobile-full-screen': isMobile
                  })}
                  trigger='click'
                >
                  <div
                    className='select-filter'
                    style={{ minWidth: isMobile ? '25%' : '130px' }}
                  >
                    <div className='select-body'>
                      {!isMobile ? (
                        <>
                          <img src={dna_ico} />
                          <span>Platform ({platforms.length})</span>
                          <img src={caret_ico} />
                        </>
                      ) : (
                        <>
                          <span>Platforms</span>
                          <span className='select-body-count'>
                            {platforms.length}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </Popover>
                {/* Temporary disable user types filter SQUAD2-1329 */}
                {/* <Popover
                  placement="bottom"
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <UserTypeFilter
                      onSelectUserTypes={onPersonaSelect}
                      selectedUserTypes={selectedPersonas}
                      userTypes={personas}
                    />
                  }
                  trigger="click"
                >
                  <div
                    className="select-filter"
                    style={{ minWidth: isMobile ? "29%" : "200px" }}
                  >
                    <span className="select-filter-title">User Types</span>
                    <div className="select-body">
                      {!isMobile ? (
                        <>
                          <span>
                            {selectedPersonas.length > 0
                              ? `${selectedPersonas.length} Selected`
                              : "Select User Types"}
                          </span>
                          <img src={caret_ico} />
                        </>
                      ) : (
                        <>
                          <span>User Types</span>
                          <span className="select-body-count">
                            {selectedPersonas.length}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </Popover> */}
                <Popover
                  placement='bottom'
                  title={null}
                  destroyTooltipOnHide={true}
                  open={timeFrameOpen}
                  onOpenChange={open => setTimeFrameOpen(open)}
                  content={
                    <TimeFilter
                      daysAgoList={daysAgoList}
                      value={[timeFrameStartDate, timeFrameEndDate]}
                      onChange={handleCustomRangeChange}
                      onClose={() => setTimeFrameOpen(false)}
                    />
                  }
                  trigger='click'
                  overlayClassName={clsx(
                    'filter-wrap all-discoveries all-discoveries-time fixed-filters',
                    {
                      'mobile-full-screen': isMobile
                    }
                  )}
                  overlayStyle={{ width: isMobile ? '35rem' : '75rem' }}
                >
                  {mode !== 'by_release' && (
                    <div
                      className='select-filter'
                      style={{ minWidth: isMobile ? '24%' : '180px' }}
                    >
                      <div className='select-body'>
                        <img src={calendar_ico} />
                        <div className='select-body-time-frame'>
                          <div className='dates'>
                            {`${timeFrameStartDate.format(
                              'MMM DD, YYYY'
                            )} - ${timeFrameEndDate.format('MMM DD, YYYY')}`}
                          </div>
                        </div>
                        <img src={caret_ico} />
                      </div>
                    </div>
                  )}
                </Popover>
                {mode === 'by_release' && (
                  <SimpleMessage
                    message='Please Select a single app and platform'
                    messageId='hideByReleaseMsg'
                    showOneTimeMessage
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='i-selected-filters'>
        {selectedProducts.map(product => (
          <div className='i-select-option' key={product.id}>
            <img className='filter-icon' src={product.icon} />
            <img
              src={x_ico}
              className='close-icon'
              onClick={() => {
                if (mode === 'by_release') return

                handleSelectProducts(
                  selectedProducts
                    .filter(({ id }) => product.id !== id)
                    .map(({ id }) => id)
                )
              }}
            />
          </div>
        ))}
        {platforms.map(platform => (
          <div className='i-select-option' key={platform}>
            <span>{platform === 'ios' ? 'IOS' : platform}</span>
            <img
              src={x_ico}
              className='close-icon'
              onClick={() => {
                if (mode === 'by_release') return

                onPlatformSelect(platforms.filter(p => p !== platform))
              }}
            />
          </div>
        ))}
        {mode === 'by_release' && (
          <div className='total-releases'>Total Releases: {totalReleases}</div>
        )}
      </div> */}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  user: state.appData.user
})

export const IntelFilters = connect(mapStateToProps, null)(IntelFiltersBase)
