import './PersonaFilter.scss'

import { Radio, RadioChangeEvent } from 'antd'
import React from 'react'

import { Persona } from '../../../../utils/consts'

type Props = {
  options: Persona[]
  selectedOption: number
  onPersonaSelect: (personaId: number) => void
}

export const PersonaFilter = ({
  options,
  selectedOption,
  onPersonaSelect
}: Props) => {
  const handleOptionChange = (e: RadioChangeEvent) => {
    onPersonaSelect(e.target.value)
  }

  return (
    <div className='uj-persona-filter-container'>
      <h3 className='uj-persona-filter-heading'>Personas</h3>
      <Radio.Group
        className='uj-persona-filter-list'
        value={selectedOption}
        onChange={handleOptionChange}
      >
        {options.map(persona => (
          <Radio key={persona.id} value={persona.id}>
            <span
              className={`flag-icon fi fi-${
                persona.location.toLocaleLowerCase() || 'us'
              }`}
              style={{ marginRight: '1rem' }}
            />
            {persona.roles && persona.roles.length > 0
              ? persona.roles[0]
              : persona.title}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  )
}
