export const numberFormatter = (bytes, decimals = 2) => {
  let returnObj = {}
  if (bytes === 0) {
    returnObj = {
      number: 0,
      suffix: 'Bytes',
      string: '0 Bytes'
    }
    return returnObj
  }
  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  returnObj = {
    number: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    suffix: sizes[i],
    formattedNumberString: `${parseFloat(
      (bytes / Math.pow(k, i)).toFixed(dm)
    )} ${sizes[i] || ''}`,
    string: `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
      sizes[i] || ''
    }`,
    kbNumber: (bytes / k).toFixed(decimals)
  }
  return returnObj
}

export const cpuUnitsFormatter = (units, decimals = 2) => {
  let returnObj = {};

  if (units === 0 || units === null || units === undefined) {
    returnObj = {
      number: 0,
      suffix: 'Units',
      string: '0 Units',
      formattedNumberString: '0 Units'
    };
    return returnObj;
  }

  const k = 1000; // Base for suffixes
  const dm = decimals < 0 ? 0 : decimals; // Ensure non-negative decimals
  const sizes = ['', 'K', 'M', 'B', 'T']; // Suffixes for CPU units

  const i = Math.floor(Math.log(units) / Math.log(k)); // Determine the tier
  returnObj = {
    number: parseFloat((units / Math.pow(k, i)).toFixed(dm)),
    suffix: sizes[i] || '', // Ensure a suffix is available
    formattedNumberString: `${parseFloat((units / Math.pow(k, i)).toFixed(dm))} ${
      sizes[i] || ''
    } Units`,
    string: `${parseFloat((units / Math.pow(k, i)).toFixed(dm))} ${
      sizes[i] || ''
    } Units`,
    rawValue: units
  };

  return returnObj;
};

export const durationFormatter = (
  ms = 0,
  threshold = 10000,
  fractionDigits = 1
) => {
  let returnObj = {}
  let number = parseFloat(ms)
  let suffix = 'ms'
  if (ms >= threshold) {
    number = ms / 1000
    suffix = 's'
  }
  returnObj = {
    number: Number(number).toFixed(2),
    numberMS: Number(ms).toFixed(2),
    formattedNumber: Number(number)
      .toFixed(fractionDigits)
      .toLocaleString('en', { useGrouping: true }),
    formattedNumberString: `${Number(number).toFixed(1).toLocaleString('en', {
      useGrouping: true
    })}${suffix}`,
    suffix
  }
  return returnObj
}

export const ordinalSuffix = number => {
  let j = number % 10,
    k = number % 100
  if (j === 1 && k !== 11) {
    return 'st'
  }
  if (j === 2 && k !== 12) {
    return 'nd'
  }
  if (j === 3 && k !== 13) {
    return 'rd'
  }
  return 'th'
}

export const addCommas = intNum => {
  return (intNum + '').replace(/(\d)(?=(\d{3})+$)/g, '$1,')
}

export const diffInPercent = (a, b) => {
  return ((b - a) / a) * 100
}
