import config from '../shared/config'
import AWS from 'aws-sdk'
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser
} from 'amazon-cognito-identity-js'
import storageService from './storageService'

class awsService {
  constructor () {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID
    })
    this.userPool = userPool
    this.client = new AWS.CognitoIdentityServiceProvider({
      apiVersion: '2016-04-19',
      region: 'us-east-1'
    })
  }
  confirmPassword = (code, username, password) =>
    new Promise((resolve, reject) => {
      const params = {
        ClientId: this.userPool.getClientId(),
        ConfirmationCode: code,
        Password: password,
        Username: username
      }

      this.client.confirmForgotPassword(params, (err, data) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
    })
  forgotPassword = username =>
    new Promise((resolve, reject) => {
      const params = {
        ClientId: this.userPool.getClientId() /* required */,
        Username: username
      }
      this.client.forgotPassword(params, (err, data) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
    })

  getUsersList = callback => {
    const _userPoolId = config.cognito.USER_POOL_ID
    const CognitoIdentityServiceProvider = AWS.CognitoIdentityServiceProvider
    const client = new CognitoIdentityServiceProvider({
      apiVersion: '2016-04-19',
      region: 'us-east-1'
    })
    // First we'll get all of the users
    this.getAllUsers(_userPoolId, client).then(allUsersInPool => {
      // Now we need to fetch admin users
      this.getAdminUsers(_userPoolId, client).then(allAdminUsers => {
        // Now we'll enhance the allUsersInPool array
        // Now we need to fetch specific feature permissions for users
        this.getRawDataUsers(_userPoolId, client).then(allRawDataUsers => {
          // Now we'll enhance the allUsersInPool array
          let enhancedUserList = { Users: [] }
          Object.entries({
            ...allUsersInPool,
            ...allAdminUsers,
            ...allRawDataUsers
          }).forEach(userObject => {
            enhancedUserList.Users.push(userObject[1])
          })
          callback(enhancedUserList)
        })
      })
    })
  }

  getAllUsers = (userPoolId, cognitoClient) => {
    return new Promise(resolve => {
      cognitoClient.listUsers(
        { UserPoolId: userPoolId },
        (error, usersInPool) => {
          if (!error) {
            let usersInPoolMap = {}
            usersInPool.Users.forEach(user => {
              usersInPoolMap[user.Username] = user
            })
            resolve(usersInPoolMap)
          }
        }
      )
    })
  }

  getAdminUsers = (userPoolId, cognitoClient) => {
    return new Promise(resolve => {
      cognitoClient.listUsersInGroup(
        {
          UserPoolId: userPoolId,
          GroupName: 'Admin'
        },
        (error, adminUsers) => {
          if (!error) {
            let adminUsersMap = {}
            adminUsers.Users.forEach(adminUser => {
              adminUsersMap[adminUser.Username] = {
                ...adminUser,
                isAdmin: true
              }
            })
            resolve(adminUsersMap)
          }
        }
      )
    })
  }

  getRawDataUsers = (userPoolId, cognitoClient) => {
    return new Promise(resolve => {
      cognitoClient.listUsersInGroup(
        {
          UserPoolId: userPoolId,
          GroupName: 'Feature_RawData'
        },
        (error, rawDataUsers) => {
          if (!error) {
            let rawDataUsersMap = {}
            rawDataUsers.Users.forEach(rawDataUser => {
              rawDataUsersMap[rawDataUser.Username] = {
                ...rawDataUser,
                canViewRawData: true
              }
            })
            resolve(rawDataUsersMap)
          }
        }
      )
    })
  }

  parseJwt = token => {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  authUser = async () => {
    const currentUser = this.getCurrentUser()
    if (currentUser === null) {
      return false
    }
    return this.getUserToken(currentUser)
  }

  getUserToken = currentUser => {
    return new Promise((resolve, reject) => {
      currentUser.getSession(function (err, session) {
        if (err) {
          reject(err)
          return
        }
        resolve(session.getIdToken().getJwtToken())
      })
    })
  }

  login = (email, password) => {
    email = email.toLowerCase()
    const user = new CognitoUser({ Username: email, Pool: this.userPool })
    const authenticationData = { Username: email, Password: password }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    return new Promise((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: result => resolve(result.getIdToken().getJwtToken()),
        onFailure: err => reject(err),
        newPasswordRequired: userAttributes => {
          delete userAttributes.email_verified
          // TODO: Ask user for a new password
          user.completeNewPasswordChallenge(password, userAttributes, {
            onSuccess: result => resolve(result.getIdToken().getJwtToken()),
            onFailure: err => reject(err)
          })
        }
      })
    )
  }

  getCurrentUser = () => {
    return this.userPool.getCurrentUser()
  }

  signOutUser = () => {
    const currentUser = this.getCurrentUser()
    if (currentUser !== null) {
      currentUser.signOut()
      const storage = storageService()
      storage.clear()
    }
  }

  setCredentials = (token, onSuccess) => {
    AWS.config.region = config.awsRegion
    let logins = {}
    logins[
      'cognito-idp.' +
        config.awsRegion +
        '.amazonaws.com/' +
        config.cognito.USER_POOL_ID
    ] = token
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.cognito.IDENTITY_POOL_ID, // your identity pool id here
      Logins: logins
    })

    AWS.config.credentials.clearCachedId()
    AWS.config.credentials.refresh(error => {
      if (error) {
        console.error(error)
      } else {
        if (onSuccess) {
          onSuccess()
        }
      }
    })
  }
}

export default awsService
