// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uj-filters {
  display: flex;
  align-items: end;
  gap: 8px;
}
.uj-filters .uj-select-filter {
  cursor: pointer;
  padding: 8px 16px !important;
  border: 1px solid #d3d3d3;
  border-radius: 0px;
  width: 180px;
  height: 58px;
  display: flex;
  gap: 4px;
  flex-direction: column;
}
.uj-filters .uj-select-filter .uj-select-title {
  color: rgba(0, 0, 0, 0.5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.uj-filters .uj-select-filter .uj-select-cur-app {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.uj-filters .uj-select-filter .uj-select-caret {
  align-self: flex-end;
  position: relative;
  top: -30px;
  cursor: pointer;
}
.uj-filters .uj-select-filter:hover {
  background-color: white !important;
}
.uj-filters .uj-personas {
  width: 24rem !important;
}`, "",{"version":3,"sources":["webpack://./src/containers/UserJourneys/components/UserJourneyFilters/UserJourneyFilters.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,QAAA;AACF;AACE;EACE,eAAA;EACA,4BAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,QAAA;EACA,sBAAA;AACJ;AACI;EACE,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACN;AAEI;EACE,WAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAN;AAGI;EACE,oBAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;AADN;AAII;EACE,kCAAA;AAFN;AAME;EACE,uBAAA;AAJJ","sourcesContent":[".uj-filters {\n  display: flex;\n  align-items: end;\n  gap: 8px;\n\n  .uj-select-filter {\n    cursor: pointer;\n    padding: 8px 16px !important;\n    border: 1px solid #d3d3d3;\n    border-radius: 0px;\n    width: 180px;\n    height: 58px;\n    display: flex;\n    gap: 4px;\n    flex-direction: column;\n\n    .uj-select-title {\n      color: rgba(0, 0, 0, 0.5);\n      font-family: Roboto;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: normal;\n    }\n\n    .uj-select-cur-app {\n      color: #000;\n      font-family: Roboto;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: normal;\n    }\n\n    .uj-select-caret {\n      align-self: flex-end;\n      position: relative;\n      top: -30px;\n      cursor: pointer;\n    }\n\n    &:hover {\n      background-color: white !important;\n    }\n  }\n\n  .uj-personas {\n    width: 24rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
