import './MarketingOverviewPage.scss'

import { Empty } from 'antd'
import NewLoader from 'common/NewLoader/NewLoader'
import RoundedContainer from 'components/RoundedContainer'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import {
  CampaignCalendar,
  CampaignPerUserType,
  MarketingAggressiveness,
  MarketingCampaignChannelsBreakdown,
  MassVsPersonalizedCampaigns,
  ShareOfVoice
} from './components/charts'
import PromoDays from './components/charts/PromoDays'
import PromoTimeOfDay from './components/charts/PromoTimeOfDay'
import PromotionsValidity from './components/charts/PromotionsValidity'
import StatsBoxes from './components/charts/StatsBoxes'
import { HeadingWithTooltip } from './components/HeadingWithTooltip'
import { MarketingOverviewFilters } from './components/MarketingOverviewFilters'
import { ViewType } from './components/MarketingOverviewFilters/MarketingOverviewFilters'
import { useIntersection } from './hooks/useIntersection'
import { useOverviewApps, useOverviewData } from './hooks/useOverviewData'
import {
  MarketingOverviewFiltersParams,
  useOverviewFilters
} from './hooks/useOverviewFilters'
import {
  ChartNames,
  eventsData,
  trackMarketingOverviewEvent
} from './utils/analytics'
import { ChartValuesToggle } from './utils/types'

const stats = [
  {
    title: 'Above Group Average',
    body: '[Comp 3] performed <b>15% above</b> the group average for the selected timeframe'
  },
  {
    title: 'Market Share',
    body: '[Comp 4] holds <b>35%</b> of the market share in promotions for the selected timeframe'
  },
  {
    title: 'Activity Increase',
    body: '[Comp1] activity <b>increased by 20%</b> from Similar Past Periods'
  }
]

export const MarketingOverviewPage = () => {
  const [viewType, setViewType] = useState<ViewType>(ViewType.Outreach)
  const { apps } = useOverviewApps()

  const {
    appIds,
    handleUpdateFilters,
    channelIds,
    customRange,
    customRangeTime,
    daysAgo,
    campaignChannelBreakdownChartValues,
    perUserTypeChartValues,
    campaignCalendarShowLines,
    selectedUserTypes,
    selectedUserRoleTypes
  } = useOverviewFilters(apps)

  const onChartValuesToggle =
    (chart: MarketingOverviewFiltersParams) => (val: ChartValuesToggle) => {
      handleUpdateFilters({ [chart]: val })
      trackMarketingOverviewEvent('chart_view_changed', {
        chart_name:
          chart ===
          MarketingOverviewFiltersParams.campaign_channel_breakdown_chart_values
            ? 'Campaign Channels Breakdown'
            : 'Promotions per User Type',
        view_name: val
      })
    }

  const {
    appsWithInsightsCounts,
    appsByInsightType,
    selectedChannels,
    appsByDateAndType,
    focusAreas,
    appsByFocusArea,
    personas,
    appsByPersonas,
    customerApp,
    isLoading,
    promoDaysOfWeek,
    promoTimeOfDay,
    userTypes,
    userRoleTypes
  } = useOverviewData({
    handleUpdateFilters,
    filters: {
      appIds,
      channelIds,
      customRange,
      customRangeTime,
      daysAgo,
      personaIds: selectedUserTypes,
      personaRoleIds: selectedUserRoleTypes
    }
  })

  // Analytics

  useEffect(() => {
    trackMarketingOverviewEvent('overview_enter', null)
    eventsData.set('overview_enter', new Date())

    return () => {
      const startDate = eventsData.get('overview_enter')
      const endDate = new Date()
      const secondsSpent = (endDate.getTime() - startDate.getTime()) / 1000

      trackMarketingOverviewEvent('overview_exit', {
        seconds_spent: secondsSpent
      })
    }
  }, [])

  const chartViewed = (chartName: ChartNames) => () => {
    trackMarketingOverviewEvent('overview_chart_viewed', {
      chart_name: chartName
    })
  }

  const { intersectionRef: campaignVolumeRef } = useIntersection({
    onIntersect: chartViewed('Promotions Volume')
  })

  const { intersectionRef: campaignChannelsBreakdownRef } = useIntersection({
    onIntersect: chartViewed('Campaign Channels Breakdown')
  })

  const { intersectionRef: campaignCalendarRef } = useIntersection({
    onIntersect: chartViewed('Promotions Calendar')
  })

  const { intersectionRef: shareOfVoiceRef } = useIntersection({
    onIntersect: chartViewed('Promo Type')
  })

  const { intersectionRef: campaignPerUserTypeRef } = useIntersection({
    onIntersect: chartViewed('Promotions per User Type')
  })

  const chartClicked = (chartName: ChartNames) => () => {
    trackMarketingOverviewEvent('overview_chart_clicked', {
      chart_name: chartName
    })
  }

  const isNoData =
    appsWithInsightsCounts.length === 0 &&
    appsByInsightType.length === 0 &&
    appsByDateAndType.length === 0 &&
    appsByFocusArea.length === 0 &&
    appsByPersonas.length === 0

  const timeFrameStartDate = customRange
    ? dayjs(customRangeTime[0].toDate())
    : dayjs().subtract(daysAgo, 'day')

  const timeFrameEndDate = customRange
    ? dayjs(customRangeTime[1].toDate())
    : dayjs()

  return (
    <div className='mo-container'>
      <MarketingOverviewFilters
        customerApp={customerApp}
        channelIds={channelIds}
        customRangeTime={[timeFrameStartDate, timeFrameEndDate]}
        handleUpdateFilters={handleUpdateFilters}
        apps={apps}
        selectedAppIds={appIds}
        viewType={viewType}
        viewTypeChanged={setViewType}
        userTypes={userTypes}
        userRoleTypes={userRoleTypes}
        selectedUserTypes={selectedUserTypes}
        selectedUserRoleTypes={selectedUserRoleTypes}
      />
      {isLoading ? (
        <div className='loading-container'>
          <NewLoader />
        </div>
      ) : isNoData ? (
        <div className='no-data-content'>
          <Empty />
        </div>
      ) : (
        <div className='mo-content'>
          <RoundedContainer>
            <div
              className='mo-section'
              ref={campaignVolumeRef}
              onClick={chartClicked('Promotions Volume')}
            >
              <MarketingCampaignChannelsBreakdown
                selectedChannels={selectedChannels}
                apps={appsByInsightType}
                numberOfDays={timeFrameEndDate.diff(timeFrameStartDate, 'day')}
              />
            </div>
          </RoundedContainer>
          {/* <RoundedContainer>
            <div
              className='mo-section'
              ref={campaignVolumeRef}
              onClick={chartClicked('Promotions Volume')}
            >
              <StatsBoxes stats={stats} />
            </div>
          </RoundedContainer> */}
          {viewType === ViewType.Outreach && appsByDateAndType.length > 0 && (
            <RoundedContainer>
              <div
                className='mo-section'
                ref={campaignCalendarRef}
                onClick={chartClicked('Promotions Calendar')}
              >
                <CampaignCalendar
                  customRangeTime={[timeFrameStartDate, timeFrameEndDate]}
                  appsByDate={appsByDateAndType}
                  showLines={campaignCalendarShowLines}
                />
              </div>
            </RoundedContainer>
          )}
          {viewType === ViewType.Outreach && (
            <div className='two-charts'>
              <RoundedContainer>
                <div
                  className='mo-section'
                  ref={campaignCalendarRef}
                  onClick={chartClicked('Promo Days Of Week')}
                >
                  <PromoDays promoDaysValues={promoDaysOfWeek} />
                </div>
              </RoundedContainer>
              <RoundedContainer>
                <div
                  className='mo-section'
                  ref={campaignCalendarRef}
                  onClick={chartClicked('Promo Days Of Week')}
                >
                  <PromoTimeOfDay promoTimeOfDayValues={promoTimeOfDay} />
                </div>
              </RoundedContainer>
            </div>
          )}
          {appsByFocusArea.length > 0 && (
            <RoundedContainer>
              <div
                className='mo-section'
                ref={shareOfVoiceRef}
                onClick={chartClicked('Promo Type')}
              >
                <HeadingWithTooltip
                  title='Promo Type'
                  subtitle='Dissecting Promotions offers aggregate by selected competitor & selected timeframe'
                />
                <ShareOfVoice
                  appInsightsByFocusAreas={appsByFocusArea}
                  focusAreas={focusAreas}
                />
              </div>
            </RoundedContainer>
          )}
          {/* <RoundedContainer>
            <div
              className='mo-section'
              ref={campaignCalendarRef}
              onClick={chartClicked('Promotions Validity Term')}
            >
              <PromotionsValidity />
            </div>
          </RoundedContainer> */}
          <RoundedContainer>
            <div
              className='mo-section'
              ref={campaignPerUserTypeRef}
              onClick={chartClicked('Promotions per User Type')}
            >
              <HeadingWithTooltip
                showToggle
                currentToggle={perUserTypeChartValues}
                onToggle={onChartValuesToggle(
                  MarketingOverviewFiltersParams.per_user_type_chart_values
                )}
                title='Promotions per User Type'
                subtitle='Segmentation Strategy: Tailoring Promotions to Different User Profiles'
              />
              <CampaignPerUserType
                appsByPersonas={appsByPersonas}
                userTypes={personas}
                chartValues={perUserTypeChartValues}
              />
            </div>
          </RoundedContainer>
        </div>
      )}
    </div>
  )
}

export default MarketingOverviewPage
