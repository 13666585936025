import './FocusAreaFilter.scss'

import { Checkbox, Input } from 'antd'
import x_ico from 'assets/images/icons/x-no-outline.svg'
import { CloseShowResults } from 'components/CloseShowResults'
import { isMobileDevice } from 'containers/MarketingOverview/utils/helpers'
import React, { useMemo, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { FocusArea } from "../../containers/MarketingCampaings/utils/types";

type Props = {
  focusAreas: FocusArea[]
  currentFocusAreaIds: number[]
  onSelect: (ids: number[]) => void
  closeDrawer?: () => void
}

export const FocusAreaFilter = ({
  focusAreas,
  currentFocusAreaIds,
  onSelect,
  closeDrawer
}: Props) => {
  const isMobile = isMobileDevice()
  const [searchApp, setSearchApp] = useState('')

  const filteredFocusAreas = useMemo(
    () =>
      focusAreas
        .filter(app => app.name.toLowerCase().includes(searchApp.toLowerCase()))
        .map(app => ({
          ...app,
          isSelected: currentFocusAreaIds.includes(app.id)
        })),
    [focusAreas, searchApp, currentFocusAreaIds]
  )

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchApp(e.target.value)
  }

  const handleAppSelection = (selectedFocusArea: FocusArea) => {
    const isAlreadyChecked = currentFocusAreaIds.some(
      appId => appId === selectedFocusArea.id
    )
    if (isAlreadyChecked) {
      onSelect(currentFocusAreaIds.filter(appId => appId !== selectedFocusArea.id))
    } else {
      onSelect(currentFocusAreaIds.concat(selectedFocusArea.id))
    }
  }

  return (
    <div className='focus-area-filter-container'>
      <div className='focus-area-filter-top-section'>
        {isMobile && (
          <div className='focus-area-filter-close' onClick={closeDrawer}>
            <img src={x_ico} />
          </div>
        )}
        <div className='fas-drawer-title'>
          <div className='fas-drawer-title-main'>
            Promo Types ({currentFocusAreaIds.length})
          </div>
        </div>
        <div className='fas-drawer-selected-count'>
          {`${currentFocusAreaIds.length} Selected`}
        </div>
        <div className='fas-search filters-search'>
          <Input
            size='large'
            placeholder='Search For Promo Type'
            suffix={<SearchOutlined />}
            onChange={onSearchChange}
            value={searchApp}
          />
        </div>
      </div>
      <div
        className='fas-selection'
        style={isMobile ? { paddingBottom: '100px' } : {}}
      >
        {filteredFocusAreas.map(focusArea => (
          <div
            key={focusArea.id}
            className={`fa-selection-item filter-checkbox ${
              focusArea.isSelected ? 'filter-checked' : ''
            }`}
            onClick={() => {
              handleAppSelection(focusArea)
            }}
          >
            <div className='fa-selection-item-left'>
              <Checkbox checked={focusArea.isSelected} value={focusArea.id} />
              <div className='fa-name'>{focusArea.name}</div>
            </div>
          </div>
        ))}
      </div>
      {isMobile && closeDrawer && (
        <div className='fas-close-mobile'>
          <CloseShowResults onClose={closeDrawer} />
        </div>
      )}
    </div>
  )
}
