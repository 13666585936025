import { Release } from "pages/Intel/utils/types";
import React from "react";
import "./styles.scss";
import { ReleaseCard } from "./release-card";
import { HandleIntelFilterChangeType } from "pages/Intel/hooks/useIntelFilters";
import { ReleaseTimeline } from "./release-timeline";
import { Spin } from "antd";

type Props = {
  releases: Release[];
  releaseId: string;
  handleChangeFilter: HandleIntelFilterChangeType;
};

export const ReleasesList = ({
  releases,
  releaseId,
  handleChangeFilter,
}: Props) => {
  const startRelease = releases[0] || null;
  const endRelease = releases[releases.length - 1] || null;

  return (
    <Spin spinning={!releases.length}>
      <div className="i-releases-container">
        <div className="i-releases-list">
          {releases.map((release) => (
            <ReleaseCard
              selected={Number(releaseId) === release.id}
              key={release.id}
              release={release}
              onSelect={() => {
                handleChangeFilter("release_id", release.id.toString());
              }}
            />
          ))}
        </div>
        {startRelease && endRelease && (
          <ReleaseTimeline
            handleChangeFilter={handleChangeFilter}
            startVersion={startRelease.release_name}
            endVersion={endRelease.release_name}
            startVersionId={startRelease.id.toString()}
            endVersionId={endRelease.id.toString()}
            releases={releases}
            releaseId={releaseId}
          />
        )}
      </div>
    </Spin>
  );
};
