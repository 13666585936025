import './UserJourneyFilters.scss'

import { Popover } from 'antd'
import React from 'react'

import caret_ico from '../../../../assets/images/icons/caret-down.svg'
import AppDrawer from '../../../../common/appDrawer/AppDrawer'

type Props = {
  appsList: any[]
  currentApps: any[]
  appsChange: Function
  clearSelectedApps: Function
}

export const UserJourneyFilters = ({
  appsChange,
  appsList,
  currentApps,
  clearSelectedApps
}: Props) => {
  return (
    <div className='uj-filters'>
      <div className='uj-filter-apps'>
        <Popover
          placement='bottomLeft'
          title={null}
          destroyTooltipOnHide={true}
          content={
            <AppDrawer
              appsList={appsList}
              currentApps={currentApps}
              currentAppsChange={() => {}}
              isProduct={true}
              page='all-discoveries'
              appsChange={appsChange}
              clearCurrentApps={clearSelectedApps}
              // @ts-ignore
              className='button'
              maxAppsSelected={10}
            />
          }
          trigger='click'
          overlayClassName={`popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps`}
        >
          <div className='uj-select-filter'>
            <span className='uj-select-title'>App</span>
            <span className='uj-select-cur-app'>
              {currentApps.length === 0
                ? 'All'
                : `${currentApps.length} Apps Selected`}
            </span>
            <span className='uj-select-caret'>
              <img src={caret_ico} />
            </span>
          </div>
        </Popover>
      </div>
    </div>
  )
}
