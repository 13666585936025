import React, { createContext, ReactNode, useContext, useEffect } from 'react'

import { HandleFilterChangeType, useFilters } from '../hooks/useFilters'
import { useIsOpen } from '../hooks/useIsOpen'
import { useTimelineData } from '../hooks/useTimelineData'
import { App, Event, EventByDate, Insight, Persona } from './types'

type ContextType = {
  appsList?: App[]
  personasList?: Persona[]
  date?: string
  eventName?: string
  handleChangeFilter?: HandleFilterChangeType

  selectedApp?: App
  selectedPersona?: Persona
  eventsList?: Event[]
  eventsByDateList?: EventByDate[]
  insightsByCurDateList?: Insight[]
  selectedInsight?: any

  isSidebarOpen?: boolean
  openSidebar?: () => void
  closeSidebar?: () => void

  isLoading?: boolean
}

const ContextTimelineContext = createContext<ContextType>({})

type Props = {
  children: ReactNode
}

export const useContextTimeline = () => {
  return useContext(ContextTimelineContext)
}

export const ContextTimelineProvider = ({ children }: Props) => {
  const { appId, handleChangeFilter, personaId, date, eventName, insightId } =
    useFilters()

  const {
    timelineApps,
    timelinePersonas,
    timelineEvents,
    timelineEventsByDate,
    insightsByCurDate,
    selectedInsight,
    isLoading
  } = useTimelineData({
    appId,
    personaId,
    date,
    insightId
  })

  const {
    isOpen: isSidebarOpen,
    open: openSidebar,
    close: closeSidebar
  } = useIsOpen({ isOpenDefault: true })

  const appsList = timelineApps
  const personasList = timelinePersonas
  const eventsList = timelineEvents
  const eventsByDateList = timelineEventsByDate
  const insightsByCurDateList = insightsByCurDate

  useEffect(() => {
    if (!appId && appsList.length > 0) {
      handleChangeFilter('app_id', appsList[0].id.toString())
    }

    if (personasList.length > 0) {
      handleChangeFilter('persona_id', personasList[0].id.toString())
    }
  }, [appsList, personasList])

  useEffect(() => {
    if (appId && !personaId && personasList.length > 0) {
      handleChangeFilter('persona_id', personasList[0].id.toString())
    }
  }, [appId, personaId, personasList])

  const selectedApp = appsList.find(app => app.id === appId)
  const selectedPersona = personasList.find(persona => persona.id === personaId)

  const context: ContextType = {
    selectedApp,
    selectedPersona,
    date,
    eventName,
    handleChangeFilter,

    appsList,
    personasList,
    eventsList,
    eventsByDateList,
    insightsByCurDateList,

    selectedInsight,

    isSidebarOpen,
    openSidebar,
    closeSidebar,

    isLoading
  }

  return (
    <ContextTimelineContext.Provider value={context}>
      {children}
    </ContextTimelineContext.Provider>
  )
}
