import './ReportsView.scss'

import { Button, notification } from 'antd'
import { AppDispatch } from 'index'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import close_ico from '../../assets/images/new_design/icons/close-ico.svg'
import download_ico from '../../assets/images/new_design/icons/download-ico.svg'
import share_ico from '../../assets/images/new_design/icons/share-ico.svg'
import report_next_ico from '../../assets/images/new_design/report-next.svg'
import report_prev_ico from '../../assets/images/new_design/report-prev.svg'
import watchful_logo_white from '../../assets/images/new_design/wf-logo-red-white-2021.svg'
import ViewModal from '../../components/discoveryView/viewModal/ViewModal'
import { IState } from '../../interfaces/Product'
import { IReport } from '../../interfaces/Reports'
import { getRecipients } from '../../redux/actions/directLinkActions'
import {
  sendReportShare,
  setReportsNotificationsRead,
  setReportsNotificationsSeen
} from '../../redux/acts/reports'
import { NEWSLETTER_PLACE_HOLDER, REPORTS_PLACE_HOLDER } from './ReportsList'

export const ReportView: FC = (): ReactElement | null => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const { user, reports, recipients, analytics, sharingReport } = useSelector(
    (state: IState) => ({
      user: state.appData.user,
      reports: state.reports.reports,
      recipients: state.linkData.recipients,
      analytics: state.appData.user.analytics,
      sharingReport: state.reports.sharingReport
    })
  )

  const reportID = Number(params.id)
  const report = reports.find((r: IReport) => r.id === reportID)
  const reportIndex = reports.findIndex((r: IReport) => r.id === reportID)
  const [shareModalShow, shareModalShowChange] = useState(false)
  const [recipientForShare, recipientForShareChange] = useState('')
  const [commentForShare, commentForShareChange] = useState('')

  const url = new URLSearchParams(location.search)

  useEffect(() => {
    if (!recipients) {
      dispatch(getRecipients())
    }

    window.onmessage = (e: any) => {
      if (e.data.action == 'pdfLink') {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: e.data.url
        }).click()
      }
    }
  }, [])

  useEffect(() => {
    if (sharingReport) {
      notification['success']({
        message: 'Report has been shared!',
        duration: 2
      })
    }
  }, [sharingReport])

  const closeReportView = () => {
    //TEMP SOLUTION
    const from_url = url.get('from')
    navigate(from_url ? from_url : '/overview')
  }

  const prevReportHandler = () => {
    const newID = reports[reportIndex - 1].id
    navigate(`/reports/${newID}?source=reports_page`, { replace: true })
  }

  const nextReportHandler = () => {
    const newID = reports[reportIndex + 1].id
    navigate(`/reports/${newID}?source=reports_page`, { replace: true })
  }

  const shareHandler = () => {
    shareModalShowChange(true)
  }
  const sendShareMail = () => {
    dispatch(
      sendReportShare({
        report_id: reportID,
        to: [recipientForShare],
        // @ts-ignore
        comment:
          commentForShare || report?.is_newsletter
            ? NEWSLETTER_PLACE_HOLDER
            : REPORTS_PLACE_HOLDER
      })
    )
    shareModalShowChange(false)
    if (report) {
      analytics.analytic(
        `User shared report ${report.id} - ${report.name} with ${recipientForShare}: ${commentForShare}`
      )
    }
  }

  useEffect(() => {
    dispatch(setReportsNotificationsRead([reportID]))
    dispatch(setReportsNotificationsSeen([reportID]))
    if (report) {
      analytics.analytic('View report', {
        report_name: report.name,
        products: report.products,
        reportUrl: report.preview_url,
        source: url.get('source')
      })
    }
  }, [params.id])

  const handleDownloadReport = () => {
    if (report) {
      analytics.analytic('Download report', {
        name: report.name,
        url: report.url
      })
    }
  }

  if (!report) return null

  return (
    <div className='report-view-page'>
      <div className='report-view-header-wrapper'>
        <div className='report-view-header'>
          <div className='logo-white'>
            <img src={watchful_logo_white} />
          </div>
          <h1>{report.name}</h1>
          <div className='report-view-header-buttons'>
            <div className='report-share'>
              <Button onClick={shareHandler}>
                <img src={share_ico} />
                Share
              </Button>
            </div>
            <a
              href={report.url}
              className='report-download'
              download
              onClick={handleDownloadReport}
            >
              <Button>
                <img src={download_ico} />
                Download
              </Button>
            </a>
            <div
              className='report-view-header-buttons-close'
              onClick={closeReportView}
            >
              <img src={close_ico} />
            </div>
          </div>
          <Button
            className={`report-btn report-prev-btn ${
              reportIndex === 0 ? 'disabled' : ''
            }`}
            disabled={reportIndex === 0}
            onClick={prevReportHandler}
          >
            <img src={report_prev_ico} />
            <span
              className={`arrow ${reportIndex === 0 ? 'opaque-arrow' : ''}`}
            >
              Previous Report
            </span>
          </Button>
          <Button
            className={`report-btn report-next-btn ${
              reportIndex === reports.length - 1 ? 'disabled' : ''
            }`}
            disabled={reportIndex === reports.length - 1}
            onClick={nextReportHandler}
          >
            <span
              className={`arrow ${
                reportIndex === reports.length - 1 ? 'opaque-arrow' : ''
              }`}
            >
              Next Report
            </span>
            <img src={report_next_ico} />
          </Button>
        </div>
      </div>
      <div className='shadow' onClick={closeReportView} />
      <div className='report-pdf-wrapper'>
        <iframe
          src={`/renderPDF.html?file=${report.preview_url}`}
          width={
            window.innerWidth > 850
              ? '800px'
              : window.innerWidth > 520
              ? `${window.innerWidth - 50}px`
              : `100%`
          }
          height='100%'
          style={{ border: 'none' }}
        />
      </div>

      <ViewModal
        closeModal={() => shareModalShowChange(false)}
        sendEmail={sendShareMail}
        modalShow={shareModalShow}
        linkUrl={`${window.location.origin}/reports/${report.id}?source=reports_shared`}
        recipients={recipients}
        recipient={recipientForShare}
        recipientChange={recipientForShareChange}
        comment={commentForShare}
        commentChange={commentForShareChange}
        subject='report'
        whoCanView='Logged in users, will be able to view the report'
        placeHolder={
          report.is_newsletter ? NEWSLETTER_PLACE_HOLDER : REPORTS_PLACE_HOLDER
        }
        linkCode={''}
        user={user}
        updateLinkData={() => {}}
      />
    </div>
  )
}

export default ReportView
