import './PromoTimeOfDay.scss'

import HeatMapGrid from 'components/charts/HeatMapGrid'
import { App } from 'containers/MarketingOverview/utils/types'
import { zip } from 'lodash'
import React from 'react'

import { HeadingWithTooltip } from '../../HeadingWithTooltip'

export interface PromoTimeOfDayValue {
  app: App
  counts: number[]
}

export interface PromoTimeOfDayProps {
  promoTimeOfDayValues: PromoTimeOfDayValue[]
}

export const PromoTimeOfDay: React.FC<PromoTimeOfDayProps> = ({
  promoTimeOfDayValues
}) => {
  const xLabels = promoTimeOfDayValues.map((value: PromoTimeOfDayValue) => (
    <img className='app-icon-label' key={value.app.id} src={value.app.icon} />
  ))

  const yLabels = [
    'Early Morning\n04:00 - 08:00',
    'Morning\n08:00 - 12:00',
    'Noon\n12:00 - 16:00',
    'After Noon\n16:00 - 20:00',
    'Evening\n20:00 - 00:00',
    'Night\n00:00 - 04:00'
  ]

  const data = zip(
    ...promoTimeOfDayValues.map((value: PromoTimeOfDayValue) => value.counts)
  ) as number[][]

  return (
    <div className='promo-days-of-week'>
      <HeadingWithTooltip
        title='Promo Time Of Day'
        subtitle='Timing Analyze AVG Activity by Hour and Day'
      />

      <div className='promo-days-chart-container'>
        <HeatMapGrid
          rgbColor='42, 93, 227'
          data={data}
          xLabels={xLabels}
          yLabels={yLabels}
        />
      </div>
    </div>
  )
}

export default PromoTimeOfDay
