import './WDonutChart.scss'

import { merge } from 'lodash'
import React, { ReactElement } from 'react'

import { PieChart } from '@carbon/charts-react'

import { IDataPoint } from '../../../interfaces/Product'
import { setOfColors } from '../commonSettings'

export interface Iimage {
  icon: string | undefined
  text: string
}

interface IProps {
  data: IDataPoint[]
  options?: any
  image?: Iimage
}

const WDonutChart = ({ data, options = {}, image }: IProps): ReactElement => {
  const defOptions = {
    color: {
      scale: {}
    },
    //height: '36rem',
    width: '29rem',
    resizable: true,
    legend: {
      alignment: 'center',
      truncation: {
        numCharacter: 25
      }
    },
    pie: {
      aligment: 'center',
      labels: {
        //@ts-ignore
        formatter: d => {
          return d.value > 1 ? d.value : ''
        }
      }
    },
    tooltip: {
      valueFormatter: (val: number, label: string) => {
        return label.slice(0, label.indexOf(' x ')).toLowerCase()
      },
      truncation: {
        numCharacter: 25
      }
    }
  }

  const fullOptions = merge(defOptions, options)

  //@ts-ignore
  const setOfGroup = [
    ...new Set(
      data.sort((a1, a2) => (a1.value > a2.value ? -1 : 1)).map(d => d.group)
    )
  ]

  setOfGroup.forEach((g, i) => {
    //@ts-ignore
    fullOptions.color.scale[g] = setOfColors[i]
  })

  return (
    <div className='donut-chart-wrapper'>
      <PieChart
        data={data}
        //@ts-ignore
        options={fullOptions}
      />
      <div className='donut-center-wrap'>
        <div className='donut-center'>
          <div className='donut-center-app'>
            {/* @ts-ignore */}
            <img src={image.icon} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WDonutChart
