// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ma-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.ma-cards-container .ma-app-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #e3e4e3;
  background: #fdfdff;
  padding: 16px 8px;
  flex: 1 1;
  min-width: 150px;
}
.ma-cards-container .ma-app-card.customer-app {
  border: 1px solid #728ad8;
  background: #f6f6f6;
}
.ma-cards-container .ma-app-card .ma-app-card-image {
  width: 48px;
  height: 48px;
}
.ma-cards-container .ma-app-card .ma-app-card-name {
  color: #858585;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}
.ma-cards-container .ma-app-card .ma-app-card-count {
  color: #202020;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/containers/MarketingOverview/components/charts/MarketingAggressiveness/MarketingAggressiveness.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,SAAA;EACA,gBAAA;AACJ;AACI;EACE,yBAAA;EACA,mBAAA;AACN;AAEI;EACE,WAAA;EACA,YAAA;AAAN;AAGI;EACE,cAAA;EACA,6CAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AADN;AAII;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,0BAAA;AAFN","sourcesContent":[".ma-cards-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 1rem;\n\n  .ma-app-card {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    border-radius: 8px;\n    border: 1px solid #e3e4e3;\n    background: #fdfdff;\n    padding: 16px 8px;\n    flex: 1;\n    min-width: 150px;\n\n    &.customer-app {\n      border: 1px solid #728ad8;\n      background: #f6f6f6;\n    }\n\n    .ma-app-card-image {\n      width: 48px;\n      height: 48px;\n    }\n\n    .ma-app-card-name {\n      color: #858585;\n      font-feature-settings: \"clig\" off, \"liga\" off;\n      font-family: Inter;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n      text-align: center;\n    }\n\n    .ma-app-card-count {\n      color: #202020;\n      font-family: Inter;\n      font-size: 2rem;\n      font-style: normal;\n      font-weight: 600;\n      text-transform: capitalize;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
