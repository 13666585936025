import './styles.scss'

import React from 'react'

type Props = {
  message: string
  messageId?: string
  showOneTimeMessage?: boolean
}

export const SimpleMessage = ({
  message,
  messageId,
  showOneTimeMessage
}: Props) => {
  const [show, setShow] = React.useState(
    showOneTimeMessage
      ? !localStorage.getItem(messageId || 'hideMessage')
      : true
  )

  const onGotItClick = () => {
    setShow(false)

    if (showOneTimeMessage) {
      localStorage.setItem(messageId || 'hideMessage', 'true')
    }
  }

  return show ? (
    <div className='s-message'>
      <span>{message}</span>
      <div className='got-it-btn' onClick={onGotItClick}>
        Got it
      </div>
    </div>
  ) : null
}
