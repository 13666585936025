import React, { useEffect, useState } from 'react'
import config from '../../shared/config'
import { connect } from 'react-redux'
import './styles.scss'
import analyticsService from 'services/analyticsService'
import { useParams } from 'react-router'

const AcceptingInvite = () => {
  const params = useParams()
  const [error, errorChange] = useState('')

  useEffect(() => {
    fetch(
      `${config.dataApi.backendUrl}/user/invitations/${params.invitation_id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ state: 'accepted' })
      }
    ).then(res => {
      res.json().then(data => {
        if (data.status === 'error') {
          if (
            data.msg ===
            'Invitation has already been accepted. Please try to login.'
          ) {
            window.location = `/`
          } else {
            errorChange(data.msg)
          }
        } else if (data.status === 'ok') {
          let user = data.data
          const analytics = analyticsService.getInstance()
          analytics.analytic('Accepted invite', {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            source: 'Invite'
          })
          setTimeout(() => {
            window.location = `/changePWD/${user.email}/${user.nonce}`
          }, 200)
        }
      })
    })
  }, [])

  return (
    <div className='invite-page'>
      <div className='invite-error'>
        <div className='logo_wrapper'>
          <a href='/'>
            <img src='/images/new_logo.svg' />
          </a>
        </div>
        {error && <div className='title-h1'>OOPS...</div>}
        <div className='title-h3'>{error}</div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ appData }) => ({
  analytics: appData.user.analytics
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptingInvite)
