// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-type-popup {
  width: 470px;
  max-height: 370px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.user-type-popup .items-selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-type-popup .items-selected .items-selected-count {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.user-type-popup .filters-content {
  display: flex;
  gap: 10px;
}
.user-type-popup .filters-content .filters-content-checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.user-type-popup .user-type-popup-checkbox-group-item {
  border-radius: 8px;
  padding: 8px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-width: 170px;
}
.user-type-popup .user-type-popup-checkbox-group-item .ant-checkbox + span {
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 100%;
  justify-content: space-between;
}
.user-type-popup .user-type-popup-checkbox-group-item .ant-checkbox + span .user-type-popup-checkbox-group-tem-label-text {
  padding-right: 10px;
}
.user-type-popup .user-type-popup-checkbox-group-item .ant-checkbox + span .user-type-popup-checkbox-group-tem-label-icon-container {
  display: inline-flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/ContextTimeline/components/user-type-filter/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AACI;EACE,WAAA;EAEA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAAN;AAIE;EACE,aAAA;EACA,SAAA;AAFJ;AAII;EACE,aAAA;EACA,eAAA;EACA,SAAA;AAFN;AAME;EACE,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAJJ;AAMI;EACE,aAAA;EACA,iBAAA;EACA,gBAAA;EACA,8BAAA;AAJN;AAMM;EACE,mBAAA;AAJR;AAOM;EACE,oBAAA;EACA,uBAAA;AALR","sourcesContent":[".user-type-popup {\n  width: 470px;\n  max-height: 370px;\n  overflow-y: scroll;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n\n  .items-selected {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    .items-selected-count {\n      color: #000;\n\n      font-family: Roboto;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 500;\n    }\n  }\n\n  .filters-content {\n    display: flex;\n    gap: 10px;\n\n    .filters-content-checkbox-wrapper {\n      display: flex;\n      flex-wrap: wrap;\n      gap: 16px;\n    }\n  }\n\n  .user-type-popup-checkbox-group-item {\n    border-radius: 8px;\n    padding: 8px;\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    min-width: 170px;\n\n    .ant-checkbox + span {\n      display: flex;\n      flex-wrap: nowrap;\n      flex-basis: 100%;\n      justify-content: space-between;\n\n      .user-type-popup-checkbox-group-tem-label-text {\n        padding-right: 10px;\n      }\n\n      .user-type-popup-checkbox-group-tem-label-icon-container {\n        display: inline-flex;\n        justify-content: center;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
