import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { CAMPAIGNS_FILTERS, ITEMS_PER_PAGE } from '../utils/conts'
import { App, UserRoleType, UserType } from '../utils/types'

export type ChangeCampaignsFilterType = (
  filter: string,
  newValue: string | string[]
) => void

type Props = {
  apps: App[]
  userTypes: UserType[]
  userRoleTypes: UserRoleType[]
}

export const useCampaignsFilters = ({
  apps,
  userTypes,
  userRoleTypes
}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const selectedAppIds = params.getAll(CAMPAIGNS_FILTERS.app_ids) || ''
  const insightId = params.get(CAMPAIGNS_FILTERS.insight_id) || ''
  const selectedUserTypes = params.getAll(CAMPAIGNS_FILTERS.user_types) || ''
  const selectedUserRoleTypes =
    params.getAll(CAMPAIGNS_FILTERS.user_role_types) || ''
  const daysAgo = params.get(CAMPAIGNS_FILTERS.days_ago)
    ? parseInt(params.get(CAMPAIGNS_FILTERS.days_ago) || '30')
    : 30
  const channelIds = params.getAll(CAMPAIGNS_FILTERS.channels) || []
  const customRange = !!params.get(CAMPAIGNS_FILTERS.custom_range_time)?.length
  const selectedFocusAreas = params.getAll(CAMPAIGNS_FILTERS.focus_areas) || []

  const customRangeTimes = params.getAll(CAMPAIGNS_FILTERS.custom_range_time)
  const customRangeTime =
    customRangeTimes.length > 1
      ? [moment(customRangeTimes[0]), moment(customRangeTimes[1])]
      : [moment(), moment()]

  const currentApps = useMemo(
    () => apps.find(app => selectedAppIds.includes(app.id.toString())),
    [apps, selectedAppIds]
  )

  const handleChangeFilter: ChangeCampaignsFilterType = (
    filter: string,
    newValue: string | string[]
  ) => {
    if (Array.isArray(newValue)) {
      params.delete(filter)
      newValue.forEach(value => {
        params.append(filter, value)
      })
    } else {
      params.set(filter, newValue)
    }

    if (
      filter !== CAMPAIGNS_FILTERS.limit &&
      !params.get(CAMPAIGNS_FILTERS.limit)
    ) {
      params.set(CAMPAIGNS_FILTERS.offset, '0')
      params.set(CAMPAIGNS_FILTERS.limit, ITEMS_PER_PAGE.toString())
    }

    navigate(`${location.pathname}?${params.toString()}`, { replace: true })
  }

  useEffect(() => {
    if (!selectedAppIds?.length && apps.length) {
      handleChangeFilter(
        CAMPAIGNS_FILTERS.app_ids,
        apps.slice(0, 5).map(({ id }) => id.toString())
      )
    }

    if (
      userTypes &&
      selectedUserRoleTypes.length === 0 &&
      selectedUserTypes.length === 0
    ) {
      handleChangeFilter(
        CAMPAIGNS_FILTERS.user_types,
        userTypes
          .filter(type => type.roles == null || type.roles.length === 0)
          .map(type => type.id.toString())
      )
    }
  }, [apps, userTypes, userRoleTypes])

  return {
    currentApps,
    selectedAppIds,
    insightId,
    selectedUserTypes,
    selectedUserRoleTypes,
    daysAgo,
    channelIds,
    customRange,
    customRangeTime,
    handleChangeFilter,
    selectedFocusAreas
  }
}
